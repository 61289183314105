import { Field, Input, Label } from "@zendeskgarden/react-forms";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaPlus, FaUserTie } from "react-icons/fa6";
import { Col } from "styled-bootstrap-grid";
import Badge from "../Badge";
import ImageBox from "../ImageBox";
import SignatureForm from "./SignatureForm";
import AddressBox from "./AddressBox";
import styled from "styled-components";
import { opacify } from "polished";

const SAddressBox = styled(AddressBox)`
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 1.2em;
  &.edited-block {
    background: ${(props) => opacify(-0.7, props.theme.successColor)};
    &:hover {
      background: ${(props) => opacify(-0.6, props.theme.successColor)};
    }
    &.has-error {
      background: ${(props) => opacify(-0.7, props.theme.errorColor)};
      &:hover {
        background: ${(props) => opacify(-0.6, props.theme.errorColor)};
      }
    }
  }
  .no-img-icon {
    color: ${(props) => opacify(-0.5, props.theme.mediumLightBg)};
  }
  .img-wrap {
    margin-right: 15px;
    width: 142px;
    position: relative;
    cursor: pointer;
    &:after {
      content: "";
      border: 3px dashed rgba(255, 255, 255, 0.2);
      position: absolute;
      inset: 0;
      pointer-events: none;
      transition: border-color 0.2s;
    }
    &:hover:after {
      border-color: ${(props) => props.theme.accentColor};
    }
  }
  .text-wrap {
    width: 100%;
    padding-right: 15px;
  }
  .input-wrap {
    .name-input {
      font-size: 15px;
      font-weight: 700;
      color: ${({ theme }) => theme.headingColor};
      margin-bottom: -4px;
    }
    &.function-input-wrap {
      position: relative;
      .function-icon {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 3px;
        font-size: 16px;
        margin: auto 13px;
        color: ${({ theme }) => theme.iconColor};
      }
      .function-input {
        margin-top: -4px;
        padding-left: 35px;
      }
    }
    &:not(:focus) {
      > div,
      input:not(:focus) {
        background: transparent;
        border-color: transparent;
      }
    }
  }
  *::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
  .empty-field svg {
    opacity: 0.4;
  }
`;

export const SignatureItem = ({
  i,
  item,
  isError,
  dialogId,
  setDialogId,
  dirtyFields,
  values,
  mutate,
  setValue,
  formSubmit,
  watch,
  register,
}) => {
  const { t } = useTranslation("common");
  const isEdited = (i) => Object.keys(dirtyFields).indexOf(i + "") > -1;

  return (
    <Col key={`${item?.name}${i}`} xl={6}>
      <SAddressBox
        key={`${item?.name}${i}`}
        className={`hover-box ${isEdited(i) ? "edited-block" : ""} ${
          isError ? "has-error" : ""
        }`}
      >
        <div
          className="img-wrap"
          onClick={(e) => {
            e.stopPropagation();
            setDialogId(i);
          }}
        >
          <ImageBox
            params={{
              Width: 200,
              Height: 200,
            }}
            id={item.fileId}
            boxHeight="100%"
            className="hover-zoom"
            asBgImage={false}
            alt={item?.fileName}
            bgColor="#ffffff"
            icon={FaPlus}
          />
        </div>
        <Badge className="badge-absolute" round>
          {i + 1}
        </Badge>
        <div className="text-wrap">
          <Field className="input-wrap">
            <Label hidden htmlFor={`${i}.name`} />

            <Input
              {...register(`${i}.name`)}
              className="name-input"
              placeholder={t("ui.Name")}
            />
          </Field>
          <Field
            className={`input-wrap function-input-wrap ${
              watch(`${i}.function`) ? "" : "empty-field"
            }`}
            title={t("ui.Function")}
          >
            <Label hidden htmlFor={`${i}.function`} />
            <FaUserTie className="function-icon" />
            <Input
              {...register(`${i}.function`)}
              className="function-input"
              placeholder={t("ui.Function")}
            />
          </Field>
          <input type="hidden" {...register(`${i}.fileId`)} />
          <input type="hidden" {...register(`${i}.fileName`)} />
        </div>
      </SAddressBox>

      {dialogId === i ? (
        <SignatureForm
          signature={values[i]}
          dialogId={dialogId}
          setDialogId={setDialogId}
          setValue={setValue}
          watch={watch}
          formSubmit={formSubmit}
        />
      ) : null}
    </Col>
  );
};

export default SignatureItem;
