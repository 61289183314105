import {
  Close,
  Notification,
  useToast,
} from "@zendeskgarden/react-notifications";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import uploadFile from "../../utils/uploadFile";
import { useDropzone } from "react-dropzone";
import Button from "../Button";
import icons from "../../icons";
import { Field, FileUpload, Input } from "@zendeskgarden/react-forms";
import { Body, Footer, Header, Modal } from "@zendeskgarden/react-modals";
import { AiOutlineUpload } from "react-icons/ai";
import ImageBox from "../ImageBox";
import { BsArrow90DegUp } from "react-icons/bs";
import Badge from "../Badge";
import styled from "styled-components";
import LoaderOverlay from "../LoaderOverlay";

const SModal = styled(Modal)`
  max-width: 97%;
  max-height: 97%;
  margin: auto;
  left: 0;
  right: 0;
  .modal-header {
    padding-left: 20px;
  }
  .modal-body {
    padding-left: 21px;
    padding-right: 21px;
  }
  .logo-modal-footer {
    justify-content: space-between;
    .info-label {
      margin: -0.5em 0;
      padding-left: 2px;
      display: flex;
      align-items: center;
      width: 100%;
      .label-smaller {
        font-size: 12px;
        line-height: 1.35em;
      }
      .icon {
        display: block;
        height: 50px;
        width: 45px;
        padding-right: 8px;
        svg {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      h4 {
        font-weight: 600;
        margin-bottom: 0;
        line-height: 1.35;
      }
    }
    .btn-wrap {
      width: 100%;
      text-align: right;
      white-space: nowrap;
      a,
      button {
        margin-left: 6px;
      }
    }
    @media (max-width: 676px) {
      flex-wrap: wrap;
      .info-label {
        margin-bottom: 0.5em;
      }
    }
  }
  .delete-btn:not(:disabled) {
    &:hover {
      background: #9d5454;
    }
  }
`;

const SFileUploadField = styled(Field)`
  ul > li > div {
    display: flex;
    position: relative;
    flex-wrap: nowrap;
  }
  .filename {
    width: 100%;
  }
  .upload-progress {
    position: absolute;
    bottom: 0px;
    left: 0px;
    transition: opacity 0.2s ease-in-out 0s;
    margin: 0px;
    width: 100%;
    > div {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    &.complete {
      opacity: 0;
    }
  }
  .photo-wrap {
    background: ${(props) => props.theme.background};
    position: relative;
    width: 80px;
    height: 80px;
    max-width: 20%;
    margin-bottom: 1em;
    border: 1px solid ${(props) => props.theme.borderColor};
    float: left;
    @media (max-width: 991px) and (min-width: 576px) {
      width: 60px;
      height: 60px;
    }
  }
  .upload-box {
    border-width: 3px;
    border-color: #ddd;
    position: absolute;
    z-index: 1;
    inset: -2px;
    justify-content: end;
    align-items: start;
    &:hover {
      background: transparent;
    }
    .upload-box-hint {
      position: absolute;
      top: 100%;
      display: block;
      right: 0;
      font-size: 12px;
      opacity: 0.5;
      transition: opacity 0.2s;
    }
    &:hover {
      border-color: ${(props) => props.theme.accentColor};
      .upload-box-hint {
        opacity: 1;
      }
    }
    .arrow {
      font-size: 1.3em;
      line-height: 1;
      margin-right: 0.4em;
      color: ${(props) => props.theme.successColor};
    }
  }
  .btn-toolbar {
    position: absolute;
    font-size: 14px;
    right: 0.6em;
    top: 0.6em;
    z-index: 1;
    .btn {
      margin-left: 6px;
      height: 39px;
      width: 39px;
      border-radius: 4px;
      vertical-align: top;
    }
    .delete-btn {
      &:hover {
        background: #9d5454;
      }
    }
  }
`;

const SignatureForm = ({
  signature: item,
  dialogId: i,
  setDialogId,
  setValue,
  watch,
  formSubmit,
}) => {
  const { t } = useTranslation("common");
  const { addToast } = useToast();

  const fileId = watch(`${i}.fileId`);
  const fileName = watch(`${i}.fileId`);

  const [isBusy, setIsBusy] = useState(false);

  const acceptedFormats = [".png", ".jpg", ".jpeg", ".gif"];

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setIsBusy(true);
      uploadFile({
        format: file.name.split(".").pop(),
        fileName: file.name,
        fileCategory: "CustomerUpload",
        file: file,
        onSuccess: (data) => {
          setValue(`${i}.fileId`, data.fileId);
          setValue(`${i}.fileName`, file.name);
          setIsBusy(false);
          formSubmit();
        },
        onError: (error) => {
          removeFile();
          setIsBusy(false);
          addToast(
            ({ close }) => (
              <Notification
                title={t("error.Oops-Something-went-wrong")}
                text={t("error.File-upload-failed")}
                errorText={
                  !!error
                    ? error
                    : t("error.Sorry-an-unexpected-error-occurred")
                }
                type="error"
                handleClose={close}
              />
            ),
            { placement: "bottom" }
          );
        },
      });
    }
  };

  const removeFile = () => {
    setValue(`${i}.fileId`, "");
    setValue(`${i}.fileName`, "");
    formSubmit();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptedFormats,
    multiple: false,
    onDrop,
  });

  const onClose = () => {
    setDialogId(null);
  };

  return (
    <SModal
      element="form"
      isLarge
      onClose={onClose}
      appendToNode={document.body}
      aria-label="Edit signature"
    >
      <Header className="modal-header">
        <h3>
          <Badge
            round
            primaryLighter
            style={{
              display: "inline-block",
              verticalAlign: "text-bottom",
              marginRight: ".5em",
            }}
          >
            {i + 1}
          </Badge>{" "}
          {item?.name}
        </h3>
      </Header>
      <Body className="modal-body">
        <SFileUploadField>
          <div
            style={{
              position: "relative",
              marginBottom: "1em",
              marginTop: ".6em",
            }}
          >
            <FileUpload
              {...getRootProps()}
              isDragging={isDragActive}
              className="upload-box"
            >
              <span className="upload-box-hint">
                <BsArrow90DegUp className="arrow" />
                {isDragActive
                  ? t("my-account.Drop-files-here")
                  : t(
                      "my-account.Click-to-choose-a-file-or-drag-and-drop-here"
                    )}
              </span>
              <Input {...getInputProps()} id="fileUpload" />
            </FileUpload>

            <ImageBox
              id={fileId || null}
              alt={fileName}
              boxHeight="70%"
              params={{
                Width: 700,
                Height: 700,
                ResizeMode: "Contain",
              }}
              asBgImage={false}
              icon={AiOutlineUpload}
            />
            {isBusy ? (
              <LoaderOverlay
                style={{ background: "rgba(255, 255, 255, 0.9)" }}
              />
            ) : null}

            {!!item?.fileId ? (
              <div className="btn-toolbar">
                <Button
                  type="button"
                  onClick={() => removeFile()}
                  secondary
                  square
                  tabIndex={-1}
                  title={t("my-account.Delete")}
                  className="btn delete-btn"
                  disabled={isBusy}
                >
                  {icons.trash}
                </Button>
              </div>
            ) : null}
          </div>
        </SFileUploadField>
      </Body>
      <Footer className="modal-footer logo-modal-footer">
        <div className="info-label">
          <span className="icon icon-color">{icons.info}</span>
          <div>
            <h4>{t("my-account.Upload-a-new-image")}</h4>
            <div className="label-smaller">
              <span className="list-label">
                {t("my-account.Accepted-formats")}{" "}
              </span>
              {acceptedFormats
                .filter((ext) => ext !== ".jpeg")
                .map((item, index) => (
                  <span key={item} className="font-monospace">
                    {item}{" "}
                  </span>
                ))}
            </div>
          </div>
        </div>
      </Footer>
      <Close
        aria-label={t("my-account.Close")}
        onClick={onClose}
        style={{ margin: "15px" }}
      />
    </SModal>
  );
};

export default SignatureForm;
