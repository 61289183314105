import React, { useState } from "react";
import fetcher from "../utils/fetcher";
import apiConfig from "../apiConfig";

export const AppContext = React.createContext();

export const AppContextProvider = ({ children }) => {
  const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { phoenix } = apiConfig;

  const getCustomer = () => {
    const customerId = localStorage.getItem("customerId");
    setIsLoading(true);
    fetcher(`${phoenix}/customers/${customerId}`)
      .then((data) => {
        setCustomer({
          id: data?.id,
          name: data?.name,
          code: data?.code,
          language: data?.language,
          addresses: data?.addresses,
          // logoFileId: data?.logoFileId, // always null
        });
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <AppContext.Provider
      value={{
        customer,
        isLoading,
        setCustomer,
        getCustomer,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
