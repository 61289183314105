import styled, { css } from "styled-components";
import { lighten, opacify } from "polished";

const Badge = styled.span`
  border-radius: ${(props) => props.theme.borderRadius};
  font-size: 0.85em;
  background: ${(props) => props.theme.borderColor};
  padding: 0 0.4em;
  color: ${(props) => props.theme.textColor};
  font-weight: 500;
  white-space: nowrap;
  transition: background 0.2s, color 0.2s;
  svg {
    margin-bottom: -0.1em;
  }
  ${(props) =>
    props.state === "new" &&
    css`
      background: #ffd400;
    `}
  ${(props) =>
    props.state === "open" &&
    css`
      background: ${lighten(0.12, props.theme.successColor)};
    `}
    ${(props) =>
    props.state === "pending" &&
    css`
      background: #86dbef;
    `}
    ${(props) =>
    props.state === "closed" &&
    css`
      background: #ffb8b0;
    `};
  ${(props) =>
    props.primary &&
    css`
      background: ${(props) => props.theme.accentColor};
      color: white;
    `};
  ${(props) =>
    props.primaryLighter &&
    css`
      background: ${(props) => opacify(-0.7, props.theme.accentColor)};
      color: ${(props) => props.theme.textColor};
    `};
  ${(props) =>
    props.secondary &&
    css`
      background: ${props.theme.secondaryColor};
      color: white;
    `};
  ${(props) =>
    props.success &&
    css`
      background: ${props.theme.successColor};
      color: white;
    `};
  ${(props) =>
    props.background &&
    css`
      background: ${props.background};
    `};
  ${(props) =>
    props.round &&
    css`
      border-radius: 1em;
      display: inline-block;
      min-width: 1em;
      height: 1em;
      line-height: 1;
      padding: 0.2em;
      box-sizing: content-box;
      text-align: center;
      font-weight: 100;
      display: flex;
      align-items: center;
      justify-content: center;
    `};
`;

export default Badge;
