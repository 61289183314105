import React from "react";
import Link from "./Link";
import CycleIcon from "./CycleIcon";
import { Col } from "styled-bootstrap-grid";
import ImageBox from "./ImageBox";

const CycleListItem = ({ item }) => (
  <Col md={6} key={item.id}>
    <Link to={`/cycle/${item.id}`} className="icon-list-link no-pad hover-zoom">
      {!!item.thumbnailFileId ? (
        <div style={{ width: "50px", marginRight: "1em", minWidth: "50px" }}>
          <ImageBox
            params={{
              Width: 50,
              Height: 50,
            }}
            id={item.thumbnailFileId}
            boxHeight="100%"
            className="hover-zoom"
          />
        </div>
      ) : (
        <CycleIcon className="icon-list-icon" />
      )}
      <h4>{!!item.name ? item.name : "..."}</h4>
    </Link>
  </Col>
);

export default CycleListItem;
