export const getLabelParams = ({
  internalLabels = [],
  externalLabels = [],
  labelFilter = "includeLabelAndNoLabel", // show product / package items with one of the labels provided or no label
  // labelFilter = "includeLabel", // show product / package items with one of the labels provided
}) => {
  const intLObjs = internalLabels.map(({ name }) => ({
    name,
    type: "customerInternal",
  }));
  const extLObjs = externalLabels.map(({ name }) => ({
    name,
    type: "customerExternal",
  }));

  const labelsArr = [...intLObjs, ...extLObjs];

  const labelParams = Object.assign(
    {},
    ...labelsArr.map(({ name, type }, i) => ({
      [`labels[${i}].name`]: name,
      [`labels[${i}].type`]: type,
    }))
  );

  const labelFilterParam = labelsArr?.length
    ? {
        labelFilter: labelFilter,
      }
    : {
        labelFilter: "noLabel", // show product / package items with no label
      };

  const labelParamsWithFilter = {
    ...labelParams,
    ...labelFilterParam,
  };
  return labelParamsWithFilter;
};
