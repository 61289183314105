import axios from "axios";
import config from "../config";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const callContact = (id, method = "GET", data = {}, onSuccess, onError) => {
  const customerId = localStorage.getItem("customerId");
  axios({
    method: method,
    url: `${phoenix}/persons${!!id ? "/" + id : ""}`,
    data: data,
    withCredentials: true,
    headers: {
      pxclient: config.clientId,
      pxcustomer: customerId,
    },
  })
    .then((res) => {
      if (
        (method === "DELETE" && res.status === 200) ||
        (method === "PUT" && res.status === 200) ||
        (method === "POST" && !!res.data)
      ) {
        !!onSuccess && onSuccess(res?.data);
      } else {
        !!onError ? onError(res) : console.log("Request failed", res);
      }
    })
    .catch((error) => {
      if (error.response) {
        // Request made and server responded
        onError(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        onError(error.request);
      } else {
        // Something happened while setting up the request that triggered an Error
        onError(error.message);
      }
    });
};

export default callContact;
