import axios from "axios";
import config from "../config";
import apiConfig from "../apiConfig";

const { files } = apiConfig;

const uploadFile = ({
  format,
  fileName,
  fileCategory = "asset",
  file,
  onSuccess,
  onError,
}) => {
  const url = `${files}/Upload`;
  const customerId = localStorage.getItem("customerId");
  axios({
    method: "POST",
    url: url,
    data: {
      format: format,
      fileName: fileName,
      fileCategory: fileCategory,
    },
    withCredentials: true,
    headers: {
      pxclient: config.clientId,
      pxcustomer: customerId,
    },
  })
    .then((res) => {
      if (res.status === 200 && !!res.data.redirectResult) {
        axios({
          method: "PUT",
          url: res.data.redirectResult,
          data: file,
          headers: {
            "content-type": "application/octet-stream",
          },
        })
          .then((uploadRes) => {
            !!onSuccess && onSuccess(res?.data);
          })
          .catch((error) => {
            console.log("error", error);
            if (!!error.response) {
              onError(
                Object.values(error.response?.data?.errors)?.[0] ||
                  error.response?.data?.title ||
                  error?.message
              );
            } else if (!!error.request) {
              onError(error.request?.statusText);
            } else {
              onError(error?.message);
            }
          });
      } else {
        console.log("response", res);
        !!onError
          ? onError(res?.statusText)
          : console.log("Request failed", res);
      }
    })
    .catch((error) => {
      console.log("error", error);
      if (!!error.response) {
        onError(
          Object.values(error.response?.data?.errors)?.[0] ||
            error.response?.data?.title ||
            error?.message
        );
      } else if (!!error.request) {
        onError(error.request?.statusText);
      } else {
        onError(error?.message);
      }
    });
};

export default uploadFile;
