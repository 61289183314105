import axios from "axios";
import config from "../config";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const callSignatures = ({
  mpId,
  data,
  onSuccess = () => null,
  onError = () => console.log("Error updating email signature"),
}) => {
  const customerId = localStorage.getItem("customerId");
  const url = `${phoenix}/marketingprofiles/${mpId || customerId}/signatures`;
  axios({
    method: "PUT",
    url: url,
    data: data,
    withCredentials: true,
    headers: {
      pxclient: config.clientId,
      pxcustomer: customerId,
    },
  })
    .then((res) => {
      if (res.status === 200) {
        !!onSuccess && onSuccess(res?.data);
      } else {
        !!onError
          ? onError(res?.statusText)
          : console.log("Request failed", res);
      }
    })
    .catch((error) => {
      if (error.response) {
        onError(error?.response?.data?.title || "");
      } else if (error.request) {
        onError(error.request);
      } else {
        onError(error.message);
      }
    });
};

export default callSignatures;
