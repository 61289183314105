import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import icons from "../icons";
import Button from "./Button";

const SPagination = styled.div`
  display: flex;
  justify-content: center;
  button {
    width: 30px;
    height: 30px;
    svg {
      font-size: 1.5em;
    }
  }
  .current-page {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
  }
`;

const Pagination = ({ page, setPage, isLastPage }) => {
  const { t } = useTranslation("common");

  return (
    <SPagination>
      <Button
        link
        square
        onClick={() => setPage((curr) => (curr > 1 ? curr - 1 : curr))}
        disabled={page === 1}
        title={t("order.Previous")}
      >
        {icons.chevronLeft}
      </Button>
      <span className="current-page">{page}</span>
      <Button
        link
        square
        onClick={() => setPage((curr) => curr + 1)}
        disabled={isLastPage}
        title={t("order.Next")}
      >
        {icons.chevronRight}
      </Button>
    </SPagination>
  );
};

export default Pagination;
