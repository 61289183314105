import axios from "axios";

const versionCheck = async () => {
  const { data: meta } = await axios.get(`/meta.json?${Date.now()}`);
  const latestVersion = meta?.version;
  const storageVersion = localStorage.getItem("version");

  if (latestVersion !== storageVersion) {
    localStorage.setItem("version", latestVersion);
    console.log(`Version ${latestVersion} available. Reloading...`);
    return window.location.reload(true);
  }

  return;
};

export default versionCheck;
