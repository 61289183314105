import React, { useContext, useEffect } from "react";
import Card from "../Card";
import Layout from "../parts/Layout";
import CollapsibleBlock from "../CollapsibleBlock";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import ContactText from "../ContactText";
import icons from "../../icons";
import SearchBox from "../SearchBox";
import groupBy from "../../utils/groupBy";
import filterBy from "../../utils/filterBy";
import { SearchContext } from "../SearchProvider";
import makeUrl from "../../utils/makeUrl";
import getFAQs from "../../utils/getFAQs";
import { useTranslation } from "react-i18next";
import ActionListReports from "../ActionListReports";
import config from "../../config";
import DOMPurify from "dompurify";
import domPurifyWithHooks from "../../utils/adjustLinks";
import { VOLVO } from "../../constants";

const AccordionWrapper = styled.div`
  .item-wrapper:not(:last-child) {
    margin-bottom: 0.5em;
    overflow: hidden;
  }
  h4 {
    font-weight: 600;
  }
`;

const Sidebar = () => {
  const { t } = useTranslation("common");
  const titleActionlists =
    config.clientCode === VOLVO
      ? t("menu-Volvo.Action-lists")
      : t("menu.Action-lists");
  return (
    <React.Fragment>
      <Card
        children={
          <SearchBox
            label={t("ui.Search-FAQ")}
            filter="faqFilter"
            setFilter="setFaqFilter"
          />
        }
        shadow
        className="hide-sm-down"
      />
      <Card
        title={t("ui.Contact")}
        children={<ContactText />}
        icon={icons.contact}
        shadow
      />
      <Card
        title={titleActionlists}
        children={<ActionListReports />}
        icon={icons.actions}
        shadow
      />
    </React.Fragment>
  );
};

const MainContent = () => {
  const searchContext = useContext(SearchContext);
  const faqItemsArray = getFAQs(config.clientCode);
  const { t, i18n } = useTranslation("common");

  let { qId } = useParams();
  useEffect(() => {
    !!document.getElementById(qId) &&
      document.getElementById(qId).scrollIntoView({ behavior: "smooth" });
  });
  domPurifyWithHooks(DOMPurify);

  qId = !!qId
    ? qId
    : faqItemsArray?.[0]?.categoryTitle
    ? makeUrl(faqItemsArray?.[0]?.categoryTitle)
    : null;

  // Search
  const q = searchContext.faqFilter;

  const filteredItems = !!q.length
    ? filterBy(faqItemsArray, ["categoryTitle", "title", "text"], q)
    : faqItemsArray;

  // Multilang
  const translatedItems =
    !!config.lang?.length &&
    !!filteredItems.filter((item) => !!item?.lang)?.length
      ? filteredItems.filter((item) => item?.lang === i18n.language)
      : filteredItems;

  const filteredGroupedFaqItems = groupBy(translatedItems, "categoryTitle");

  if (!translatedItems.length || !faqItemsArray.length) {
    return (
      <Card title={t("ui.Frequently-asked-questions")} icon={icons.faq} shadow>
        <p
          style={{
            opacity: ".7",
          }}
        >
          {t("ui.No-FAQs-available")}
        </p>
      </Card>
    );
  }

  return (
    <React.Fragment>
      <Card
        children={
          <SearchBox
            label={t("ui.Search-FAQ")}
            filter="faqFilter"
            setFilter="setFaqFilter"
          />
        }
        shadow
        className="hide-md-up"
      />
      <Card title={t("ui.Frequently-asked-questions")} icon={icons.faq} shadow>
        <AccordionWrapper>
          {filteredGroupedFaqItems &&
            Object.keys(filteredGroupedFaqItems).map((key, i) => (
              <div className="item-wrapper" key={i}>
                <CollapsibleBlock
                  triggerText={
                    <h3>
                      {filteredGroupedFaqItems[key].key
                        ? filteredGroupedFaqItems[key].key
                        : "..."}
                    </h3>
                  }
                  open={
                    qId.indexOf(makeUrl(filteredGroupedFaqItems[key].key)) === 0
                  }
                >
                  {filteredGroupedFaqItems[key].items.map((item, j) => (
                    <div
                      className="item-wrapper"
                      key={j}
                      id={
                        makeUrl(filteredGroupedFaqItems[key].key) +
                        "-q" +
                        (+j + 1)
                      }
                    >
                      {item.title ? (
                        <CollapsibleBlock
                          triggerText={<h4>{item.title}</h4>}
                          open={
                            makeUrl(filteredGroupedFaqItems[key].key) +
                              "-q" +
                              (+j + 1) ===
                            qId
                          }
                        >
                          {item?.text && item.text.indexOf("<") > -1 ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(item.text, {
                                  USE_PROFILES: { html: true },
                                }),
                              }}
                            />
                          ) : (
                            <p>{item.text}</p>
                          )}
                        </CollapsibleBlock>
                      ) : item?.text && item.text.indexOf("<") > -1 ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(item.text, {
                              USE_PROFILES: { html: true },
                            }),
                          }}
                        />
                      ) : (
                        <p>{item.text}</p>
                      )}
                    </div>
                  ))}
                </CollapsibleBlock>
              </div>
            ))}
        </AccordionWrapper>
      </Card>
    </React.Fragment>
  );
};

const FAQ = () => {
  const { t } = useTranslation("common");
  return (
    <React.Fragment>
      <Layout
        main={<MainContent />}
        sidebar={<Sidebar />}
        pageTitle={t("menu.FAQ")}
      />
      ;
    </React.Fragment>
  );
};

export default FAQ;
