const apiConfig = {
  appUrl: process.env.REACT_APP_HOST,
  bffUrl: process.env.REACT_APP_BFF_HOST,
  accountMigrationUrl: `${process.env.REACT_APP_LLAUTH_HOST}/account/migrate`,
  getLegacySession: `${process.env.REACT_APP_BFF_HOST}/auth/GetLegacySession`,
  phoenix: `${process.env.REACT_APP_BFF_HOST}/phoenix`,
  order: `${process.env.REACT_APP_BFF_HOST}/order`,
  reporting: `${process.env.REACT_APP_BFF_HOST}/reporting`,
  user: `${process.env.REACT_APP_BFF_HOST}/user`,
  files: `${process.env.REACT_APP_BFF_HOST}/files`,
  images: "https://files.llcrm.net/images",
  proofs: "https://files.llcrm.net/proofs",
  fileUpload: `https://files.api.llcrm.net`,
  userRequest: "https://user.api.llcrm.net/users/requestuser",
  vddnTools: "https://vddntools.llcrm.nl",
};

export default apiConfig;
