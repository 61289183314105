import React from "react";
import styled, { css } from "styled-components";
import { darken, lighten, opacify } from "polished";

const SButton = styled.button`
  ${({
    disabled,
    primary,
    $primary,
    secondary,
    $secondary,
    success,
    $success,
    dark,
    $dark,
    light,
    $light,
    darkOutline,
    $darkOutline,
    outline,
    $outline,
    wide,
    $wide,
    stretch,
    $stretch,
    link,
    $link,
    small,
    $small,
    bigger,
    $bigger,
    round,
    $round,
    square,
    $square,
  }) => css`
    color: white;
    border: none;
    border-radius: 6px;
    outline: none;
    padding: 0.75em 1.8em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    cursor: pointer;
    text-decoration: none;
    line-height: 1;
    user-select: none;
    transition: background 0.2s, color 0.2s, border-color 0.2s;
    &:focus-visible {
      outline: none !important;
    }
    .btn-icon-right {
      margin-left: 0.7em;
      margin-right: -0.9em;
    }
    .btn-icon-left {
      margin-right: 0.7em;
      margin-left: -0.9em;
    }
    svg {
      font-size: 1.5em;
      line-height: 1;
      &.btn-arrow-right {
        margin-left: 0;
        margin-right: -0.3em;
      }
      &:last-child:not(:only-child) {
        margin-left: 0.6em;
      }
      &:first-child:not(:only-child) {
        margin-right: 0.6em;
      }
    }
    &:hover {
      color: white;
    }
    &:active {
      animation-name: growShadow;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
    }
    &:disabled {
      cursor: default;
      background: #ddd;
      &:hover,
      &:active,
      &.active {
        background: #ddd;
        animation-name: none;
      }
    }
    @keyframes growShadow {
      from {
        box-shadow: 0 0 1px ${opacify(-0.4, "#666")};
      }
      to {
        box-shadow: 0 0 15px ${opacify(-0.4, "#666")};
      }
    }
    @keyframes growShadowPrimary {
      from {
        box-shadow: 0 0 1px ${(props) => opacify(-0.4, props.theme.buttonColor)};
      }
      to {
        box-shadow: 0 0 15px
          ${(props) => opacify(-0.4, props.theme.buttonColor)};
      }
    }
    ${(primary || $primary) &&
    css`
      background: ${(props) => props.theme.buttonColor};
      &:hover {
        background: ${(props) => darken(0.03, props.theme.buttonColor)};
      }
      &:active,
      &.active {
        background: ${(props) => darken(0.04, props.theme.buttonColor)};
        animation-name: growShadowPrimary;
      }
      ${disabled &&
      css`
        cursor: default;
        background: "#ddd";
        &:hover,
        &:active,
        &.active {
          background: "#ddd";
        }
      `}
    `}
    ${(dark || $dark) &&
    css`
      background: rgba(0, 0, 0, 0.6);
      &:hover {
        background: rgba(0, 0, 0, 0.67);
      }
      &:active,
      &.active {
        background: rgba(0, 0, 0, 0.7);
        animation-name: growShadowPrimary;
      }
      ${disabled &&
      css`
        cursor: default;
        background: "#ddd";
        &:hover,
        &:active,
        &.active {
          background: "#ddd";
        }
      `}
    `}

    ${(light || $light) &&
    css`
      background: ${(props) => props.theme.lightBg};
      color: ${(props) => props.theme.linkColor};
      &:hover {
        background: ${(props) => lighten(0.05, props.theme.lightBg)};
        color: ${(props) => props.theme.linkColor};
      }
      &:active,
      &.active {
        background: ${(props) => lighten(0.07, props.theme.lightBg)};
        color: ${(props) => props.theme.linkColor};
      }
    `}
    ${(secondary || $secondary) &&
    css`
      background: ${(props) => props.theme.secondaryColor};
      &:hover {
        background: ${(props) => darken(0.05, props.theme.secondaryColor)};
      }
      &:active,
      &.active {
        background: ${(props) => darken(0.07, props.theme.secondaryColor)};
      }
    `}
    ${success ||
    ($success &&
      css`
        background: ${(props) => props.theme.successColor};
        &:hover {
          background: ${(props) => props.theme.successColor};
        }
        &:active,
        &.active {
          background: ${(props) => darken(0.03, props.theme.successColor)};
        }
      `)}
    ${(outline || $outline) &&
    css`
      background: transparent;
      border: 1px solid ${(props) => darken(0.04, props.theme.buttonColor)};
      color: ${(props) => darken(0.04, props.theme.buttonColor)};
      &:hover {
        border-color: ${(props) =>
          darken(0.06, darken(0.04, props.theme.buttonColor))};
        color: ${(props) =>
          darken(0.06, darken(0.04, props.theme.buttonColor))};
      }
      &:active,
      &.active {
        background: transparent;
        border-color: ${(props) =>
          darken(0.07, darken(0.04, props.theme.buttonColor))};
        color: ${(props) =>
          darken(0.07, darken(0.04, props.theme.buttonColor))};
        animation-name: growShadowPrimary;
      }
      svg {
        opacity: 0.75;
      }
    `}
    ${(darkOutline || $darkOutline) &&
    css`
      background: transparent;
      border: 1px solid rgba(0, 0, 0, 0.6);
      color: rgba(0, 0, 0, 0.6);
      &:hover {
        border-color: rgba(0, 0, 0, 0.7);
        color: rgba(0, 0, 0, 0.7);
      }
      &:active,
      &.active {
        background: transparent;
        border-color: rgba(0, 0, 0, 0.8);
        color: rgba(0, 0, 0, 0.8);
        animation-name: growShadowPrimary;
      }
      svg {
        opacity: 0.75;
      }
    `}
    ${disabled &&
    css`
      cursor: default;
    `}
    ${(wide || $wide) &&
    css`
      min-width: 15em;
    `}
  ${(stretch || $stretch) &&
    css`
      width: 100%;
    `}
  ${(link || $link) &&
    css`
      padding: 0;
      height: auto;
      background: transparent;
      color: ${(props) => props.theme.linkColor};
      text-decoration: underline;
      &.link-on-bg {
        color: ${(props) => props.theme.linkOnBg};
        &:hover,
        &:active,
        &.active {
          color: ${(props) => darken(0.05, props.theme.linkOnBg)};
        }
      }
      &:hover,
      &:active,
      &.active {
        color: ${(props) => darken(0.05, props.theme.linkColor)};
        animation: none;
      }
      svg {
        font-size: 1em;
        &:last-child:not(:only-child) {
          margin-left: 0.3em;
        }
        &:first-child:not(:only-child) {
          margin-right: 0.3em;
        }
      }
      &:disabled {
        color: rgba(0, 0, 0, 0.4);
        background: transparent;
        &:hover,
        &:active,
        &.active {
          background: transparent;
        }
      }
    `}
  ${(small || $small) &&
    css`
      padding: 0.6em 1em;
      height: 2.4em;
    `}
  ${(bigger || $bigger) &&
    css`
      font-size: 1.5em;
    `}
  ${(round || $round) &&
    css`
      padding: 0;
      height: 3em;
      width: 3em;
      line-height: 1;
      border-radius: 10em;
      svg {
        margin: 0;
      }
    `}
  ${(square || $square) &&
    css`
      padding: 0;
      width: 3.2em;
      svg {
        margin: 0;
      }
    `}
  `}
`;

const Button = (props) => {
  return <SButton {...props} type={!props.type ? "button" : props.type} />;
};

export const ButtonWithRef = React.forwardRef((props, ref) => (
  <SButton ref={ref} {...props} type={!props.type ? "button" : props.type} />
));

export default Button;
