import React, { useState } from "react";
import Card from "../Card";
import { useTranslation } from "react-i18next";
import icons from "../../icons";
import Button from "../Button";
import getIconFromString from "../../utils/getIconFromString";
import { Field, MediaInput, Label, Checkbox } from "@zendeskgarden/react-forms";
import WithMargin from "../WithMargin";
import { Dropdown, Trigger, Menu, Item } from "@zendeskgarden/react-dropdowns";
import styled from "styled-components";
import ButtonWrap from "../ButtonWrap";
import { VALIDATIONPATTERNS } from "../../constants";
import callSocials from "../../utils/callSocials";
import { useToast } from "@zendeskgarden/react-notifications";
import Notification from "../Notification";
import { Col, GridThemeProvider, Row } from "styled-bootstrap-grid";
import theme from "../../styles/theme";
import Loader from "../Loader";

const SSocialBlock = styled.div`
  margin-top: 1.2em;
  .label-col {
    width: 150px;
    justify-content: start;
  }
  .row-wrap-condensed {
    padding: 0 3px;
  }
  .margin-bottom-small {
    margin-bottom: 6px;
  }
  .icon-text-label {
    svg {
      opacity: 0.7;
      transform: scale(1.2);
    }
    .label-text:first-child,
    .label-text.mr {
      margin-right: 0.5em;
    }
    .label-text:last-child,
    .label-text.ml {
      margin-left: 0.5em;
    }
  }
  input[disabled][readonly] {
    color: #2f3941;
  }
  .active-field {
    justify-content: flex-start;
    min-width: 86px;
    label {
      padding-right: 1em;
      margin-left: 0.5em;
    }
  }
  .active-label {
    justify-content: flex-start;
    padding-right: 1em;
    padding-left: 0.5em;
    min-width: 86px;
    label {
      font-weight: 600;
    }
    svg {
      font-size: 1.5em;
      color: ${(props) => props.theme.successColor};
      margin-right: 0.2em;
      margin-bottom: -4px;
    }
  }
  .type-menu {
    min-width: 140px;
    li {
      padding-left: 1em;
      padding-right: 1em;
      .item-icon {
        vertical-align: -1px;
      }
    }
  }
  .dropdown-label {
    .arrow {
      transform: rotate(90deg);
      svg {
        opacity: 1;
      }
    }
  }
  &.editable {
    .dropdown-label {
      cursor: pointer;
    }
  }
  &:not(.editable) {
    .input-wrap input {
      height: 41px;
      padding: 0.7142857142857143em 0.8571428571428571em;
      border: 1px solid transparent;
    }
    .empty {
      display: none;
    }
    .form-wrap {
      padding-bottom: 0.2em;
    }
  }
  .input-with-btn + button {
    position: absolute;
    right: 0.6em;
    font-size: 125%;
    color: #999;
    &:hover {
      color: #9d5454;
    }
  }
  .delete-btn {
    margin-left: 6px;
    height: 39px;
    width: 39px;
    border-radius: 4px;
    &:hover {
      background: #9d5454;
    }
  }
  .field-wrap {
    display: flex;
    align-items: center;
    .input-wrap {
      flex-grow: 1;
    }
  }
  .add-btn-wrap {
    padding-top: 0.5em;
    text-align: right;
    padding-bottom: 0.9em;
  }
  .add-btn-ph {
    padding-top: 0.6em;
  }
  .form-wrap {
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
  }
  @media (max-width: 574px) {
    .grid-form-label {
      min-height: 32px;
    }
    .label-col.custom-input > div {
      padding-top: 4px;
      padding-bottom: 2px;
      min-height: 28px;
    }
    .label-col-wrap {
      flex-grow: 1;
    }
    &:not(.editable) {
      .input-wrap input {
        margin-top: -1em;
        padding: 0.7142857142857143em 0;
      }
    }
  }
`;

const STATIC_LABELS = [
  "Facebook",
  "Twitter",
  "GooglePlus",
  "LinkedIn",
  "YouTube",
  "Hyves",
  "Instagram",
];

const SocialBlock = ({ mpId, data, isLoading, refreshMp }) => {
  const { t } = useTranslation("common");
  const { addToast } = useToast();

  const TOTAL = 9;
  const OTHER = t("my-account.Other");

  const CUSTOM_TYPES = [
    ...STATIC_LABELS.filter((item) => item !== "Hyves"),
    OTHER,
  ];

  const staticLinksCount = STATIC_LABELS.length;
  const defaultNewItemName = "Facebook";

  const isValidUrl = (str) => str.match(VALIDATIONPATTERNS.urlPattern);
  const getNameFromLabel = (label) => (label === "Twitter" ? "X" : label);

  const [socialLinks, setSocialLinks] = useState(
    data.map((item, i) => {
      return {
        ...item,
        name: STATIC_LABELS?.[i] || item.name,
      };
    })
  );

  const [formOpen, setFormOpen] = useState(false);
  const [editingName, setEditingName] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleUrlChange = (value, i) =>
    setSocialLinks(
      socialLinks.map((item, index) =>
        index === i ? { ...item, url: value } : item
      )
    );

  const handleNameChange = (value, i) =>
    setSocialLinks(
      socialLinks.map((item, index) =>
        index === i ? { ...item, name: value || defaultNewItemName } : item
      )
    );

  const handleToggle = (i) => {
    setSocialLinks((curr) =>
      curr.map((item, index) =>
        index === i ? { ...item, active: !item.active } : item
      )
    );
  };

  const addRow = () => {
    const linksWithName = socialLinks.filter((item) => !!item.name)?.length;
    if (!!formOpen && linksWithName < socialLinks?.length) {
      setSocialLinks(
        socialLinks.map((item, index) =>
          index === linksWithName
            ? { ...item, name: defaultNewItemName, url: "" }
            : item
        )
      );
    }
  };

  const prefillUrl = (name) =>
    STATIC_LABELS.indexOf(name) > -1
      ? `https://${name.toLowerCase()}.${
          name.toLowerCase().indexOf("hyves") > -1 ? "nl" : "com"
        }`
      : "https:/";

  const handleClearItem = (i) => {
    setSocialLinks(
      socialLinks.map((item, index) =>
        index === i ? { ...item, url: "", active: false } : item
      )
    );
  };

  const handleRemoveRow = (i) => {
    setSocialLinks(
      socialLinks.map((item, index) =>
        index === i ? { name: "", url: "", active: false } : item
      )
    );
  };

  const handleFocus = (e, i) => {
    if (!!formOpen && e.target.value === "") {
      setSocialLinks(
        socialLinks.map((item, index) =>
          index === i ? { ...item, url: prefillUrl(item.name) + "/" } : item
        )
      );
    }
  };

  const handleNameFocus = (e, i) => {
    e.target.select();
  };

  const handleFocusOut = (e, i) => {
    if (
      !!formOpen &&
      e.target.value === prefillUrl(socialLinks[i].name) + "/"
    ) {
      setSocialLinks(
        socialLinks.map((item, index) =>
          index === i ? { ...item, url: "" } : item
        )
      );
    }
  };

  const handleNameFocusOut = (e, i) => {
    if (e?.relatedTarget?.tabIndex !== -2) {
      setEditingName(null);
    }
  };

  const isNotEmpty = (index) =>
    !!socialLinks[index] &&
    !!socialLinks[index].url &&
    socialLinks[index].url.length > 0;

  const isValidByIndex = (i) =>
    !socialLinks[i] ||
    !socialLinks[i].url ||
    (!!socialLinks[i] && !!socialLinks[i].url && isValidUrl(socialLinks[i].url))
      ? null
      : "error";

  const handleUpdate = () => {
    setIsUpdating(true);
    const payload = socialLinks
      .filter((item) => !!item?.name)
      .map((item) => ({
        ...item,
        active: !!item.active,
        url: item.url || null,
      }));
    if (payload.every((item) => !item.url || isValidUrl(item.url))) {
      callSocials({
        mpId: mpId,
        data: payload,
        onSuccess: () => {
          refreshMp();
          setFormOpen(false);
          setIsUpdating(false);
        },
        onError: (error) => {
          setIsUpdating(false);
          addToast(
            ({ close }) => (
              <Notification
                title={t("error.Something-went-wrong")}
                text={t("error.Failed-to-update-social-media")}
                errorText={error?.response?.data?.title || null}
                type="error"
                handleClose={close}
              />
            ),
            { placement: "bottom" }
          );
        },
      });
    } else {
      setIsUpdating(false);
    }
  };

  const handleKeyDown = (e, i) => {
    if (e.key === "Enter") {
      e.target.blur();
      handleUpdate();
    }
    e.key === "Escape" && handleClearItem(i);
  };

  const handleNameKeyDown = (e, i) => {
    if (e.key === "Enter") {
      e.target.blur();
      handleUpdate();
    }
    if (e.key === "Escape") {
      handleNameChange(defaultNewItemName, i);
    }
  };

  const handleCancel = () => {
    setSocialLinks(
      data.map((item, i) => {
        return {
          ...item,
          name: STATIC_LABELS?.[i] || item.name,
        };
      })
    );
    setFormOpen(false);
  };

  return (
    <Card title={t("my-account.Social")} shadow icon={icons.link}>
      <WithMargin>
        <p>{t("my-account.Social-media-URLs")}</p>
      </WithMargin>
      {isLoading || isUpdating ? (
        <Loader />
      ) : (
        <SSocialBlock className={formOpen ? "editable" : ""}>
          <div className="form-wrap">
            {socialLinks
              .filter((item) => !!item.name)
              .map((item, index) => (
                <div
                  className={`row-wrap-condensed ${
                    !item.url && !item.active && index < staticLinksCount
                      ? "empty"
                      : ""
                  }`}
                  key={"social" + index}
                >
                  <GridThemeProvider gridTheme={theme.gridThemeCondensed}>
                    <Row>
                      <Col auto>
                        {!formOpen ? (
                          <div className="active-label grid-form-label">
                            {item.active ? (
                              <span>
                                {icons.check}
                                <label>{t("order.active")}</label>
                              </span>
                            ) : null}
                          </div>
                        ) : (
                          <Field className="grid-form-label active-field">
                            <Checkbox
                              checked={!!item.active}
                              onChange={(e) => handleToggle(index)}
                              disabled={!formOpen}
                              tabIndex={-1}
                            >
                              <Label>{t("order.active")}</Label>
                            </Checkbox>
                          </Field>
                        )}
                      </Col>
                      <Col auto className="label-col-wrap">
                        {index < staticLinksCount || !formOpen ? (
                          <Label className="grid-form-label icon-text-label label-col static-label">
                            {getIconFromString(item.name)}
                            <span className="label-text">
                              {getNameFromLabel(item.name)}
                            </span>
                          </Label>
                        ) : STATIC_LABELS.indexOf(item.name) > -1 ||
                          editingName !== index ? (
                          <Dropdown
                            onSelect={(value) => {
                              handleNameChange(value, index);
                              if (value === OTHER) {
                                setEditingName(index);
                              }
                            }}
                          >
                            <Trigger disabled={!formOpen}>
                              <Label className="grid-form-label icon-text-label label-col dropdown-label">
                                {getIconFromString(item.name)}
                                <span className="label-text ml">
                                  {getNameFromLabel(item.name)}
                                </span>
                                <span className="arrow">
                                  {icons.chevronRight}
                                </span>
                              </Label>
                            </Trigger>
                            <Menu className="type-menu">
                              {CUSTOM_TYPES.map((option) => (
                                <Item
                                  key={option}
                                  value={option}
                                  className="icon-text-label dropdown-item"
                                >
                                  <span className="item-icon">
                                    {getIconFromString(option)}{" "}
                                  </span>
                                  <span className="label-text">
                                    {getNameFromLabel(option)}
                                  </span>
                                </Item>
                              ))}
                            </Menu>
                          </Dropdown>
                        ) : (
                          <Field className="label-col custom-input">
                            <MediaInput
                              onChange={(e) =>
                                handleNameChange(e.target.value, index)
                              }
                              onKeyDown={(e) => handleNameKeyDown(e, index)}
                              onFocus={(e) => handleNameFocus(e, index)}
                              onBlur={(e) => handleNameFocusOut(e, index)}
                              maxLength={50}
                              end={
                                <Button
                                  link
                                  onClick={(e) => {
                                    handleNameChange(defaultNewItemName, index);
                                  }}
                                  tabIndex={-2}
                                  title={t("ui.Clear")}
                                >
                                  {icons.close}
                                </Button>
                              }
                              value={item.name || ""}
                              readOnly={!formOpen}
                              disabled={!formOpen}
                              className="input-with-btn"
                              autoFocus
                            />
                          </Field>
                        )}
                      </Col>
                      <Col col xs={12} className="margin-bottom-small">
                        <div className="field-wrap">
                          <Field className="input-wrap">
                            <MediaInput
                              onChange={(e) =>
                                handleUrlChange(e.target.value, index)
                              }
                              onKeyDown={(e) => handleKeyDown(e, index)}
                              onFocus={(e) => handleFocus(e, index)}
                              onBlur={(e) => handleFocusOut(e, index)}
                              placeholder={
                                !!formOpen
                                  ? prefillUrl(item.name) + "/..."
                                  : "-"
                              }
                              maxLength={50}
                              end={
                                isNotEmpty(index) &&
                                formOpen && (
                                  <Button
                                    link
                                    onClick={() => handleClearItem(index)}
                                    tabIndex={-1}
                                    title={t("ui.Clear")}
                                  >
                                    {icons.close}
                                  </Button>
                                )
                              }
                              value={item.url || ""}
                              validation={isValidByIndex(index)}
                              readOnly={!formOpen}
                              className="input-with-btn"
                              isBare={!formOpen}
                            />
                          </Field>
                          {!formOpen || index < staticLinksCount ? null : (
                            <Button
                              secondary
                              square
                              onClick={() => handleRemoveRow(index)}
                              tabIndex={-1}
                              title={t("my-account.Delete")}
                              className="delete-btn"
                            >
                              {icons.trash}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </GridThemeProvider>
                </div>
              ))}
            {formOpen &&
            socialLinks.filter((item) => !!item?.name)?.length < TOTAL ? (
              <div className="add-btn-wrap">
                <Button
                  link
                  onClick={addRow}
                  disabled={
                    !formOpen ||
                    socialLinks.filter((item) => !!item?.name)?.length === TOTAL
                  }
                >
                  {icons.add} <span>{t("order.Add")}</span>
                </Button>
              </div>
            ) : (
              <div className="add-btn-ph"></div>
            )}
          </div>

          {!formOpen ? (
            <ButtonWrap>
              <Button link small onClick={() => setFormOpen(true)}>
                {icons.edit}
                <span>{t("my-account.Edit")}</span>
              </Button>
            </ButtonWrap>
          ) : (
            <ButtonWrap>
              <Button link onClick={() => handleCancel()}>
                {icons.close}
                <span>{t("ui.Cancel")} </span>
              </Button>

              <Button $success onClick={() => handleUpdate()}>
                {icons.check}
                <span>{t("my-account.Save")}</span>
              </Button>
            </ButtonWrap>
          )}
        </SSocialBlock>
      )}
    </Card>
  );
};

export default SocialBlock;
