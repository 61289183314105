import Button from "../components/Button";
import React from "react";
import {
  CgChevronRight,
  CgPushChevronRight,
  CgChevronLeft,
  CgPushChevronLeft,
} from "react-icons/cg";
import parseLinkHeader from "../utils/parseLinkHeader";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const SPagination = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 75%;
  .active-nr {
    font-size: 1.3em;
    cursor: default;
    white-space: nowrap;
  }
  button {
    height: 3em;
    &:first-child {
      margin-right: 0.2em;
    }
    &:last-child {
      margin-left: 0.2em;
    }
  }
`;

const PaginationFromHeader = ({
  current,
  linkStr,
  setPage,
  pageSize,
  currentPageCount,
}) => {
  const links = parseLinkHeader(linkStr);
  const { t } = useTranslation("common");

  const PageBtn = ({
    page,
    isCurrent = false,
    isDisabled = false,
    label,
    icon: Icon,
  }) => {
    return (
      <Button
        disabled={isDisabled}
        onClick={() => (!isCurrent ? setPage(page) : null)}
        title={
          !isCurrent ? `${label}${page && page > 1 ? ` (${page})` : ""}` : ""
        }
        active={isCurrent}
        square
        link={isCurrent}
        secondary={!isCurrent}
        className={isCurrent ? "active no-decoration" : ""}
        style={{ height: "3em" }}
      >
        {isCurrent ? <span className="active-nr">{page}</span> : <Icon />}
      </Button>
    );
  };

  return currentPageCount ? (
    <span className="current-page-count">{`${pageSize * (current - 1) + 1} - ${
      pageSize * current
    }`}</span>
  ) : (
    <SPagination>
      <div>
        <PageBtn
          page={links?.first?.page}
          isDisabled={!links?.first}
          label={t("ui.First-page")}
          icon={CgPushChevronLeft}
        />
        <PageBtn
          page={links?.prev?.page}
          isDisabled={!links?.first}
          label={t("order.Previous")}
          icon={CgChevronLeft}
        />
      </div>
      <div style={{ display: "flex" }}>
        <PageBtn
          page={`${current}  ${t("ui.out-of")} ${
            !!links?.last?.page ? links?.last?.page : current
          }`}
          isCurrent
        />
      </div>
      <div>
        <PageBtn
          page={links?.next?.page}
          isDisabled={!links?.next}
          label={t("order.Next")}
          icon={CgChevronRight}
        />
        <PageBtn
          page={links?.last?.page}
          isDisabled={!links?.last}
          label={t("ui.Last-page")}
          icon={CgPushChevronRight}
        />
      </div>
    </SPagination>
  );
};

export default PaginationFromHeader;
