import React, { useEffect, useRef, useState } from "react";
import {
  Dropdown,
  Field as SelectField,
  Menu,
  Item,
  Select,
} from "@zendeskgarden/react-dropdowns";
import { Input, Field, Textarea } from "@zendeskgarden/react-forms";
import styled from "styled-components";

const StyledDropdown = styled.div`
  display: flex;
  position: relative;
  width: "100%";
  .input-container {
    width: 100%;
  }
  .select-btn {
    width: 40px;
    margin-left: 4px;
    svg {
      margin: 1px 0 0 -1px;
    }
  }
  .select-btn + div {
    right: 0;
    transform: translateY(40px) !important;
    ul {
      width: auto !important;
      li {
        margin: 0;
        padding: 0.6em 1.15em;
        transition: background 0.2s, color 0.2s;
        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }
        &:hover,
        &.selected-option:hover {
          background: ${(props) => props?.theme?.hoverColor};
        }
        &.selected-option {
          background: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }
`;

const DropdownTextField = React.forwardRef(
  (
    {
      options,
      value = "",
      onChange,
      placeholder,
      name,
      style,
      disabled = false,
      hasResizing = false,
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(value);
    const textareaRef = useRef(null);

    const handleSelect = (selectedValue) => {
      setInputValue(selectedValue);
      const event = {
        target: {
          name,
          value: selectedValue,
        },
      };
      onChange(event);
    };

    const handleInputChange = (e) => {
      setInputValue(e.target.value);
      onChange(e);
    };

    const adjustHeight = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto"; // Reset the height
        textareaRef.current.style.height = `${
          textareaRef.current.scrollHeight + 2
        }px`; // Adjust height based on content
      }
    };

    useEffect(() => {
      if (hasResizing) {
        adjustHeight(); // Adjust height on initial load and when inputValue changes
      }
    }, [inputValue, hasResizing]);

    return (
      <Dropdown
        className="w-100"
        inputValue={inputValue}
        onSelect={handleSelect}
        downshiftProps={{
          itemToString: (item) => item || "",
        }}
      >
        <StyledDropdown>
          <div className="input-container">
            <Field>
              {hasResizing ? (
                <Textarea
                  disabled={disabled}
                  ref={(el) => {
                    textareaRef.current = el; // Assign textareaRef for resizing
                    if (ref) {
                      // Forward the ref if provided
                      if (typeof ref === "function") {
                        ref(el);
                      } else {
                        ref.current = el;
                      }
                    }
                  }}
                  value={inputValue}
                  placeholder={placeholder}
                  onChange={handleInputChange}
                  name={name}
                  style={style} // Adjust the minHeight as needed
                  rows={1} // Start with 2 rows
                />
              ) : (
                <Input
                  disabled={disabled}
                  ref={ref}
                  value={inputValue}
                  placeholder={placeholder}
                  onChange={handleInputChange}
                  name={name}
                  style={style}
                />
              )}
            </Field>
          </div>

          <SelectField className="select-btn">
            <Select style={{ minWidth: "0" }} disabled={disabled} />
          </SelectField>
          <Menu>
            {options?.map((option, index) =>
              option ? (
                <Item
                  key={index}
                  value={option}
                  className={inputValue === option ? "selected-option" : ""}
                >
                  {option}
                </Item>
              ) : null
            )}
          </Menu>
        </StyledDropdown>
      </Dropdown>
    );
  }
);

export default DropdownTextField;
