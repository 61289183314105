import React, { useState } from "react";
import Layout from "../parts/Layout";
import Card from "../Card";
import Sidebar from "../parts/Sidebar";
import { useTranslation } from "react-i18next";
import icons from "../../icons";
import useMarketingProfile from "../../utils/useMarketingProfile";
import Error from "../Error";
import Loader from "../Loader";
import SocialBlock from "./SocialBlock";
import AddressBlock from "./AddressBlock";
import SuppliersBlock from "./SuppliersBlock";
import ContactPersonsBlock from "./ContactPersonsBlock";
import AddressesBlock from "./AddressesBlock";
import BrandsBlock from "./BrandsBlock";
import { useSWRConfig } from "swr";
import LogosBlock from "./LogosBlock";
import config from "../../config";
import Button from "../Button";
import { useNavigate, useParams } from "react-router";
import { FaUsers, FaIdCard } from "react-icons/fa";
import { FaCartFlatbed, FaSignature } from "react-icons/fa6";
import { useAuth } from "../../context/AuthContext";
import CustomerInfo from "./CustomerInfo";
import SignaturesWrapper from "./SignaturesWrapper";

const Main = ({ selectedTab }) => {
  const { user } = useAuth();
  const customerId = localStorage.getItem("customerId");

  const {
    data: mp,
    isLoading,
    isError,
    cacheKey,
  } = useMarketingProfile({
    customerId: customerId,
    shouldFetch: () => !!customerId,
  });
  const { t } = useTranslation("common");
  const { mutate } = useSWRConfig();
  const refreshMp = () => mutate(cacheKey);

  if (isLoading)
    return (
      <Card
        title={t("my-account.User-info")}
        children={<Loader />}
        icon={icons.info}
        shadow
      />
    );

  if (isError)
    return (
      <Card
        title={t("my-account.User-info")}
        children={<Error errorObj={isError} />}
        icon={icons.info}
        shadow
      />
    );

  return (
    <React.Fragment>
      {selectedTab === "info" ? (
        <CustomerInfo
          user={user}
          logo={mp.images.logo}
          images={mp.images}
          refreshMp={refreshMp}
          mpId={mp.id}
          mp={mp}
        />
      ) : null}

      {selectedTab === "addresses" ? (
        <AddressesBlock customerId={mp.id} />
      ) : null}

      {selectedTab === "persons" ? (
        <ContactPersonsBlock customerId={mp.id} />
      ) : null}

      {selectedTab === "social" ? (
        <SocialBlock
          mpId={mp.id}
          data={mp.socialMedia}
          isLoading={isLoading}
          refreshMp={refreshMp}
        />
      ) : null}

      {selectedTab === "addressblock" ? (
        <AddressBlock
          mpId={mp.id}
          addressBlock={mp.properties.addressBlock}
          refreshMp={refreshMp}
        />
      ) : null}

      {selectedTab === "brands" && config.uiSettings.brandSelection ? (
        <BrandsBlock
          mpId={mp.id}
          mpBrands={mp.brandLogos}
          refreshMp={refreshMp}
        />
      ) : null}

      {selectedTab === "logos" && config.uiSettings.showLogos ? (
        <LogosBlock images={mp.images} refreshMp={refreshMp} mpId={mp.id} />
      ) : null}

      {selectedTab === "suppliers" ? (
        <SuppliersBlock mpId={mp.id} customerId={mp.id} />
      ) : null}
      {selectedTab === "signatures" ? (
        <SignaturesWrapper customerId={mp.id} />
      ) : null}
    </React.Fragment>
  );
};

const MyAccount = () => {
  const { t } = useTranslation("common");

  const { sectionId } = useParams();
  const navigate = useNavigate();

  const sections = [
    { id: "info", label: "User-info", icon: <FaIdCard /> },
    { id: "addresses", label: "Addresses", icon: icons.location },
    { id: "persons", label: "Contact-persons", icon: <FaUsers /> },
    { id: "social", label: "Social", icon: icons.link },
    { id: "addressblock", label: "Address-block", icon: icons.newspaper },
    { id: "brands", label: "Brand-selection", icon: icons.bookmark },
    { id: "logos", label: "Logos", icon: icons.star },
    { id: "suppliers", label: "Suppliers", icon: <FaCartFlatbed /> },
    { id: "signatures", label: "Signatures", icon: <FaSignature /> },
  ];

  const [selectedTab, setSelectedTab] = useState(
    !!sectionId ? sectionId : "info"
  );

  const handleSelectSection = (id) => {
    setSelectedTab(id);
    navigate(`/my-account/${id}`);
  };

  return (
    <Layout
      extraSection={
        <div className="btn-pills-wrap">
          {sections.map(({ id, label, icon }) =>
            (!config?.uiSettings?.brandSelection && id === "brands") ||
            (!config?.uiSettings?.showSuppliers && id === "suppliers") ||
            (!config?.uiSettings?.showLogos && id === "logos") ||
            (!config.uiSettings.showSignatures &&
              id === "signatures") ? null : (
              <Button
                light={id !== selectedTab}
                primary={id === selectedTab}
                key={id}
                onClick={() => handleSelectSection(id)}
              >
                {icon}
                <span>{t(`my-account.${label}`)}</span>
              </Button>
            )
          )}
        </div>
      }
      main={<Main selectedTab={selectedTab} />}
      sidebar={<Sidebar showActionLists={false} />}
      pageTitle={t("ui.My-account")}
    />
  );
};

export default MyAccount;
