import React from "react";
import { useTranslation } from "react-i18next";
import Card from "../Card";
import Layout from "../parts/Layout";
import styled from "styled-components";
import ScrollToTop from "../ScrollToTop";
import Loader from "../Loader";
import Error from "../Error";
import { opacify } from "polished";
import ImageBox from "../ImageBox";
import useVariantsSearch from "../../utils/useVariantsSearch";
import Link from "../Link";
import { Col, Row } from "styled-bootstrap-grid";
import { lighten, desaturate } from "polished";
import apiConfig from "../../apiConfig";
import EmailSignaturePreview from "../proofing/EmailSignaturePreview";
import { BsPostcard } from "react-icons/bs";

const SWrapper = styled.div`
  .signature-list {
    margin-bottom: -1.2em;
    .es-col {
      width: auto;
      flex-grow: 1;
    }
    .iframe-wrap {
      max-width: 100%;
      overflow: hidden;
      background: white;
      height: 368px;
    }
    .list-item {
      padding-bottom: 1.5em;
      background: rgba(0, 0, 0, 0.05);
      margin-bottom: 1.2em;
      transition: background 0.2s;
      @media (max-width: 1350px) {
        padding: 0 0.5em 0.5em;
      }
      h2 {
        padding: 0.5em 0.1em 0.1em;
      }
      &:hover,
      &.active {
        background: ${(props) => opacify(-0.8, props.theme.accentColor)};
        cursor: pointer;
        .badge-absolute {
          background: ${({ theme }) =>
            lighten(0.3, desaturate(0.4, theme.accentColor))};
        }
      }
      .img-wrap {
        background: white;
        height: 368px;
        @media (max-width: 1350px) {
          height: auto;
        }
      }
    }
    .list-item-inner {
      width: 601px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      a {
        display: block;
      }
    }
    .iframe-wrap {
      pointer-events: none;
    }
  }
  .item-details {
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .sign-img-wrap {
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 1em;
    }
    border: 1px solid ${(props) => props.theme.borderColor};
    transition: border-color 0.2s;
    position: relative;
    .overlay {
      display: flex;
      opacity: 0;
      position: absolute;
      inset: 0;
      align-items: center;
      justify-content: center;
      transition: opacity 0.2s;
      background: ${(props) => opacify(-0.8, props.theme.accentColor)};
    }
    &:hover {
      border-color: ${(props) => props.theme.accentColor};
      .overlay {
        opacity: 1;
      }
    }
  }
  .sidebar-card .sign-img-wrap {
    border-color: ${(props) => props.theme.borderColor};
  }
  fieldset {
    border: none;
    outline: none;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 1em 0;
  }
  .text-top {
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
  }
  .btn-wrap-with-border {
    padding-top: 1em;
    border-top: 1px solid ${(props) => props.theme.borderColor};
    margin-top: 1em;
  }
`;

const EmailSignatureList = () => {
  const { t } = useTranslation("common");

  const {
    data: emailSignaturesWithHeaders,
    isLoading: emailSignaturesIsLoading,
    isError: emailSignaturesIsError,
  } = useVariantsSearch({ programId: 1185 });

  const emailSignatures =
    !!emailSignaturesWithHeaders && !!emailSignaturesWithHeaders.data
      ? emailSignaturesWithHeaders.data
      : [];

  return (
    <SWrapper>
      <Layout
        main={
          <Card
            shadow
            hasFooter
            stickyFooter
            icon={<BsPostcard />}
            title={t("ui.Email-signature")}
          >
            {emailSignaturesIsLoading ? (
              <Loader />
            ) : emailSignaturesIsError ? (
              <Error errorObj={emailSignaturesIsError} />
            ) : !!emailSignatures &&
              !emailSignaturesIsLoading &&
              !emailSignaturesIsError ? (
              <div className="signature-list">
                <ScrollToTop />
                <div className="text-top">
                  <p>{t("ui.Select-signature-description")}</p>
                </div>
                <div className="row-wrap">
                  <Row>
                    {!!emailSignatures && !!emailSignatures.length
                      ? emailSignatures.map((v) => (
                          <Col
                            className="es-col"
                            xs="auto"
                            key={`${v.id}-email-signature`}
                          >
                            <div className="list-item">
                              <Link
                                className="no-decoration"
                                to={`/tools/email-signature/${v.id}`}
                              >
                                <div className="list-item-inner">
                                  <h2>{v?.name}</h2>
                                  {v.recentProofFileId ? (
                                    <div className="iframe-wrap">
                                      <EmailSignaturePreview
                                        url={`${apiConfig.proofs}/${v.recentProofFileId}`}
                                        docId={v.recentProofFileId}
                                      />
                                    </div>
                                  ) : (
                                    <div className="img-wrap">
                                      <ImageBox
                                        id={v?.program?.thumbnailFileId}
                                        boxHeight="50%"
                                        params={{ ResizeMode: "Contain" }}
                                        bgColor="transparent"
                                        asBgImage={false}
                                        noZoom
                                      />
                                    </div>
                                  )}
                                </div>
                              </Link>
                            </div>
                          </Col>
                        ))
                      : null}
                  </Row>
                </div>
              </div>
            ) : null}
          </Card>
        }
        pageTitle={t("ui.Email-signature")}
      />
    </SWrapper>
  );
};

export default EmailSignatureList;
