import axios from "axios";
import config from "../config";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const callAddresses = ({ customerId, data = [], onSuccess, onError }) =>
  axios({
    method: "PUT",
    url: `${phoenix}/customers/${customerId}/addresses?sendNotification=true`,
    data: data,
    withCredentials: true,
    headers: {
      pxclient: config.clientId,
      pxcustomer: customerId,
    },
  })
    .then((res) => {
      if (res.status === 200) {
        !!onSuccess && onSuccess(res?.data);
      } else {
        !!onError ? onError(res) : console.log("Request failed", res);
      }
    })
    .catch((error) => {
      if (!!error.response) {
        onError(!!error.response?.data?.title);
      } else if (!!error.request) {
        onError();
      } else {
        onError(error?.message);
      }
    });

export default callAddresses;
