import useSWR from "swr";
import fetcherWithHeaders from "./fetcherWithHeaders";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const useCustomers = ({
  shouldFetch = () => true,
  pageSize = 30,
  page = 1,
  query = "",
  orderBy = "",
}) => {
  const cacheKey = `${phoenix}/customers?pageSize=${pageSize}&page=${page}&query=${query}&orderBy=${orderBy}&filter=active`;
  const { data, error } = useSWR(
    () => (shouldFetch() ? { url: cacheKey, multiCustomer: true } : null),
    fetcherWithHeaders,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
    }
  );
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    cacheKey: cacheKey,
  };
};

export default useCustomers;
