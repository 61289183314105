import useSWR from "swr";
import fetcher from "./fetcher";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const useSignatures = ({ mpId, shouldFetch = () => true }) => {
  const customerId = localStorage.getItem("customerId");
  const cacheKey = `${phoenix}/marketingprofiles/${
    mpId || customerId
  }/signatures`;
  const { data, error, mutate } = useSWR(
    () => (shouldFetch() ? cacheKey : null),
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  return {
    data,
    isLoading: !error && !data,
    error,
    mutate,
  };
};

export default useSignatures;
