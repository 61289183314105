import React from "react";
import Card from "../Card";
import ContactText from "../ContactText";
import FAQList from "../FAQList";
import sidebarBanner from "../../assets/img/privacy.png";
import sidebarBannerLight from "../../assets/img/privacy-light.png";
import styled from "styled-components";
import {
  OOGWERELDNL,
  OOGWERELDBE,
  OMP,
  BIKENL,
  ACOUSTICSNL,
} from "../../constants";
import icons from "../../icons";
import { useTranslation } from "react-i18next";
import ActionListReports from "../ActionListReports";
import { Col, Row } from "styled-bootstrap-grid";
import { VOLVO } from "../../constants";

import config from "../../config";

const SSidebarBanner = styled.div`
  padding: 1em 0;
  img {
    margin: auto;
    width: auto;
    max-height: 7em;
  }
`;

const Sidebar = ({ isRow = false, showActionLists = true }) => {
  const { t } = useTranslation("common");
  const titleActionlists =
    config.clientCode === VOLVO
      ? t("menu-Volvo.Action-lists")
      : t("menu.Action-lists");

  return isRow && (showActionLists || FAQList) ? (
    <div className="row-wrap">
      <Row>
        <Col md={showActionLists ? 4 : 6}>
          <Card
            title={t("ui.Contact")}
            children={<ContactText />}
            icon={icons.contact}
            shadow
          />
        </Col>
        {FAQList ? (
          <Col md={showActionLists ? 4 : 6}>
            <Card
              title={t("menu.FAQ")}
              children={<FAQList />}
              icon={icons.faq}
              withBg
              shadow
            />
          </Col>
        ) : null}
        {showActionLists ? (
          <Col md={4}>
            <Card
              title={titleActionlists}
              children={<ActionListReports />}
              icon={icons.actions}
              shadow
            />
          </Col>
        ) : null}
      </Row>
    </div>
  ) : (
    <React.Fragment>
      <Card
        title={t("ui.Contact")}
        children={<ContactText />}
        icon={icons.contact}
        shadow
      />
      {FAQList ? (
        <Card
          title={t("menu.FAQ")}
          children={<FAQList />}
          icon={icons.faq}
          withBg
          shadow
        />
      ) : null}
      {showActionLists ? (
        <Card
          title={titleActionlists}
          children={<ActionListReports />}
          icon={icons.actions}
          shadow
        />
      ) : null}
      {(config.clientCode === OOGWERELDNL ||
        config.clientCode === OOGWERELDBE ||
        config.clientCode === ACOUSTICSNL) && (
        <SSidebarBanner>
          <img src={sidebarBanner} alt="Privacy" />
        </SSidebarBanner>
      )}
      {config.clientCode === OMP ||
        (config.clientCode === BIKENL && (
          <SSidebarBanner>
            <img src={sidebarBannerLight} alt="Privacy" />
          </SSidebarBanner>
        ))}
    </React.Fragment>
  );
};

export default Sidebar;
