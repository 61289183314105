import React from "react";
import PropTypes from "prop-types";
import Card from "../Card";
import styled from "styled-components";
import Nav from "./Nav";
import SideMenu from "./SideMenu";
import { FiLogOut } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../Logo";
import icons from "../../icons";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";
import useCustomer from "../../utils/useCustomer";
import { darken, opacify } from "polished";
import useMarketingProfile from "../../utils/useMarketingProfile";
import config from "../../config";
import { Modal, Body as ModalBody } from "@zendeskgarden/react-modals";
import Error from "../Error";
import { FaUserLock } from "react-icons/fa";
import Button from "../Button";
import { Link as RouterLink } from "react-router-dom";
import AuthButton from "../AuthButton";
import LanguageSwitch from "../MyAccount/LanguageSwitch";
import { BsExclamationTriangleFill, BsFillCartCheckFill } from "react-icons/bs";
import useStoredSession from "../../utils/useStoredSession";
import {
  Menu as DropdownMenu,
  Item as DropDownItem,
  Separator as DropdownSeparator,
} from "@zendeskgarden/react-dropdowns.next";
import ImageBox from "../ImageBox";
import { RiArchiveStackFill } from "react-icons/ri";
import { MdEditDocument } from "react-icons/md";
import { HiBuildingStorefront } from "react-icons/hi2";

const showCustomerApproval = config.uiSettings.showCustomerApproval;

const SHeader = styled.header`
  .header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-left {
    display: flex;
    align-items: center;
  }
  .header-right {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: end;
    padding: calc((7em - 36px) / 2);
    @media (max-width: 720px) {
      padding: 1em 1.1em;
    }
  }
  .header-lang {
    margin-right: 0.6em;
    .trigger-wrap {
      opacity: 0.6;
    }
  }
  .header-warning {
    margin-right: 0.8em;
  }
  .logo-link {
    display: flex;
    text-decoration: none;
    min-width: 180px;
    cursor: pointer;
    padding: 1.3em;
    align-items: center;
    min-height: 7em;
    img {
      width: auto;
      max-width: 100%;
      max-height: 90px;
      &.logo-custom-EGSDE,
      &.logo-custom-RSDE {
        margin-left: 10px;
        @media (max-width: ${(props) => props.theme.menuBreakpoint}) {
          margin-left: 1px;
        }
      }
    }
    @media (max-width: ${(props) => props.theme.menuBreakpoint}) {
      padding: 0;
      height: 48px;
      min-height: auto;
      img {
        max-height: 90%;
        max-width: 75%;
      }
    }
  }
  .user-menu {
    > [type="button"] {
      display: flex;
      align-items: center;
    }
    ul {
      font-size: 13px;
      li.active {
        transition: background 0.2s;
        background: ${(props) => props.theme.hoverColor};
      }
      svg:not(:first-child):not(:last-child) {
        color: ${(props) => props.theme.accentColor};
        opacity: 0.5;
        margin: 0 1em 0 -1.5em;
        width: 1.4em;
        height: 1.4em;
      }
    }
    .user-menu-icon-btn {
      vertical-align: -6px;
      color: ${(props) => props.theme.accentColor};
      &.extra-space {
        margin-left: 1em;
      }
      svg {
        color: ${(props) => props.theme.accentColor};
      }
    }
    .user-menu-icon-btn svg {
      width: auto;
      height: auto;
      font-size: 1em;
    }
    a,
    button:not(.default-button) {
      text-decoration: none;
      color: inherit;
    }
  }
  .banner-wrapper {
    display: block;
    border-radius: 0 0 5px 5px;
    overflow: hidden;
  }
  .user-name-label {
    margin-right: 0.5em;
    color: ${(props) => props.theme.linkColor};
    margin-left: 1em;
    cursor: pointer;
    transition: color 0.2s;
    &:hover {
      color: ${(props) => darken(0.05, props.theme.linkColor)};
    }
    @media (max-width: 720px) {
      display: none;
    }
  }
  .user-logo-wrap {
    max-width: 120px;
    display: flex;
    align-items: center;
    padding: 0.3em;
    margin-left: 0.4em;
    max-height: 48px;
    min-height: 28px;
    min-width: 28px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    transition: border-color 0.2s, height 0.2s;
    border: 1px solid ${(props) => props.theme.borderColor};
    background: white;
    .img-box-img {
      max-width: 100%;
      max-height: 100%;
    }
    @media (max-width: 720px) {
      max-height: 36px;
      .img-box-img {
        max-height: 36px;
      }
    }
  }
  .user-menu:hover {
    .user-logo-wrap {
      border-color: ${(props) => opacify(-0.4, props.theme.accentColor)};
    }
  }
  .dropdown-menu-icon {
    margin-left: -1em;
    font-size: 1.4em;
    color: ${(props) => opacify(-0.4, props.theme.accentColor)};
    margin-right: 0.6em;
    vertical-align: -0.2em;
  }
  .warning-icon-btn {
    margin-top: 0.3em;
    color: #e68a01;
    opacity: 0.5;
    transition: opacity 0.2s;
    &:hover,
    &.active {
      opacity: 1;
    }
  }
`;

const Header = ({
  children,
  showMessage,
  setShowMessage,
  hideMessage,
  messageVisible,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const { logout, user } = useAuth();

  const customerId = localStorage.getItem("customerId");

  const { data: customer, isLoading, isError } = useCustomer();
  const {
    data: mp,
    isLoading: mpIsLoading,
    isError: mpIsError,
  } = useMarketingProfile({
    customerId: customerId,
    shouldFetch: () => !!customerId,
  });

  const session = useStoredSession();
  const { pathname } = useLocation();

  const MenuItem = ({
    value,
    icon,
    onClick = () => navigate(value),
    children,
    ...rest
  }) => {
    return (
      <DropDownItem
        icon={icon}
        value={value}
        className={pathname === value ? "active" : ""}
        onClick={onClick}
        {...rest}
      >
        {children}
      </DropDownItem>
    );
  };

  return (
    <SHeader>
      <Card noPadding shadow className="no-print">
        <React.Fragment>
          <div className="header-top">
            <div className="header-left">
              <SideMenu
                customerCode={customer?.code}
                clientId={config.clientId}
                sessionGuid={session}
              />
              <Logo />
            </div>
            <div className="header-right">
              {config?.uiSettings?.maintenanceMessage && messageVisible ? (
                <div className="header-warning">
                  <Button
                    link
                    bigger
                    onClick={() => {
                      showMessage ? hideMessage() : setShowMessage(true);
                    }}
                    className={`warning-icon-btn ${
                      showMessage ? "active" : ""
                    }`}
                    title={t("ui.Scheduled-maintenance")}
                  >
                    <BsExclamationTriangleFill />
                  </Button>
                </div>
              ) : null}
              {config.uiSettings.languageSwitch ? (
                <div className="header-lang">
                  <LanguageSwitch />
                </div>
              ) : null}
              <div className="user-menu">
                {!!user?.subject && !!customerId ? (
                  <DropdownMenu
                    hasArrow
                    button={(props) => (
                      <div {...props}>
                        <span className="user-name-label">
                          {!!customer && !isLoading && !isError ? (
                            <span>
                              {customer.name}
                              <HiBuildingStorefront
                                className="icon-inline"
                                style={{
                                  marginLeft: ".4em",
                                  fontSize: "1.5em",
                                  opacity: 0.6,
                                  marginRight: 0,
                                }}
                              />
                            </span>
                          ) : null}
                        </span>
                        {!!mp &&
                        !!mp.images &&
                        !!mp.images.logo &&
                        !!mp.images.logo.fileId &&
                        !mpIsLoading &&
                        !mpIsError ? (
                          <div className="user-logo-wrap fadeInGrow">
                            <ImageBox
                              id={mp.images.logo.fileId}
                              noZoom
                              icon={<HiBuildingStorefront />}
                              bgColor="#ffffff"
                              params={{
                                ResizeMode: "Contain",
                                Width: 100,
                                Height: 36,
                              }}
                              center={false}
                              asBgImage={false}
                            />
                          </div>
                        ) : null}
                      </div>
                    )}
                  >
                    <MenuItem icon={icons.user} value="/my-account">
                      {t("ui.My-account")}
                    </MenuItem>
                    <MenuItem icon={<BsFillCartCheckFill />} value="/orders">
                      {t("ui.My-orders")}
                    </MenuItem>
                    {showCustomerApproval ? (
                      <MenuItem
                        icon={<MdEditDocument />}
                        value="/variants/to-approve"
                      >
                        {t("ui.Variants-to-approve")}
                      </MenuItem>
                    ) : null}
                    {showCustomerApproval ? (
                      <MenuItem
                        icon={<RiArchiveStackFill />}
                        value="/variants/approved"
                      >
                        {t("ui.Approved-variants")}
                      </MenuItem>
                    ) : null}
                    <MenuItem
                      icon={<HiBuildingStorefront />}
                      value="/select-customer"
                    >
                      {t("ui.Select-location")}
                    </MenuItem>
                    <DropdownSeparator />
                    <MenuItem
                      type="next"
                      value="logout"
                      icon={<FiLogOut />}
                      onClick={logout}
                    >
                      {t("ui.Log-out")}
                    </MenuItem>
                  </DropdownMenu>
                ) : null}
              </div>
            </div>
          </div>
          {!!customerId ? (
            <Nav customerCode={customer?.code} sessionGuid={session} />
          ) : null}
          {children}
        </React.Fragment>
      </Card>
      {isError || mpIsError ? (
        <Modal>
          <ModalBody>
            <Error
              errorObj={isError || mpIsError}
              icon={
                <FaUserLock
                  className="center-icon fadeInGrow"
                  style={{ marginBottom: "28px" }}
                />
              }
            >
              <p className="links-wrap">
                <Button
                  as={RouterLink}
                  $link
                  to="/select-customer"
                  className="icon-link"
                >
                  <HiBuildingStorefront style={{ opacity: ".4" }} />{" "}
                  {t("ui.Select-location")}
                </Button>{" "}
                <AuthButton
                  btnProps={{
                    stretch: true,
                    className: "no-margin margin-top margin-bottom-small",
                  }}
                  logoutText={
                    <>
                      <span> {t("error.Log-in-again-btn")}</span>
                      <FiLogOut />
                    </>
                  }
                />
              </p>
            </Error>
          </ModalBody>
        </Modal>
      ) : null}
    </SHeader>
  );
};

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Header;
