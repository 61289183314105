import React, { useState } from "react";
import ImageBox from "../ImageBox";
import Card from "../Card";
import { useTranslation } from "react-i18next";
import icons from "../../icons";
import Button from "../Button";
import DOMPurify from "dompurify";
import Badge from "../Badge";
import theme from "../../styles/theme";
import { Alert, useToast } from "@zendeskgarden/react-notifications";
import callVariantApproval from "../../utils/callVariantApproval";
import Notification from "../Notification";
import VariantModal from "./VariantModal";
import { GiFoldedPaper } from "react-icons/gi";
import config from "../../config";
import domPurifyWithHooks from "../../utils/adjustLinks";
import styled from "styled-components";
import createProof from "../../utils/createProof";
import { Spinner } from "@zendeskgarden/react-loaders";
import useVariantsSearch from "../../utils/useVariantsSearch";
import { useSWRConfig } from "swr";
import Loader from "../Loader";
import apiConfig from "../../apiConfig";
import {
  Modal,
  Header as ModalHeader,
  Body as ModalBody,
  Footer as ModalFooter,
  Close,
} from "@zendeskgarden/react-modals";
import { IoInformationCircleSharp } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { BsEnvelopeAtFill } from "react-icons/bs";

const showCustomerApproval = config.uiSettings.showCustomerApproval;

const SMailMomentCard = styled(Card)`
  .star-icon {
    color: orange;
    font-size: 1.5em;
    margin: -1em 0.4em -1em 0;
    vertical-align: middle;
    display: inline-block;
  }
  .badge {
    font-weight: 600;
  }
  .preview-wrap {
    border: 1px solid ${(props) => props.theme.borderColor};
    width: 88px;
    float: left;
    cursor: pointer;
  }
  .preview-wrap + .text-wrap {
    padding-left: calc(88px + 1em);
    min-height: 88px;
  }
  .v-wrap {
    padding-top: 1em;
    margin-top: 1em;
    border-top: 1px solid ${(props) => props.theme.borderColor};
  }
  .title-with-icon {
    margin-bottom: 0.5em;
    font-weight: 400;
    font-size: 1.1em;
    svg {
      font-size: 1.1em;
      width: 1em;
      opacity: 0.6;
      display: inline-block;
      color: ${(props) => props.theme.accentColor};
      vertical-align: -0.17em;
      margin-right: 0.4em;
    }
  }
  @media (max-width: 440px) {
    .preview-wrap {
      float: none;
    }
    .text-wrap {
      padding-left: 0;
    }
    .preview-wrap + .text-wrap {
      padding-top: 0.6em;
    }
  }
`;

const MailMoment = ({
  isSelected,
  onNext,
  shortName,
  code,
  description,
  expandedDescription = null,
  isDefault,
  name,
  id,
  thumbnailFileId,
  variants = [],
  fetchVariants = () => false,
  updateParentVariants,
}) => {
  const { t } = useTranslation("common");
  const [modalId, setModalId] = useState(null);
  const [creatingNew, setCreatingNew] = useState(false);
  const { addToast } = useToast();
  const { mutate } = useSWRConfig();
  const { mmId } = useParams();

  const [showExpandDescription, setShowExpandedDescription] = useState(
    +mmId === +id
  );

  const {
    data: variantsByMMWithHeaders,
    isLoading: variantsByMMIsLoading,
    isError: variantsByMMIsError,
    cacheKey: variantsByMMCacheKey,
  } = useVariantsSearch({
    mailmomentId: id,
    shouldFetch: fetchVariants,
  });

  const variantsByMM = variants?.length
    ? variants
    : !!variantsByMMWithHeaders
    ? variantsByMMWithHeaders.data
    : [];

  const updateVariants = () =>
    updateParentVariants
      ? updateParentVariants()
      : mutate(variantsByMMCacheKey);

  const approveVariant = (id) => {
    callVariantApproval({
      id: id,
      onSuccess: () => {
        addToast(
          ({ close }) => (
            <Notification
              title={t("ui.Variant-approved")}
              type="success"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
        updateVariants();
        // Go to next step if no more variants in this mailmoment
        if (variantsByMM[variantsByMM.length - 1].id === id) {
          setTimeout(() => {
            onNext();
          }, 1000);
        }
        setModalId(null);
      },
      onError: (error) => {
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Something-went-wrong")}
              text={t("error.Failed-to-approve-variant")}
              errorText={
                !!error && !!error.length && !!error[0]
                  ? error[0]
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
    });
  };

  const handleCreateNewProof = (id) => {
    setCreatingNew(id);
    createProof({
      id: id,
      onSuccess: (res) => {
        setModalId(id);
        setCreatingNew(null);
      },
      onError: (error) => {
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Something-went-wrong")}
              text={t("error.Failed-to-create-proof")}
              errorText={
                !!error && !!error.length && !!error[0]
                  ? error[0]
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
        setCreatingNew(null);
      },
    });
  };

  domPurifyWithHooks(DOMPurify);

  return (
    <SMailMomentCard
      whiteBg
      active={isSelected}
      style={
        !variantsByMM.length && !showCustomerApproval ? { display: "none" } : {}
      }
    >
      <div>
        <span style={{ float: "right" }}>
          {showCustomerApproval ? (
            !!variantsByMM && !!variantsByMM.length > 0 ? (
              variantsByMM.every((item) => item?.customerApproval?.approved) ? (
                <Badge
                  background={theme.successColor}
                  style={{ color: "white" }}
                >
                  {icons.check} {t("ui.approved")}
                </Badge>
              ) : (
                <Badge state={"closed"}>
                  {icons.close} {t("ui.rejected")}
                </Badge>
              )
            ) : (
              <Badge>
                {icons.close} {t("ui.no-variants-available")}
              </Badge>
            )
          ) : null}
        </span>
        <h4 style={{ overflowWrap: "break-word" }}>
          {isDefault ? (
            <span className="star-icon" title={t("ui.Recommended")}>
              {icons.star}
            </span>
          ) : null}
          <Badge className="badge">{shortName}</Badge>
          &nbsp;&nbsp;
          {name}
        </h4>
      </div>

      {isSelected ? (
        <div>
          {!!thumbnailFileId || !!description || !!expandedDescription ? (
            <div style={{ marginTop: "1em" }}>
              {!!thumbnailFileId ? (
                <div
                  style={{
                    width: "65px",
                    marginRight: "1em",
                    float: "left",
                  }}
                >
                  <ImageBox id={thumbnailFileId} boxHeight="100%" />
                </div>
              ) : null}

              {!!description || !!expandedDescription ? (
                <div className="content-block">
                  {description ? (
                    <div
                      className="html-block"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(description, {
                          USE_PROFILES: { html: true },
                        }),
                      }}
                    />
                  ) : null}

                  {!!expandedDescription ? (
                    <Button
                      link
                      onClick={() => setShowExpandedDescription(true)}
                    >
                      <IoInformationCircleSharp className="list-icon" />
                      <span>{t("ui.More-info")}</span>
                    </Button>
                  ) : null}
                </div>
              ) : null}

              <div className="clearfix"></div>

              {expandedDescription && showExpandDescription ? (
                <Modal
                  isLarge
                  onClose={() => setShowExpandedDescription(false)}
                  appendToNode={document.body}
                >
                  <ModalHeader className="modal-header">
                    <h3>
                      <Badge>{code}</Badge> {name}
                    </h3>
                  </ModalHeader>
                  <ModalBody className="content-block">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(expandedDescription, {
                          USE_PROFILES: { html: true },
                        }),
                      }}
                    />
                  </ModalBody>
                  <ModalFooter className="modal-footer">
                    <Button
                      secondary
                      onClick={() => setShowExpandedDescription(false)}
                    >
                      {icons.check}
                    </Button>
                  </ModalFooter>
                  <Close aria-label="Close modal" className="modal-close-btn" />
                </Modal>
              ) : null}
            </div>
          ) : null}

          {!variants?.length && fetchVariants() && !!variantsByMMIsLoading ? (
            <div className="border-top margin-top padding-top">
              <Loader style={{ width: "100%", maxHeight: "90px" }} />
            </div>
          ) : !variants?.length && fetchVariants() && !!variantsByMMIsError ? (
            <div className="border-top margin-top padding-top">
              <Alert type="error">
                {t("error.Something-went-wrong")}:{" "}
                {t("ui.no-variants-available")}
              </Alert>
            </div>
          ) : !!variantsByMM && variantsByMM.length > 0 ? (
            variantsByMM.map((v) => (
              <div key={v?.id} className="v-wrap">
                <h4 className="title-with-icon">
                  {v?.kind === "dm" ? (
                    <GiFoldedPaper className="title-icon" />
                  ) : (
                    <BsEnvelopeAtFill className="title-icon" />
                  )}
                  {v?.name}
                  {/* <Badge style={{ float: "right" }}>
                    {v?.kind === "dm" ? "DM" : "eDM"}
                  </Badge> */}
                </h4>
                {v?.recentProofFileId ? (
                  <div
                    className="preview-wrap"
                    onClick={() => setModalId(v?.id)}
                  >
                    <ImageBox
                      id={v?.recentProofFileId}
                      api={apiConfig.proofs}
                      boxHeight="100%"
                      className="hover-zoom"
                      params={{
                        Format: "jpg",
                        Width: 120,
                        Height: 120,
                      }}
                    />
                  </div>
                ) : null}
                <div className="text-wrap">
                  <Button
                    stretch
                    outline={v?.id !== creatingNew}
                    onClick={() => handleCreateNewProof(v?.id)}
                    style={{ marginBottom: ".6em" }}
                    disabled={v?.id === creatingNew}
                  >
                    {v?.id === creatingNew ? (
                      <span
                        style={{
                          display: "inline-block",
                          minWidth: "20px",
                          marginRight: "0.9em",
                        }}
                      >
                        <Spinner />
                      </span>
                    ) : (
                      icons.edit
                    )}
                    <span>
                      {v?.id === creatingNew
                        ? t("ui.One-moment-please")
                        : // t("ui.View")
                          t("ui.View-and-edit")}
                    </span>
                  </Button>
                  {showCustomerApproval ? (
                    <Button
                      disabled={v?.customerApproval?.approved}
                      stretch
                      primary
                      onClick={() => approveVariant(v?.id)}
                    >
                      {icons.check}{" "}
                      <span>
                        {v?.customerApproval?.approved
                          ? t("ui.Approved")
                          : t("ui.Approve")}
                      </span>
                    </Button>
                  ) : null}
                  {modalId === v?.id ? (
                    <VariantModal
                      setModalId={setModalId}
                      updateVariants={updateVariants}
                      variant={v}
                      approveVariant={approveVariant}
                    />
                  ) : null}
                </div>
              </div>
            ))
          ) : null}
        </div>
      ) : null}
    </SMailMomentCard>
  );
};

export default MailMoment;
