const groupByPath = (arr = []) => {
  let obj = {};
  arr.forEach((item) => {
    item.path
      .substring(1)
      .split("/")
      .reduce((prev, curr) => {
        const currArr = !!prev[!curr ? "/" : curr]?.nestedItems
          ? prev[!curr ? "/" : curr].nestedItems
          : [];
        return (prev[!curr ? "/" : curr] = {
          nestedItems: item.path.endsWith(curr) ? [...currArr, item] : currArr,
          nestedItemPath: !curr
            ? "/"
            : item.path.substring(0, item.path.indexOf(curr)) + curr,
        });
      }, obj);
  });

  return obj;
};

export default groupByPath;
