import React from "react";
import { useTranslation } from "react-i18next";
import config from "../config";

const LoginText = () => {
  const { t } = useTranslation("common");

  return <p>{t(`ui.Login-intro-${config.clientCode}`)}</p>;
};

export default LoginText;
