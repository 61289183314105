import React, { useState } from "react";
import Card from "../Card";
import { useTranslation } from "react-i18next";
import icons from "../../icons";
import { Row, Col } from "styled-bootstrap-grid";
import Button from "../Button";
import AddressBox from "./AddressBox";
import ImageBox from "../ImageBox";
import styled from "styled-components";
import {
  Modal,
  Header,
  Body,
  Footer,
  Close,
} from "@zendeskgarden/react-modals";
import { useToast } from "@zendeskgarden/react-notifications";
import { useDropzone } from "react-dropzone";
import { Field, Input, FileUpload } from "@zendeskgarden/react-forms";
import callImages from "../../utils/callImages";
import uploadFile from "../../utils/uploadFile";
import Notification from "../Notification";
import { CUSTOMERIMAGES } from "../../constants";
import apiConfig from "../../apiConfig";
import { BsArrow90DegUp } from "react-icons/bs";
import { AiOutlineFullscreen, AiOutlineUpload } from "react-icons/ai";

const { images: imageApi } = apiConfig;

const SAddressBox = styled(AddressBox)`
  .img-box-img {
    padding: 1em;
  }
`;

const SModal = styled(Modal)`
  max-width: 97%;
  max-height: 97%;
  margin: auto;
  left: 0;
  right: 0;
  .modal-header {
    padding-left: 20px;
  }
  .modal-body {
    padding-left: 21px;
    padding-right: 21px;
  }
  .logo-modal-footer {
    justify-content: space-between;
    .info-label {
      margin: -0.5em 0;
      padding-left: 2px;
      width: 100%;
      .label-smaller {
        font-size: 12px;
        line-height: 1.35em;
      }
      .icon {
        float: left;
        display: block;
        height: 50px;
        width: 45px;
        padding-right: 8px;
        svg {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      h4 {
        font-weight: 600;
        margin-bottom: 0;
        line-height: 1.35;
      }
    }
    .btn-wrap {
      width: 100%;
      text-align: right;
      white-space: nowrap;
      a,
      button {
        margin-left: 6px;
      }
    }
    @media (max-width: 676px) {
      flex-wrap: wrap;
      .info-label {
        margin-bottom: 0.5em;
      }
    }
  }
  .delete-btn:not(:disabled) {
    &:hover {
      background: #9d5454;
    }
  }
`;

const SFileUploadField = styled(Field)`
  label {
    display: block;
    margin-bottom: 1.2em;
  }
  .upload-box {
    border-width: 3px;
    border-color: #ddd;
    position: absolute;
    z-index: 1;
    inset: -2px;
    justify-content: end;
    align-items: start;
    &:hover {
      background: transparent;
    }
    .upload-box-hint {
      position: absolute;
      top: 100%;
      display: block;
      right: 0;
      font-size: 12px;
      opacity: 0.5;
      transition: opacity 0.2s;
    }
    &:hover {
      border-color: ${(props) => props.theme.accentColor};
      .upload-box-hint {
        opacity: 1;
      }
    }
    .arrow {
      font-size: 1.3em;
      line-height: 1;
      margin-right: 0.4em;
      color: ${(props) => props.theme.successColor};
    }
  }
  .btn-toolbar {
    position: absolute;
    font-size: 14px;
    right: 0.6em;
    top: 0.6em;
    z-index: 1;
    .btn {
      margin-left: 6px;
      height: 39px;
      width: 39px;
      border-radius: 4px;
      vertical-align: top;
    }
    .delete-btn {
      &:hover {
        background: #9d5454;
      }
    }
  }
`;

const LogosBlock = ({ images, refreshMp, mpId }) => {
  const { t } = useTranslation("common");

  const [dialogId, setDialogId] = useState("");
  const [isBusy, setIsBusy] = useState(false);

  const { addToast } = useToast();

  const openDialog = (id) => {
    setDialogId(id);
  };

  const cancelDialog = () => {
    setDialogId("");
  };

  const captions = [
    {
      key: CUSTOMERIMAGES.LOGO,
      caption: "Full-color-logo",
    },
    {
      key: CUSTOMERIMAGES.TEAM,
      caption: "Team-photo",
    },
    {
      key: CUSTOMERIMAGES.ROUTE,
      caption: "Route",
    },
    {
      key: CUSTOMERIMAGES.LOCATION,
      caption: "Location",
    },
    {
      key: CUSTOMERIMAGES.ADVISOR,
      caption: "Service-advisor",
    },
    {
      key: CUSTOMERIMAGES.PREMIUM,
      caption: "Premium-photo",
    },
  ];

  const imgWithCaptions = captions.map((item) => {
    return {
      ...item,
      ...images[item.key],
    };
  });

  const handleSubmit = ({ key, fileName, fileId }) => {
    callImages({
      mpId: mpId,
      data: {
        ...images,
        [key]: {
          target: key,
          fileName: !!fileName ? fileName : "noFileName",
          fileId: fileId,
          description: "",
        },
      },
      onSuccess: () => {
        setIsBusy(false);
        refreshMp();
        addToast(
          ({ close }) => (
            <Notification
              title={`${t(`my-account.File-upload-successful`)}`}
              text={`${t(`my-account.Logos`)}: ${t(
                `my-account.${
                  captions.filter((c) => c.key === key)?.[0]?.caption
                }`
              )} ${t("ui.saved")}`}
              type="success"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
      onError: (error) => {
        setIsBusy(false);
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Oops-Something-went-wrong")}
              text={t("error.Saving-image-failed")}
              errorText={
                !!error ? error : t("error.Sorry-an-unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
    });
  };

  const onDrop = (acceptedFiles) => {
    setIsBusy(true);
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      uploadFile({
        format: file.name.split(".").pop(),
        fileName: file.name,
        fileCategory: "CustomerUpload",
        file: file,
        onSuccess: (data) => {
          handleSubmit({
            key: dialogId,
            fileName: file.name,
            fileId: data.fileId,
          });
        },
        onError: (error) => {
          setIsBusy(false);
          addToast(
            ({ close }) => (
              <Notification
                title={t("error.Oops-Something-went-wrong")}
                text={t("error.File-upload-failed")}
                errorText={
                  !!error
                    ? error
                    : t("error.Sorry-an-unexpected-error-occurred")
                }
                type="error"
                handleClose={close}
              />
            ),
            { placement: "bottom" }
          );
        },
      });
    }
  };

  const acceptedFormats = {
    "image/*": [".png", ".jpg", ".jpeg", ".psd", ".eps"],
    "application/pdf": [".pdf", ".ai"],
  };

  const extensionsArr = Object.values(acceptedFormats).flat();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptedFormats,
    multiple: false,
    onDrop,
  });

  const handleDelete = (key) => {
    setIsBusy(true);
    callImages({
      mpId: mpId,
      data: { ...images, [key]: {} },
      onSuccess: () => {
        setIsBusy(false);
        refreshMp();
      },
      onError: (error) => {
        setIsBusy(false);
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Oops-Something-went-wrong")}
              text={t("error.Deleting-image-failed")}
              errorText={
                !!error ? error : t("error.Sorry-an-unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
    });
  };

  return (
    <Card title={t("my-account.Logos")} shadow icon={icons.star}>
      <p className="margin-bottom">{t("my-account.Logos-description")}</p>
      <div className="row-wrap" style={{ marginBottom: "-1.2em" }}>
        <Row>
          {imgWithCaptions.map((item) => (
            <Col sm={4} key={item.key}>
              <SAddressBox
                style={{ marginBottom: "1.2em" }}
                onClick={() => openDialog(item.key)}
                className={`hover-box ${dialogId === item.key ? "active" : ""}`}
              >
                <div style={{ position: "relative", cursor: "pointer" }}>
                  <ImageBox
                    params={{
                      Width: 200,
                      Height: 200,
                      ResizeMode: "Contain",
                    }}
                    id={item.fileId}
                    boxHeight="100%"
                    className="hover-zoom"
                    asBgImage={false}
                    alt={item?.caption || item.fileId}
                    bgColor="#ffffff"
                  />
                </div>
                <div className="box-footer">
                  <h3>{t(`my-account.${item.caption}`)}</h3>
                </div>
              </SAddressBox>
              {dialogId === item.key && (
                <SModal
                  isLarge
                  onClose={cancelDialog}
                  appendToNode={document.body}
                  aria-label="Select logos"
                >
                  <Header className="modal-header">
                    <h3>
                      <span className="list-icon">{icons.star}</span>
                      <span className="icon-color">
                        {t("my-account.Logos")}:
                      </span>{" "}
                      {t(`my-account.${item.caption}`)}
                    </h3>
                  </Header>
                  <Body className="modal-body">
                    <SFileUploadField>
                      <div
                        style={{
                          position: "relative",
                          marginBottom: "1em",
                          marginTop: ".6em",
                        }}
                      >
                        <FileUpload
                          {...getRootProps()}
                          isDragging={isDragActive}
                          className="upload-box"
                        >
                          <span className="upload-box-hint">
                            <BsArrow90DegUp className="arrow" />
                            {isDragActive
                              ? t("my-account.Drop-files-here")
                              : t(
                                  "my-account.Click-to-choose-a-file-or-drag-and-drop-here"
                                )}
                          </span>
                          <Input {...getInputProps()} id="fileUpload" />
                        </FileUpload>

                        <ImageBox
                          id={item?.fileId || null}
                          alt="Preview"
                          boxHeight="70%"
                          params={{
                            Width: 700,
                            Height: 700,
                            ResizeMode: "Contain",
                          }}
                          asBgImage={false}
                          icon={AiOutlineUpload}
                          withLoader={isBusy}
                        />

                        {!!item?.fileId ? (
                          <div className="btn-toolbar">
                            <Button
                              as={"a"}
                              target="_blank"
                              href={`${imageApi}/${item?.fileId}`}
                              secondary
                              square
                              tabIndex={-1}
                              title={t("ui.View-fullscreen")}
                              className="btn"
                            >
                              <AiOutlineFullscreen />
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    </SFileUploadField>
                  </Body>
                  <Footer className="modal-footer logo-modal-footer">
                    <div className="info-label">
                      <span className="icon icon-color">{icons.info}</span>
                      <h4>{t("my-account.Upload-a-new-image")}</h4>
                      <div className="label-smaller">
                        <span className="list-label">
                          {t("my-account.Accepted-formats")}{" "}
                        </span>
                        {extensionsArr
                          .filter((ext) => ext !== ".jpeg")
                          .map((item, index) => (
                            <span key={item} className="font-monospace">
                              {item}{" "}
                            </span>
                          ))}
                        <br />
                        <span className="list-label">
                          {t("my-account.Minimum-resolution")}{" "}
                        </span>
                        <span key={item} className="font-monospace">
                          300+ dpi
                        </span>
                      </div>
                    </div>

                    <div className="btn-wrap">
                      <Button
                        secondary
                        type="button"
                        onClick={() => handleDelete(item.key)}
                        className="delete-btn"
                        disabled={!item?.fileId || isBusy}
                      >
                        {icons.trash}
                        <span>{t("my-account.Delete")} </span>
                      </Button>
                    </div>
                  </Footer>
                  <Close aria-label={t("my-account.Close")} />
                </SModal>
              )}
            </Col>
          ))}
        </Row>
      </div>
    </Card>
  );
};

export default LogosBlock;
