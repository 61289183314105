import React, { useContext, useState } from "react";
import Card from "../Card";
import { useTranslation } from "react-i18next";
import icons from "../../icons";
import { Row, Col } from "styled-bootstrap-grid";
import Button from "../Button";
import AddressBox from "./AddressBox";
import Notification from "../Notification";
import encodeParams from "../../utils/encodeParams";
import { useToast } from "@zendeskgarden/react-notifications";
import Dialog from "../Dialog";
import callBrands from "../../utils/callBrands";
import LoaderOverlay from "../LoaderOverlay";
import styled, { css } from "styled-components";
import {
  Modal,
  Header,
  Body,
  Footer,
  FooterItem,
  Close,
} from "@zendeskgarden/react-modals";
import Badge from "../Badge";
import { lighten } from "polished";
import SearchBox from "../SearchBox";
import { SearchContext } from "../SearchProvider";
import filterBy from "../../utils/filterBy";
import ImageBox from "../ImageBox";
import useAllBrands from "../../utils/useAllBrands";
import apiConfig from "../../apiConfig";

const { images: imageApi } = apiConfig;

const SModal = styled(Modal)`
  max-width: 97%;
  max-height: 97%;
  margin: auto;
  left: 0;
  right: 0;
`;

const SelectBox = styled(AddressBox)`
  transition: background 0.2s;
  ${(props) =>
    props.selected &&
    css`
      background: ${lighten(0.06, props.theme.successColor)};
    `}
`;

const BrandsBlock = ({ mpId, mpBrands, refreshMp }) => {
  const { t } = useTranslation("common");
  const brands = mpBrands.filter((item) => !!item.fileName && !!item.fileId);

  const { data: allBrands } = useAllBrands();

  // Confirm deletion dialog
  const [itemEditedId, setItemEditedId] = useState("");
  const [dialogId, setDialogId] = useState("");
  const { addToast } = useToast();

  const openDialog = (id) => {
    setDialogId(id);
    setItemEditedId(id);
  };
  const cancelDelete = () => {
    setDialogId("");
    setItemEditedId("");
  };
  const handleDelete = (fileId) => {
    callBrands({
      mpId: mpId,
      data: brands.filter((item) => item.fileId !== fileId),
      onSuccess: () => {
        setDialogId("");
        setItemEditedId("");
        refreshMp();
      },
      onError: (error) => {
        setDialogId("");
        setItemEditedId("");
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Something-went-wrong")}
              text={t("error.Failed-to-update-brand-logos")}
              errorText={
                !!error.title
                  ? error.title
                  : typeof error === "string" && !!error
                  ? error
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
    });
  };

  // Add/edit modal
  const [modalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState(brands);
  const isSelected = (fileId) =>
    selected.filter((item) => item.fileId === fileId).length > 0;
  const handleSelectBrand = (newItem) => {
    if (!newItem.fileName) {
      newItem.fileName = "NoFileName";
    }
    if (!isSelected(newItem.fileId) && selected.length < mpBrands.length) {
      setSelected([...selected, newItem]);
    } else {
      setSelected(selected.filter((item) => item.fileId !== newItem.fileId));
    }
  };
  const handleSubmit = () => {
    callBrands({
      mpId: mpId,
      data: selected,
      onSuccess: () => {
        setModalOpen(false);
        refreshMp();
      },
      onError: (error) => {
        setModalOpen(false);
        addToast(
          ({ close }) => (
            <Notification
              title={t("ui.Something-went-wrong")}
              text={t("ui.Failed-to-delete-supplier")}
              errorText={
                !!error.title
                  ? error.title
                  : typeof error === "string" && !!error
                  ? error
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
    });
  };

  // Search
  const searchContext = useContext(SearchContext);
  const q = searchContext.brandFilter;
  const filteredItems = !!q.length
    ? filterBy(allBrands, ["fileName"], q)
    : allBrands;

  return (
    <Card
      title={t("my-account.Brand-selection")}
      shadow
      icon={icons.bookmark}
      headerRight={
        <h3>
          <Badge large primaryLighter>
            {brands.length} / {mpBrands.length}
          </Badge>
        </h3>
      }
    >
      <p className="margin-bottom">{t("my-account.Brands-description")}</p>
      <div className="row-wrap">
        <Row>
          {brands.map((item) => (
            <Col sm={4} key={item.fileId}>
              <AddressBox style={{ marginBottom: "1.2em" }}>
                <div
                  style={{
                    position: "relative",
                    cursor: "pointer",
                  }}
                  onClick={() => setModalOpen(true)}
                >
                  <ImageBox
                    params={{
                      Width: 140,
                      Height: 140,
                      ResizeMode: "Contain",
                    }}
                    id={item.fileId}
                    boxHeight="100%"
                    className="hover-zoom"
                  />
                  {itemEditedId === item.fileId && <LoaderOverlay />}
                </div>
                <h4 style={{ marginTop: ".6em", fontSize: ".9em" }}>
                  {item.fileName}
                </h4>
                <div className="text-right">
                  <Button link small onClick={() => openDialog(item.fileId)}>
                    {icons.trash}
                    <span>{t("my-account.Delete")}</span>
                  </Button>
                </div>
                {dialogId === item.fileId && (
                  <Dialog
                    title={t("my-account.Delete-brand")}
                    text={t("my-account.Would-you-like-to-delete-this-brand")}
                    name={item.fileName}
                    onConfirm={() => handleDelete(item.fileId)}
                    onCancel={cancelDelete}
                  />
                )}
              </AddressBox>
            </Col>
          ))}
        </Row>
        <div className="text-right">
          <React.Fragment>
            <Button
              primary
              round
              onClick={() => {
                setModalOpen(true);
                setSelected(brands);
              }}
            >
              {icons.add}
            </Button>

            {modalOpen && (
              <SModal
                isLarge
                onClose={() => setModalOpen(false)}
                appendToNode={document.body}
              >
                <Header>
                  <h3>
                    {t("my-account.Add-brands")}{" "}
                    <Badge primaryLighter>
                      {selected.length} / {mpBrands.length}
                    </Badge>
                  </h3>
                </Header>
                <Body>
                  <div style={{ marginBottom: "1.2em", display: "flex" }}>
                    <div style={{ flexGrow: 1, marginRight: ".6em" }}>
                      <SearchBox
                        label={t("my-account.Search-brands")}
                        filter="brandFilter"
                        setFilter="setBrandFilter"
                      />
                    </div>
                    <Button outline onClick={() => setSelected([])}>
                      {t("my-account.Deselect-all")}
                    </Button>
                  </div>
                  <div className="row-wrap">
                    <Row>
                      {filteredItems.map((item) => (
                        <Col sm={3} key={item.fileId}>
                          <SelectBox
                            onClick={() => handleSelectBrand(item)}
                            style={{
                              marginBottom: "1.2em",
                            }}
                            selected={isSelected(item.fileId)}
                          >
                            <div
                              style={{
                                position: "relative",
                                paddingBottom: "100%",
                              }}
                            >
                              <img
                                src={`${imageApi}/${item.fileId}${encodeParams({
                                  Width: "140",
                                  Height: "140",
                                  ResizeMode: "Contain",
                                })}`}
                                alt={item.fileName}
                                className="logo"
                                style={{
                                  position: "absolute",
                                  inset: 0,
                                  maxHeight: "100%",
                                }}
                              />
                            </div>
                            <h4 style={{ marginTop: ".6em", fontSize: ".8em" }}>
                              {item.fileName}
                            </h4>
                          </SelectBox>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Body>
                <Footer>
                  <FooterItem>
                    <Button
                      type="button"
                      onClick={() => setModalOpen(false)}
                      outline
                    >
                      {icons.close}
                      <span>{t("ui.Cancel")} </span>
                    </Button>
                  </FooterItem>
                  <FooterItem>
                    <Button primary type="submit" onClick={handleSubmit}>
                      {icons.check}
                      <span>{t("my-account.Save")}</span>
                    </Button>
                  </FooterItem>
                </Footer>
                <Close aria-label={t("my-account.Close")} />
              </SModal>
            )}
          </React.Fragment>
        </div>
      </div>
    </Card>
  );
};

export default BrandsBlock;
