import React, { useEffect, useState } from "react";
import Card from "../Card";
import Layout from "../parts/Layout";
import { useTranslation } from "react-i18next";
import Loader from "../Loader";
import Error from "../Error";
import icons from "../../icons";
import ContactText from "../ContactText";
import OrderList from "../OrderList";
import { useParams } from "react-router-dom";
import useProgram from "../../utils/useProgram";
import config from "../../config";
import encodeParams from "../../utils/encodeParams";
import Button from "../Button";
import WizardButtons from "../OrderWizard/WizardButtons";
import DOMPurify from "dompurify";
import CardList from "../CardList";
import { Col } from "styled-bootstrap-grid";
import CycleIcon from "../CycleIcon";
import {
  Alert,
  Title,
  Close as CloseAlert,
} from "@zendeskgarden/react-notifications";
import useOrders from "../../utils/useOrders";
import useDefaultProfile from "../../utils/useDefaultProfile";
import MailMoment from "../proofing/MailMoment";
import Breadcrumbs from "../parts/Breadcrumbs";
import domPurifyWithHooks from "../../utils/adjustLinks";
import apiConfig from "../../apiConfig";
import { VOLVO } from "../../constants";
import useInfinite from "../../utils/useInfinite";

const showCustomerApproval = config.uiSettings.showCustomerApproval;

const CycleDetail = () => {
  const { cycleId } = useParams();

  // Data
  const {
    data: cycle,
    isLoading: cycleIsLoading,
    isError: cycleIsError,
  } = useProgram(cycleId);

  const {
    data: ordersWithHeaders,
    isLoading: ordersIsLoading,
    isError: ordersIsError,
  } = useOrders({ pageSize: 5 });
  const orders = !!ordersWithHeaders ? ordersWithHeaders.data : [];

  const {
    data: defaultProfile,
    isLoading: defaultProfileIsLoading,
    isError: defaultProfileIsError,
  } = useDefaultProfile(cycleId);

  // Fetch all variants
  const PAGE_SIZE = 30;
  const { phoenix: phoenixApi } = apiConfig;
  const customerId = localStorage.getItem("customerId");

  const {
    data: variants,
    isError: variantsError,
    size: variantsPage,
    setSize: setVariantsPage,
    mutate: mutateVariants,
  } = useInfinite({
    key: `${phoenixApi}/variants`,
    pageSize: PAGE_SIZE,
    params: {
      programId: cycleId,
      functionallyActive: true,
      orderByDirection: "descending",
      customerId: customerId,
    },
  });

  const allVariants = !!variants ? [].concat(...variants) : [];
  const variantsIsLoadingInitialData = !variants && !variantsError;
  const variantsIsLoadingMore =
    variantsIsLoadingInitialData ||
    (variantsPage > 0 &&
      !!variants &&
      typeof variants[variantsPage - 1] === "undefined");
  const variantsIsEmpty = variants?.[0]?.length === 0;
  const variantsIsReachingEnd =
    variantsIsEmpty ||
    (!!variants && variants[variants.length - 1]?.length < PAGE_SIZE);

  useEffect(() => {
    if (
      !!variants &&
      !!variants[variantsPage - 1] &&
      !variantsIsEmpty &&
      !variantsIsLoadingMore &&
      !variantsIsReachingEnd
    ) {
      setVariantsPage(variantsPage + 1);
    }
  }, [
    variants,
    variantsPage,
    variantsIsEmpty,
    variantsIsLoadingMore,
    variantsIsReachingEnd,
    setVariantsPage,
  ]);

  const { t } = useTranslation("common");
  const titleCycles =
    config.clientCode === VOLVO ? t("menu-Volvo.Cycles") : t("menu.Cycles");

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showMessage, setShowMessage] = useState(true);

  const displayMM = !!cycle
    ? cycle.mailMoments
        .filter((mm) => !!mm.active)
        .sort((val1, val2) => (val1.sequence > val2.sequence ? 1 : -1))
        .map((mm) => ({
          code: mm.code,
          shortName: mm.shortName,
          description: mm.description,
          expandedDescription: mm.expandedDescription,
          id: mm.id,
          name: mm.name,
          thumbnailFileId: mm.thumbnailFileId,
          isDefault:
            !!defaultProfile &&
            !defaultProfileIsLoading &&
            !defaultProfileIsError
              ? !!defaultProfile.profileVariants &&
                defaultProfile.profileVariants.filter(
                  (defaultProfileVariant) =>
                    defaultProfileVariant.mailMoment.id === mm.id
                ).length > 0
              : false,
          variants: allVariants?.length
            ? allVariants?.filter((v) => v?.mailMoment?.id === mm.id)
            : [],
        }))
        .filter((mm) => !!mm?.variants?.length)
    : [];

  const allApproved =
    !showCustomerApproval ||
    (allVariants?.length > 0 &&
      displayMM.every((mm) =>
        mm?.variants?.every((v) => !!v?.customerApproval?.approved)
      )) ||
    allVariants?.length === 0;

  const bannerImgUrl =
    !!cycle &&
    (!!cycle.bigSliderFileId || !!cycle.smallSliderFileId || !!cycle.demoFileId)
      ? apiConfig.images +
        "/" +
        (!!cycle.bigSliderFileId
          ? cycle.bigSliderFileId
          : !!cycle.smallSliderFileId
          ? cycle.smallSliderFileId
          : !!cycle.demoFileId
          ? cycle.demoFileId
          : null) +
        encodeParams({
          Width: 715,
          Height: 300,
          ResizeMode: "Cover",
        })
      : null;

  const onNext = () => setSelectedIndex(selectedIndex + 1);
  const onBack = () => setSelectedIndex(selectedIndex - 1);

  domPurifyWithHooks(DOMPurify);

  return (
    <Layout
      main={
        <Card
          title={
            (!!cycle && cycle.marketingName) || (!!cycle && cycle.name) || "..."
          }
          shadow
          icon={<CycleIcon />}
        >
          {cycleIsLoading ? (
            // || variantsIsLoading
            <Loader />
          ) : cycleIsError ? (
            // || !!variantsIsError
            <Error
              errorObj={cycleIsError}
              errorMessageUnauthorized={t("error.Content-unavailable")}
            />
          ) : !cycle ? null : ( // ||      (!!variantsByCycle && !variantsByCycle.length)
            <>
              <Breadcrumbs
                pageTitle={
                  (!!cycle && cycle.marketingName) ||
                  (!!cycle && cycle.name) ||
                  "..."
                }
                pathArray={[{ title: titleCycles, url: "/cycles" }]}
              />
              {!!bannerImgUrl && (
                <div className="margin-bottom">
                  <img
                    src={bannerImgUrl}
                    alt={cycle.marketingName || cycle.name || "Cycle banner"}
                  />
                </div>
              )}
              {!!cycle.description && (
                <div
                  style={{ marginBottom: "1.2em" }}
                  dangerouslySetInnerHTML={
                    !!cycle &&
                    !!cycle.description && {
                      __html: DOMPurify.sanitize(cycle.description, {
                        USE_PROFILES: { html: true },
                      }),
                    }
                  }
                />
              )}

              {variantsIsLoadingInitialData ? (
                <Loader />
              ) : variantsError ? (
                <Error errorObj={variantsError} />
              ) : allVariants ? (
                <CardList style={{ marginBottom: "-1.2em" }}>
                  {displayMM.map((mm, index) => (
                    <Col
                      xs={12}
                      key={mm.id}
                      onClick={() => setSelectedIndex(index)}
                    >
                      <MailMoment
                        {...mm}
                        isSelected={index === selectedIndex}
                        onNext={onNext}
                        updateParentVariants={mutateVariants}
                        variantsLinkHeader={variants?.headers?.link}
                      />
                    </Col>
                  ))}

                  {allApproved ? (
                    <Col xs={12}>
                      <Alert type="success" className="margin-bottom">
                        {showCustomerApproval ? (
                          <Title>{t("order.All-steps-approved")}</Title>
                        ) : null}
                        {t("order.Loyalty-cycle-is-activated")}
                      </Alert>
                    </Col>
                  ) : !!displayMM.length ? (
                    !allApproved && showMessage ? (
                      <Col xs={12}>
                        <Alert type="info" className="margin-bottom">
                          {t("order.All-steps-must-be-approved-to-proceed")}
                          <CloseAlert
                            aria-label="Close Alert"
                            onClick={() => setShowMessage(false)}
                          />
                        </Alert>
                      </Col>
                    ) : null
                  ) : null}
                </CardList>
              ) : null}

              <WizardButtons>
                {selectedIndex !== 0 ? (
                  <Button $link onClick={onBack}>
                    {icons.chevronLeft}
                    <span>{t("order.Previous")}</span>
                  </Button>
                ) : (
                  <Button $link disabled>
                    {icons.chevronLeft}
                    <span>{t("order.Previous")}</span>
                  </Button>
                )}
                {selectedIndex < displayMM.length - 1 ? (
                  <Button $primary onClick={onNext}>
                    <span>{t("order.Next")}</span>
                    {icons.chevronRight}
                  </Button>
                ) : (
                  <Button $primary disabled>
                    <span>{t("order.Next")}</span>
                    {icons.chevronRight}
                  </Button>
                )}
              </WizardButtons>
            </>
          )}
        </Card>
      }
      sidebar={
        <React.Fragment>
          <Card
            title={t("ui.My-orders")}
            children={
              ordersIsLoading ? (
                <Loader />
              ) : ordersIsError ? (
                <Error errorObj={ordersIsError} />
              ) : (
                <OrderList orders={orders} />
              )
            }
            icon={icons.orders}
            shadow
            withBg
          />
          <Card
            title={t("ui.Contact")}
            children={<ContactText />}
            icon={icons.contact}
            shadow
          />
        </React.Fragment>
      }
      pageTitle={
        (!!cycle && cycle.marketingName) || (!!cycle && cycle.name) || "..."
      }
    />
  );
};

export default CycleDetail;
