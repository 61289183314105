import axios from "axios";
import config from "../config";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const callImages = ({ mpId, data = {}, onSuccess, onError }) => {
  const url = `${phoenix}/marketingprofiles/${mpId}/images`;
  const customerId = localStorage.getItem("customerId");
  axios({
    method: "PUT",
    url: url,
    data: data,
    withCredentials: true,
    headers: {
      pxclient: config.clientId,
      pxcustomer: customerId,
    },
  })
    .then((res) => {
      if (res.status === 200) {
        !!onSuccess && onSuccess(res?.data);
      } else {
        console.log("response", res);
        !!onError
          ? onError(res?.statusText)
          : console.log("Request failed", res);
      }
    })
    .catch((error) => {
      console.log("error", error);
      if (!!error.response) {
        onError(error.response?.data?.title || error?.message);
      } else if (!!error.request) {
        onError();
      } else {
        onError(error?.message);
      }
    });
};

export default callImages;
