import useSWR from "swr";
import fetcher from "./fetcher";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const useVariantParams = (id, shouldFetch = () => true) => {
  const cacheKey = `${phoenix}/variants/${id}/parameters`;
  const { data, error } = useSWR(
    () => (shouldFetch() ? cacheKey : null),
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    cacheKey: cacheKey,
  };
};

export default useVariantParams;
