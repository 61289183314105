import React, { useState } from "react";
import FormRow from "../FormRow";
import { useTranslation } from "react-i18next";
import icons from "../../icons";
import { Row, Col } from "styled-bootstrap-grid";
import Button from "../Button";
import { Input, Textarea, Field, MediaInput } from "@zendeskgarden/react-forms";
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";
import {
  Modal,
  Header,
  Body,
  Footer,
  FooterItem,
  Close,
} from "@zendeskgarden/react-modals";
import callSuppliers from "../../utils/callSuppliers";
import {
  Dropdown,
  Field as SelectField,
  Menu,
  Item,
  Select,
} from "@zendeskgarden/react-dropdowns";
import Loader from "../Loader";
import Error from "../Error";
import { useToast } from "@zendeskgarden/react-notifications";
import Notification from "../Notification";
import useSupplierTypes from "../../utils/useSupplierTypes";
import contactRules from "../../utils/contactRules";
import CollapsibleBlock from "../CollapsibleBlock";
import Badge from "../Badge";
import AccordionWrapper from "../OrderWizard/AccordionWrapper";

const SModal = styled(Modal)`
  max-width: 97%;
  max-height: 97%;
  margin: auto;
  left: 0;
  right: 0;
  .show-overflow > div {
    overflow: visible;
  }
`;

const SAddressWrap = styled.div`
  &:not(:last-child) {
    margin-bottom: 2em;
    padding-bottom: 2em;
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
  }
`;

const SLoaderOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(255 255 255 / 50%);
  user-select: none;
  > div {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 50em;
    margin: auto;
  }
`;

const SupplierForm = ({ onSuccess, customerId, formData, contactTypes }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);

  const { control, handleSubmit, trigger, isSubmitting, watch, reset } =
    useForm();
  const { t } = useTranslation("common");
  const { addToast } = useToast();

  const {
    data: supplierTypesData,
    isLoading: supplierTypesIsLoading,
    isError: supplierTypesIsError,
  } = useSupplierTypes();

  // Contacts
  const [contacts, setContacts] = useState(
    !!formData && !!formData.contactDetails && !!formData.contactDetails.length
      ? formData.contactDetails.map((item, index) => index)
      : [0]
  );
  const addContact = () =>
    setContacts([
      ...contacts,
      contacts.length > 0 ? contacts[contacts.length - 1] + 1 : 0,
    ]);
  const deleteContact = (id) =>
    setContacts(contacts.filter((item) => item !== id));
  const selectedContactTypes = watch(contacts.map((id) => "contactType" + id));

  // Addresses
  const [addresses, setAddresses] = useState(
    !!formData && !!formData.addresses && !!formData.addresses.length
      ? formData.addresses.map((item, index) => index)
      : [0]
  );
  const addAddress = () =>
    setAddresses([
      ...addresses,
      addresses.length > 0 ? addresses[addresses.length - 1] + 1 : 0,
    ]);
  const deleteAddress = (id) =>
    setAddresses(addresses.filter((item) => item !== id));

  // Stepper
  const onNext = () => {
    if (step === 0) {
      trigger("name", "supplierType", "description").then((isValid) => {
        isValid && setStep(step + 1);
      });
    } else if (step === 1) {
      trigger(contacts.map((item) => "contactValue" + item)).then(
        (isValid) => isValid && setStep(step + 1)
      );
    }
  };
  const onBack = () => setStep(step - 1);
  const resetForm = () => {
    reset();
    setStep(0);
    setIsLoading(false);
  };
  const nextOnEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onNext();
    }
  };
  const nextOnCtrlEnter = (e) => {
    if (e.key === "Enter" && e.ctrlKey) {
      e.preventDefault();
      onNext();
    }
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    callSuppliers(
      !!formData ? formData.id : null,
      !!formData ? "PUT" : "POST",
      {
        customerId: customerId,
        name: data.name,
        description: data.description,
        supplierType: data.supplierType.value,
        contactDetails: contacts.map((id) => ({
          type: data["contactType" + id].value,
          value: data["contactValue" + id],
          sequence: 0,
          notes: null,
        })),
        addresses: addresses.map((id) => ({
          type: data["addressType" + id].value,
          name: data["addressName" + id],
          street: data["addressStreetName" + id],
          houseNumber: data["addressHouseNumber" + id],
          suffix: data["addressHouseNumberExtension" + id],
          postalCode: data["addressPostcode" + id],
          city: data["addressCity" + id],
          province: data["addressProvince" + id],
          country: data["addressCountry" + id],
        })),
        sendNotification: true,
      },
      (res) => {
        onSuccess().then(() => {
          setModalOpen(false);
          resetForm();
        });
      },
      (error) => {
        setIsLoading(false);
        setModalOpen(false);
        setStep(0);
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Something-went-wrong")}
              text={t("error.Failed-to-update-supplier")}
              errorText={
                !!error.title
                  ? error.title
                  : typeof error === "string" && !!error
                  ? error
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      }
    );
  };

  const supplierTypes =
    !!supplierTypesData && !!supplierTypesData.length
      ? supplierTypesData.map((item) => ({
          value: item,
          label: `my-account.${item}`,
        }))
      : [];

  const addressTypes = [
    {
      value: "invoice",
      label: "my-account.Invoice-address",
    },
    {
      value: "shop",
      label: "my-account.Shop",
    },
    {
      value: "postal",
      label: "my-account.Post-address",
    },
    {
      value: "delivery",
      label: "my-account.Delivery-address",
    },
  ];

  const defaultContactRules = { required: true, maxLength: 50 };

  return (
    <>
      {!!formData ? (
        <Button link small onClick={() => setModalOpen(true)}>
          {icons.edit}
          <span>{t("my-account.Edit")}</span>
        </Button>
      ) : (
        <Button
          primary
          round
          onClick={() => setModalOpen(true)}
          title={t("my-account.Add-new-supplier")}
        >
          {icons.add}
        </Button>
      )}
      {modalOpen && (
        <SModal
          isLarge
          onClose={() => setModalOpen(false)}
          appendToNode={document.body}
          aria-label="edit-supplier-modal"
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ position: "relative" }}
          >
            <Header>
              <h3>
                {!!formData
                  ? t("my-account.Edit-supplier")
                  : t("my-account.New-supplier")}
              </h3>
            </Header>
            <Body>
              {supplierTypesIsLoading ? (
                <Loader />
              ) : supplierTypesIsError ? (
                <Error errorObj={supplierTypesIsError} />
              ) : (
                <div>
                  <AccordionWrapper isStepper style={{ paddingLeft: "2em" }}>
                    <CollapsibleBlock
                      triggerIcon={
                        step === 0 ? (
                          <Badge primary round>
                            1
                          </Badge>
                        ) : (
                          <Badge success round>
                            {icons.check}
                          </Badge>
                        )
                      }
                      triggerText={<h3>{t("my-account.General")}</h3>}
                      open={step === 0}
                      isStepper
                      disableClick
                    >
                      <div>
                        <FormRow label={t("ui.Name") + "*"}>
                          <Controller
                            name="name"
                            control={control}
                            defaultValue={
                              !!formData && !!formData.name ? formData.name : ""
                            }
                            rules={{ required: true, maxLength: 50 }}
                            render={({ field, fieldState }) => (
                              <Input
                                {...field}
                                validation={fieldState.invalid ? "error" : null}
                                onKeyDown={nextOnEnter}
                              />
                            )}
                          />
                        </FormRow>
                        <FormRow label={t("my-account.Type") + "*"}>
                          <Controller
                            name="supplierType"
                            control={control}
                            defaultValue={
                              !!formData && !!formData.supplierType
                                ? supplierTypes.filter(
                                    (item) =>
                                      item.value === formData.supplierType
                                  )[0]
                                : supplierTypes[0]
                            }
                            rules={{ required: true, maxLength: 50 }}
                            render={({ field }) => (
                              <Dropdown
                                selectedItem={field.value}
                                onSelect={field.onSelect}
                                downshiftProps={{
                                  ...field,
                                  itemToString: (item) => item && item.label,
                                }}
                              >
                                <SelectField>
                                  <Select>{t(field.value.label)}</Select>
                                </SelectField>
                                <Menu>
                                  {supplierTypes.map((option) => (
                                    <Item key={option.value} value={option}>
                                      {t(option.label)}
                                    </Item>
                                  ))}
                                </Menu>
                              </Dropdown>
                            )}
                          />
                        </FormRow>
                        <FormRow label={t("my-account.Description")}>
                          <Controller
                            name="description"
                            control={control}
                            defaultValue={
                              !!formData && !!formData.description
                                ? formData.description
                                : ""
                            }
                            rules={{ maxLength: 200 }}
                            render={({ field, fieldState }) => (
                              <Textarea
                                {...field}
                                validation={fieldState.invalid ? "error" : null}
                                onKeyDown={nextOnCtrlEnter}
                                isResizable
                              />
                            )}
                          />
                        </FormRow>
                      </div>
                    </CollapsibleBlock>
                    <CollapsibleBlock
                      triggerIcon={
                        step <= 1 ? (
                          <Badge
                            primary={step === 1}
                            primaryLighter={step !== 1}
                            round
                          >
                            2
                          </Badge>
                        ) : (
                          <Badge success round>
                            {icons.check}
                          </Badge>
                        )
                      }
                      triggerText={<h3>{t("my-account.Contact-details")}</h3>}
                      open={step === 1}
                      isStepper
                      disableClick
                    >
                      <div>
                        {contacts.map((id, index) => (
                          <FormRow key={id} multiField noMargin>
                            <div className="row-wrap">
                              <Row>
                                <Col
                                  md={6}
                                  col={12}
                                  style={{ marginBottom: "1.2em" }}
                                >
                                  <Field>
                                    <Controller
                                      name={"contactType" + id}
                                      control={control}
                                      defaultValue={
                                        !!formData &&
                                        !!formData.contactDetails &&
                                        !!formData.contactDetails.length &&
                                        !!formData.contactDetails[id] &&
                                        !!formData.contactDetails[id].type
                                          ? contactTypes.filter(
                                              (item) =>
                                                item.value ===
                                                formData.contactDetails[id].type
                                            )[0]
                                          : contactTypes[0]
                                      }
                                      rules={{ required: true, maxLength: 50 }}
                                      render={({ field }) => (
                                        <Dropdown
                                          selectedItem={field.value}
                                          onSelect={field.onSelect}
                                          downshiftProps={{
                                            ...field,
                                            itemToString: (item) =>
                                              item && item.label,
                                          }}
                                        >
                                          <SelectField>
                                            <Select>
                                              {t(field.value.label)}
                                            </Select>
                                          </SelectField>
                                          <Menu className="select-menu-max-height">
                                            {contactTypes.map((option) => (
                                              <Item
                                                key={option.value}
                                                value={option}
                                              >
                                                {t(option.label)}
                                              </Item>
                                            ))}
                                          </Menu>
                                        </Dropdown>
                                      )}
                                    />
                                  </Field>
                                </Col>
                                <Col
                                  md={6}
                                  col={12}
                                  style={{ marginBottom: "1.2em" }}
                                >
                                  <Field>
                                    <Controller
                                      name={"contactValue" + id}
                                      control={control}
                                      defaultValue={
                                        !!formData &&
                                        !!formData.contactDetails &&
                                        !!formData.contactDetails.length &&
                                        !!formData.contactDetails[id] &&
                                        !!formData.contactDetails[id].value
                                          ? formData.contactDetails[id].value
                                          : ""
                                      }
                                      rules={
                                        !!selectedContactTypes[index] &&
                                        !!selectedContactTypes[index].value &&
                                        !!contactRules[
                                          selectedContactTypes[index].value
                                        ]
                                          ? contactRules[
                                              selectedContactTypes[index].value
                                            ]
                                          : defaultContactRules
                                      }
                                      render={({ field, fieldState }) => (
                                        <MediaInput
                                          {...field}
                                          placeholder={t("my-account.Value")}
                                          validation={
                                            fieldState.invalid ? "error" : null
                                          }
                                          onKeyDown={nextOnEnter}
                                          end={
                                            <Button
                                              link
                                              onClick={() => deleteContact(id)}
                                              title={t(
                                                "my-account.Delete-this-contact"
                                              )}
                                              style={{
                                                position: "absolute",
                                                right: ".6em",
                                                fontSize: "125%",
                                              }}
                                            >
                                              {icons.trash}
                                            </Button>
                                          }
                                        />
                                      )}
                                    />
                                  </Field>
                                </Col>
                              </Row>
                            </div>
                          </FormRow>
                        ))}
                        <FormRow multiField>
                          {contacts.length === 0 && (
                            <p>{t("my-account.No-contact-details")}</p>
                          )}
                          <Button link onClick={addContact}>
                            {icons.add}
                            <span>{t("my-account.Add-contact")}</span>
                          </Button>
                        </FormRow>
                      </div>
                    </CollapsibleBlock>
                    <CollapsibleBlock
                      triggerIcon={
                        step <= 2 ? (
                          <Badge
                            primary={step === 2}
                            primaryLighter={step !== 2}
                            round
                          >
                            3
                          </Badge>
                        ) : (
                          <Badge success round>
                            {icons.check}
                          </Badge>
                        )
                      }
                      triggerText={<h3>{t("my-account.Addresses")}</h3>}
                      open={step === 2}
                      isStepper
                      disableClick
                    >
                      <div>
                        {addresses.map((id) => (
                          <SAddressWrap key={id}>
                            <FormRow label={t("my-account.Type") + "*"}>
                              <Controller
                                name={"addressType" + id}
                                control={control}
                                defaultValue={
                                  !!formData &&
                                  !!formData.addresses &&
                                  !!formData.addresses.length &&
                                  !!formData.addresses[id] &&
                                  !!formData.addresses[id].type
                                    ? addressTypes.filter(
                                        (item) =>
                                          item.value ===
                                          formData.addresses[id].type
                                      )[0]
                                    : addressTypes[0]
                                }
                                rules={{ required: true, maxLength: 50 }}
                                render={({ field }) => (
                                  <Dropdown
                                    selectedItem={field.value}
                                    onSelect={field.onSelect}
                                    downshiftProps={{
                                      ...field,
                                      itemToString: (item) =>
                                        item && item.label,
                                    }}
                                  >
                                    <SelectField>
                                      <Select>{t(field.value.label)}</Select>
                                    </SelectField>
                                    <Menu>
                                      {addressTypes.map((option) => (
                                        <Item key={option.value} value={option}>
                                          {t(option.label)}
                                        </Item>
                                      ))}
                                    </Menu>
                                  </Dropdown>
                                )}
                              />
                            </FormRow>
                            <FormRow label={t("ui.Name") + "*"}>
                              <Controller
                                name={"addressName" + id}
                                control={control}
                                defaultValue={
                                  !!formData &&
                                  !!formData.addresses &&
                                  !!formData.addresses.length &&
                                  !!formData.addresses[id] &&
                                  !!formData.addresses[id].name
                                    ? formData.addresses[id].name
                                    : ""
                                }
                                rules={{ required: true, maxLength: 50 }}
                                render={({ field, fieldState }) => (
                                  <Input
                                    {...field}
                                    validation={
                                      fieldState.invalid ? "error" : null
                                    }
                                  />
                                )}
                              />
                            </FormRow>
                            <FormRow label={t("my-account.Street") + "*"}>
                              <Controller
                                name={"addressStreetName" + id}
                                control={control}
                                defaultValue={
                                  !!formData &&
                                  !!formData.addresses &&
                                  !!formData.addresses.length &&
                                  !!formData.addresses[id] &&
                                  !!formData.addresses[id].street
                                    ? formData.addresses[id].street
                                    : ""
                                }
                                rules={{ required: true, maxLength: 50 }}
                                render={({ field, fieldState }) => (
                                  <Input
                                    {...field}
                                    validation={
                                      fieldState.invalid ? "error" : null
                                    }
                                  />
                                )}
                              />
                            </FormRow>
                            <FormRow label={t("my-account.House-number") + "*"}>
                              <div className="row-wrap margin-bottom">
                                <Row>
                                  <Col sm={6} xs={6}>
                                    <Field>
                                      <Controller
                                        name={"addressHouseNumber" + id}
                                        control={control}
                                        defaultValue={
                                          !!formData &&
                                          !!formData.addresses &&
                                          !!formData.addresses.length &&
                                          !!formData.addresses[id] &&
                                          !!formData.addresses[id].houseNumber
                                            ? formData.addresses[id].houseNumber
                                            : ""
                                        }
                                        rules={{
                                          required: true,
                                          maxLength: 10,
                                        }}
                                        render={({ field, fieldState }) => (
                                          <Input
                                            {...field}
                                            placeholder={t(
                                              "my-account.House-number"
                                            )}
                                            validation={
                                              fieldState.invalid
                                                ? "error"
                                                : null
                                            }
                                          />
                                        )}
                                      />
                                    </Field>
                                  </Col>
                                  <Col sm={6} xs={6}>
                                    <Field>
                                      <Controller
                                        name={
                                          "addressHouseNumberExtension" + id
                                        }
                                        control={control}
                                        defaultValue={
                                          !!formData &&
                                          !!formData.addresses &&
                                          !!formData.addresses.length &&
                                          !!formData.addresses[id] &&
                                          !!formData.addresses[id].suffix
                                            ? formData.addresses[id].suffix
                                            : ""
                                        }
                                        rules={{ maxLength: 30 }}
                                        render={({ field, fieldState }) => (
                                          <Input
                                            {...field}
                                            placeholder={t(
                                              "my-account.Extension"
                                            )}
                                            validation={
                                              fieldState.invalid
                                                ? "error"
                                                : null
                                            }
                                          />
                                        )}
                                      />
                                    </Field>
                                  </Col>
                                </Row>
                              </div>
                            </FormRow>
                            <FormRow label={t("my-account.Postcode") + "*"}>
                              <Controller
                                name={"addressPostcode" + id}
                                control={control}
                                defaultValue={
                                  !!formData &&
                                  !!formData.addresses &&
                                  !!formData.addresses.length &&
                                  !!formData.addresses[id] &&
                                  !!formData.addresses[id].postalCode
                                    ? formData.addresses[id].postalCode
                                    : ""
                                }
                                rules={{ required: true, maxLength: 8 }}
                                render={({ field, fieldState }) => (
                                  <Input
                                    {...field}
                                    validation={
                                      fieldState.invalid ? "error" : null
                                    }
                                  />
                                )}
                              />
                            </FormRow>
                            <FormRow label={t("my-account.City") + "*"}>
                              <Controller
                                name={"addressCity" + id}
                                control={control}
                                defaultValue={
                                  !!formData &&
                                  !!formData.addresses &&
                                  !!formData.addresses.length &&
                                  !!formData.addresses[id] &&
                                  !!formData.addresses[id].city
                                    ? formData.addresses[id].city
                                    : ""
                                }
                                rules={{ required: true, maxLength: 50 }}
                                render={({ field, fieldState }) => (
                                  <Input
                                    {...field}
                                    validation={
                                      fieldState.invalid ? "error" : null
                                    }
                                  />
                                )}
                              />
                            </FormRow>
                            <FormRow label={t("my-account.Province")}>
                              <Controller
                                name={"addressProvince" + id}
                                control={control}
                                defaultValue={
                                  !!formData &&
                                  !!formData.addresses &&
                                  !!formData.addresses.length &&
                                  !!formData.addresses[id] &&
                                  !!formData.addresses[id].province
                                    ? formData.addresses[id].province
                                    : ""
                                }
                                rules={{ required: false, maxLength: 50 }}
                                render={({ field, fieldState }) => (
                                  <Input
                                    {...field}
                                    validation={
                                      fieldState.invalid ? "error" : null
                                    }
                                  />
                                )}
                              />
                            </FormRow>
                            <FormRow label={t("my-account.Country")}>
                              <Controller
                                name={"addressCountry" + id}
                                control={control}
                                defaultValue={
                                  !!formData &&
                                  !!formData.addresses &&
                                  !!formData.addresses.length &&
                                  !!formData.addresses[id] &&
                                  !!formData.addresses[id].country
                                    ? formData.addresses[id].country
                                    : ""
                                }
                                rules={{ maxLength: 50 }}
                                render={({ field, fieldState }) => (
                                  <Input
                                    {...field}
                                    validation={
                                      fieldState.invalid ? "error" : null
                                    }
                                  />
                                )}
                              />
                            </FormRow>
                            <Row className="flex-grow-1">
                              <Col sm={3}></Col>
                              <Col sm={9}>
                                <Button
                                  link
                                  onClick={() => deleteAddress(id)}
                                  style={{ marginRight: "1em" }}
                                >
                                  {icons.trash}
                                  <span>{t("my-account.Delete-address")}</span>
                                </Button>
                                {
                                  // is last item
                                  id === addresses[addresses.length - 1] && (
                                    <Button link onClick={addAddress}>
                                      {icons.add}
                                      <span>
                                        {t("my-account.Add-another-address")}
                                      </span>
                                    </Button>
                                  )
                                }
                              </Col>
                            </Row>
                          </SAddressWrap>
                        ))}
                        {addresses.length === 0 && (
                          <Row className="flex-grow-1">
                            <Col sm={3}></Col>
                            <Col sm={9}>
                              <p>{t("my-account.No-addresses")}</p>
                              <Button link onClick={addAddress}>
                                {icons.add}
                                <span>{t("my-account.Add-address")}</span>
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </CollapsibleBlock>
                  </AccordionWrapper>
                </div>
              )}
            </Body>
            <Footer
              style={{
                justifyContent: "space-between",
                position: "sticky",
                bottom: 0,
                background: "white",
              }}
            >
              {step > 0 ? (
                <FooterItem>
                  <Button link onClick={onBack}>
                    {icons.chevronLeft}
                    <span>{t("order.Previous")}</span>
                  </Button>
                </FooterItem>
              ) : (
                <FooterItem></FooterItem>
              )}
              {step < 2 && (
                <FooterItem>
                  <Button primary onClick={onNext}>
                    <span>{t("order.Next")}</span>
                    {icons.chevronRight}
                  </Button>
                </FooterItem>
              )}
              {step === 2 && (
                <FooterItem>
                  <Button
                    primary
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={isSubmitting}
                  >
                    {icons.check}
                    <span>{t("my-account.Save")}</span>
                  </Button>
                </FooterItem>
              )}
            </Footer>
            {isLoading && (
              <SLoaderOverlay
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  background: "rgb(255 255 255 / 50%)",
                  userSelect: "none",
                }}
              >
                <Loader />
              </SLoaderOverlay>
            )}
          </form>
          <Close aria-label="Close" />
        </SModal>
      )}
    </>
  );
};

export default SupplierForm;
