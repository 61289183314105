import useSWR from "swr";
import fetcher from "./fetcher";
import encodeParams from "../utils/encodeParams";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const useParticipations = ({ params = {}, shouldFetch = () => true }) => {
  const { data, error } = useSWR(
    () =>
      shouldFetch()
        ? `${phoenix}/participations${encodeParams({
            ...params,
          })}`
        : null,
    fetcher,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
    }
  );
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useParticipations;
