import React from "react";
import { Row } from "styled-bootstrap-grid";
import styled from "styled-components";
import theme from "../styles/theme";
import { GridThemeProvider } from "styled-bootstrap-grid";
import { opacify } from "polished";

const SIconList = styled.div`
  margin-bottom: -0.6em;
  :not(:last-child) {
    margin-bottom: 0;
  }
  .icon-list-link {
    display: flex;
    align-items: center;
    background: white;
    margin-bottom: 0.6em;
    padding: 1em;
    border-radius: 3px;
    text-decoration: none;
    box-shadow: 1px 2px 5px rgb(0 0 0 / 5%);
    cursor: pointer;
    overflow: hidden;
    &.no-pad {
      padding: 0;
    }
    h4 {
      color: ${(props) => props.theme.headingColor};
      transition: all 0.4s;
      font-weight: 500;
      margin-bottom: 0.1em;
    }
    transition: all 0.4s;
    &:hover {
      background: ${(props) => opacify(-0.3, props.theme.accentColor)};
      svg {
        fill: white;
      }
      h4 {
        color: white;
      }
      .btn-link {
        color: white;
      }
    }
  }
  .icon-list-icon {
    width: 3em;
    margin-right: 1em;
    transition: all 0.4s;
    fill: ${(props) => props.theme.accentColor};
  }
  .no-pad {
    .icon-list-icon {
      width: 50px;
      display: block;
      padding: 0.5em;
      overflow: visible;
      background: ${(props) => opacify(-0.8, props.theme.accentColor)};
    }
  }
`;

const IconList = ({ flush = false, children }) => {
  return (
    <GridThemeProvider gridTheme={theme.gridThemeCondensed}>
      <SIconList className="row-wrap-sm">
        <Row>{children}</Row>
      </SIconList>
    </GridThemeProvider>
  );
};

export default IconList;
