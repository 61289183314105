import React from "react";
import styled from "styled-components";
import { RiImageFill } from "react-icons/ri";
import encodeParams from "../utils/encodeParams";
import LoaderOverlay from "./LoaderOverlay";
import apiConfig from "../apiConfig";

const { images: imageApi } = apiConfig;

const SImageBox = styled.div`
  position: ${(props) => (props.center ? "relative" : "static")};
  padding-bottom: ${(props) => (props.center ? props.boxHeight : 0)};
  background-color: ${(props) => props.bgColor};
  overflow: hidden;
  box-sizing: border-box;
  .img-box-img {
    width: ${(props) => (props.center ? "100%" : "auto")};
    height: ${(props) => (props.center ? "100%" : "auto")};
    position: ${(props) => (props.center ? "absolute" : "static")};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: ${(props) =>
      props.params.ResizeMode === "Contain" ? "contain" : "cover"};
  }
  .no-img-icon {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    color: ${(props) => props.theme.iconColor};
    padding: 20%;
  }
`;

const BgImgBox = styled.div`
  position: ${(props) => (props.center ? "absolute" : "static")};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-size: ${(props) =>
    props.params.ResizeMode === "Contain" ? "contain" : "cover"};
  background-repeat: no-repeat;
  background-image: url(${(props) => props.dataSrc});
`;

const ImageBox = ({
  params = { ResizeMode: "Cover" },
  id,
  alt = "",
  boxHeight,
  withLoader,
  noZoom = false,
  api = null,
  icon: Icon = RiImageFill,
  bgColor = "#f4f4f4",
  asBgImage = true,
  center = true,
}) => {
  if (!params.hasOwnProperty("ResizeMode")) {
    params.ResizeMode = "Cover";
  }

  const ImageBlock = () => {
    // as a background
    if (asBgImage) {
      return (
        <BgImgBox
          dataSrc={`${api ? api : imageApi}/${id}${encodeParams(params)}`}
          className="zoom"
          params={params}
          center={center}
        />
      );
    } else {
      // as img
      return (
        <img
          src={`${api ? api : imageApi}/${id}${encodeParams(params)}`}
          alt={alt || id}
          className="img-box-img zoom"
        />
      );
    }
  };

  return (
    <SImageBox
      boxHeight={boxHeight}
      className={noZoom ? "" : "hover-zoom"}
      isPlaceholder={!id}
      params={params}
      bgColor={bgColor}
      center={center}
    >
      {id ? <ImageBlock /> : <Icon className="no-img-icon zoom" />}
      {!!withLoader ? <LoaderOverlay /> : null}
    </SImageBox>
  );
};

export default ImageBox;
