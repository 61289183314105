import React from "react";
import styled from "styled-components";
import useViews from "../utils/useViews";
import Link from "./Link";
import { opacify } from "polished";
import Error from "./Error";
import Loader from "./Loader";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import icons from "../icons";

const SActionListsReports = styled.ul`
  list-style: none;
  padding: 0;
  margin: -1.2em -1.2em 0;
  a {
    display: block;
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
    padding: 0.8em 1.2em;
    transition: 0.2s background;
    text-decoration: none;
    &:hover {
      background: ${(props) => opacify(-0.9, props.theme.accentColor)};
    }
  }
`;

const ActionListReports = () => {
  const {
    data: actionLists,
    isLoading,
    isError,
  } = useViews({ chartType: "actionList", pageSize: 5 });
  const { t } = useTranslation("common");

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <Error errorObj={isError} />
  ) : (
    <>
      <SActionListsReports>
        {actionLists.map((item) => (
          <li key={`${item.reportId}${item.id}`}>
            <Link to={`/action-list/${item.reportId}/${item.id}`}>
              {item.name}
            </Link>
          </li>
        ))}
      </SActionListsReports>
      <div className="bottom-btn-wrap" style={{ marginBottom: "-.5em" }}>
        <Link to="/action-lists">
          <Button link>
            {t("ui.All-action-lists")}
            {icons.chevronRight}
          </Button>
        </Link>
      </div>
    </>
  );
};

export default ActionListReports;
