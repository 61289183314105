import axios from "axios";
import apiConfig from "../apiConfig";

const { fileUpload } = apiConfig;

const callPrivateUpload = ({ token, file, onSuccess, onError }) => {
  const url = `${fileUpload}/Upload/PrivateFileUpload`;
  axios({
    method: "POST",
    url: url,
    data: {
      TokenId: token,
      FileName: file.name,
    },
  })
    .then((res) => {
      if (res.status === 200 && !!res.data.redirectResult) {
        axios({
          method: "PUT",
          url: res.data.redirectResult,
          data: file,
          headers: {
            "content-type": "application/octet-stream",
          },
        })
          .then((uploadRes) => {
            !!onSuccess && onSuccess(uploadRes?.data);
          })
          .catch((error) => {
            if (!!error.response) {
              onError(error?.response?.data?.title);
            } else if (!!error.request) {
              onError();
            } else {
              onError(error?.message);
            }
          });
      } else {
        !!onError
          ? onError(res?.statusText)
          : console.log("Request failed", res);
      }
    })
    .catch((error) => {
      if (!!error.response) {
        onError(!!error.response?.data?.title);
      } else if (!!error.request) {
        onError();
      } else {
        onError(error?.message);
      }
    });
};

export default callPrivateUpload;
