import React from "react";
import styled from "styled-components";
import spinner from "../assets/img/spinner.svg";

const SLoader = styled.div`
  text-align: center;
  display: flex;
  height: 100%;
  img {
    max-width: 50%;
    margin: 0 auto;
  }
  .loader-inner {
    width: 100%;
    max-width: 700px;
    margin: auto;
    position: relative;
    padding-bottom: 3em;
    img {
      max-height: 35vh;
    }
  }
  .loader-caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1em;
    opacity: 0.8;
    h3 {
      margin-bottom: 0;
    }
    p {
      color: ${(props) => props.theme.headingColor};
    }
  }
`;

const Loader = ({ style, children }) => {
  return (
    <SLoader style={{ ...style }}>
      {!!children ? (
        <div className="loader-inner">
          <img src={spinner} alt="" className="fadeInGrow" />
          <div className="loader-caption">{children}</div>
        </div>
      ) : (
        <img src={spinner} alt="" className="fadeInGrow" />
      )}
    </SLoader>
  );
};

export default Loader;
