import React from "react";
import styled from "styled-components";

const SPreview = styled.div`
  text-align: center;
  iframe {
    width: 652px;
    margin: 0 -52px;
    outline: none;
    display: block;
    height: 365px;
    border: none;
    body {
      margin: 0;
    }
  }
`;

const EmailSignaturePreview = ({ url, docId }) => {
  return (
    <SPreview className="edm-wrap-inner">
      <iframe src={url} title={`id: ${docId}`} />
    </SPreview>
  );
};

export default EmailSignaturePreview;
