import React from "react";
import Card from "../Card";
import Breadcrumbs from "../parts/Breadcrumbs";
import Layout from "../parts/Layout";
import Sidebar from "../parts/Sidebar";

const Default = ({
  pageTitle = "Default page",
  pathArray = [],
  icon = null,
  children,
}) => {
  return (
    <Layout
      main={
        <Card title={pageTitle} shadow icon={icon}>
          <Breadcrumbs pageTitle={pageTitle} pathArray={pathArray} />
          {children}
        </Card>
      }
      sidebar={<Sidebar />}
      pageTitle={pageTitle}
    />
  );
};

export default Default;
