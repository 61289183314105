// Returns number of days till next work day
// Doesn't work for holidays

// 0 Sun
// 1 Mon
// 2 Tue
// 3 Wed
// 4 Thu
// 5 Fri
// 6 Sat

const daysTillNextWorkDay = () => {
  const today = new Date();
  const weekdayIndex = today.getDay();
  const deltaDays = weekdayIndex === 5 ? 2 : weekdayIndex === 6 ? 1 : 0;
  return deltaDays;
};

export default daysTillNextWorkDay;
