import React, { useEffect, useState } from "react";
import { Input } from "@zendeskgarden/react-forms";
import { BiMinus, BiPlus } from "react-icons/bi";
import styled from "styled-components";
import Button from "../Button";
import { useTranslation } from "react-i18next";

const SAmountInput = styled.div`
  display: flex;
  .text-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: -1px;
  }
  .amount-btn-wrap {
    button {
      height: 50%;
      width: 30px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      display: flex;
      padding: 0;
      &.plus-btn {
        border-bottom-right-radius: 0;
      }
      &.minus-btn {
        border-top-right-radius: 0;
        opacity: 0.85;
      }
      svg {
        font-size: 1.2em;
      }
    }
  }
`;

const AmountInput = ({
  currentAmount,
  minAmount = 1,
  stepSize = 1,
  updateAmount,
  itemProps,
  disabled,
}) => {
  const { t } = useTranslation("common");

  const [inputAmount, setInputAmount] = useState(currentAmount);
  const updateToNewAmount = (newAmount) => updateAmount(newAmount, itemProps);
  const mod = currentAmount % stepSize;

  // Validation rules
  // newAmount is not less than minAmount
  // newAmount is divisible by stepSize, or equal to minAmount

  const validateAmount = (newAmount) =>
    newAmount >= minAmount &&
    (newAmount % stepSize === 0 || +newAmount === +minAmount);

  const handlePlus = () => {
    const newAmount =
      currentAmount < minAmount
        ? minAmount
        : currentAmount < stepSize
        ? stepSize
        : mod === 0
        ? currentAmount + stepSize
        : currentAmount + stepSize - mod;
    if (newAmount !== currentAmount && validateAmount(newAmount)) {
      updateToNewAmount(newAmount);
    }
  };

  const handleMinus = () => {
    const newAmount =
      currentAmount - stepSize < minAmount
        ? minAmount
        : currentAmount - stepSize > minAmount &&
          currentAmount - stepSize < stepSize
        ? stepSize
        : mod === 0
        ? currentAmount - stepSize
        : currentAmount - mod;
    if (newAmount !== currentAmount && validateAmount(newAmount)) {
      updateToNewAmount(newAmount);
    }
  };

  const handleChange = (e) => {
    setInputAmount(e.target.value.replace(/\D/g, ""));
  };

  const handleSubmit = () => {
    const newAmount = +inputAmount;
    if (newAmount !== currentAmount) {
      if (validateAmount(newAmount)) {
        updateToNewAmount(+newAmount);
      } else {
        if (newAmount >= minAmount) {
          const adjustedAmount = newAmount - (newAmount % stepSize) + stepSize;
          if (currentAmount !== adjustedAmount) {
            updateToNewAmount(adjustedAmount);
          } else {
            setInputAmount(adjustedAmount);
          }
        } else {
          if (currentAmount !== minAmount) {
            updateToNewAmount(minAmount);
          } else {
            setInputAmount(minAmount);
          }
        }
      }
    }
  };

  const handleKey = (e) => {
    if (e.code === "ArrowUp" || e.code === "ArrowDown" || e.code === "Enter") {
      e.preventDefault();
      e.code === "ArrowUp" && handlePlus();
      e.code === "ArrowDown" && handleMinus();
      e.code === "Enter" && handleSubmit();
    }
  };

  useEffect(() => {
    setInputAmount(currentAmount);
  }, [currentAmount]);

  return (
    <SAmountInput>
      <label hidden htmlFor="amountInput">
        {t("order.Quantity")}
      </label>
      <Input
        disabled={disabled}
        name="amountInput"
        type="text"
        value={inputAmount}
        onChange={handleChange}
        onKeyDown={handleKey}
        className="text-input"
        onBlur={handleSubmit}
        max="1000000"
        placeholder={t("order.Quantity")}
        title={t("order.Quantity")}
      />
      <div className="amount-btn-wrap">
        <Button
          primary
          className="plus-btn"
          onClick={handlePlus}
          disabled={disabled}
        >
          <BiPlus />
        </Button>
        <Button
          primary
          className="minus-btn"
          onClick={handleMinus}
          disabled={
            currentAmount === 1 || currentAmount === minAmount || disabled
          }
        >
          <BiMinus />
        </Button>
      </div>
    </SAmountInput>
  );
};

export default AmountInput;
