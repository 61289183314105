import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { isExternalLink } from "../utils/isExternalLink";

const Link = (props) => {
  const handleClick = () => {
    if (!isExternalLink(props.to) && props.to !== "/login") {
      const sessionStorage = !!window ? window.sessionStorage : null;
      !!sessionStorage && sessionStorage.setItem("pathname", props.to);
    }
  };
  return <RouterLink {...props} onClick={handleClick} />;
};

export default Link;
