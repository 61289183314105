import React from "react";
import styled from "styled-components";
import Loader from "./Loader";

const SLoaderOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(255 255 255 / 50%);
  img {
    width: 400px;
  }
`;

const LoaderOverlay = ({ style }) => {
  return (
    <SLoaderOverlay
      style={{
        ...style,
      }}
    >
      <Loader />
    </SLoaderOverlay>
  );
};

export default LoaderOverlay;
