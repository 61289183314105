import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../Loader";
import Error from "../Error";
import icons from "../../icons";
import Button from "../Button";
import { useToast } from "@zendeskgarden/react-notifications";
import {
  Modal,
  Header,
  Body,
  Footer,
  FooterItem,
  Close,
} from "@zendeskgarden/react-modals";
import styled from "styled-components";
import useVariantParams from "../../utils/useVariantParams";
import createProof from "../../utils/createProof";
import callVariantParams from "../../utils/callVariantParams";
import { useSWRConfig } from "swr";
import Notification from "../Notification";
import VariantCarousel from "../proofing/VariantCarousel";
import config from "../../config";
import { INHERIT } from "../../constants";
import EdmPreview from "./EdmPreview";
import VariantParam from "./VariantParam";
import { GiFoldedPaper } from "react-icons/gi";
import { BsEnvelopeAtFill } from "react-icons/bs";

const showCustomerApproval = config.uiSettings.showCustomerApproval;

const SModal = styled(Modal)`
  width: ${(props) => (props.isLarge ? "95vw" : "622px")};
  max-width: 98%;
  &.modal-full-screen {
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    max-height: 100%;
    border-radius: 0;
  }
  .slider-wrapper {
    max-height: calc(80vh + 1em);
    overflow-y: auto;
    border: 1px solid ${(props) => props.theme.borderColor};
    width: ${(props) => (props.proofType === "edm" ? "712px" : "100%")};
    max-width: 100%;
  }
  .placeholder {
    padding-bottom: 65%;
  }
  .clp-popup-body {
    &.modal-body-edm {
      padding: 0;
      margin-top: -1px;
      iframe {
        min-height: 70vh;
      }
    }
    &.modal-body-dm {
      padding: 0;
      margin-top: -1px;
    }
  }
  .params-body {
    padding: 20px;
    .form-box {
      min-height: 45vh;
    }
  }
  .modal-footer {
    margin-top: -1px;
  }
  .modal-close-btn {
    right: 13px;
    top: 13px;
  }
  .title-icon {
    font-size: 2em;
    margin-right: 0.2em;
    line-height: 1;
    color: ${(props) => props.theme.accentColor};
    opacity: 0.5;
    width: 1em;
    margin-bottom: -0.3em;
    margin-top: -0.1em;
  }
`;

export const VariantModal = ({
  variant,
  setModalId,
  updateVariants,
  approveVariant,
}) => {
  const { t } = useTranslation("common");
  const { mutate } = useSWRConfig();
  const { addToast } = useToast();

  const {
    data: variantParams,
    isLoading: variantParamsIsLoading,
    isError: variantParamsIsError,
    cacheKey: variantParamsCacheKey,
  } = useVariantParams(variant.id);

  const [proofs, setProofs] = useState([]);
  const [proofType, setProofType] = useState(null);
  const [proofsError, setProofsError] = useState(null);
  const [showParams, setShowParams] = useState(false);
  const [viewFullScreen, setViewFullScreen] = useState(false);
  const [flipPosition, setFlipPosition] = useState(0);

  const handleFullScreen = () => {
    setViewFullScreen((curr) => {
      if (!curr) {
        document
          .getElementsByTagName("body")?.[0]
          ?.classList.add("overflow-hidden");
      } else {
        document
          .getElementsByTagName("body")?.[0]
          ?.classList.remove("overflow-hidden");
      }

      return !curr;
    });
  };

  const handleFlip = () => setFlipPosition((prev) => (prev < 3 ? prev + 1 : 0));
  const resetFlip = () => setFlipPosition(0);

  useEffect(() => {
    createProof({
      id: variant.id,
      onSuccess: (res) => {
        setProofs(res.results);
        setProofsError(null);
        setProofType(res.type);
      },
      onError: (error) => {
        setProofs([]);
        setProofsError(error);
        setProofType(null);
      },
    });
  }, [variant.id]);

  // Param form
  const [paramsFormDisabled, setParamsFormDisabled] = useState(true);
  const [customParams, setCustomParams] = useState([]);

  const onSubmit = (e) => {
    e.preventDefault();
    setParamsFormDisabled(true);
    const data = customParams.map((param) =>
      param.value === INHERIT ? { name: param.name, value: null } : param
    );
    callVariantParams({
      id: variant.id,
      data: data,
      onSuccess: () => {
        setProofs([]);
        createProof({
          id: variant.id,
          onSuccess: (res) => {
            setProofs(res.results);
            setProofsError(null);
            setProofType(res.type);
          },
          onError: (error) => {
            setProofsError(error);
            setProofType(null);
          },
        });
        mutate(variantParamsCacheKey);
        updateVariants();
        setShowParams(false);
      },
      onError: (error) => {
        setParamsFormDisabled(false);
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Something-went-wrong")}
              text={t("error.Failed-to-update-parameters")}
              errorText={
                !!error && !!error.length && !!error[0]
                  ? error[0]
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
    });
  };

  const cancelParamsEdit = () => {
    setParamsFormDisabled(true);
    !!variantParams &&
      !!variantParams.length &&
      setCustomParams(
        variantParams.map((param) => ({
          name: param.name,
          value: !!param.parameterValue ? param.parameterValue : INHERIT,
        }))
      );
    setShowParams(false);
  };

  // set custom params
  useEffect(() => {
    !!variantParams &&
      !!variantParams.length &&
      setCustomParams(
        variantParams.map((param) => ({
          name: param.name,
          value: !!param.parameterValue ? param.parameterValue : INHERIT,
        }))
      );
  }, [variantParams]);

  return (
    <form>
      <SModal
        isLarge={proofType === "dm" && !showParams}
        onClose={() => setModalId(null)}
        appendToNode={document.body}
        proofType={proofType}
        aria-label="Variant modal"
        className={viewFullScreen ? "modal-full-screen" : ""}
      >
        <Header className="modal-header">
          <h3>
            {proofType === "dm" ? (
              <GiFoldedPaper className="title-icon" />
            ) : (
              <BsEnvelopeAtFill className="title-icon" />
            )}
            <span style={{ marginRight: ".5em" }}>{variant.name}</span>
          </h3>
        </Header>
        {!showParams ? (
          <Body
            className={`clp-popup-body ${
              proofType === "edm" ? "modal-body-edm" : "modal-body-dm"
            }`}
          >
            {!!proofs.length ? (
              proofType === "edm" ? (
                !!proofs[0].urls &&
                !!proofs[0].urls.length &&
                !!proofs[0].documentId ? (
                  <EdmPreview
                    url={proofs[0].urls[0]}
                    docId={proofs[0].documentId}
                    isPopup
                  />
                ) : null
              ) : (
                <VariantCarousel
                  proofs={proofs}
                  proofType={proofType}
                  isPopup
                  viewFullScreen={viewFullScreen}
                  handleFullScreen={handleFullScreen}
                  flipPosition={flipPosition}
                  handleFlip={handleFlip}
                  resetFlip={resetFlip}
                />
              )
            ) : proofsError ? (
              <Error
                errorMessage={t("error.Failed-to-create-proof")}
                details={proofsError}
                children={<></>}
                errorObj={proofsError}
              />
            ) : (
              <Loader
                children={
                  <div>
                    <h3>{t("ui.One-moment-please")}</h3>
                    <p>{t("ui.A-proof-is-being-generated")}</p>
                  </div>
                }
                style={{ maxHeight: "65vh", minHeight: "50vh" }}
              />
            )}
          </Body>
        ) : (
          <Body className="params-body">
            <div className="form-box">
              {variantParams.map((param) => (
                <VariantParam
                  param={param}
                  key={param.name}
                  customParams={customParams}
                  setCustomParams={setCustomParams}
                  setParamsFormDisabled={setParamsFormDisabled}
                />
              ))}
            </div>
          </Body>
        )}
        {!showParams ? (
          <Footer className="modal-footer mob-btn">
            <FooterItem className="modal-footer-item">
              <Button
                className="hide-label-xs"
                type="button"
                onClick={() => {
                  setModalId(null);
                }}
                outline
                title={t("my-account.Close")}
              >
                {icons.close}
                <span className="btn-label-hidden-xs">
                  {t("my-account.Close")}
                </span>
              </Button>
            </FooterItem>

            {!!variantParams &&
            !variantParamsIsLoading &&
            !variantParamsIsError ? (
              <FooterItem className="modal-footer-item">
                <Button
                  className="hide-label-xs"
                  secondary
                  type="button"
                  onClick={() => setShowParams(true)}
                  disabled={!variantParams.length}
                  title={t("my-account.Edit")}
                >
                  {icons.edit}
                  <span className="btn-label-hidden-xs">
                    {t("my-account.Edit")}
                  </span>
                </Button>
              </FooterItem>
            ) : null}

            {showCustomerApproval ? (
              <FooterItem className="modal-footer-item">
                <Button
                  className="hide-label-xs"
                  disabled={variant?.customerApproval?.approved}
                  stretch
                  primary
                  onClick={() => approveVariant(variant.id)}
                  title={
                    variant?.customerApproval?.approved
                      ? t("ui.Approved")
                      : t("ui.Approve")
                  }
                >
                  {icons.check}
                  <span className="btn-label-hidden-xs">
                    {variant?.customerApproval?.approved
                      ? t("ui.Approved")
                      : t("ui.Approve")}
                  </span>
                </Button>
              </FooterItem>
            ) : null}
          </Footer>
        ) : (
          <Footer className="modal-footer mob-btn">
            <FooterItem className="modal-footer-item">
              <Button
                className="hide-label-xs"
                type="button"
                onClick={() => {
                  cancelParamsEdit();
                }}
                outline
                title={t("ui.Cancel")}
              >
                {icons.close}
                <span className="btn-label-hidden-xs">{t("ui.Cancel")}</span>
              </Button>
            </FooterItem>
            <FooterItem className="modal-footer-item">
              <Button
                className="hide-label-xs"
                primary
                type="submit"
                onClick={onSubmit}
                disabled={paramsFormDisabled}
                title={t("ui.Apply-changes")}
              >
                {icons.check}
                <span className="btn-label-hidden-xs">
                  {t("ui.Apply-changes")}
                </span>
              </Button>
            </FooterItem>
          </Footer>
        )}

        <Close aria-label="Close modal" className="modal-close-btn" />
      </SModal>
    </form>
  );
};

export default VariantModal;
