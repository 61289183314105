import React, { useState } from "react";
import Card from "../Card";
import Loader from "../Loader";
import Layout from "../parts/Layout";
import Sidebar from "../parts/Sidebar";
import Error from "../Error";
import Link from "../Link";
import Button from "../Button";
import Badge from "../Badge";
import formatDate from "../../utils/formatDate";
import icons from "../../icons";
import { useTranslation } from "react-i18next";
import {
  FaClock,
  FaCube,
  FaCubes,
  FaShoppingCart,
  FaTruck,
} from "react-icons/fa";
import ImageBox from "../ImageBox";
import styled from "styled-components";
import OrderLine from "../OrderWizard/OrderLine";
import PaginationFromHeader from "../PaginationFromHeader";
import useOrders from "../../utils/useOrders";
import { IoCartSharp } from "react-icons/io5";
import config from "../../config";
import { BsFillCartCheckFill } from "react-icons/bs";

const SOrderItem = styled.div`
  margin-bottom: 1.2em;
  padding-bottom: 1.2em;
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
  }
  .img-wrap {
    width: 70px;
    float: left;
    border: 1px solid ${(props) => props.theme.borderColor};
    display: block;
  }
  .text-wrap {
    padding-left: calc(70px + 1em);
  }
  .order-head {
    margin-bottom: 0.2em;
  }
  .inline-title {
    display: inline-block;
    margin-right: 0.2em;
  }
  .campaign-link {
    display: inline-block;
    font-size: 90%;
  }
  .date-item {
    display: inline-block;
    margin-right: 1em;
  }
  .summary-header {
    margin-top: 0.8em;
    font-size: 1em;
  }
  .orderline-wrap {
    border-top: 1px dashed #bbb;
    margin-bottom: 1em;
  }
  .pack-prod-summary {
    padding-left: 27px;
    margin-bottom: 0.5em;
  }
`;

const Orders = () => {
  const { t } = useTranslation("common");

  const PAGE_SIZE = 10;
  const [page, setPage] = useState(1);
  const {
    data: ordersWithHeaders,
    isLoading,
    isError,
  } = useOrders({
    pageSize: PAGE_SIZE,
    page: page,
  });

  const orders = !!ordersWithHeaders ? ordersWithHeaders.data : [];
  const headers = !!ordersWithHeaders ? ordersWithHeaders.headers : null;

  return (
    <Layout
      main={
        isLoading ? (
          <Card
            title={t("ui.My-orders")}
            children={<Loader style={{ minHeight: "60vh" }} />}
            icon={<BsFillCartCheckFill />}
            shadow
          />
        ) : isError ? (
          <Card
            title={t("ui.My-orders")}
            children={<Error errorObj={isError} />}
            icon={<BsFillCartCheckFill />}
            shadow
          />
        ) : !!orders && orders?.length === 0 ? (
          <Card
            title={t("ui.My-orders")}
            children={
              <>
                <div>{t("order.No-orders")}</div>{" "}
              </>
            }
            icon={<BsFillCartCheckFill />}
            shadow
          />
        ) : (
          <Card
            title={t("ui.My-orders")}
            icon={<BsFillCartCheckFill />}
            shadow
            headerRight={
              <h3>
                <Badge primaryLighter>
                  {orders?.length}
                  {orders?.length === PAGE_SIZE && "+"}
                </Badge>
              </h3>
            }
            hasFooter={!isError}
            stickyFooter
            footer={
              isError ? null : (
                <>
                  {!!headers?.link ? (
                    <PaginationFromHeader
                      current={page}
                      linkStr={headers.link}
                      setPage={setPage}
                    />
                  ) : (
                    <div style={{ height: "29.25px", width: "1px" }}></div>
                  )}
                </>
              )
            }
          >
            {orders.map((order) => (
              <SOrderItem key={order.id}>
                <Link to={`/campaign/${order.programId}`} className="img-wrap">
                  <ImageBox
                    params={{
                      Width: 70,
                      Height: 70,
                    }}
                    id={order?.program?.thumbnail}
                    boxHeight="100%"
                    icon={IoCartSharp}
                  />
                </Link>

                <div className="text-wrap">
                  <h3 className="order-head">
                    <Badge state={order.state} style={{ float: "right" }}>
                      {t(`order.${order.state}`)}
                    </Badge>
                    <Link to={`/order/${order.id}`} className="inline-title">
                      {t("order.Order")} {order.id}
                    </Link>
                    <span className="list-label"> | </span>
                    <span className="campaign-link">
                      {!!order.programName ? (
                        <Link to={`/campaign/${order.programId}`}>
                          {order.programName}
                        </Link>
                      ) : (
                        t("ui.Campaign") + " " + order.programId
                      )}
                    </span>
                  </h3>

                  {/* Dates */}
                  <p className="smaller">
                    {!!order.modified && (
                      <span className="date-item">
                        <span className="list-label">
                          <span className="list-icon">
                            <FaClock />
                          </span>
                        </span>
                        <span className="list-label">
                          {t("order.Modified")}:{" "}
                        </span>
                        {formatDate(order.modified)}
                      </span>
                    )}
                    {!!order.deliveryDate && (
                      <span className="date-item">
                        <span className="list-label">
                          <span className="list-icon">{<FaTruck />}</span>
                        </span>
                        <span className="list-label">
                          {t("order.Start-date")}:{" "}
                        </span>
                        {formatDate(order.deliveryDate)}
                      </span>
                    )}
                    {!!order.orderDate && (
                      <span className="date-item">
                        <span className="list-label">
                          <span className="list-icon">
                            <FaShoppingCart />
                          </span>
                        </span>
                        <span className="list-label">
                          {t("order.Order-date")}:{" "}
                        </span>
                        {formatDate(order.orderDate)}
                      </span>
                    )}
                    {!!order.participationDate && (
                      <span className="date-item">
                        <span className="list-label">
                          <span className="list-icon">{icons.calendar}</span>
                        </span>
                        <span className="list-label">
                          {config.uiSettings.showParticipationDate
                            ? t("order.Participation-date")
                            : t("order.Campaign-date")}
                          :{" "}
                        </span>
                        {formatDate(order.participationDate)}
                      </span>
                    )}
                  </p>

                  {/* Packages */}
                  {!!order?.packageItems.length && (
                    <>
                      <h4 className="summary-header">
                        <FaCubes className="icon-inline icon-color" />
                        {t("order.Packages")}
                      </h4>
                      <div className="orderline-wrap">
                        {order.packageItems.map((item) => (
                          <div key={item.id}>
                            <OrderLine
                              name={
                                item.packageName ||
                                `${t("order.Package")} ID ${item.packageId}`
                              }
                            />
                            <div className="pack-prod-summary">
                              {item.products.map((productItem) => (
                                <OrderLine
                                  key={productItem.productId}
                                  name={productItem.productName}
                                  amount={productItem.amount}
                                />
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}

                  {/* Products */}
                  {!!order?.productItems.length && (
                    <>
                      <h4 className="summary-header">
                        <FaCube className="icon-inline icon-color" />
                        {t("order.Products")}
                      </h4>
                      <div className="orderline-wrap">
                        {order.productItems.map((item) => (
                          <OrderLine
                            amount={item.amount}
                            name={item.productName}
                            key={item.id}
                          />
                        ))}
                      </div>
                    </>
                  )}

                  <p style={{ textAlign: "right" }}>
                    <Link to={`/order/${order.id}`}>
                      <Button link>
                        {t(
                          order.state === "new" ||
                            order.state === "open" ||
                            order.state === "unknown"
                            ? "order.Resume-order"
                            : "ui.View"
                        )}{" "}
                        {icons.chevronRight}
                      </Button>
                    </Link>
                  </p>
                </div>
              </SOrderItem>
            ))}
          </Card>
        )
      }
      sidebar={<Sidebar />}
      pageTitle={t("ui.My-orders")}
    />
  );
};

export default Orders;
