import styled, { css } from "styled-components";

const WithMargin = styled.div`
  :not(:last-child) {
    margin-bottom: 1em;
  }
  ${(props) =>
    !!props.always &&
    css`
      margin-bottom: 1em;
    `}
`;

export default WithMargin;
