import React, { useState } from "react";
import Card from "../Card";
import icons from "../../icons";
import { Row, Col } from "styled-bootstrap-grid";
import Button from "../Button";
import AddressBox from "./AddressBox";
import { useTranslation } from "react-i18next";
import WithMargin from "../WithMargin";
import useSuppliers from "../../utils/useSuppliers";
import styled from "styled-components";
import Badge from "../Badge";
import ButtonWrap from "../ButtonWrap";
import SupplierForm from "./SupplierForm";
import { useSWRConfig } from "swr";
import Dialog from "../Dialog";
import Loader from "../Loader";
import Error from "../Error";
import callSuppliers from "../../utils/callSuppliers";
import { useToast } from "@zendeskgarden/react-notifications";
import Notification from "../Notification";
import getIconFromString from "../../utils/getIconFromString";
import useContactTypes from "../../utils/useContactTypes";
import DOMPurify from "dompurify";
import domPurifyWithHooks from "../../utils/adjustLinks";

const ColWithMargin = styled(Col)`
  &:not(:last-child) {
    margin-bottom: 1em;
  }
`;

const SuppliersBlock = ({ mpId, customerId }) => {
  const { t } = useTranslation("common");
  const { data, isLoading, isError, cacheKey } = useSuppliers(mpId);
  const { mutate } = useSWRConfig();

  const [itemEditedId, setItemEditedId] = useState("");
  const [dialogId, setDialogId] = useState("");
  const { addToast } = useToast();

  const {
    data: contactTypesData,
    isLoading: contactTypesIsLoading,
    isError: contactTypesIsError,
  } = useContactTypes();
  const contactTypes =
    !!contactTypesData && !!contactTypesData.length
      ? contactTypesData.map((item) => ({
          value: item,
          label: `my-account.${item}`,
        }))
      : [];

  const openDialog = (id) => {
    setDialogId(id);
    setItemEditedId(id);
  };
  const cancelDelete = () => {
    setDialogId("");
    setItemEditedId("");
  };

  // if (isLoading || isError) {
  //   return null;
  // }

  const suppliers = data;

  const handleDelete = (id) => {
    callSuppliers(
      id,
      "DELETE",
      {
        sendNotification: true,
      },
      () => {
        setDialogId("");
        mutate(cacheKey);
      },
      (error) => {
        setDialogId("");
        setItemEditedId("");
        addToast(
          ({ close }) => (
            <Notification
              title={t("ui.Something-went-wrong")}
              text={t("ui.Failed-to-delete-supplier")}
              errorText={
                !!error.title
                  ? error.title
                  : typeof error === "string" && !!error
                  ? error
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      }
    );
  };

  const addressTypes = [
    {
      value: "invoice",
      label: "my-account.Invoice-address",
    },
    {
      value: "shop",
      label: "my-account.Shop",
    },
    {
      value: "postal",
      label: "my-account.Post-address",
    },
    {
      value: "delivery",
      label: "my-account.Delivery-address",
    },
  ];

  domPurifyWithHooks(DOMPurify);

  return (
    <Card title={t("my-account.Suppliers")} shadow icon={icons.store}>
      {isLoading || contactTypesIsLoading ? (
        <Loader />
      ) : isError || contactTypesIsError ? (
        <Error
          errorObj={
            isError ? isError : contactTypesIsError ? contactTypesIsError : null
          }
        />
      ) : (
        <>
          <p className="margin-bottom">
            {t("my-account.Suppliers-description")}
          </p>
          {!!suppliers.length &&
            suppliers.map((item) => (
              <WithMargin key={item.id}>
                <AddressBox
                  isLoading={itemEditedId === item.id}
                  className="fadeInDown"
                >
                  {(!!item.name || !!item.supplierType) && (
                    <h3>
                      {!!item.supplierType && (
                        <Badge primaryLighter>
                          {t(`my-account.${item.supplierType}`)}
                        </Badge>
                      )}
                      {!!item.name && " " + item.name}
                    </h3>
                  )}
                  {!!item.description && (
                    <div
                      className="margin-bottom"
                      dangerouslySetInnerHTML={
                        !!item.description && {
                          __html: DOMPurify.sanitize(item.description, {
                            USE_PROFILES: { html: true },
                          }),
                        }
                      }
                    />
                  )}
                  {!!item.addresses && !!item.addresses.length && (
                    <div className="row-wrap">
                      <Row>
                        {item.addresses.map((address, index) => (
                          <ColWithMargin sm={6} key={index}>
                            {(!!address.name || address.type) && (
                              <h4>
                                {!!address.name && address.name + " "}
                                {!!address.type && (
                                  <span>
                                    (
                                    {!!addressTypes.filter(
                                      (addressType) =>
                                        addressType.value === address.type
                                    ).length
                                      ? t(
                                          addressTypes.filter(
                                            (addressType) =>
                                              addressType.value === address.type
                                          )[0].label
                                        )
                                      : address.type}
                                    )
                                  </span>
                                )}
                              </h4>
                            )}
                            {(!!address.street ||
                              !!address.houseNumber ||
                              !!address.suffix) && (
                              <div>
                                {!!address.street && address.street + " "}
                                {!!address.houseNumber &&
                                  address.houseNumber + " "}
                                {!!address.suffix && address.suffix}
                              </div>
                            )}
                            {(!!address.postalCode || !!address.city) && (
                              <div>
                                {!!address.postalCode &&
                                  address.postalCode + " "}
                                {!!address.city && address.city}
                              </div>
                            )}
                            {!!address.province && (
                              <div>{address.province}</div>
                            )}
                            {!!address.country && <div>{address.country}</div>}
                          </ColWithMargin>
                        ))}
                      </Row>
                    </div>
                  )}
                  {!!item.contactDetails && !!item.contactDetails.length && (
                    <WithMargin>
                      {/* Contact details */}
                      {!!item.contactDetails.length ? (
                        <>
                          <h4 style={{ marginTop: "1em" }}>
                            {t("my-account.Contact-details")}
                          </h4>
                          {item.contactDetails.map((item, index) => (
                            <p key={index}>
                              <span className="list-label">
                                <span className="list-icon">
                                  {getIconFromString(item.type, icons.contact)}
                                </span>
                                {contactTypes.filter(
                                  (contact) => contact.value === item.type
                                ).length > 0
                                  ? t(
                                      contactTypes.filter(
                                        (contact) => contact.value === item.type
                                      )[0].label
                                    )
                                  : ""}
                              </span>{" "}
                              {item.value}
                            </p>
                          ))}
                        </>
                      ) : null}
                    </WithMargin>
                  )}

                  <ButtonWrap>
                    <SupplierForm
                      onSuccess={() => mutate(cacheKey)}
                      customerId={customerId}
                      formData={item}
                      contactTypes={contactTypes}
                    />
                    <Button link small onClick={() => openDialog(item.id)}>
                      {icons.trash}
                      <span>{t("my-account.Delete")}</span>
                    </Button>
                  </ButtonWrap>
                  {itemEditedId === item.id && (
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                  {dialogId === item.id && (
                    <Dialog
                      title={t("my-account.Delete-supplier")}
                      text={t("my-account.Delete-this-supplier")}
                      name={item.name}
                      onConfirm={() => handleDelete(item.id)}
                      onCancel={cancelDelete}
                    />
                  )}
                </AddressBox>
              </WithMargin>
            ))}
          <div className="text-right">
            <SupplierForm
              onSuccess={() => mutate(cacheKey)}
              customerId={customerId}
              contactTypes={contactTypes}
            />
          </div>
        </>
      )}
    </Card>
  );
};

export default SuppliersBlock;
