import React from "react";
import { useTranslation } from "react-i18next";
import { MONTHS } from "../constants";

// input like 2017-12-22T00:00:00
const LocaleDate = ({ date }) => {
  const { t } = useTranslation("common");

  const monthNames = MONTHS;
  const dateObj = new Date(date);

  if (
    !date ||
    !dateObj.getDate() ||
    !(dateObj.getMonth() + 1) ||
    !dateObj.getFullYear()
  ) {
    return null;
  }

  return (
    <>
      {dateObj.getDate()} {t(`month.${monthNames[dateObj.getMonth()]}`)}{" "}
      {dateObj.getFullYear()}
    </>
  );
};

export default LocaleDate;
