import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "styled-bootstrap-grid";
import Footer from "./Footer";
import Header from "./Header";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import { useAuth } from "../../context/AuthContext";
import { Navigate, useLocation } from "react-router-dom";
import { publicRoutes } from "../../routes";
import config from "../../config";
import { useTranslation } from "react-i18next";
import MaintenanceMessage from "../MaintenanceMessage";
import isFutureDate from "../../utils/isFutureDate";

const SLayoutContainer = styled(Container)`
  margin-bottom: ${(props) =>
    props.bottomNav && document.body.clientWidth < 768 ? "2.2em" : 0};
`;

const Layout = ({
  main,
  sidebar,
  banner,
  extraSection,
  pageTitle,
  bottomNav,
  fullScreen = false,
  showFooter = true,
}) => {
  const { i18n } = useTranslation("common");
  const { user } = useAuth();
  const customerId = localStorage.getItem("customerId");
  const loc = useLocation();

  // Maintenance warning
  const messageSeen = localStorage.getItem("maintenanceMessageSeen");
  const isFutureOrInf =
    (config?.uiSettings?.maintenanceMessage?.end &&
      isFutureDate(config?.uiSettings?.maintenanceMessage?.end)) ||
    !config?.uiSettings?.maintenanceMessage?.end;
  const [showMessage, setShowMessage] = useState(
    config?.uiSettings?.maintenanceMessage && !messageSeen
  );
  const hideMessage = () => {
    localStorage.setItem("maintenanceMessageSeen", true);
    setShowMessage(false);
  };

  // logged in, no customer selected
  if (
    user?.subject &&
    !customerId &&
    publicRoutes.filter((r) => loc.pathname.indexOf(r.path) > -1).length === 0
  ) {
    return <Navigate to="/select-customer" />;
  }

  // not logged in
  if (
    !user?.subject &&
    publicRoutes.filter((r) => loc.pathname.indexOf(r.path) > -1).length === 0
  ) {
    return <Navigate to="/login" />;
  }

  return (
    <React.Fragment>
      <Helmet htmlAttributes={{ lang: i18n.language || config.defaultLang }}>
        <title>
          {pageTitle} | {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      {!!fullScreen ? (
        main
      ) : (
        <SLayoutContainer
          bottomNav={bottomNav}
          fluid
          style={{ maxWidth: "1340px" }}
        >
          {config?.uiSettings?.maintenanceMessage && isFutureOrInf ? (
            <MaintenanceMessage
              showMessage={showMessage}
              hideMessage={hideMessage}
            />
          ) : null}
          <Row>
            <Col col={12}>
              <Header
                showMessage={showMessage}
                setShowMessage={setShowMessage}
                hideMessage={hideMessage}
                messageVisible={isFutureOrInf}
              >
                {banner}
              </Header>
            </Col>
          </Row>
          {extraSection ? (
            <Row>
              <Col col={12}>{extraSection}</Col>
            </Row>
          ) : null}

          <main>
            {sidebar ? (
              <Row>
                <Col md={8}>{main}</Col>
                <Col md={4}>{sidebar}</Col>
              </Row>
            ) : (
              <Row>
                <Col>{main}</Col>
              </Row>
            )}
          </main>
          {showFooter ? <Footer /> : null}
        </SLayoutContainer>
      )}
    </React.Fragment>
  );
};

Layout.propTypes = {
  main: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  sidebar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  banner: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  pageTitle: PropTypes.string.isRequired,
};

export default Layout;
