import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as RCarousel } from "react-responsive-carousel";
import styled, { css } from "styled-components";
import { opacify } from "polished";

const SCarousel = styled.div`
  .slide {
    transition: opacity 0.2s;
  }
  .slide:not(.selected) {
    opacity: 0;
  }
  .carousel-slide {
    text-decoration: none;
    display: block;
    h4 {
      margin-bottom: 0;
    }
  }
  .carousel-legend {
    text-align: left;
    color: ${(props) => props.theme.headingColor};
    padding: 2em 3.5em 1.1em calc(50% + 2em);
    &:after {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      bottom: 0;
      height: 56px;
      background: linear-gradient(
        transparent,
        ${(props) => props.theme.lightBg} 30%
      );
    }
  }
  .carousel-title {
    font-size: 1.4em;
    line-height: 1.2;
  }
  .carousel-descr {
    overflow: hidden;
    max-width: 100%;
  }
  .carousel.carousel-slider .control-arrow {
    width: 2.4rem;
    &:before {
      transform: scale(1.7);
      border-left-color: ${(props) => opacify(-0.7, props.theme.accentColor)};
      border-right-color: ${(props) => opacify(-0.7, props.theme.accentColor)};
    }
    ${(props) =>
      props.hideControls &&
      css`
        display: none;
      `}
    &:hover {
      opacity: 0.6;
    }
  }
  .carousel .control-dots {
    margin: 1.35em 0;
    height: 1em;
    ${(props) =>
      props.hideControls &&
      css`
        display: none;
      `}
    .dot {
      background: ${(props) => opacify(-0.5, props.theme.accentColor)};
      box-shadow: none;
      width: 1em;
      height: 1em;
      &:only-child {
        display: none;
      }
    }
    .indicator {
      background: ${(props) => props.theme.accentColor};
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 1em;
      color: white;
      margin: 0 3px;
      line-height: 29px;
      &.selected {
        opacity: 1;
      }
    }
  }
  .carousel-thumb-wrap {
    height: auto;
    margin-bottom: -1px;
    width: 50%;
    position: relative;
    z-index: 1;
    float: left;
  }

  @media (min-width: 1200px) {
    .carousel-title {
      font-size: 1.8em;
    }
    .carousel-thumb-wrap {
      max-width: 350px;
    }
    .carousel-legend {
      padding-left: calc(350px + 2em);
      max-height: 350px;
    }
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    .carousel-legend {
      max-height: 232px;
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    .carousel-legend {
      max-height: 212px;
    }
  }
  @media (max-width: 480px) {
    .carousel-legend {
      position: relative;
      top: auto;
      width: 100%;
      padding: 1.1em;
      max-height: 75vw;
      &:after {
        height: 30px;
        background: linear-gradient(
          transparent,
          ${(props) => props.theme.lightBg}
        );
      }
    }
    .carousel-thumb-wrap {
      width: 100%;
      float: none;
    }
    .carousel .control-dots {
      top: 0;
    }
  }
`;

const Carousel = (props) => {
  return (
    <SCarousel hideControls={props.hideControls}>
      <RCarousel {...props} />
    </SCarousel>
  );
};

export default Carousel;
