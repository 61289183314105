import React, { useState } from "react";
import FormRow from "../FormRow";
import { useTranslation } from "react-i18next";
import icons from "../../icons";
import { Row, Col } from "styled-bootstrap-grid";
import Button from "../Button";
import { Input, Field, MediaInput } from "@zendeskgarden/react-forms";
import { useForm, Controller } from "react-hook-form";
import {
  Dropdown,
  Field as SelectField,
  Menu,
  Item,
  Select,
} from "@zendeskgarden/react-dropdowns";
import styled from "styled-components";
import {
  Modal,
  Header,
  Body,
  Footer,
  FooterItem,
  Close,
} from "@zendeskgarden/react-modals";
import { useToast } from "@zendeskgarden/react-notifications";
import callContact from "../../utils/callContact";
import Notification from "../Notification";
import Loader from "../Loader";
import { CgArrowsV } from "react-icons/cg";
import {
  MONTHS as MONTHSCONST,
  PERSONSROLES,
  PERSONSGENDERS,
} from "../../constants";
import contactRules from "../../utils/contactRules";
import useRoles from "../../utils/useRoles";

const SModal = styled(Modal)`
  max-width: 97%;
  max-height: 97%;
  margin: auto;
  left: 0;
  right: 0;
`;

const SLoaderOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(255 255 255 / 50%);
  user-select: none;
  > div {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 50em;
    margin: auto;
  }
`;

const ContactPersonForm = ({
  onSuccess,
  customerId,
  formData,
  contactTypes,
  isLink = false,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, isSubmitting, reset, watch } = useForm();
  const { t } = useTranslation("common");
  const { addToast } = useToast();
  const { data: rolesList } = useRoles();

  // Contacts
  const [contacts, setContacts] = useState(
    !!formData && !!formData.contactDetails && !!formData.contactDetails.length
      ? formData.contactDetails.map((item, index) => index)
      : [0]
  );
  const addContact = () =>
    setContacts([
      ...contacts,
      contacts.length > 0 ? contacts[contacts.length - 1] + 1 : 0,
    ]);
  const deleteContact = (id) =>
    setContacts(contacts.filter((item) => item !== id));
  const selectedContactTypes = watch(contacts.map((id) => "contactType" + id));

  // Roles
  const [roles, setRoles] = useState(
    !!formData && !!formData.roles && !!formData.roles.length
      ? formData.roles.map((item, index) => index)
      : [0]
  );
  const addRole = () =>
    setRoles([...roles, roles.length > 0 ? roles[roles.length - 1] + 1 : 0]);
  const deleteRole = (id) => setRoles(roles.filter((item) => item !== id));

  // Form
  const resetForm = () => {
    reset();
    setIsLoading(false);
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    callContact(
      !!formData ? formData.id : null,
      !!formData ? "PUT" : "POST",
      {
        customerId: customerId,
        gender: data.gender.value,
        prefix: data.prefix,
        initials: data.initials,
        firstName: data.firstName,
        lastName: data.lastName,
        dateOfBirth:
          !!data.year && !!data.month.value && !!data.day
            ? `${data.year}-${data.month.value}-${String(data.day).padStart(
                2,
                "0"
              )}`
            : null,
        contactDetails: contacts.map((id) => ({
          type: data["contactType" + id].value,
          value: data["contactValue" + id],
          sequence: 0,
          notes: null,
        })),
        roles:
          roles.length > 0 ? roles.map((id) => data["role" + id].value) : null,
        sendNotification: true,
      },
      (res) => {
        setModalOpen(false);
        resetForm();
        onSuccess();
      },
      (error) => {
        setIsLoading(false);
        setModalOpen(false);
        addToast(
          ({ close }) => (
            <Notification
              title={t("ui.Something-went-wrong")}
              text={t("my-account.Failed-to-edit-contact-persons")}
              errorText={
                !!error.title
                  ? error.title
                  : typeof error === "string" && !!error
                  ? error
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      }
    );
  };

  const genders = [
    {
      value: PERSONSGENDERS.UNKNOWN,
      label: "my-account.Unknown",
    },
    {
      value: PERSONSGENDERS.MALE,
      label: "my-account.Male",
    },
    {
      value: PERSONSGENDERS.FEMALE,
      label: "my-account.Female",
    },
  ];

  const monthNames = MONTHSCONST;
  const months = monthNames.map((item, index) => ({
    value: String(index + 1).padStart(2, "0"),
    label: item,
  }));

  const roleNames = [
    PERSONSROLES.CONTACTPERSONCAMPAINGS,
    PERSONSROLES.MANAGER,
    PERSONSROLES.ACCOUNTING,
    PERSONSROLES.CAMPAIGNLISTRECEPIENT,
    PERSONSROLES.CONTACTPERSON,
    PERSONSROLES.OWNER,
    PERSONSROLES.SECONDOWNDER,
  ];

  const array = !!rolesList && rolesList.length ? rolesList : roleNames;

  const filterRoles = [
    //short term solution
    "Contactperson campaigns",
    "CampaignList recipient",
    "Customer proofing recipient",
    "Seedlist eDM recipient",
    "Relatiegraad Goed",
    "Relatiegraad Neutraal",
    "Relatiegraad Slecht",
    "Tekenbevoegd",
    "Ambassadeur",
    "Blokker",
    "Accounting",
    "Finance",
    "IT (Wapper)",
    "IT (technical)",
  ];

  const filteredRoles = array.filter((role) => !filterRoles.includes(role));

  const rolesWithLabels = filteredRoles.map((item) => ({
    value: item,
    label: item,
  }));

  const filterContactTypes = [
    //short term solution
    "Website",
    "Afspraak url",
    "Facebook",
    "Twitter",
    "Instagram",
    "LinkedIn",
    "Google+",
    "YouTube",
    "Anders",
    "Werkplaatsafspraken URL",
    "Bandenwisselafspraak URL",
    "Email afzenderadres",
    "Fax",
    "Occasion URL",
    "Volvo Card Telefoon nr.",
    "Email DatabaseNotifications",
  ];

  const filteredContactTypes = contactTypes.filter(
    (contactType) => !filterContactTypes.includes(contactType.value)
  );

  const defaultContactRules = { required: true, maxLength: 50 };

  const getDay = (dateOB) => {
    const jsDate = new Date(dateOB);
    return jsDate.getDate();
  };
  const getMonth = (dateOB) => {
    const jsDate = new Date(dateOB);
    return jsDate.getMonth();
  };
  const getYear = (dateOB) => {
    const jsDate = new Date(dateOB);
    return jsDate.getFullYear();
  };

  const doBRequired = () => {
    const day = watch("day");
    const month = watch("month");
    const year = watch("year");
    return !!day || (!!month && !!month.value) || !!year;
  };

  return (
    <React.Fragment>
      {!!formData ? (
        <Button link small onClick={() => setModalOpen(true)}>
          {icons.edit}
          <span>{t("my-account.Edit")}</span>
        </Button>
      ) : (
        <Button
          primary={!isLink}
          link={isLink}
          round={!isLink}
          onClick={() => setModalOpen(true)}
          title={t("my-account.Add-new-contact-person")}
        >
          {isLink ? (
            <>
              {icons.user}
              <span>{t("my-account.Add-new-contact-person")}</span>{" "}
              {/* Display text */}
            </>
          ) : (
            icons.add
          )}
        </Button>
      )}
      {modalOpen && (
        <SModal
          isLarge
          onClose={() => setModalOpen(false)}
          appendToNode={document.body}
          aria-label="Edit Contact Person"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Header>
              <h3>{t("my-account.New-contact-person")}</h3>
            </Header>
            <Body style={{ overflow: "visible" }}>
              <>
                <FormRow multiField noMargin label={t("my-account.Gender")}>
                  <div className="row-wrap">
                    <Row>
                      <Col md={6} col={12} style={{ marginBottom: "1.2em" }}>
                        <Field>
                          <Controller
                            name="gender"
                            control={control}
                            defaultValue={
                              !!formData &&
                              !!formData.gender &&
                              genders.filter(
                                (item) =>
                                  item.value.toLowerCase() ===
                                  formData.gender.toLowerCase()
                              ).length > 0
                                ? genders.filter(
                                    (item) =>
                                      item.value.toLowerCase() ===
                                      formData.gender.toLowerCase()
                                  )[0]
                                : genders[0]
                            }
                            rules={{ required: true, maxLength: 30 }}
                            render={({ field }) => (
                              <Dropdown
                                selectedItem={field.value}
                                onSelect={field.onSelect}
                                downshiftProps={{
                                  ...field,
                                  itemToString: (item) => item && item.label,
                                }}
                              >
                                <SelectField>
                                  <Select>{t(field.value.label)}</Select>
                                </SelectField>
                                <Menu>
                                  {genders.map((option) => (
                                    <Item key={option.value} value={option}>
                                      {t(option.label)}
                                    </Item>
                                  ))}
                                </Menu>
                              </Dropdown>
                            )}
                          />
                        </Field>
                      </Col>
                    </Row>
                  </div>
                </FormRow>
                <FormRow
                  multiField
                  noMargin
                  label={t("my-account.Title-and-initials")}
                >
                  <div className="row-wrap">
                    <Row>
                      <Col md={6} col={12} style={{ marginBottom: "1.2em" }}>
                        <Field>
                          <Controller
                            name="prefix"
                            control={control}
                            defaultValue={
                              !!formData && !!formData.prefix
                                ? formData.prefix
                                : ""
                            }
                            rules={{ maxLength: 30 }}
                            render={({ field, fieldState }) => (
                              <Input
                                {...field}
                                validation={fieldState.invalid ? "error" : null}
                                placeholder={t("my-account.Title")}
                              />
                            )}
                          />
                        </Field>
                      </Col>
                      <Col md={6} col={12} style={{ marginBottom: "1.2em" }}>
                        <Field>
                          <Controller
                            name="initials"
                            control={control}
                            defaultValue={
                              !!formData && !!formData.initials
                                ? formData.initials
                                : ""
                            }
                            rules={{ required: true, maxLength: 30 }}
                            render={({ field, fieldState }) => (
                              <Input
                                {...field}
                                validation={fieldState.invalid ? "error" : null}
                                placeholder={t("my-account.Initials")}
                              />
                            )}
                          />
                        </Field>
                      </Col>
                    </Row>
                  </div>
                </FormRow>
                <FormRow multiField noMargin label={t("ui.Name")}>
                  <div className="row-wrap">
                    <Row>
                      <Col md={6} col={12} style={{ marginBottom: "1.2em" }}>
                        <Field>
                          <Controller
                            name="firstName"
                            control={control}
                            defaultValue={
                              !!formData && !!formData.firstName
                                ? formData.firstName
                                : ""
                            }
                            rules={{ required: true, maxLength: 50 }}
                            render={({ field, fieldState }) => (
                              <Input
                                {...field}
                                validation={fieldState.invalid ? "error" : null}
                                placeholder={t("my-account.First-name")}
                              />
                            )}
                          />
                        </Field>
                      </Col>
                      <Col md={6} col={12} style={{ marginBottom: "1.2em" }}>
                        <Field>
                          <Controller
                            name="lastName"
                            control={control}
                            defaultValue={
                              !!formData && !!formData.lastName
                                ? formData.lastName
                                : ""
                            }
                            rules={{ required: true, maxLength: 50 }}
                            render={({ field, fieldState }) => (
                              <Input
                                {...field}
                                validation={fieldState.invalid ? "error" : null}
                                placeholder={t("my-account.Last-name")}
                              />
                            )}
                          />
                        </Field>
                      </Col>
                    </Row>
                  </div>
                </FormRow>
                <FormRow
                  multiField
                  noMargin
                  label={t("my-account.Date-of-birth")}
                >
                  <div className="row-wrap">
                    <Row>
                      <Col col={4} style={{ marginBottom: "1.2em" }}>
                        <Field>
                          <Controller
                            name="day"
                            control={control}
                            defaultValue={
                              !!formData && !!formData.dateOfBirth
                                ? getDay(formData.dateOfBirth)
                                : ""
                            }
                            rules={{ maxLength: 2, required: doBRequired() }}
                            render={({ field, fieldState }) => (
                              <MediaInput
                                {...field}
                                validation={fieldState.invalid ? "error" : null}
                                placeholder={t("my-account.Day")}
                                type="number"
                                min="1"
                                max="31"
                                end={<CgArrowsV />}
                              />
                            )}
                          />
                        </Field>
                      </Col>
                      <Col col={4} style={{ marginBottom: "1.2em" }}>
                        <Field>
                          <Controller
                            name="month"
                            control={control}
                            defaultValue={
                              !!formData && !!formData.dateOfBirth
                                ? months[getMonth(formData.dateOfBirth)]
                                : ""
                            }
                            rules={{ maxLength: 30, required: doBRequired() }}
                            render={({ field }) => (
                              <Dropdown
                                selectedItem={field.value}
                                onSelect={field.onSelect}
                                downshiftProps={{
                                  ...field,
                                  itemToString: (item) => item && item.label,
                                }}
                              >
                                <SelectField>
                                  <Select>{t(field.value.label)}</Select>
                                </SelectField>
                                <Menu>
                                  <Item value={{ label: "", value: "" }}>
                                    ...
                                  </Item>
                                  {months.map((option) => (
                                    <Item key={option.value} value={option}>
                                      {t(option.label)}
                                    </Item>
                                  ))}
                                </Menu>
                              </Dropdown>
                            )}
                          />
                        </Field>
                      </Col>
                      <Col col={4} style={{ marginBottom: "1.2em" }}>
                        <Field>
                          <Controller
                            name="year"
                            control={control}
                            defaultValue={
                              !!formData && !!formData.dateOfBirth
                                ? getYear(formData.dateOfBirth)
                                : ""
                            }
                            rules={{ maxLength: 4, required: doBRequired() }}
                            render={({ field, fieldState }) => (
                              <MediaInput
                                {...field}
                                validation={fieldState.invalid ? "error" : null}
                                placeholder={t("my-account.Year")}
                                type="number"
                                min="1900"
                                max={new Date().getFullYear()}
                                end={<CgArrowsV />}
                              />
                            )}
                          />
                        </Field>
                      </Col>
                    </Row>
                  </div>
                </FormRow>
                {contacts.map((id, index) => (
                  <FormRow
                    key={id}
                    multiField
                    noMargin
                    label={index === 0 ? t("my-account.Contact-details") : ""}
                  >
                    <div className="row-wrap">
                      <Row>
                        <Col md={5} col={12} style={{ marginBottom: "1.2em" }}>
                          <Field>
                            <Controller
                              name={"contactType" + id}
                              control={control}
                              defaultValue={
                                !!formData &&
                                !!formData.contactDetails &&
                                !!formData.contactDetails.length &&
                                !!formData.contactDetails[id] &&
                                !!formData.contactDetails[id].type &&
                                filteredContactTypes.filter(
                                  (item) =>
                                    item.value.toLowerCase() ===
                                    formData.contactDetails[
                                      id
                                    ].type.toLowerCase()
                                ).length > 0
                                  ? filteredContactTypes.filter(
                                      (item) =>
                                        item.value.toLowerCase() ===
                                        formData.contactDetails[
                                          id
                                        ].type.toLowerCase()
                                    )[0]
                                  : filteredContactTypes[0]
                              }
                              rules={{ required: true, maxLength: 50 }}
                              render={({ field }) => (
                                <Dropdown
                                  selectedItem={field.value}
                                  onSelect={field.onSelect}
                                  downshiftProps={{
                                    ...field,
                                    itemToString: (item) => item && item.label,
                                  }}
                                >
                                  <SelectField>
                                    <Select>{t(field.value.label)}</Select>
                                  </SelectField>
                                  <Menu>
                                    {filteredContactTypes.map((option) => (
                                      <Item key={option.value} value={option}>
                                        {option.value}
                                      </Item>
                                    ))}
                                  </Menu>
                                </Dropdown>
                              )}
                            />
                          </Field>
                        </Col>
                        <Col
                          md={7}
                          col={12}
                          style={{ marginBottom: "1.2em", display: "flex" }}
                        >
                          <Field style={{ width: "100%" }}>
                            <Controller
                              name={"contactValue" + id}
                              control={control}
                              defaultValue={
                                !!formData &&
                                !!formData.contactDetails &&
                                !!formData.contactDetails.length &&
                                !!formData.contactDetails[id] &&
                                !!formData.contactDetails[id].value
                                  ? formData.contactDetails[id].value
                                  : ""
                              }
                              rules={
                                !!selectedContactTypes[index] &&
                                !!selectedContactTypes[index].value &&
                                !!contactRules[
                                  selectedContactTypes[index].value
                                ]
                                  ? contactRules[
                                      selectedContactTypes[index].value
                                    ]
                                  : defaultContactRules
                              }
                              render={({ field, fieldState }) => (
                                <MediaInput
                                  {...field}
                                  placeholder={t(
                                    !!selectedContactTypes[index]
                                      ? selectedContactTypes[index].label
                                      : filteredContactTypes[0].label
                                  )}
                                  validation={
                                    fieldState.invalid ? "error" : null
                                  }
                                />
                              )}
                            />
                          </Field>
                          <Button
                            outline
                            square
                            onClick={() => deleteContact(id)}
                            title={t("my-account.Delete-this-contact")}
                            style={{
                              height: "40px",
                              marginLeft: "1.2em",
                              minWidth: "40px",
                            }}
                          >
                            {icons.trash}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </FormRow>
                ))}
                <FormRow
                  multiField
                  label={
                    contacts.length === 0 ? t("my-account.Contact-details") : ""
                  }
                >
                  <div style={{ display: "flex", height: "100%" }}>
                    <Button link onClick={addContact}>
                      {icons.add}
                      <span>{t("my-account.Add-contact")}</span>
                    </Button>
                  </div>
                </FormRow>
                {roles.map((id, index) => (
                  <FormRow
                    key={id}
                    multiField
                    noMargin
                    label={index === 0 ? t("my-account.Role") : ""}
                  >
                    <div className="row-wrap">
                      <Row>
                        <Col
                          col={12}
                          style={{ marginBottom: "1.2em", display: "flex" }}
                        >
                          <Field style={{ width: "100%" }}>
                            <Controller
                              name={"role" + id}
                              control={control}
                              defaultValue={
                                !!formData &&
                                !!formData.roles &&
                                !!formData.roles.length &&
                                !!formData.roles[id]
                                  ? rolesWithLabels.filter(
                                      (item) =>
                                        item.value.toLowerCase() ===
                                        formData.roles[id].toLowerCase()
                                    )[0]
                                  : rolesWithLabels[0]
                              }
                              rules={{ required: true, maxLength: 50 }}
                              render={({ field }) => (
                                <Dropdown
                                  selectedItem={field.value}
                                  onSelect={field.onSelect}
                                  downshiftProps={{
                                    ...field,
                                    itemToString: (item) => item && item.label,
                                  }}
                                >
                                  <SelectField>
                                    <Select>
                                      {t(
                                        !!field.value
                                          ? field.value.label
                                          : !!formData &&
                                            !!formData.roles &&
                                            !!formData.roles[id]
                                          ? formData.roles[id]
                                          : ""
                                      )}
                                    </Select>
                                  </SelectField>
                                  <Menu>
                                    {rolesWithLabels.map((option) => (
                                      <Item key={option.value} value={option}>
                                        {t(option.label)}
                                      </Item>
                                    ))}
                                  </Menu>
                                </Dropdown>
                              )}
                            />
                          </Field>
                          {index > 0 ? (
                            <Button
                              outline
                              square
                              onClick={() => deleteRole(id)}
                              title={t("my-account.Delete-this-role")}
                              style={{
                                height: "40px",
                                marginLeft: "1.2em",
                                minWidth: "40px",
                              }}
                            >
                              {icons.trash}
                            </Button>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  </FormRow>
                ))}
                <FormRow
                  multiField
                  label={roles.length === 0 ? t("my-account.Role") : ""}
                >
                  <div style={{ display: "flex", height: "100%" }}>
                    <Button link onClick={addRole}>
                      {icons.add}
                      <span>{t("my-account.Add-role")}</span>
                    </Button>
                  </div>
                </FormRow>
              </>
            </Body>
            <Footer>
              <FooterItem>
                <Button
                  type="button"
                  onClick={() => setModalOpen(false)}
                  outline
                >
                  {icons.close}
                  <span>{t("ui.Cancel")} </span>
                </Button>
              </FooterItem>
              <FooterItem>
                <Button primary type="submit" disabled={isSubmitting}>
                  {icons.check}
                  <span>{t("my-account.Save")}</span>
                </Button>
              </FooterItem>
            </Footer>
            {isLoading && (
              <SLoaderOverlay
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  background: "rgb(255 255 255 / 50%)",
                  userSelect: "none",
                }}
              >
                <Loader />
              </SLoaderOverlay>
            )}
          </form>
          <Close aria-label={t("my-account.Close")} />
        </SModal>
      )}
    </React.Fragment>
  );
};

export default ContactPersonForm;
