import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import Card from "../Card";
import CollapsibleBlock from "../CollapsibleBlock";
import icons from "../../icons";
import Layout from "../parts/Layout";
import {
  Field,
  Label,
  Textarea,
  MediaInput,
  Select as FormSelect,
} from "@zendeskgarden/react-forms";
import Badge from "../Badge";
import FormRow from "../FormRow";
import { Datepicker } from "@zendeskgarden/react-datepickers";
import { ReactComponent as StartIcon } from "@zendeskgarden/svg-icons/src/16/calendar-stroke.svg";
import WizardButtons from "./WizardButtons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CartSummary from "./CartSummary";
import CampaignBlock from "./CampaignBlock";
import AccordionWrapper from "./AccordionWrapper";
import useProducts from "../../utils/useProducts";
import CartProduct from "./CartProduct";
import callOrder from "../../utils/callOrder";
import usePackages from "../../utils/usePackages";
import useProgram from "../../utils/useProgram";
import callOrderItem from "../../utils/callOrderItem";
import {
  Alert,
  Close,
  Title,
  useToast,
} from "@zendeskgarden/react-notifications";
import Notification from "../Notification";
import Error from "../Error";
import Loader from "../Loader";
import {
  Dropdown,
  Field as DropdownField,
  Menu,
  Item,
  Select,
} from "@zendeskgarden/react-dropdowns";
import LocaleDate from "../LocaleDate";
import Link from "../Link";
import submitOrder from "../../utils/submitOrder";
import useCustomer from "../../utils/useCustomer";
import useContacts from "../../utils/useContacts";
import config from "../../config";
import { IoCart, IoHelpCircle } from "react-icons/io5";
import { TooltipModal } from "@zendeskgarden/react-modals";
import getOrder from "../../utils/getOrder";
import addTime from "../../utils/addTime";
import addDays from "../../utils/addDays";
import { FaCube, FaCubes } from "react-icons/fa";
import isFutureDate from "../../utils/isFutureDate";
import daysTillNextWorkDay from "../../utils/daysTillNextWorkDay";
import ContactPersonForm from "../MyAccount/ContactPersonForm";
import { useSWRConfig } from "swr";
import useContactTypes from "../../utils/useContactTypes";
import AddressForm from "../MyAccount/AddressForm";
import OrderMailMoments from "./OrderMailMoments";
import useInfinite from "../../utils/useInfinite";
import apiConfig from "../../apiConfig";
import NoItems from "../NoItems";
import { getLabelParams } from "../../utils/getLabelParams";

const showCustomerApproval = config.uiSettings.showCustomerApproval;

// active:
//  new : just created, can be edited in portal
//  pending : waiting on approval, cannot be changed in portal
//  open : can be edited in portal

// inactive:
//  closed
//  final
//  unknown : should not happen

const OrderWizard = () => {
  // Init hooks
  const { t, i18n } = useTranslation("common");
  const { mutate } = useSWRConfig();
  const { addToast } = useToast();
  const navigate = useNavigate();

  // URL params
  const { orderId } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const { month, year } = {
    month: searchParams.get("month"),
    year: searchParams.get("year"),
  };

  //////////////////// Init state ////////////////////

  // Order
  const [orderWithHeaders, setOrderWithHeaders] = useState(null);
  const [orderIsLoading, setOrderIsLoading] = useState(false);
  const [orderIsError, setOrderIsError] = useState(null);

  // Wizard steps
  const [initialRender, setInitialRender] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [disabled, setDisabled] = useState(false);

  // User selected
  const [selectedDropDate, setSelectedDropDate] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedContactPerson, setSelectedContactPerson] = useState(null);
  const [selectedVIndex, setSelectedVIndex] = useState(0);

  // UI
  const [showWarning, setShowWarning] = useState(true);

  const [tooltipRefEl, setTooltipRefEl] = useState(null);
  const tooltipButtonRef = useRef();

  //////////////////// Data ////////////////////

  // Pick up order on mount
  useEffect(() => {
    getOrder({
      id: orderId,
      onSucces: (res) => {
        setOrderWithHeaders(res);
        setOrderIsLoading(false);
        setOrderIsError(null);
      },
      onError: (error) => {
        setOrderWithHeaders(null);
        setOrderIsLoading(false);
        setOrderIsError(error);
      },
    });
  }, [orderId]);

  const order = useMemo(
    () => (!!orderWithHeaders ? orderWithHeaders.data : null),
    [orderWithHeaders]
  );
  const etag = useMemo(
    () => (!!orderWithHeaders ? orderWithHeaders.headers.etag : null),
    [orderWithHeaders]
  );

  const { data: program, isError: programIsError } = useProgram(
    order?.programId,
    () => order?.programId
  );

  //customer is fetched for addresses & labels
  const {
    data: customer,
    customerIsLoading,
    customerIsError,
    cacheKey: refreshAddress,
  } = useCustomer();

  const labelParamsWithFilter = getLabelParams({
    internalLabels: customer?.internalLabels || [],
    externalLabels: customer?.externalLabels || [],
  });

  const {
    data: products,
    isLoading: productsIsLoading,
    isError: productsIsError,
  } = useProducts({
    params: {
      programId: order?.programId,
      orderBy: "sequence",
      ...labelParamsWithFilter,
    },

    shouldFetch: () => !!order.programId && !!customer,
  });

  const {
    data: packages,
    isLoading: packagesIsLoading,
    isError: packagesIsError,
  } = usePackages({
    params: {
      programId: order?.programId,
      orderBy: "sequence",
      ...labelParamsWithFilter,
    },

    shouldFetch: () => !!order.programId && !!customer,
  });

  const {
    data: persons,
    personsIsLoading,
    personsIsError,
    cacheKey,
  } = useContacts(() => order?.orderFlow === "normal");

  //keeps track of the latest addresses before new ones are fetched
  const [localAddresses, setLocalAddresses] = useState(customer?.addresses);
  const [checkForNewAddresses, setCheckForNewAddresses] = useState(false);
  const [sourceIdSelectedAddress, setSourceIdSelectedAddress] = useState(
    order?.deliveryAddress?.sourceId
  ); //selected id for addresses

  //keeps track of the latest persons before new ones are fetched
  const [localPersons, setLocalPersons] = useState(persons);
  const [checkForNewPersons, setCheckForNewPersons] = useState(false);
  const [sourceIdSelectedPerson, setSourceIdSelectedPerson] = useState(
    order?.contactPerson?.sourceId
  ); //selected id for persons

  //this adds sourceId to the addresses of customers. This is not preferably
  //but it works just fine and could be removed once addresses have real ids
  useEffect(() => {
    if (customer?.addresses) {
      customer.addresses = customer?.addresses.map((address, index) => {
        if (!address.hasOwnProperty("sourceId")) {
          return {
            ...address,
            sourceId: index,
          };
        }
        return address;
      });
    }
  }, [customer]);

  const refetchAddress = () => {
    //function that is called when addresses are edited or added
    setLocalAddresses(customer?.addresses);
    setCheckForNewAddresses(false);
    mutate(refreshAddress);
  };

  const refetchPerson = () => {
    //function that is called when persons are edited or added
    setLocalPersons(persons);
    setCheckForNewPersons(false);
    mutate(cacheKey);
  };

  useEffect(() => {
    setCheckForNewAddresses(true); //for when new addresses are fetched we can compare in other useEffect
  }, [customer]);

  useEffect(() => {
    setCheckForNewPersons(true); //same for persons
  }, [persons]);

  const {
    data: contactTypesData,
    isLoading: contactTypesIsLoading,
    isError: contactTypesIsError,
  } = useContactTypes();
  const contactTypes =
    !!contactTypesData && !!contactTypesData.length
      ? contactTypesData.map((item) => ({
          value: item,
          label: item,
        }))
      : [];

  const orderVariantIds = useMemo(
    () => [
      ...new Set(
        (!!order && !!order.productItems && !!order.productItems.length) ||
        (!!order && !!order.packageItems && !!order.packageItems.length)
          ? [
              order.productItems.map((prod) => prod.variantId),
              order.packageItems.map((pack) =>
                !!pack.products.length
                  ? pack.products.map((prod) => prod.variantId)
                  : []
              ),
            ]
              .flat()
              .flat()
              .filter((item) => !!item)
          : []
      ),
    ],
    [order]
  );

  // Fetch all variants
  const PAGE_SIZE = 30;
  const { phoenix: phoenixApi } = apiConfig;
  const customerId = localStorage.getItem("customerId");

  const {
    data: variants,
    isError: variantsError,
    size: variantsPage,
    setSize: setVariantsPage,
    mutate: mutateOrderVariants,
  } = useInfinite({
    key: `${phoenixApi}/variants`,
    pageSize: PAGE_SIZE,
    shouldFetch: () =>
      orderVariantIds?.length > 0 && order?.orderFlow === "normal",
    params: {
      functionallyActive: true,
      orderByDirection: "descending",
      customerId: customerId,
      variantIds: orderVariantIds?.join("&variantIds="),
    },
  });

  const allVariants = !!variants ? [].concat(...variants) : [];
  const variantsIsLoadingInitialData = !variants && !variantsError;
  const variantsIsLoadingMore =
    variantsIsLoadingInitialData ||
    (variantsPage > 0 &&
      !!variants &&
      typeof variants[variantsPage - 1] === "undefined");
  const variantsIsEmpty = variants?.[0]?.length === 0;
  const variantsIsReachingEnd =
    variantsIsEmpty ||
    (!!variants && variants[variants.length - 1]?.length < PAGE_SIZE);

  useEffect(() => {
    if (
      !!variants &&
      !!variants[variantsPage - 1] &&
      !variantsIsEmpty &&
      !variantsIsLoadingMore &&
      !variantsIsReachingEnd
    ) {
      setVariantsPage(variantsPage + 1);
    }
  }, [
    variants,
    variantsPage,
    variantsIsEmpty,
    variantsIsLoadingMore,
    variantsIsReachingEnd,
    setVariantsPage,
  ]);

  const productsWithOrderInfo = useMemo(() => {
    const findAlreadyInCartPackWithProd = (prodId) =>
      (!!order &&
        !!order?.packageItems.length &&
        order.packageItems
          .map((orderPackage) => ({
            ...orderPackage,
            products: orderPackage?.products.filter(
              (orderItem) => orderItem?.productId === prodId
            ),
          }))
          .filter((orderPackage) => !!orderPackage.products.length)?.[0]) ||
      null;
    return !!products && products.length > 0
      ? products.map((productItem) => {
          const thisProdInCart =
            (!!order &&
              !!order?.productItems.length &&
              order.productItems.filter(
                (orderItem) => orderItem?.productId === productItem?.id
              )?.[0]) ||
            null;

          return !!thisProdInCart
            ? {
                ...productItem,
                amount: thisProdInCart?.amount,
                orderItemId: thisProdInCart?.id,
                isCustom: thisProdInCart?.isCustom,
              }
            : {
                ...productItem,
                amount: 0,
                orderItemId: null,
                isCustom: false,
                inCartPackName:
                  findAlreadyInCartPackWithProd(productItem.id)?.name || null,
              };
        })
      : [];
  }, [order, products]);

  const packagesWithOrderInfo = useMemo(() => {
    const findAlreadyInCartPackWithProd = (prodId) =>
      (!!order &&
        !!order?.packageItems.length &&
        order.packageItems
          .map((orderPackage) => ({
            ...orderPackage,
            products: orderPackage?.products.filter(
              (orderItem) => orderItem?.productId === prodId
            ),
          }))
          .filter((orderPackage) => !!orderPackage.products.length)?.[0]) ||
      null;
    const findAlreadyInCartProd = (prodId) =>
      (!!order &&
        !!order?.productItems.length &&
        order.productItems.filter((op) => op.productId === prodId)?.[0]) ||
      null;
    return !!packages && packages.length > 0
      ? packages.map((packageItem) => {
          const thisPackInCart =
            (!!order &&
              !!order?.packageItems.length &&
              order?.packageItems.filter(
                (orderItem) => orderItem?.packageId === packageItem?.id
              )?.[0]) ||
            null;

          return !!thisPackInCart
            ? {
                ...packageItem,
                amount: thisPackInCart?.amount,
                orderItemId: thisPackInCart?.id,
                isCustom: thisPackInCart?.isCustom,
                products: packageItem.products.map((pp) => ({
                  ...pp,
                  orderItemId: thisPackInCart?.products.filter(
                    (op) => op?.productId === pp?.productId
                  )?.[0]?.id,
                  isCustom: thisPackInCart?.products.filter(
                    (op) => op.productId === pp.productId
                  )?.isCustom,
                  amount: thisPackInCart?.products.filter(
                    (op) => op.productId === pp.productId
                  )?.[0]?.amount,
                  minPackageAmount: pp.amount,
                })),
              }
            : {
                ...packageItem,
                amount: 0,
                orderItemId: null,
                isCustom: false,
                products: packageItem.products.map((pp) => ({
                  ...pp,
                  minPackageAmount: pp.amount,
                  inCartProdOrderItemId:
                    findAlreadyInCartProd(pp.productId)?.id || null,
                  inCartPackName:
                    findAlreadyInCartPackWithProd(pp.productId)?.name || null,
                })),
              };
        })
      : [];
  }, [order, packages]);

  //////////////////// Order methods ////////////////////

  // Order

  const refreshOrder = useCallback(() => {
    getOrder({
      id: orderId,
      onSucces: (res) => {
        setDisabled(false);
        setOrderWithHeaders(res);
      },
      onError: (error) => {
        setDisabled(false);
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Something-went-wrong")}
              text={t("error.Failed-to-update-order")}
              errorText={
                !!error.title
                  ? error.title
                  : typeof error === "string" && !!error
                  ? error
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
    });
  }, [orderId, addToast, t]);

  const updateOrder = useCallback(
    ({
      newParticipationDate = order?.participationDate || null,
      newRemarks = order?.remarks || null,
      newContactPerson = order?.contactPerson || {},
      newDeliveryAddress = order?.deliveryAddress || {},
    }) => {
      if (!disabled && !!order) {
        setDisabled(true);
        callOrder({
          id: order?.id,
          method: "PUT",
          data: {
            // participationDate: addTime(newParticipationDate) || null,
            // participationDate: addTime(newParticipationDate) || null,
            participationDate: newParticipationDate,
            remarks: newRemarks,
            contactPerson: newContactPerson,
            deliveryAddress: newDeliveryAddress,
            distributor: order.distributor || {},
            printer: order.printer || {},
          },
          onSuccess: () => {
            refreshOrder();
          },
          onError: (error) => {
            setDisabled(false);
            addToast(
              ({ close }) => (
                <Notification
                  title={t("error.Something-went-wrong")}
                  text={t("error.Failed-to-update-order")}
                  errorText={
                    !!error.title
                      ? error.title
                      : typeof error === "string" && !!error
                      ? error
                      : t("error.Unexpected-error-occurred")
                  }
                  type="error"
                  handleClose={close}
                />
              ),
              { placement: "bottom" }
            );
          },
          headers: { "If-Match": etag },
        });
      }
    },
    [order, disabled, etag, refreshOrder, addToast, t]
  );

  const handleCheckout = () => {
    if (!!disabled) return;
    if (!order || !order.forwardAction) return;
    setDisabled(true);
    submitOrder({
      url: order.forwardAction.url,
      onSuccess: () => {
        refreshOrder();
      },
      onError: (error) => {
        setDisabled(false);
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Something-went-wrong")}
              text={t("error.Failed-to-update-order")}
              errorText={
                !!error.title
                  ? error.title
                  : typeof error === "string" && !!error
                  ? error
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
      headers: { "If-Match": etag },
    });
  };

  const addOrderItem = ({
    productId = null,
    packageId = null,
    amount = 1,
    onSuccess,
    onError,
  }) => {
    if (!!disabled) return;
    setDisabled(true);
    callOrderItem({
      orderId: order.id,
      method: "POST",
      data: {
        productId: productId,
        packageId: packageId,
        amount: amount,
      },
      onSuccess: () => {
        !!onSuccess && onSuccess();
        refreshOrder();
      },
      onError: (error) => {
        !!onError && onError();
        setDisabled(false);
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Something-went-wrong")}
              text={t("error.Failed-to-add-article")}
              errorText={
                !!error.title
                  ? error.title
                  : typeof error === "string" && !!error
                  ? error
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
      headers: { "If-Match": etag },
    });
  };

  const deleteOrderItem = ({ orderItemId, onSuccess, onError }) => {
    if (!!disabled) return;
    setDisabled(true);
    callOrderItem({
      orderId: order.id,
      orderItemId: orderItemId,
      method: "DELETE",
      data: {},
      onSuccess: () => {
        !!onSuccess && onSuccess();
        refreshOrder();
      },
      onError: (error) => {
        !!onError && onError();
        setDisabled(false);
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Something-went-wrong")}
              text={t("error.Failed-to-delete-article")}
              errorText={
                !!error.title
                  ? error.title
                  : typeof error === "string" && !!error
                  ? error
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
      headers: { "If-Match": etag },
    });
  };

  const updateOrderItem = ({
    orderItemId,
    productId,
    packageId,
    amount,
    isCustom = false,
  }) => {
    if (!!disabled) return;
    setDisabled(true);
    callOrderItem({
      orderId: order.id,
      orderItemId: orderItemId,
      method: "PUT",
      data: {
        productId: productId,
        packageId: packageId,
        amount: amount,
        isCustom: isCustom,
      },
      onSuccess: () => {
        refreshOrder();
      },
      onError: (error) => {
        setDisabled(false);
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Something-went-wrong")}
              text={t("error.Failed-to-update-article")}
              errorText={
                !!error.title
                  ? error.title
                  : typeof error === "string" && !!error
                  ? error
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
      headers: { "If-Match": etag },
    });
  };

  // Wizard steps

  const steps = useMemo(
    () =>
      !!order && order.orderFlow === "short" ? [0, 1, 2] : [0, 1, 2, 3, 4],
    [order]
  );

  const submitNewOrderOnStepChange = () => {
    if (
      order?.orderFlow === "normal" &&
      !!order?.participationDate &&
      (!!order?.productItems.length || !!order?.packageItems.length) &&
      order?.state === "new" &&
      order?.forwardAction?.name === "Submit"
    ) {
      handleCheckout();
    }
  };

  const onNext = () => {
    submitNewOrderOnStepChange();
    setCurrentStep(currentStep + 1);
  };

  const onBack = () => {
    submitNewOrderOnStepChange();
    setCurrentStep(currentStep - 1);
  };

  // Date
  const weekendParticipationDate = config.uiSettings.weekendParticipationDate;
  const orderableAfter =
    program?.orderableAfterDays ||
    config.uiSettings.participationDateDelay ||
    21;
  const today = new Date();

  const handleSelectDropDate = (value) => {
    setDisabled(true);
    setSelectedDropDate(value);
    updateOrder({
      newParticipationDate: value || null,
      newRemarks: order.remarks,
    });
    setSearchParams({});
  };

  const clearDate = () => {
    setDisabled(true);
    updateOrder({
      newParticipationDate: null,
      newRemarks: order.remarks,
    });
    setSelectedDropDate("");
    setSearchParams({});
  };

  const isInTheFutureOrNull = (orderDeadline) =>
    !!orderDeadline ? new Date(orderDeadline) > today : true;

  const getMatchingDate = useCallback(() => {
    const dropDates = program.dropDateSchedules.filter((item) => {
      const date = new Date(item.dropDateStart);
      return date.getMonth() === +month && date.getFullYear() === +year;
    });
    return dropDates.length > 0 ? new Date(dropDates[0].dropDateStart) : null;
  }, [month, year, program]);

  const isValidDropDate = (datesObj) =>
    isInTheFutureOrNull(datesObj.orderDeadline) &&
    isFutureDate(datesObj.dropDateStart);

  const hasValidDates = !!program?.dropDateSchedules.filter((item) =>
    isValidDropDate(item)
  )?.length;

  // When new address is selected
  const handleSelectDeliveryAddress = (address) => {
    setDisabled(true);
    setSelectedAddress(address);
    updateOrder({
      newDeliveryAddress: !!address ? address.value : {},
    });
  };

  //When new contact persons is selected
  const handleSelectContactPerson = (person) => {
    setDisabled(true);
    setSelectedContactPerson(person);
    updateOrder({
      newContactPerson: !!person ? person.value : {},
    });
  };

  //When address is changed we search which element doesn't match the localAddresses
  //One can be edited at a time so this is always the mismatch, same holds for adding
  const matchCustomerAddress = ({ address1, address2 }) => {
    return address1.find(
      (newAddr) =>
        !address2.some(
          (currAddr) =>
            newAddr.city === currAddr.city &&
            newAddr.houseNumber === currAddr.houseNumber &&
            newAddr.suffix === currAddr.suffix &&
            newAddr.postalCode === currAddr.postalCode &&
            newAddr.street === currAddr.street &&
            newAddr.province === currAddr.province &&
            newAddr.country === currAddr.country
        )
    );
  };

  //Since the sourceIds are not filled and we cannot send data to the sourceId property
  //we need to match the current selected address with the corresponding
  const matchAddress = ({ address1, address2Array }) => {
    for (let address2 of address2Array) {
      if (address1?.sourceId === address2?.sourceId) {
        return address2?.sourceId; // Return the sourceId of the matching address
      } else if (
        address1.sourceId === null &&
        address1.city === address2.city &&
        address1.housenumber === address2.houseNumber &&
        address1.housenumberSuffix === address2.suffix &&
        address1.postalCode === address2.postalCode &&
        address1.street === address2.street &&
        address1.region === address2.province
      ) {
        return address2?.sourceId; // Return the sourceId of the matching address
      }
    }
    return null; // Return null if no match is found
  };

  //same holds for person we need know what value to match to the sourceId since
  //we cannot send this value to the api, needs to be fixed in the backend
  const matchPerson = ({ person1, person2Array }) => {
    for (let person2 of person2Array) {
      const telephone =
        person2.contactDetails?.find(
          (contact) => contact.type === "Telefoon nr."
        )?.value || null;

      const email =
        person2.contactDetails?.find((contact) => contact.type === "Email")
          ?.value || null;
      if (person1?.sourceId === person2.id) {
        return person2.id;
      } else if (
        person1.sourceId === null &&
        person1.name === `${person2.firstName} ${person2.lastName}` &&
        person1.telephone === telephone &&
        person1.email === email
      ) {
        return person2.id;
      }
    }
    return null;
  };

  //////////////////// Effects ////////////////////
  useEffect(() => {
    setDisabled(false);
  }, []);

  // Update order if date is set in query string
  useEffect(() => {
    if (!!month && !!year && !!order && !!program && !order.participationDate) {
      if (!!disabled) return;
      setDisabled(true);
      const calendarDate =
        !!program && program.dropDateSchedules.length > 0
          ? getMatchingDate()
          : new Date().getFullYear() === +year &&
            new Date().getMonth() === +month
          ? new Date(new Date().setDate(new Date().getDate() + 1))
          : new Date(year, month, 1);
      if (!!calendarDate) {
        updateOrder({
          newParticipationDate: calendarDate,
          newRemarks: order.remarks,
        });
      }
      setSearchParams({});
      setDisabled(false);
    }
  }, [
    disabled,
    getMatchingDate,
    navigate,
    month,
    order,
    program,
    updateOrder,
    setSearchParams,
    year,
  ]);

  //We update order the new edited/added address
  useEffect(() => {
    if (checkForNewAddresses && customer?.addresses && !!localAddresses) {
      const matchingAddress = matchCustomerAddress({
        address1: customer?.addresses,
        address2: localAddresses,
      });

      if (matchingAddress) {
        const newDeliveryAddress = {
          sourceId: matchingAddress.sourceId,
          city: matchingAddress.city,
          housenumber: matchingAddress.houseNumber,
          housenumberSuffix: matchingAddress.suffix,
          postalCode: matchingAddress.postalCode,
          region: matchingAddress.province,
          street: matchingAddress.street,
        };
        updateOrder({
          newDeliveryAddress,
        });
        setLocalAddresses(customer?.addresses);
        setCheckForNewAddresses(false);
      }
    }
  }, [customer, checkForNewAddresses, localAddresses, updateOrder]);

  //We update order based on the new added/edited person
  useEffect(() => {
    const matchPersons = ({ person1, person2 }) => {
      const compareArrayDetails = (details1, details2) => {
        if (details1.length !== details2.length) return false;
        return details1.every((detail1) => {
          return details2.some(
            (detail2) =>
              detail1.type === detail2.type && detail1.value === detail2.value
          );
        });
      };

      return person1.find(
        (newPerson) =>
          !person2?.some(
            (currPerson) =>
              newPerson.dateOfBirth === currPerson.dateOfBirth &&
              newPerson.firstName === currPerson.firstName &&
              newPerson.gender === currPerson.gender &&
              newPerson.id === currPerson.id &&
              newPerson.initials === currPerson.initials &&
              newPerson.lastName === currPerson.lastName &&
              newPerson.prefix === currPerson.prefix &&
              newPerson.titlePostfix === currPerson.titlePostfix &&
              newPerson.titlePrefix === currPerson.titlePrefix &&
              compareArrayDetails(
                newPerson.contactDetails,
                currPerson.contactDetails
              ) &&
              compareArrayDetails(newPerson.roles, currPerson.roles)
          )
      );
    };
    if (checkForNewPersons && !!persons && !!localPersons) {
      const matchingPerson = matchPersons({
        person1: persons,
        person2: localPersons,
      });

      if (matchingPerson) {
        const emailContact =
          matchingPerson.contactDetails?.find(
            (contact) => contact.type === "Email"
          )?.value || null;
        const phoneContact =
          matchingPerson.contactDetails?.find(
            (contact) => contact.type === "Telefoon nr."
          )?.value || null;
        const updatedContactPerson = {
          sourceId: matchingPerson.sourceId,
          name: `${matchingPerson.firstName} ${matchingPerson.lastName}`,
          email: emailContact,
          telephone: phoneContact,
        };

        updateOrder({
          newContactPerson: updatedContactPerson,
        });

        setLocalPersons(persons);
        setCheckForNewAddresses(false);
      }
    }
  }, [checkForNewPersons, persons, localPersons, updateOrder]);

  //we set the sourceId so that we are able to know which form we can edit. That edit button is the one that will be rendered
  //To see why this is important check where matchedSourceId is used
  useEffect(() => {
    if (
      !!order &&
      !!order.deliveryAddress &&
      !!customer &&
      customer?.addresses
    ) {
      const matchedSourceId = matchAddress({
        address1: order.deliveryAddress,
        address2Array: customer?.addresses,
      });
      setSourceIdSelectedAddress(matchedSourceId);
    }
  }, [order, customer]);

  //does the same but for persons
  useEffect(() => {
    if (persons && order && order.contactPerson) {
      const matchedSourceId = matchPerson({
        person1: order.contactPerson,
        person2Array: persons,
      });
      setSourceIdSelectedPerson(matchedSourceId);
    }
  }, [persons, order]);

  useEffect(() => {
    setSelectedDropDate(
      !!order && !!order.participationDate ? order.participationDate : ""
    );

    setSelectedAddress(
      !!order && !!order.deliveryAddress
        ? {
            label: `${order.deliveryAddress.street} ${order.deliveryAddress.housenumber} ${order.deliveryAddress.housenumberSuffix} ${order.deliveryAddress.postalCode} ${order.deliveryAddress.city}`,
            value: order.deliveryAddress,
          }
        : null
    );

    setSelectedContactPerson(
      !!order && !!order.contactPerson
        ? {
            label: `${order.contactPerson?.name}`,
            value: order.contactPerson,
          }
        : null
    );
  }, [order]);

  // Proceed to first invalid step
  const allApproved =
    !showCustomerApproval ||
    (allVariants?.length > 0 &&
      allVariants?.length === orderVariantIds?.length &&
      allVariants?.every((v) => !!v?.customerApproval?.approved)) ||
    (allVariants?.length === 0 && orderVariantIds?.length === 0);

  useEffect(() => {
    const normalFlowConditions =
      order?.orderFlow === "short" ||
      (!personsIsLoading &&
        orderVariantIds?.length > 0 &&
        !variantsIsLoadingMore) ||
      (!productsIsLoading &&
        !packagesIsLoading &&
        orderVariantIds?.length === 0);

    if (
      currentStep === 0 &&
      !!order &&
      !productsIsLoading &&
      !packagesIsLoading &&
      normalFlowConditions &&
      !!initialRender
    ) {
      if (
        !!order.participationDate &&
        !order.productItems.length &&
        !order.packageItems.length
      ) {
        setCurrentStep(1); // Products & packages
      } else if (
        !!order.participationDate &&
        (!!order.productItems.length || !!order.packageItems.length) &&
        !allApproved
      ) {
        // Approval | skip for 'short' order flow
        setCurrentStep(2);
      } else if (
        !!order.participationDate &&
        (!!order.productItems.length || !!order.packageItems.length) &&
        !!allApproved &&
        (!order.deliveryAddress || !order.contactPerson)
      ) {
        // Delivery & contact | skip for 'short' order flow
        setCurrentStep(order?.orderFlow === "normal" ? 3 : 2);
      } else if (
        !!order.participationDate &&
        (!!order.productItems.length || !!order.packageItems.length) &&
        !!allApproved &&
        !!order.deliveryAddress &&
        !!order.contactPerson
      ) {
        setCurrentStep(order?.orderFlow === "normal" ? 4 : 2); // Remarks
      }
      setInitialRender(false);
    }
  }, [
    order,
    allApproved,
    currentStep,
    initialRender,
    setInitialRender,
    productsIsLoading,
    packagesIsLoading,
    customerIsLoading,
    personsIsLoading,
    variantsIsLoadingMore,
    orderVariantIds?.length,
  ]);

  //////////////////// End Effects ////////////////////

  // Validation
  const isValid = !!order
    ? order.orderFlow === "short"
      ? (!!order.participationDate &&
          !!order.productItems &&
          order.productItems.length > 0) ||
        (!!order.participationDate &&
          !!order.packageItems &&
          order.packageItems.length > 0)
      : ((!!order.participationDate &&
          !!order.productItems &&
          order.productItems.length > 0) ||
          (!!order.participationDate &&
            !!order.packageItems &&
            order.packageItems.length > 0)) &&
        !!allApproved &&
        !!order.contactPerson
    : false;

  return (
    <Layout
      pageTitle={`${t("order.Order")} ${!!order && !!order.id ? order.id : ""}`}
      bottomNav
      main={
        <React.Fragment>
          <Card
            title={`${t("order.Order")} ${
              !!order && !!order.id ? order.id : ""
            }`}
            icon={<IoCart />}
            shadow
            headerRight={
              !!order ? (
                order?.cancelled ? (
                  <Badge state="closed" style={{ fontSize: "1.2em" }}>
                    {t("order.cancelled")}
                  </Badge>
                ) : (
                  <Badge state={order.state} style={{ fontSize: "1.2em" }}>
                    {t(`order.${order.state}`)}
                  </Badge>
                )
              ) : null
            }
          >
            {!!orderIsLoading ? (
              <Loader />
            ) : !!orderIsError ? (
              <Error errorObj={orderIsError} />
            ) : !!order ? (
              order?.cancelled ? (
                <NoItems label={t("order.Order-cancelled")} />
              ) : (!!order && order.state === "new") ||
                (!!order && order.state === "open") ? (
                <>
                  <AccordionWrapper isStepper>
                    {/* Date selection */}
                    <div
                      className="item-wrapper"
                      onClick={() => {
                        submitNewOrderOnStepChange();
                        currentStep !== 0 && setCurrentStep(0);
                      }}
                    >
                      <CollapsibleBlock
                        triggerIcon={
                          !order.participationDate ? (
                            <Badge
                              primaryLighter={currentStep !== 0}
                              primary={currentStep === 0}
                              round
                            >
                              1
                            </Badge>
                          ) : (
                            <Badge success round>
                              {icons.check}
                            </Badge>
                          )
                        }
                        triggerText={
                          <h3>
                            {config.uiSettings.showParticipationDate
                              ? t("order.Participation-date")
                              : t("order.Campaign-date")}{" "}
                            {!!order.participationDate ? (
                              <span className="color-secondary smaller">
                                <LocaleDate date={order.participationDate} />
                              </span>
                            ) : null}
                          </h3>
                        }
                        open={currentStep === 0}
                        isStepper
                      >
                        <FormRow
                          label={
                            config.uiSettings.showParticipationDate
                              ? t("order.Participation-date") + "*"
                              : t("order.Campaign-date") + "*"
                          }
                          multiField
                          labelSizeSm={4}
                        >
                          {!!program && program.dropDateSchedules.length > 0 ? (
                            <FormSelect
                              onChange={(e) =>
                                handleSelectDropDate(e.target.value)
                              }
                              disabled={!hasValidDates}
                              className="select-bg"
                              value={selectedDropDate}
                            >
                              {/* Always show "Select date" option*/}
                              <option style={{ color: "#888" }} value="">
                                {t("order.Select-date")}...
                              </option>
                              {/* No dates selectable but has value */}
                              {!program.dropDateSchedules.filter((item) =>
                                isValidDropDate(item)
                              )?.length && order?.participationDate ? (
                                <option value={order?.participationDate}>
                                  <LocaleDate date={order?.participationDate} />
                                </option>
                              ) : null}
                              {/* Dates selectable */}
                              {program.dropDateSchedules
                                .filter((item) => isValidDropDate(item))
                                .sort(
                                  (prev, curr) =>
                                    new Date(prev.dropDateStart) >
                                    new Date(curr.dropDateStart)
                                )
                                .map((item) => (
                                  <option
                                    key={item?.dropDateStart}
                                    value={addTime(item?.dropDateStart)}
                                  >
                                    <LocaleDate
                                      date={addTime(item.dropDateStart)}
                                    />
                                  </option>
                                ))}
                            </FormSelect>
                          ) : (
                            <Datepicker
                              value={
                                !!selectedDropDate
                                  ? new Date(selectedDropDate)
                                  : null
                              }
                              minValue={addDays(
                                orderableAfter +
                                  (weekendParticipationDate
                                    ? 0
                                    : daysTillNextWorkDay())
                              )}
                              onChange={(newDate) => {
                                setSelectedDropDate(addTime(newDate));
                                updateOrder({
                                  newParticipationDate: addTime(newDate),
                                  newRemarks: order.remarks,
                                });
                              }}
                              locale={i18n.language}
                            >
                              <MediaInput start={<StartIcon />} />
                            </Datepicker>
                          )}
                          <div style={{ marginTop: ".6em" }}>
                            <Button
                              onClick={clearDate}
                              link
                              disabled={
                                !order.participationDate || !hasValidDates
                              }
                            >
                              {icons.trash} <span>{t("ui.Clear")}</span>
                            </Button>
                          </div>
                        </FormRow>
                      </CollapsibleBlock>
                    </div>

                    {/* Article selection */}
                    <div
                      className="item-wrapper"
                      onClick={() => {
                        submitNewOrderOnStepChange();
                        currentStep !== 1 && setCurrentStep(1);
                      }}
                    >
                      <CollapsibleBlock
                        triggerText={
                          <h3>
                            {t("order.Articles-Marketingmeans")}{" "}
                            {order.packageItems.length > 0 ||
                            order.productItems.length > 0 ? (
                              <span className="color-secondary smaller">
                                {order.productItems.length +
                                  order.packageItems.length}
                              </span>
                            ) : null}
                          </h3>
                        }
                        triggerIcon={
                          order.productItems.length +
                            order.packageItems.length ===
                          0 ? (
                            <Badge
                              primaryLighter={currentStep !== 1}
                              primary={currentStep === 1}
                              round
                            >
                              2
                            </Badge>
                          ) : (
                            <Badge success round>
                              {icons.check}
                            </Badge>
                          )
                        }
                        open={currentStep === 1}
                        isStepper
                      >
                        {!!packagesWithOrderInfo &&
                        !!packagesWithOrderInfo.length ? (
                          <h3>
                            <FaCubes className="icon-inline" />
                            {t("order.Packages")}
                          </h3>
                        ) : null}

                        {packagesIsLoading ? (
                          <Loader />
                        ) : packagesIsError || customerIsError ? (
                          <Error
                            errorObj={packagesIsError || customerIsError}
                          />
                        ) : (
                          !!packagesWithOrderInfo &&
                          packagesWithOrderInfo.map((item) => (
                            <CartProduct
                              {...item}
                              key={item.id}
                              addOrderItem={addOrderItem}
                              deleteOrderItem={deleteOrderItem}
                              updateOrderItem={updateOrderItem}
                              packageId={item.id}
                              disabled={disabled}
                              setDisabled={setDisabled}
                            />
                          ))
                        )}
                        {!!productsWithOrderInfo &&
                        !!productsWithOrderInfo.length ? (
                          <h3>
                            <FaCube className="icon-inline" />
                            {t("order.Products")}
                          </h3>
                        ) : null}
                        {productsIsLoading ? (
                          <Loader />
                        ) : productsIsError ? (
                          <Error errorObj={productsIsError} />
                        ) : (
                          !!productsWithOrderInfo &&
                          productsWithOrderInfo.map((item) => (
                            <CartProduct
                              {...item}
                              key={item.id}
                              addOrderItem={addOrderItem}
                              deleteOrderItem={deleteOrderItem}
                              updateOrderItem={updateOrderItem}
                              productId={item.id}
                              disabled={disabled}
                              setDisabled={setDisabled}
                            />
                          ))
                        )}

                        {!packages?.length &&
                        !packagesIsLoading &&
                        !packagesIsError &&
                        !products?.length &&
                        !productsIsLoading &&
                        !productsIsError ? (
                          <NoItems />
                        ) : null}
                      </CollapsibleBlock>
                    </div>

                    {order.orderFlow === "normal" ? (
                      <>
                        {/* Variant approval */}
                        <div
                          className="item-wrapper"
                          onClick={() => {
                            submitNewOrderOnStepChange();
                            currentStep !== 2 && setCurrentStep(2);
                          }}
                        >
                          <CollapsibleBlock
                            triggerText={<h3>{t("order.Variant-choice")}</h3>}
                            triggerIcon={
                              !orderVariantIds?.length || !allApproved ? (
                                <Badge
                                  primaryLighter={currentStep !== 2}
                                  primary={currentStep === 2}
                                  round
                                >
                                  3
                                </Badge>
                              ) : (
                                <Badge success round>
                                  {icons.check}
                                </Badge>
                              )
                            }
                            open={currentStep === 2}
                            isStepper
                          >
                            <div>
                              {!orderVariantIds?.length ? (
                                <NoItems
                                  label={t("order.No-articles-selected")}
                                />
                              ) : variantsIsLoadingInitialData ? (
                                <Loader />
                              ) : variantsError ? (
                                <Error errorObj={variantsError} />
                              ) : allVariants ? (
                                <OrderMailMoments
                                  mailMoments={program?.mailMoments}
                                  orderVariants={allVariants}
                                  orderVariantIds={orderVariantIds}
                                  selectedVIndex={selectedVIndex}
                                  setSelectedVIndex={setSelectedVIndex}
                                  onNext={onNext}
                                  updateOrderVariants={mutateOrderVariants}
                                  key={allVariants?.id}
                                />
                              ) : null}
                            </div>
                          </CollapsibleBlock>
                        </div>

                        {/* Delivery & contact */}
                        <div
                          className="item-wrapper"
                          onClick={() => {
                            submitNewOrderOnStepChange();
                            currentStep !== 3 && setCurrentStep(3);
                          }}
                        >
                          <CollapsibleBlock
                            triggerText={
                              <h3>{t("order.Delivery-and-contact")}</h3>
                            }
                            triggerIcon={
                              !order.contactPerson ? (
                                <Badge
                                  primaryLighter={currentStep !== 3}
                                  primary={currentStep === 3}
                                  round
                                >
                                  4
                                </Badge>
                              ) : (
                                <Badge success round>
                                  {icons.check}
                                </Badge>
                              )
                            }
                            open={currentStep === 3}
                            isStepper
                          >
                            {/* Delivery address  */}
                            {!!customerIsLoading ? (
                              <div style={{ width: "100%" }}>
                                <Loader />
                              </div>
                            ) : !!customerIsError ? (
                              <div style={{ width: "100%" }}>
                                <Error errorObj={customerIsError} />
                              </div>
                            ) : !!customer && customer?.addresses ? (
                              <>
                                <FormRow
                                  label={t("order.Delivery-address")}
                                  multiField
                                  labelSizeSm={4}
                                >
                                  <Dropdown
                                    selectedItem={selectedAddress}
                                    onSelect={handleSelectDeliveryAddress}
                                    downshiftProps={{
                                      itemToString: (dropdownItem) =>
                                        dropdownItem && dropdownItem.label,
                                    }}
                                  >
                                    <DropdownField>
                                      <Select
                                        disabled={!customer?.addresses.length}
                                      >
                                        {!!selectedAddress
                                          ? `${
                                              selectedAddress.value.street || ""
                                            } ${
                                              selectedAddress.value
                                                .housenumber || ""
                                            } ${
                                              selectedAddress.value
                                                .housenumberSuffix || ""
                                            } ${
                                              selectedAddress.value
                                                .postalCode || ""
                                            } ${
                                              selectedAddress.value.city || ""
                                            }`
                                          : !customer?.addresses.length
                                          ? t("order.no-addresses-yet")
                                          : null}
                                      </Select>
                                    </DropdownField>
                                    <Menu>
                                      <Item
                                        key="no-value" //yet to change
                                        className="dropdown-item"
                                        value={{
                                          label: t("order.not-applicable"),
                                          value: {
                                            street: "",
                                            housenumber: "",
                                            housenumberSuffix: "",
                                            postalCode: "",
                                            city: "",
                                            region: "",
                                          },
                                        }}
                                      >
                                        {t("order.not-applicable")}
                                      </Item>
                                      {customer?.addresses.map(
                                        (address, index) => (
                                          <Item
                                            key={address.postalCode + index}
                                            className="dropdown-item smaller"
                                            value={{
                                              label: `${address.street || ""} ${
                                                address.housenumber || ""
                                              } ${
                                                address.housenumberSuffix || ""
                                              } ${address.postalCode || ""} ${
                                                address.city || ""
                                              }`,
                                              value: {
                                                sourceId: address.sourceId,
                                                street: address.street,
                                                housenumber:
                                                  address.houseNumber,
                                                housenumberSuffix:
                                                  address.suffix,
                                                postalCode: address.postalCode,
                                                city: address.city,
                                                region: address.province,
                                              },
                                            }}
                                          >
                                            <div>
                                              {`${address.street || ""} ${
                                                address.houseNumber || ""
                                              } ${address.suffix || ""}`}
                                              <br />
                                              {`${address.postalCode || ""} ${
                                                address.city || ""
                                              }`}
                                            </div>
                                          </Item>
                                        )
                                      )}
                                    </Menu>
                                  </Dropdown>

                                  {!!selectedAddress ? (
                                    <div style={{ marginTop: "1em" }}>
                                      {(!!selectedAddress.value.street ||
                                        !!selectedAddress.value.houseNumber ||
                                        !!selectedAddress.value.suffix) && (
                                        <div>
                                          {!!selectedAddress.value.street &&
                                            selectedAddress.value.street + " "}
                                          {!!selectedAddress.value
                                            .housenumber &&
                                            selectedAddress.value.housenumber +
                                              " "}
                                          {!!selectedAddress.value
                                            .housenumberSuffix &&
                                            selectedAddress.value
                                              .housenumberSuffix}
                                        </div>
                                      )}
                                      {(!!selectedAddress.value.postalCode ||
                                        !!selectedAddress.value.city) && (
                                        <div>
                                          {!!selectedAddress.value.postalCode &&
                                            selectedAddress.value.postalCode +
                                              " "}
                                          {!!selectedAddress.value.city &&
                                            selectedAddress.value.city}
                                        </div>
                                      )}
                                      {!!selectedAddress.value.region && (
                                        <div>
                                          {selectedAddress.value.region}
                                        </div>
                                      )}
                                    </div>
                                  ) : null}

                                  <div style={{ marginTop: ".6em" }}>
                                    {!!selectedAddress &&
                                      customer?.addresses.map(
                                        (address, index) => {
                                          if (
                                            address?.sourceId ===
                                            sourceIdSelectedAddress
                                          ) {
                                            return (
                                              <AddressForm
                                                key={index}
                                                customerId={customer?.id}
                                                onSuccess={refetchAddress}
                                                formData={address}
                                                index={index}
                                                addresses={customer?.addresses}
                                              />
                                            );
                                          }
                                          return null;
                                        }
                                      )}
                                    <AddressForm
                                      customerId={customer?.id}
                                      addresses={customer?.addresses}
                                      onSuccess={refetchAddress}
                                      isLink={true}
                                    />
                                  </div>
                                </FormRow>
                              </>
                            ) : null}

                            <br />

                            {/* Contact person */}
                            {!!personsIsLoading ? (
                              <div style={{ width: "100%" }}>
                                <Loader />
                              </div>
                            ) : !!personsIsError ? (
                              <div style={{ width: "100%" }}>
                                <Error errorObj={personsIsError} />
                              </div>
                            ) : !!persons ? (
                              <>
                                <TooltipModal
                                  referenceElement={tooltipRefEl}
                                  onClose={() => setTooltipRefEl(null)}
                                  placement="top"
                                  className="tooltip"
                                  aria-label={t("order.Contact-person")}
                                >
                                  <TooltipModal.Body>
                                    <div style={{ paddingRight: "2em" }}>
                                      {t("ui.Contact-person-description")}
                                    </div>
                                  </TooltipModal.Body>
                                  <TooltipModal.Close aria-label="Close" />
                                </TooltipModal>
                                <FormRow
                                  label={
                                    <>
                                      <span
                                        className="help-link-wrap"
                                        ref={tooltipButtonRef}
                                      >
                                        <Button
                                          style={{ textDecoration: "none" }}
                                          link
                                          onClick={() => {
                                            setTooltipRefEl(
                                              tooltipButtonRef.current
                                            );
                                          }}
                                        >
                                          <strong>
                                            {t("order.Contact-person")}*{" "}
                                          </strong>
                                          <IoHelpCircle />
                                        </Button>
                                      </span>
                                    </>
                                  }
                                  multiField
                                  labelSizeSm={4}
                                >
                                  <Dropdown
                                    selectedItem={selectedContactPerson}
                                    onSelect={handleSelectContactPerson}
                                    downshiftProps={{
                                      itemToString: (dropdownItem) =>
                                        dropdownItem && dropdownItem.label,
                                    }}
                                  >
                                    <DropdownField>
                                      <Select disabled={!persons?.length}>
                                        {!!selectedContactPerson
                                          ? selectedContactPerson.value.name
                                          : !persons?.length
                                          ? t("order.no-contact-persons-yet")
                                          : ""}
                                      </Select>
                                    </DropdownField>
                                    <Menu>
                                      {persons.map((person, index) => (
                                        <Item
                                          key={person.id}
                                          className="dropdown-item"
                                          value={{
                                            label: `${person.firstName} ${person.lastName}`,
                                            value: {
                                              sourceId: person.id,
                                              name: `${person.firstName} ${person.lastName}`,
                                              email:
                                                !!person.contactDetails.filter(
                                                  (contact) =>
                                                    contact.type === "Email"
                                                ).length
                                                  ? person.contactDetails.filter(
                                                      (contact) =>
                                                        contact.type === "Email"
                                                    )[0].value
                                                  : null,
                                              telephone:
                                                !!person.contactDetails.filter(
                                                  (contact) =>
                                                    contact.type ===
                                                    "Telefoon nr."
                                                ).length
                                                  ? person.contactDetails.filter(
                                                      (contact) =>
                                                        contact.type ===
                                                        "Telefoon nr."
                                                    )[0].value
                                                  : null,
                                            },
                                          }}
                                        >
                                          {person.firstName} {person.lastName}
                                        </Item>
                                      ))}
                                    </Menu>
                                  </Dropdown>

                                  {!!selectedContactPerson &&
                                  !!selectedContactPerson.value.name ? (
                                    <div style={{ marginTop: "1em" }}>
                                      {selectedContactPerson.value.email ? (
                                        <p>
                                          {" "}
                                          <span className="list-label">
                                            <span className="list-icon">
                                              {icons.email}
                                            </span>
                                            {t(`my-account.Email`)}
                                          </span>{" "}
                                          {selectedContactPerson.value.email}
                                        </p>
                                      ) : null}
                                      {selectedContactPerson.value.telephone ? (
                                        <p>
                                          {" "}
                                          <span className="list-label">
                                            <span className="list-icon">
                                              {icons.phone}
                                            </span>
                                            {t(`my-account.Phone-number`)}
                                          </span>{" "}
                                          {
                                            selectedContactPerson.value
                                              .telephone
                                          }
                                        </p>
                                      ) : null}
                                    </div>
                                  ) : null}

                                  <div
                                    style={{
                                      marginTop: ".9em",
                                    }}
                                  >
                                    {contactTypesIsLoading ||
                                    personsIsLoading ? (
                                      <Loader />
                                    ) : contactTypesIsError ||
                                      personsIsError ? (
                                      <Error
                                        errorObj={
                                          contactTypesIsError
                                            ? contactTypesIsError
                                            : personsIsError
                                            ? personsIsError
                                            : null
                                        }
                                      />
                                    ) : (
                                      !!persons &&
                                      !!order &&
                                      persons.map((person) => {
                                        if (
                                          person.id === sourceIdSelectedPerson
                                        ) {
                                          return (
                                            <ContactPersonForm
                                              key={person.id} // Changed from person.email to person.id as email might not be unique or present
                                              formData={person}
                                              onSuccess={refetchPerson}
                                              customerId={customer?.id}
                                              contactTypes={contactTypes}
                                            />
                                          );
                                        }
                                        return null;
                                      })
                                    )}
                                    <ContactPersonForm
                                      onSuccess={refetchPerson}
                                      customerId={customer?.id}
                                      contactTypes={contactTypes}
                                      isLink={true}
                                    />
                                  </div>
                                </FormRow>
                              </>
                            ) : null}
                          </CollapsibleBlock>
                        </div>
                      </>
                    ) : null}

                    {/* Remarks */}
                    <div
                      className="item-wrapper"
                      onClick={() => {
                        submitNewOrderOnStepChange();
                        currentStep !==
                          (order.orderFlow === "normal" ? 4 : 2) &&
                          setCurrentStep(order.orderFlow === "normal" ? 4 : 2);
                      }}
                    >
                      <CollapsibleBlock
                        triggerText={<h3>{t("order.Remarks")}</h3>}
                        triggerIcon={
                          !order.remarks ? (
                            <Badge
                              primaryLighter={
                                currentStep !==
                                (order.orderFlow === "normal" ? 4 : 2)
                              }
                              primary={
                                currentStep ===
                                (order.orderFlow === "normal" ? 4 : 2)
                              }
                              round
                            >
                              {(order.orderFlow === "normal" ? 4 : 2) + 1}
                            </Badge>
                          ) : (
                            <Badge success round>
                              {icons.check}
                            </Badge>
                          )
                        }
                        open={
                          currentStep === (order.orderFlow === "normal" ? 4 : 2)
                        }
                        isStepper
                      >
                        <Field>
                          <Label hidden>{t("order.Remarks")}</Label>
                          <Textarea
                            isResizable
                            minRows={3}
                            placeholder={t(
                              "order.Remarks-regarding-this-order"
                            )}
                            onBlur={(e) =>
                              e.target.value !== order.remarks &&
                              updateOrder({
                                newParticipationDate: order.participationDate,
                                newRemarks: e.target.value,
                              })
                            }
                            maxLength={250}
                            defaultValue={order.remarks}
                          />
                        </Field>
                        {!!order && order.state === "open" && !!showWarning ? (
                          <Alert
                            type="info"
                            className="margin-top-small fadeInDown"
                          >
                            <Close
                              onClick={() => setShowWarning(false)}
                              aria-label="Close"
                            />
                            <Title>
                              {t("order.Finalize-order-warning-title")}
                            </Title>
                            <p className="smaller">
                              {t("order.Finalize-order-warning-text")}
                            </p>
                          </Alert>
                        ) : null}
                      </CollapsibleBlock>
                    </div>
                  </AccordionWrapper>
                  {document.body.clientWidth >= 768 && (
                    <WizardButtons>
                      {currentStep !== 0 ? (
                        <Button link onClick={onBack}>
                          {icons.chevronLeft}
                          <span>{t("order.Previous")}</span>
                        </Button>
                      ) : (
                        <span></span>
                      )}
                      {currentStep === steps[steps.length - 1] ? (
                        <Button
                          primary
                          wide
                          onClick={handleCheckout}
                          disabled={!isValid || disabled}
                        >
                          {icons.checkCircle}{" "}
                          <span>
                            {order.state === "new"
                              ? t("order.Place-order")
                              : t("order.Finalize-order")}
                          </span>
                        </Button>
                      ) : (
                        <Button primary wide onClick={onNext}>
                          <span>{t("order.Next")}</span> {icons.chevronRight}
                        </Button>
                      )}
                    </WizardButtons>
                  )}
                </>
              ) : (
                <div style={{ paddingLeft: "2.5em" }}>
                  <div style={{ marginBottom: "1em" }}>
                    {!!order && order.state === "pending" ? (
                      <>
                        <h3>{t("order.Order-is-being-processed")}</h3>
                        <p>{t("order.We-will-be-in-touch-with-you-shortly")}</p>
                      </>
                    ) : null}
                    {!!order &&
                    (order.state === "closed" ||
                      order.state === "final" ||
                      order.state === "unknown") ? (
                      <>
                        <h3>{t("order.Thank-you-for-your-order!")}</h3>
                        <p>{t("order.Thank-you-1")}</p>
                      </>
                    ) : null}
                    {!!order && !!order.participationDate ? (
                      <p>
                        <span className="list-label">
                          <span className="list-icon">{icons.calendar}</span>
                          {config.uiSettings.showParticipationDate
                            ? t("order.Participation-date")
                            : t("order.Campaign-date")}{" "}
                        </span>
                        <LocaleDate date={order.participationDate} />
                      </p>
                    ) : null}
                    {!!order && !!order.remarks ? (
                      <p>
                        <span className="list-label">
                          <span className="list-icon">{icons.contact}</span>
                          {t("order.Remarks")}{" "}
                        </span>
                        {order.remarks}
                      </p>
                    ) : null}
                  </div>
                  {!!order && order.orderFlow === "normal" ? (
                    <>
                      {!!order.contactPerson ? (
                        <div style={{ marginBottom: "1em" }}>
                          <h4>{t("order.Contact-person")}</h4>
                          <p>{order.contactPerson.name}</p>
                          {!!order.contactPerson.email ? (
                            <p>
                              <span className="list-label">
                                <span className="list-icon">{icons.email}</span>
                                {t("my-account.Email")}{" "}
                              </span>
                              {order.contactPerson.email}
                            </p>
                          ) : null}
                          {!!order.contactPerson.telephone ? (
                            <p>
                              <span className="list-label">
                                <span className="list-icon">{icons.email}</span>
                                {t("my-account.Phone-number")}{" "}
                              </span>
                              {order.contactPerson.telephone}
                            </p>
                          ) : null}
                        </div>
                      ) : null}
                      {!!order.deliveryAddress ? (
                        <>
                          <h4>{t("order.Delivery-address")}</h4>
                          {(!!order.deliveryAddress.street ||
                            !!order.deliveryAddress.houseNumber ||
                            !!order.deliveryAddress.suffix) && (
                            <div>
                              {!!order.deliveryAddress.street &&
                                order.deliveryAddress.street + " "}
                              {!!order.deliveryAddress.housenumber &&
                                order.deliveryAddress.housenumber + " "}
                              {!!order.deliveryAddress.housenumberSuffix &&
                                order.deliveryAddress.housenumberSuffix}
                            </div>
                          )}
                          {(!!order.deliveryAddress.postalCode ||
                            !!order.deliveryAddress.city) && (
                            <div>
                              {!!order.deliveryAddress.postalCode &&
                                order.deliveryAddress.postalCode + " "}
                              {!!order.deliveryAddress.city &&
                                order.deliveryAddress.city}
                            </div>
                          )}
                          {!!order.deliveryAddress.region && (
                            <div>{order.deliveryAddress.region}</div>
                          )}
                        </>
                      ) : null}
                    </>
                  ) : null}

                  <WizardButtons>
                    <Button $link as={Link} to="/orders">
                      {icons.chevronLeft}
                      <span>{t("ui.My-orders")}</span>
                    </Button>
                  </WizardButtons>
                </div>
              )
            ) : null}
          </Card>
          {document.body.clientWidth < 768 &&
          ((!!order && order.state === "new") ||
            (!!order && order.state === "open")) ? (
            <WizardButtons fixed className="fadeInUp">
              {currentStep !== 0 ? (
                <Button link onClick={onBack}>
                  {icons.chevronLeft}
                  <span>{t("order.Previous")}</span>
                </Button>
              ) : (
                <span></span>
              )}
              {currentStep === steps[steps.length - 1] ? (
                <Button
                  primary
                  wide
                  onClick={handleCheckout}
                  disabled={!isValid || disabled}
                >
                  {icons.checkCircle}{" "}
                  <span>
                    {order.state === "new"
                      ? t("order.Place-order")
                      : t("order.Finalize-order")}
                  </span>
                </Button>
              ) : (
                <Button primary wide onClick={onNext}>
                  <span>{t("order.Next")}</span> {icons.chevronRight}
                </Button>
              )}
            </WizardButtons>
          ) : null}
        </React.Fragment>
      }
      sidebar={
        <React.Fragment>
          <Card
            title={t("order.Selected-campaign")}
            children={
              !!programIsError && !!order?.program ? (
                <CampaignBlock
                  programData={{
                    name: order?.program?.name,
                    thumbnailFileId: order?.program?.thumbnail,
                  }}
                />
              ) : !!program ? (
                <CampaignBlock programData={program} />
              ) : (
                <CampaignBlock
                  programData={{
                    name: `${t("ui.Campaign")} ${
                      order?.program?.id || `ID ${t("order.unknown")}`
                    }`,
                  }}
                />
              )
            }
            icon={icons.campaigns}
            shadow
          />
          <CartSummary
            orderProductItems={!!order ? order.productItems : []}
            orderPackageItems={!!order ? order.packageItems : []}
            isLoading={orderIsLoading}
            total={!!order ? order.price : 0}
            deleteOrderItem={deleteOrderItem}
            disabled={disabled || order?.cancelled}
            orderStatus={order?.state}
          />
        </React.Fragment>
      }
    />
  );
};

export default OrderWizard;
