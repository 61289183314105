import React from "react";
import { FiLogIn, FiMeh } from "react-icons/fi";
import { FaSync, FaUserLock, FaUserSecret, FaUserTimes } from "react-icons/fa";
import styled from "styled-components";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import icons from "../icons";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { NOT_LOGGED_IN, SESSION_EXPIRED, UNAUTHORIZED } from "../constants";
import AuthButton from "./AuthButton";

const S404Block = styled.div`
  padding: 2em;
  text-align: center;
  .error-title {
    margin-bottom: 0.3em;
  }
  .center-icon {
    display: block;
    font-size: 20em;
    color: ${(props) => props.theme.mediumLightBg};
    margin: 2rem auto 1rem;
    max-width: 85%;
    max-height: 30vh;
  }
  .error-inner {
    width: 350px;
    max-width: 100%;
    margin: auto;
    color: ${(props) => props.theme.headingColor};
  }
  button:not(.no-margin) {
    margin: 0 0.5em;
  }
  .error-details {
    font-size: 0.9em;
    font-family: monospace;
    background: rgb(238, 238, 238);
    padding: 0.6em 1.3em;
    margin: 1.2em auto;
    text-align: left;
    overflow-wrap: break-word;
    max-height: 83px;
    overflow: auto;
  }
  .links-wrap {
    padding-top: 0.2em;
  }
`;

const Error = ({
  errorObj = null,
  errorTitle,
  errorMessage: propErrorMessage,
  errorMessageUnauthorized,
  actionsUnauthorized,
  details: propDetails,
  children,
  icon = null,
}) => {
  const { t } = useTranslation("common");
  const defaultError = "Sorry-an-unexpected-error-occurred";

  const { user } = useAuth();

  const errorCase =
    errorObj?.message === "Network Error"
      ? !user
        ? NOT_LOGGED_IN
        : SESSION_EXPIRED
      : errorObj?.message === "Request failed with status code 403" ||
        errorObj?.message === "Request failed with status code 401"
      ? UNAUTHORIZED
      : null;

  const errorMessage = propErrorMessage
    ? propErrorMessage
    : errorObj?.response?.data?.title ===
      "One or more validation errors occurred."
    ? t("error.Validation-errors")
    : null;

  const details = propDetails
    ? propDetails
    : errorObj?.response?.data
    ? errorObj?.response?.data
    : null;
  const errorDetailsArr =
    typeof details === "object"
      ? !!details?.[0]
        ? details
        : !!details?.errors &&
          !!details.errors &&
          typeof details.errors === "object" &&
          !!Object.keys(details.errors).length
        ? Object.keys(details.errors).map((key) =>
            typeof details.errors[key] === "object" && details.errors[key]?.[0]
              ? details.errors[key].map((item) => `${key}: ${item}`)
              : null
          )
        : null
      : null;
  const errorDetailsStr = typeof details === "string" ? details : null;

  const handleReload = () => !!window && window.location.reload();

  return (
    <S404Block>
      {!!icon ? (
        icon
      ) : errorCase === NOT_LOGGED_IN ? (
        <FaUserSecret
          className="center-icon fadeInGrow"
          style={{ marginBottom: "28px" }}
        />
      ) : errorCase === SESSION_EXPIRED ? (
        <FaUserLock
          className="center-icon fadeInGrow"
          style={{ marginBottom: "28px" }}
        />
      ) : errorCase === UNAUTHORIZED ? (
        <FaUserTimes
          className="center-icon fadeInGrow"
          style={{ marginBottom: "28px" }}
        />
      ) : (
        <FiMeh className="center-icon fadeInGrow" />
      )}

      <div className="error-inner">
        <h3 className="error-title">
          {!!errorTitle
            ? errorTitle
            : errorCase === NOT_LOGGED_IN
            ? t("error.Not-logged-in")
            : errorCase === SESSION_EXPIRED
            ? t("error.Session-expired")
            : errorCase === UNAUTHORIZED
            ? t("error.Unauthorized")
            : t("error.Oops-Something-went-wrong")}
        </h3>
        <p>
          {!!errorMessage ? (
            errorMessage
          ) : errorCase === NOT_LOGGED_IN ? (
            t("error.Log-in-to-continue")
          ) : errorCase === SESSION_EXPIRED ? (
            t("error.Log-in-again")
          ) : errorCase === UNAUTHORIZED ? (
            errorMessageUnauthorized ? (
              errorMessageUnauthorized
            ) : (
              <span>
                {t("error.Not-authorized-to-access-this-content")}.
                <br />
                {t("error.Log-in-with-another-account")}
              </span>
            )
          ) : (
            t(`error.${defaultError}`)
          )}
        </p>
        {!!errorDetailsArr && !!errorDetailsArr.length ? (
          <div className="error-details">
            {errorDetailsArr.map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </div>
        ) : null}
        {!!errorDetailsStr ? (
          <div className="error-details">{errorDetailsStr}</div>
        ) : null}
        {children ? (
          children
        ) : errorCase === NOT_LOGGED_IN ||
          errorCase === SESSION_EXPIRED ||
          errorCase === UNAUTHORIZED ? (
          actionsUnauthorized ? (
            actionsUnauthorized
          ) : (
            <AuthButton
              btnProps={{
                stretch: true,
                className: "no-margin margin-top margin-bottom-small",
              }}
              logoutText={
                <>
                  <span>{t("error.Log-in-again-btn")}</span>
                  <FiLogIn />
                </>
              }
            />
          )
        ) : (
          <p className="links-wrap">
            <Button as={Link} $link to="/" className="icon-link">
              {icons.chevronLeft} {t("order.Back-home")}
            </Button>{" "}
            <Button link onClick={handleReload} className="icon-link">
              <FaSync /> {t("error.Refresh")}
            </Button>
          </p>
        )}
      </div>
    </S404Block>
  );
};

export default Error;
