import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { opacify } from "polished";

const SCard = styled.div`
  ${(props) =>
    !!props.shadow &&
    css`
      box-shadow: 0px 2px 5px #00000038;
    `};
  ${(props) =>
    !!props.borderLeft &&
    css`
      border-left: 1px solid ${props.theme.borderColor};
    `};
  margin: ${(props) => (props.stretchFullScreen ? "-1.5em -1em -1em -1em" : 0)};
  margin-bottom: ${(props) => (props.noMargin ? 0 : "1.2em")};
  @media (max-width: 575px) {
    margin-bottom: ${(props) => (props.noMargin ? 0 : ".6em")};
    margin-top: ${(props) => (props.stretchFullScreen ? "-8px" : 0)};
  }
  border-radius: ${(props) => (props.noRadius ? 0 : props.theme.borderRadius)};
  display: block;
  .card-header {
    background: ${(props) => props.theme.lightBg};
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: ${(props) =>
      props.noRadius
        ? 0
        : `${props.theme.borderRadius} ${props.theme.borderRadius} 0 0`};
    .header-item {
      padding: 0 1.5em;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
      color: ${(props) => props.theme.headingColor};
      font-weight: normal;
      margin-top: 1px;
      margin-bottom: -1px;
    }
    h2 {
      font-size: 1.4em;
    }
    .icon {
      font-size: 2em;
      margin-right: 0.4em;
      line-height: 1;
      margin-top: 1px;
      margin-bottom: -1px;
      color: ${(props) => opacify(-0.5, props.theme.accentColor)};
      width: 1em;
    }
    .header-left {
      display: flex;
    }
    .header-right {
      margin: -1px 0 -1px auto;
      .pad-right {
        margin-right: 1em;
      }
      .min-width-btn {
        min-width: 12em;
      }
    }
  }
  .card-body {
    background: ${(props) => (props.whiteBg ? "#fff" : props.theme.lightBg)};
    padding: ${(props) => (props.noPadding === true ? 0 : "1.2em")};
    border-radius: ${(props) =>
      props.noRadius
        ? 0
        : !!props.cardTitle
        ? `0 0 ${props.theme.borderRadius} ${props.theme.borderRadius}`
        : props.theme.borderRadius};
    border-bottom-left-radius: ${(props) =>
      !!props.hasFooter || props.noRadius ? 0 : props.theme.borderRadius};
    border-bottom-right-radius: ${(props) =>
      !!props.hasFooter || props.noRadius ? 0 : props.theme.borderRadius};
    .card-heading-link {
      color: ${(props) => props.theme.accentColor};
    }
  }
  .card-footer {
    background: ${(props) => props.theme.lightBg};
    padding: 0.6em;
    border-top: 1px solid ${(props) => props.theme.borderColor};
    border-bottom-left-radius: ${(props) =>
      props.noRadius ? 0 : props.theme.borderRadius};
    border-bottom-right-radius: ${(props) =>
      props.noRadius ? 0 : props.theme.borderRadius};
    position: ${(props) => (props.stickyFooter ? "sticky" : "unset")};
    bottom: ${(props) => (props.stickyFooter ? 0 : "unset")};
  }
  .bottom-btn-wrap {
    padding-top: 0.6em;
  }
  ${(props) =>
    (props.to !== undefined || props.onClick !== undefined || !!props.isLink) &&
    css`
      box-shadow: none;
      border: 1px solid ${props.theme.borderColor};
      text-decoration: none;
      cursor: pointer;
      &:hover {
        border-color: ${props.theme.accentColor};
      }
    `}
  ${(props) =>
    !!props.active &&
    css`
      border: 1px solid ${props.theme.borderColor};
      border-color: ${props.theme.accentColor};
    `}
  ${(props) =>
    props.menu !== undefined &&
    css`
      background: ${props.theme.lightBg};
      .card-body {
        padding: 0;
        .menu-item {
          padding: 1em 1.2em;
          display: block;
          text-decoration: none;
          border-bottom: 1px solid ${props.theme.borderColor};
          color: ${props.theme.headingColor};
          &:hover {
            background: ${props.theme.mediumLightBg};
          }
        }
        .menu-btn-wrap {
          padding: 1em 1.2em;
        }
      }
    `}
  ${(props) =>
    props.smaller !== undefined &&
    css`
      .card-header h2 {
        font-size: 1.25em;
      }
    `}
  ${(props) =>
    props.overflowHidden !== undefined &&
    css`
      overflow: hidden;
    `}
  @media print {
    box-shadow: none;
  }
`;

const Card = ({
  title,
  icon,
  headerRight,
  extraHeader,
  children,
  className,
  enterEffect,
  footer,
  ...rest
}) => {
  return (
    <SCard
      {...rest}
      cardTitle={title}
      className={
        className +
        (!!enterEffect
          ? " " + (enterEffect !== "none" ? enterEffect : "")
          : " fadeInDown")
      }
    >
      {title && (
        <div className="card-header">
          <div className="header-left">
            {icon && <span className="icon">{icon}</span>}
            <h2 className="card-title">{title}</h2>
          </div>
          {!!headerRight && <div className="header-right">{headerRight}</div>}
        </div>
      )}
      {extraHeader ? <div className="extra-header">{extraHeader}</div> : null}
      <div className="card-body">{children}</div>
      {footer ? <div className="card-footer">{footer}</div> : null}
    </SCard>
  );
};

Card.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  headerRight: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  noPadding: PropTypes.bool,
  shadow: PropTypes.bool,
};

export default Card;
