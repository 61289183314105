import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, GridThemeProvider, Row } from "styled-bootstrap-grid";
import styled from "styled-components";
import config from "../../config";
import { useTranslation } from "react-i18next";
import Card from "../Card";
import ImageBox from "../ImageBox";
import Loader from "../Loader";
import Error from "../Error";
import { Navigate, useNavigate } from "react-router-dom";
import { Field, Label, MediaInput } from "@zendeskgarden/react-forms";
import { ReactComponent as StartIcon } from "@zendeskgarden/svg-icons/src/16/search-stroke.svg";
import { ReactComponent as EndIcon } from "@zendeskgarden/svg-icons/src/16/x-stroke.svg";
import useCustomers from "../../utils/useCustomers";
import Button from "../Button";
import PaginationFromHeader from "../PaginationFromHeader";
import icons from "../../icons";
import AuthButton from "../AuthButton";
import { FaUserLock, FaUserSecret, FaUserTimes } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
import { FiLogIn } from "react-icons/fi";
import { NOT_LOGGED_IN, SESSION_EXPIRED, UNAUTHORIZED } from "../../constants";
import LanguageSwitch from "../MyAccount/LanguageSwitch";
import Logo from "../Logo";
import theme from "../../styles/theme";
import { opacify } from "polished";
import { AppContext } from "../../context/AppContext";
import { HiBuildingStorefront } from "react-icons/hi2";

const SSelectCustomer = styled.div`
  .bg-overlay {
    position: fixed;
    inset: 0;
    z-index: -1;
    background: linear-gradient(
        168deg,
        rgba(255, 255, 255, 0.8) 0%,
        rgba(255, 255, 255, 0.4) 20%,
        transparent 20%,
        transparent 100%
      ),
      linear-gradient(
        168deg,
        transparent 0%,
        transparent 80%,
        rgba(0, 0, 0, 0.1) 80%,
        rgba(0, 0, 0, 0.1) 100%
      );
  }
  .container-wrap {
    min-height: calc(100vh - 1.5em);
    display: flex;
  }
  .logo-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 2em;
    @media (max-width: 767px) {
      display: none;
    }
  }
  .center-container {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    width: 100%;
    margin: auto;
    @media (min-width: 1200px) {
      padding-left: 80px;
      padding-right: 80px;
    }
    @media (max-width: 575px) {
      padding: 12px 0 8px 0;
    }
  }
  .cs-card {
    width: 100%;
    margin-bottom: 0;
    position: relative;
    z-index: 2;
    background: ${(props) => props.theme.lightBg};
    .card-body {
      padding-bottom: 0;
    }
    .card-inner {
      min-height: 727px;
      @media (max-width: 1199px) {
        min-height: 718px;
      }
      &.center {
        display: flex;
        width: 100%;
        align-items: center;
        > div {
          width: 100%;
        }
      }
    }
  }

  /* Header */
  .card-header {
    padding: 0.6em;
    position: sticky;
    top: -1px;
    z-index: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    .icon-title {
      padding-left: 0.6em;
    }
    .header-left {
      flex-shrink: 0;
    }
    .icon-title {
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
    }
    .header-right {
      display: flex;
    }
    .current-customer-btn {
      margin-left: 0.5em;
      flex-shrink: 0;
      min-width: 180px;
      border-radius: 4px;
    }
    .search-box {
      margin-left: 0.5em;
      width: 180px;
    }
    @media (max-width: 767px) {
      .search-box {
        margin: 0.6em 0 0 0;
        width: 100%;
      }
      .current-customer-btn {
        margin: 0.6em 0 0 0;
        width: 100%;
      }
    }
  }
  // language switch
  .btn-only {
    display: flex;
    align-items: center;
    margin-right: 0.1em;
    margin-left: 0.6em;
    .trigger-wrap {
      opacity: 0.7;
    }
  }

  /* Footer */
  .card-footer {
    padding: 0.6em 0.8em 0.6em 1.2em;
    border-top: 1px solid ${(props) => props.theme.borderColor};
  }
  .footer-left {
    .current-page-count {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  /* Customer */
  .customer-col {
    margin-bottom: 1.2em;
    cursor: pointer;
    .no-img-icon {
      padding: 15%;
    }
    .img-wrap {
      overflow: hidden;
      border: 1px solid transparent;
      transition: border-color 0.2s;
    }
    .img-box-img {
      padding: 0.7em;
    }
    .customer-btn {
      text-decoration: none;
      width: 100%;
      text-align: left;
      display: block;
      border-radius: 0;
      transition: background 0.2s;
      background: ${(props) => props.theme.mediumLightBg};
      &.selected {
        background: ${(props) => opacify(-0.5, props.theme.successColor)};
        &:hover,
        &:focus,
        &.hover {
          background: ${(props) => opacify(-0.5, props.theme.successColor)};
        }
      }
      &:hover,
      &:focus,
      &.hover {
        background: ${(props) => opacify(-0.7, props.theme.accentColor)};
      }
      &:focus {
        outline: 1px dashed ${(props) => opacify(-0.8, props.theme.accentColor)};
      }
    }
    .text-wrap {
      padding: 0.2em 0.6em;
      min-height: 4.2em;
      text-align: center;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .name {
        font-size: 13px;
        line-height: 1.1;
        margin-bottom: 0;
      }
      .code {
        font-family: monospace;
        font-size: 95%;
        color: ${(props) => props.theme.textColor};
        opacity: 0.8;
        padding-top: 0.2em;
      }
    }
  }

  /* Error */
  .center-icon {
    color: rgb(0 0 0 / 7%);
  }
`;

const SelectCustomer = () => {
  const { t, i18n } = useTranslation("common");
  const [filter, setFilter] = useState("");
  const { getCustomer } = useContext(AppContext);
  const [customersPage, setCustomersPage] = useState(1);

  // data
  const PAGE_SIZE = 30;
  const {
    data: customersWithHeaders,
    isLoading: customersIsLoading,
    isError: customersIsError,
  } = useCustomers({
    pageSize: PAGE_SIZE,
    page: customersPage,
    query: filter,
    orderBy: "name",
  });
  const customers = !!customersWithHeaders ? customersWithHeaders.data : [];
  const headers = !!customersWithHeaders ? customersWithHeaders.headers : null;

  const currentCustomerId = localStorage.getItem("customerId");
  const { data: currentCustomerWithHeaders } = useCustomers({
    pageSize: 1,
    query: currentCustomerId,
    shouldFetch: () => !!currentCustomerId || null,
  });
  const currentCustomer = currentCustomerWithHeaders?.data?.[0] || null;

  const navigate = useNavigate();
  const pathname = !!sessionStorage ? sessionStorage.getItem("pathname") : "/";

  const { user } = useAuth();

  const handleClick = (id) => {
    localStorage.setItem("customerId", id);
    localStorage.removeItem("session");

    localStorage.getItem("session");

    getCustomer();
    navigate(
      `${
        !!pathname && pathname !== "/select-customer" && pathname !== "/login"
          ? pathname
          : "/"
      }`
    );
  };

  // search
  const inputRef = React.createRef();
  const resetBtn = React.createRef();

  const clear = () => {
    setFilter("");
    setCustomersPage(1);
    resetBtn.current.click();
  };

  const handleInput = (e) => {
    if (!!e.target.value.length >= 0) {
      setCustomersPage(1);
      setFilter(e.target.value.toLowerCase());
    }
  };

  const handleKeyDown = (e) => {
    e.keyCode === 27 && clear(); // Esc
    e.key === "Enter" && e.preventDefault();
    e.target.focus();
  };

  const errorCase =
    customersIsError?.message === "Network Error"
      ? !user
        ? NOT_LOGGED_IN
        : SESSION_EXPIRED
      : customersIsError?.message === "Request failed with status code 403" ||
        customersIsError?.message === "Request failed with status code 401"
      ? UNAUTHORIZED
      : null;

  // Single customer available
  if (
    customers &&
    customers.length === 1 &&
    !filter.length &&
    customersPage === 1
  ) {
    localStorage.setItem("customerId", customers[0].id);
    return <Navigate to={`${!!pathname ? pathname : "/"}`} />;
  }

  return (
    <SSelectCustomer>
      <Helmet htmlAttributes={{ lang: i18n.language || config.defaultLang }}>
        <title>
          {t("ui.Select-location")} | {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <Container className="container-wrap">
        <div className="bg-overlay"></div>
        <div className="logo-container">
          <Logo withLink={false} fixed={false} />
        </div>

        <div className="center-container">
          <Card
            shadow
            extraHeader={
              <div className="card-header">
                <div className="icon-title">
                  <span className="icon">
                    <HiBuildingStorefront />
                  </span>
                  <h2>{t("ui.Select-location")}</h2>
                </div>

                <LanguageSwitch />

                <form className="search-box">
                  <Field>
                    <Label hidden>{t("ui.Search")}</Label>
                    <MediaInput
                      start={<StartIcon />}
                      end={
                        <span onClick={clear}>
                          <EndIcon
                            className="cursor-pointer"
                            visibility={!!filter ? "visible" : "hidden"}
                            style={{
                              transition: "opacity .1s",
                              opacity: !!filter ? 1 : 0,
                            }}
                          />
                        </span>
                      }
                      placeholder={t("ui.Search")}
                      onChange={handleInput}
                      onKeyDown={handleKeyDown}
                      defaultValue={""}
                      ref={inputRef}
                      autoFocus
                      isCompact
                    />
                  </Field>
                  <input type="reset" ref={resetBtn} hidden />
                </form>

                {!!currentCustomer && !customersIsError ? (
                  <Button
                    $success
                    small
                    onClick={() => handleClick(currentCustomer.id)}
                    className="current-customer-btn"
                    title={currentCustomer.name}
                  >
                    {t("ui.Continue-as-current")}
                  </Button>
                ) : null}
              </div>
            }
            icon={<HiBuildingStorefront />}
            className="cs-card"
            stickyFooter
            footer={
              <GridThemeProvider gridTheme={theme.gridThemeCondensed}>
                <Row>
                  <Col xs={6} sm hiddenMdDown className="footer-left">
                    {!!headers?.link ? (
                      <PaginationFromHeader
                        current={customersPage}
                        linkStr={headers.link}
                        setPage={setCustomersPage}
                        pageSize={PAGE_SIZE}
                        currentPageCount={true}
                      />
                    ) : null}
                  </Col>
                  <Col lg={3} md={4}>
                    {!!headers?.link ? (
                      <PaginationFromHeader
                        current={customersPage}
                        linkStr={headers.link}
                        setPage={setCustomersPage}
                        pageSize={PAGE_SIZE}
                      />
                    ) : (
                      <div style={{ height: "29.25px", width: "1px" }}></div>
                    )}
                  </Col>
                  <Col xs={6} sm className="text-right"></Col>
                </Row>
              </GridThemeProvider>
            }
          >
            <div
              className={`card-inner ${
                customersIsLoading ||
                customersIsError ||
                customers?.length === 0
                  ? "center"
                  : ""
              }`}
            >
              {customersIsLoading ? (
                <Loader />
              ) : !!customersIsError ? (
                <Error
                  icon={
                    errorCase === NOT_LOGGED_IN ? (
                      <FaUserSecret
                        className="center-icon fadeInGrow"
                        style={{ marginBottom: "28px" }}
                      />
                    ) : errorCase === SESSION_EXPIRED ? (
                      <FaUserLock
                        className="center-icon fadeInGrow"
                        style={{ marginBottom: "28px" }}
                      />
                    ) : errorCase === UNAUTHORIZED ? (
                      <FaUserTimes
                        className="center-icon fadeInGrow"
                        style={{ marginBottom: "28px" }}
                      />
                    ) : null
                  }
                  details={
                    errorCase === NOT_LOGGED_IN ||
                    errorCase === SESSION_EXPIRED ||
                    errorCase === UNAUTHORIZED
                      ? null
                      : customersIsError?.message
                  }
                  errorTitle={
                    errorCase === NOT_LOGGED_IN
                      ? t("error.Not-logged-in")
                      : errorCase === SESSION_EXPIRED
                      ? t("error.Session-expired")
                      : errorCase === UNAUTHORIZED
                      ? t("error.Unauthorized")
                      : null
                  }
                  errorMessage={
                    errorCase === NOT_LOGGED_IN ? (
                      t("error.Log-in-to-continue")
                    ) : errorCase === SESSION_EXPIRED ? (
                      t("error.Log-in-again")
                    ) : errorCase === UNAUTHORIZED ? (
                      <span>
                        {t("error.Not-authorized-to-access-this-content")}.
                        <br />
                        {t("error.Log-in-with-another-account")}
                      </span>
                    ) : null
                  }
                >
                  {errorCase === NOT_LOGGED_IN ||
                  errorCase === SESSION_EXPIRED ||
                  errorCase === UNAUTHORIZED ? (
                    <AuthButton
                      btnProps={{
                        stretch: true,
                        className: "no-margin margin-top margin-bottom-small",
                      }}
                      logoutText={
                        errorCase === UNAUTHORIZED ? null : (
                          <>
                            <span>{t("ui.Log-in")}</span>
                            <FiLogIn />
                          </>
                        )
                      }
                    />
                  ) : null}
                  <Button link onClick={() => navigate("/login")}>
                    {icons.chevronLeft}{" "}
                    <span>{t("ui.Back-to-start-page")}</span>
                  </Button>
                </Error>
              ) : customers && customers.length ? (
                <div className="row-wrap">
                  <Row>
                    {customers.map((c) => (
                      <Col
                        xs={6}
                        sm={4}
                        md={4}
                        lg={2}
                        key={c.id}
                        className="customer-col"
                      >
                        <Button
                          link
                          className={`customer-btn ${
                            +currentCustomerId === c.id ? "selected" : ""
                          }`}
                          onClick={() => handleClick(c.id)}
                        >
                          <div className="img-wrap">
                            <ImageBox
                              params={{
                                Width: 170,
                                Height: 100,
                                ResizeMode: "Contain",
                              }}
                              alt={`${c.name} logo`}
                              id={c.logoFileId}
                              boxHeight="52%"
                              asBgImage={false}
                              bgColor="white"
                              icon={HiBuildingStorefront}
                              noZoom
                            />
                          </div>
                          <div className="text-wrap">
                            <h4 className="name">{c.name}</h4>
                            <div className="code">{c.code}</div>
                          </div>
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : (
                <div
                  style={{
                    opacity: ".7",
                    textAlign: "center",
                  }}
                >
                  {t("ui.No-results-available")}
                </div>
              )}
            </div>
          </Card>
        </div>
      </Container>
    </SSelectCustomer>
  );
};

export default SelectCustomer;
