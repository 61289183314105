import useSWR from "swr";
import fetcher from "./fetcher";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const useContacts = (shouldFetch = () => true) => {
  const customerId = localStorage.getItem("customerId");
  const cacheKey = `${phoenix}/persons?customerId=${customerId}&pageSize=30`;
  const { data, error } = useSWR(
    () => (shouldFetch() ? cacheKey : null),
    fetcher,
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    cacheKey: cacheKey,
  };
};

export default useContacts;
