import styled from "styled-components";

export const AccordionWrapper = styled.div`
  .item-wrapper:not(:last-child) {
    margin-bottom: 0.5em;
  }
  .item-wrapper {
    @media (min-width: 520px) {
      padding-left: ${(props) => (props.isStepper ? "3.5em" : 0)};
    }
  }
`;

export default AccordionWrapper;
