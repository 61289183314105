import useSWR from "swr";
import fetcher from "./fetcher";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const useCustomer = (shouldFetch = (customerId) => !!customerId) => {
  const customerId = localStorage.getItem("customerId");
  const cacheKey = `${phoenix}/customers/${customerId}`;
  const { data, error } = useSWR(
    () => (shouldFetch(customerId) ? cacheKey : null),
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    cacheKey: cacheKey,
  };
};

export default useCustomer;
