import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { Row, Col, Container } from "styled-bootstrap-grid";
import styled, { createGlobalStyle } from "styled-components";
import config from "../../config";
import { useTranslation } from "react-i18next";
import AuthButton from "../AuthButton";
import Logo from "../Logo";
import img from "../../assets/img/wireframe.png";
import Footer from "../parts/Footer";
import LoginText from "../LoginText";
import Button from "../Button";
import { TooltipModal } from "@zendeskgarden/react-modals";
import {
  ESSILORNL,
  ESSILORBE,
  OOGWERELDNL,
  OOGWERELDBE,
  PORTALUK,
  TEMATICA,
  VOLVO,
  OMP,
  BIKENL,
  ACOUSTICSNL,
  RODENSTOCKDE,
  EYECARENL,
  EGSDE,
  TEST,
} from "../../constants";
import contentEssilorNL from "../../content/essilorNL/contact.json";
import contentEssilorBE from "../../content/essilorBE/contact.json";
import contentOogwereldNL from "../../content/oogwereldNL/contact.json";
import contentOogwereldBE from "../../content/oogwereldBE/contact.json";
import contentPortalUK from "../../content/portalUK/contact.json";
import contentTematica from "../../content/tematica/contact.json";
import contentVolvo from "../../content/volvo/contact.json";
import contentOMP from "../../content/OMP/contact.json";
import contentBikeNL from "../../content/bikeNL/contact.json";
import contentAcousticsNL from "../../content/acousticsNL/contact.json";
import contentEyecareNL from "../../content/eyecareNL/contact.json";
import contentEGSDE from "../../content/EGSDE/contact.json";
import WarningModal from "../WarningModal";
import LanguageSwitch from "../MyAccount/LanguageSwitch";
import RequestAccountButton from "../RequestAccountButton";

const GlobalStyle = createGlobalStyle`
  body {
    overflow-y: auto;
  }
`;

const SLogin = styled.div`
  min-height: 95vh;
  padding: 130px 0 6em 0;
  width: 100%;
  display: flex;
  align-items: center;
  .img-col,
  .text-col {
    display: flex;
    align-items: center;
  }
  .img-wrap {
    padding: 2em;
  }
  .login-heading {
    font-weight: 900;
    margin-bottom: 0.8em;
    line-height: 1.1;
    color: ${(props) => props.theme.textOnBg};
    .welcome-msg {
      display: block;
      font-size: 1.7em;
    }
    .name {
      font-size: 80%;
    }
  }

  .login-btn-wrap {
    .login-btn-inner {
      vertical-align: middle;
      font-size: 112%;
      margin-top: 0.7em;
      &:first-child {
        margin-right: 1.5em;
      }
    }
    .auth-btn-wrap button {
      min-width: 12em;
    }
  }
  .help-link-wrap {
    display: flex;
    padding-top: 0.5em;
    font-size: 115%;
    button {
      height: 40px;
      padding: 0;
      margin-right: 2em;
    }
  }
  .side-img {
    background: white;
    border-radius: 100%;
    padding: 1em;
    box-shadow: 0px 0px 35px #00000017;
  }

  .text-wrap {
    padding-left: 3em;
    color: ${(props) => props.theme.textOnBg};
    width: 100%;
  }
  .text-block {
    margin-bottom: 2em;
    width: 100%;
  }
  .help-text {
    margin-top: 2em;
    li {
      margin-bottom: 0.5em;
    }
    a.smaller {
      font-size: 100%;
    }
  }
  .bg-overlay {
    position: fixed;
    inset: 0;
    z-index: -1;
    background: linear-gradient(
        168deg,
        rgba(255, 255, 255, 0.8) 0%,
        rgba(255, 255, 255, 0.4) 20%,
        transparent 20%,
        transparent 100%
      ),
      linear-gradient(
        168deg,
        transparent 0%,
        transparent 80%,
        rgba(0, 0, 0, 0.1) 80%,
        rgba(0, 0, 0, 0.1) 100%
      );
  }
  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
  .tooltip {
    max-width: 95vw;
  }
  @media (max-width: 1200px) {
    .login-heading {
      font-size: 1.6em;
    }
    .login-btn-wrap {
      .login-btn-inner {
        font-size: 100%;
        display: inline-block;
        min-width: 16em;
        &:first-child {
          margin-right: 0;
        }
        button {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .img-wrap {
      display: none;
    }
    .login-heading {
      text-align: center;
    }
    .login-btn-wrap {
      text-align: center;
    }
    .text-wrap {
      padding: 0 2em;
      .text-block {
        p {
          text-align: center;
        }
      }
    }
    .help-link-wrap {
      display: block;
      padding-top: 1.3em;
      button,
      a {
        margin: -0.4em auto;
      }
    }
  }
`;

const Login = () => {
  const { t, i18n } = useTranslation("common");
  const buttonRef = useRef();
  const [referenceElement, setReferenceElement] = useState(null);

  const selectContent = (clientName) => {
    switch (clientName) {
      case TEST:
      case ESSILORNL:
        return contentEssilorNL;
      case ESSILORBE:
        return contentEssilorBE;
      case RODENSTOCKDE:
        return contentEssilorBE;
      case OOGWERELDNL:
        return contentOogwereldNL;
      case OOGWERELDBE:
        return contentOogwereldBE;
      case PORTALUK:
        return contentPortalUK;
      case TEMATICA:
        return contentTematica;
      case VOLVO:
        return contentVolvo;
      case OMP:
        return contentOMP;
      case BIKENL:
        return contentBikeNL;
      case ACOUSTICSNL:
        return contentAcousticsNL;
      case EYECARENL:
        return contentEyecareNL;
      case EGSDE:
        return contentEGSDE;
      default:
        return null;
    }
  };

  const content = selectContent(config.clientCode);
  const showRequestAccountButton = config.uiSettings.showRequestAccountButton;

  return (
    <SLogin>
      <GlobalStyle />
      <Helmet htmlAttributes={{ lang: i18n.language || config.defaultLang }}>
        <title>
          {t("ui.Log-in")} | {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <Container>
        <div className="bg-overlay"></div>
        <div className="logo-container">
          <Logo withLink={false} />
        </div>
        {config.uiSettings.languageSwitch ? (
          <div className="lang-container">
            <LanguageSwitch />
          </div>
        ) : null}
        <div className="login-inner">
          <Row>
            <Col md={5} className="img-col">
              <div className="img-wrap">
                <div className="side-img fadeInGrow">
                  <img src={img} alt="Welcome" />
                </div>
              </div>
            </Col>
            <Col md={7} className="text-col">
              <div className="text-wrap">
                <div className="text-block">
                  <h1 className="login-heading">
                    <span className="welcome-msg">{t("ui.Welcome-to")}</span>
                    <span className="name">{process.env.REACT_APP_NAME}</span>
                  </h1>
                  <LoginText />
                </div>

                <div className="login-btn-wrap">
                  <span className="login-btn-inner auth-btn-wrap">
                    <AuthButton />
                  </span>{" "}
                  {showRequestAccountButton && (
                    <RequestAccountButton
                      clientId={config.clientId}
                      clientCode={config.clientCode}
                    />
                  )}
                  <div className="help-link-wrap">
                    <span ref={buttonRef}>
                      <Button
                        link
                        onClick={() => {
                          setReferenceElement(buttonRef.current);
                        }}
                        className="link-on-bg"
                      >
                        <span>{t("ui.Need-help?")}</span>
                      </Button>
                    </span>
                    {config.uiSettings.showMigrationLink ? (
                      <WarningModal helpContent={content} />
                    ) : null}
                  </div>
                </div>

                <TooltipModal
                  referenceElement={referenceElement}
                  onClose={() => setReferenceElement(null)}
                  placement="bottom"
                  className="tooltip"
                  aria-label={t("ui.Forgot-your-login-details?" + 1)}
                >
                  <TooltipModal.Title>
                    {t("ui.Forgot-your-login-details?")}
                  </TooltipModal.Title>
                  <TooltipModal.Body>
                    {content?.helpdeskPhone ? (
                      <p>
                        {t("ui.Contact-helpdesk-text")}{" "}
                        <a href={`tel:${content.helpdeskPhone}`}>
                          {content.helpdeskPhone}
                        </a>
                      </p>
                    ) : null}
                    {content?.email ? (
                      <p>
                        {t("ui.Contact-helpdesk-email") || t("ui.Email-us-to")}{" "}
                        <a href={`mailto:${content.email}`}>{content.email}</a>
                      </p>
                    ) : null}
                    {content?.llPhone && !config?.clientCode === VOLVO ? (
                      <p>
                        {t("ui.Contact-Loyalty-Lab-text")}{" "}
                        <a href={`tel:${content.llPhone}`}>{content.llPhone}</a>
                      </p>
                    ) : null}
                  </TooltipModal.Body>
                  <TooltipModal.Close aria-label="Close" />
                </TooltipModal>
              </div>
            </Col>
          </Row>
        </div>
        <Footer />
      </Container>
    </SLogin>
  );
};

export default Login;
/*
 */
