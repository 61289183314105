import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import icons from "../icons";
import Button from "./Button";
import {
  Modal,
  Header,
  Body,
  Footer,
  FooterItem,
  Close,
} from "@zendeskgarden/react-modals";

const Dialog = ({ title, text, name, onConfirm, onCancel }) => {
  const [visible, setVisible] = useState(true);
  const { t } = useTranslation("common");
  const closeModal = () => {
    onCancel();
    setVisible(false);
  };
  const handleConfirm = () => {
    onConfirm();
    setVisible(false);
  };

  return (
    <React.Fragment>
      {visible && (
        <Modal
          onClose={closeModal}
          appendToNode={document.body}
          className="dialog-modal"
          aria-label="confirmation modal"
        >
          <Header>
            <h3>{title}</h3>
          </Header>
          <Body>
            {text} <strong>{name}</strong>?
          </Body>
          <Footer>
            <FooterItem>
              <Button type="button" onClick={closeModal} outline>
                {icons.close}
                <span>{t("my-account.No")}</span>
              </Button>
            </FooterItem>
            <FooterItem>
              <Button $success type="submit" onClick={handleConfirm}>
                {icons.check}
                <span>{t("my-account.Yes")}</span>
              </Button>
            </FooterItem>
          </Footer>
          <Close aria-label="Close modal" />
        </Modal>
      )}
    </React.Fragment>
  );
};

export default Dialog;
