import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Card from "../Card";
import { IoCloudUpload } from "react-icons/io5";
import styled from "styled-components";
import {
  Alert,
  Title,
  Close,
  useToast,
} from "@zendeskgarden/react-notifications";
import { useDropzone } from "react-dropzone";
import { Field, Input, FileUpload } from "@zendeskgarden/react-forms";
import callPrivateUpload from "../../utils/callPrivateUpload";
import Notification from "../Notification";
import { useSearchParams } from "react-router-dom";
import icons from "../../icons";
import Loader from "../Loader";
import {
  BsCloudUploadFill,
  BsFillFilePlayFill,
  BsFillFileEarmarkMusicFill,
  BsFillFileEarmarkPdfFill,
  BsFillFileEarmarkTextFill,
} from "react-icons/bs";
import { Container } from "styled-bootstrap-grid";
import { Helmet } from "react-helmet-async";
import config from "../../config";
import Logo from "../Logo";
import Footer from "../parts/Footer";
import LanguageSwitch from "../MyAccount/LanguageSwitch";

const UploadContainer = styled.div`
  min-height: 95vh;
  padding: 130px 0 6em 0;
  width: 100%;
  display: flex;
  align-items: center;
  .bg-overlay {
    position: fixed;
    inset: 0;
    z-index: -1;
    background: linear-gradient(
      135deg,
      #ffffff7d 0%,
      transparent 50%,
      #0000001f 100%
    );
  }
  .upload-inner {
    width: 754px;
    margin: auto;
    max-width: 100%;
    position: relative;
    z-index: 1;
  }
  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
`;

const SFileUploadField = styled(Field)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ul > li > div {
    display: flex;
    position: relative;
    flex-wrap: nowrap;
  }
  .filename {
    width: 100%;
  }
  .dropzone-wrap {
    min-height: 25vh;
    width: 100%;
    border: 2px dashed ${(props) => props.theme.borderColor};
    &:hover {
      border: 2px dashed ${(props) => props.theme.accent};
    }
    .dropzone-label {
      max-width: 15em;
      margin: auto;
      font-size: 120%;
    }
  }
  .upload-progress {
    position: absolute;
    bottom: 0px;
    left: 0px;
    transition: opacity 0.2s ease-in-out 0s;
    margin: 0px;
    width: 100%;
    > div {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    &.complete {
      opacity: 0;
    }
  }
  .preview-wrap {
    margin: -3px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 7px;
    .preview-block {
      width: 83px;
      height: 83px;
      margin: 4px;
      border: 1px solid ${(props) => props.theme.borderColor};
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .overlay-icon {
        position: absolute;
        inset: 0;
        img {
          width: 75%;
        }
        .overlay-icon-block {
          svg {
            width: 50%;
            height: 50%;
            position: absolute;
            inset: 0;
            margin: auto;
          }
          &.success {
            color: ${(props) => props.theme.successColor};
          }
          &.error {
            color: #bd5656;
          }
        }
      }
      img {
        max-height: 100%;
        width: auto;
        max-width: 100%;
      }
      .preview-icon {
        color: #ddd;
        width: 70%;
        height: 70%;
      }
    }
  }
`;

const UploadTool = () => {
  const { t, i18n } = useTranslation("common");

  let [searchParams] = useSearchParams();
  const { token } = {
    token: searchParams.get("token"),
  };

  const [files, setFiles] = useState([]);
  const [showRejected, setShowRejected] = useState(true);

  const { addToast } = useToast();

  const onDrop = (acceptedFiles) => {
    setShowRejected(true);
    if (acceptedFiles && acceptedFiles.length > 0) {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            uploadInProgress: true,
            uploadFinished: false,
            fileId: null,
          })
        )
      );

      acceptedFiles.forEach((file) =>
        callPrivateUpload({
          token: token,
          file: file,
          onSuccess: (data) => {
            setFiles((currentFiles) =>
              currentFiles.map((item) =>
                item.path === file.path
                  ? Object.assign(file, {
                      uploadInProgress: false,
                      uploadFinished: true,
                    })
                  : item
              )
            );
          },
          onError: (error) => {
            setFiles((currentFiles) =>
              currentFiles.map((item) =>
                item.path === file.path
                  ? Object.assign(file, {
                      uploadInProgress: false,
                    })
                  : item
              )
            );
            addToast(
              ({ close }) => (
                <Notification
                  title={t("error.Oops-Something-went-wrong")}
                  text={t("error.File-upload-failed")}
                  errorText={!!error ? error : file.path}
                  type="error"
                  handleClose={close}
                />
              ),
              { placement: "bottom" }
            );
          },
        })
      );
    }
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      multiple: true,
      onDrop,
      accept: {
        "image/*": [
          ".png",
          ".gif",
          ".jpeg",
          ".jpg",
          ".bmp",
          ".tiff",
          ".tif",
          ".psd",
          ".eps",
          ".indd",
          ".webp",
          ".svg",
          ".avif",
          ".ico",
        ],
        "application/vnd.amazon.ebook": [".azw"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
        "application/epub+zip": [".epub"],
        "application/vnd.ms-fontobject": [".eot"],
        "application/json": [".json"],
        "application/ld+json": [".jsonld"],
        "application/vnd.oasis.opendocument.presentation": [".odp"],
        "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
        "application/vnd.oasis.opendocument.text": [".odt"],
        "application/pdf": [".pdf", ".ai"],
        "application/vnd.ms-powerpoint": [".ppt"],
        "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          [".pptx"],
        "application/rtf": [".rtf"],
        "application/x-shockwave-flash": [".swf"],
        "application/vnd.visio": [".vsd"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
        "application/xml": [".xml"],
        "application/postscript": [".eps"],
        "text/*": [".xml", ".css", ".csv", ".txt"],
        "video/*": [".avi", ".mp4", ".mpeg", ".ogv", ".webm"],
        "audio/*": [
          ".aac",
          ".mp3",
          ".oga",
          ".opus",
          ".mid",
          ".midi",
          ".wav",
          ".weba",
        ],
        "font/otf": [".otf"],
        "font/ttf": [".ttf"],
        "font/woff": [".woff"],
        "font/woff2": [".woff2"],
      },
    });

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  });

  return (
    <UploadContainer>
      <Helmet htmlAttributes={{ lang: i18n.language || config.defaultLang }}>
        <title>
          {t("ui.File-upload")} | {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <Container>
        <div className="bg-overlay"></div>
        <div className="logo-container">
          <Logo withLink={false} />
        </div>
        {config.uiSettings.languageSwitch ? (
          <div className="lang-container">
            <LanguageSwitch />
          </div>
        ) : null}
        <div className="upload-inner">
          <Card title={t("ui.File-upload")} icon={<IoCloudUpload />} shadow>
            {!token ? (
              <Alert type="error" className="fadeInDown">
                <Title>{t("ui.URL-invalid-or-expired")}</Title>
                <p className="smaller">
                  {t("ui.URL-invalid-or-expired-description")}
                </p>
              </Alert>
            ) : (
              <SFileUploadField>
                <FileUpload
                  {...getRootProps()}
                  isDragging={isDragActive}
                  className="dropzone-wrap"
                >
                  <div>
                    <div style={{ fontSize: "5em", opacity: ".5" }}>
                      <BsCloudUploadFill />
                    </div>
                    {isDragActive ? (
                      <p className="dropzone-label">
                        {t("my-account.Drop-files-here")}
                      </p>
                    ) : (
                      <p className="dropzone-label">
                        {t(
                          "my-account.Click-to-choose-files-or-drag-and-drop-here"
                        )}
                      </p>
                    )}
                  </div>
                  <Input {...getInputProps()} id="fileUpload" />
                </FileUpload>
                {!!files.length ? (
                  <div className="preview-wrap">
                    {files.map((file) => (
                      <div
                        key={file.path}
                        className="preview-block"
                        title={file.path}
                      >
                        {file.type.indexOf("image/") > -1 ? (
                          <img
                            src={file.preview}
                            alt={`Uploaded ${file.path}`}
                            // Revoke data uri after image is loaded
                            onLoad={() => {
                              URL.revokeObjectURL(file.preview);
                            }}
                          />
                        ) : file.type.indexOf("video/") > -1 ? (
                          <BsFillFilePlayFill className="preview-icon" />
                        ) : file.type.indexOf("application/pdf") > -1 ? (
                          <BsFillFileEarmarkPdfFill className="preview-icon" />
                        ) : file.type.indexOf("audio/") > -1 ? (
                          <BsFillFileEarmarkMusicFill className="preview-icon" />
                        ) : (
                          <BsFillFileEarmarkTextFill className="preview-icon" />
                        )}
                        <div className="overlay-icon">
                          {file.uploadInProgress && !file.uploadFinished ? (
                            <Loader />
                          ) : file.uploadFinished ? (
                            <span className="overlay-icon-block success">
                              {icons.check}
                            </span>
                          ) : (
                            <span className="overlay-icon-block error">
                              {icons.close}
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </SFileUploadField>
            )}
            {!!files.length &&
            !!files.filter(
              (file) => !!file.uploadFinished && !file.uploadInProgress
            ).length ? (
              <Alert type="success" className="margin-top-small fadeInDown">
                <Close onClick={() => setFiles([])} />
                <Title>{t("ui.All-files-uploaded-successfully")}</Title>
                <p className="smaller">
                  {files.map((file, index) => (
                    <span key={file.path}>
                      {file.path}
                      {index !== files.length - 1 ? ", " : ""}
                    </span>
                  ))}
                </p>
              </Alert>
            ) : null}

            {/* Rejected files */}
            {!!fileRejections.length && !!showRejected ? (
              <Alert type="error" className="margin-top-small fadeInDown">
                <Close onClick={() => setShowRejected(false)} />
                <Title>
                  {t("ui.Files-rejected")} ({fileRejections.length})
                </Title>
                <p className="smaller">
                  {fileRejections.map((file, index) => (
                    <span key={file.file.path}>
                      {file.file.path}
                      {index !== fileRejections.length - 1 ? ", " : ""}
                    </span>
                  ))}
                </p>
              </Alert>
            ) : null}
          </Card>
        </div>
        <Footer />
      </Container>
    </UploadContainer>
  );
};

export default UploadTool;
