const domPurifyWithHooks = (domPurify) => {
  domPurify.addHook("afterSanitizeAttributes", (node) => {
    if (
      node.hasAttribute("href") &&
      node.getAttribute("href").charAt(0) !== "/" &&
      node.getAttribute("href").charAt(0) !== "#" &&
      node.getAttribute("href").charAt(0) !== ""
    ) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener");
    }
  });
};

export default domPurifyWithHooks;
