import useSWR from "swr";
import fetcherWithHeaders from "./fetcherWithHeaders";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const useVariantsSearch = ({
  page = 1,
  pageSize = 30,
  approvalFilter = null,
  programId = null,
  orderByDirection = "descending",
  functionallyActive = true,
  mailmomentId = null,
  searchPhrase = "",
  shouldFetch = () => true,
}) => {
  const customerId = localStorage.getItem("customerId");
  const cacheKey = `${phoenix}/variants?page=${page}&pageSize=${pageSize}${
    approvalFilter ? `&approvalFilter=${approvalFilter}` : ""
  }${programId ? `&programId=${programId}` : ""}${
    mailmomentId ? `&mailmomentId=${mailmomentId}` : ""
  }${
    functionallyActive ? `&functionallyActive=${functionallyActive}` : ""
  }&orderByDirection=${orderByDirection}&customerId=${customerId}${
    searchPhrase ? `&searchPhrase=${searchPhrase}` : ""
  }`;

  const { data, error, mutate } = useSWR(
    () => (shouldFetch() ? { url: cacheKey } : null),
    fetcherWithHeaders,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
    }
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    cacheKey: cacheKey,
    mutate,
  };
};

export default useVariantsSearch;
