import React from "react";
import { useTranslation } from "react-i18next";
import icons from "../../icons";
import Link from "../Link";
import {
  VOLVO,
} from "../../constants";
import config from "../../config";

const Breadcrumbs = ({ pageTitle, pathArray = [], noMargin = false }) => {
  const { t } = useTranslation("common");
  const titleDashboard = config.clientCode === VOLVO ? t("menu-Volvo.Dashboard") : t("menu.Dashboard");

  return (
    <div className={!!noMargin ? "" : "margin-bottom"}>
      <Link to="/">{titleDashboard}</Link>
      <span style={{ verticalAlign: "middle" }}>{icons.chevronRight}</span>
      {pathArray.map(({ title, url }) => (
        <span key={title + url}>
          {!!url ? <Link to={url}>{title}</Link> : <span>{title}</span>}
          <span style={{ verticalAlign: "middle" }}>{icons.chevronRight}</span>
        </span>
      ))}
      {pageTitle}
    </div>
  );
};

export default Breadcrumbs;
