import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import icons from "../../icons";
import createProof from "../../utils/createProof";
import useVariant from "../../utils/useVariant";
import useVariantParams from "../../utils/useVariantParams";
import Card from "../Card";
import Error from "../Error";
import Loader from "../Loader";
import Notification from "../Notification";
import Layout from "../parts/Layout";
import { useSWRConfig } from "swr";
import Button from "../Button";
import { useToast } from "@zendeskgarden/react-notifications";
import styled from "styled-components";
import callVariantParams from "../../utils/callVariantParams";
import { GiFoldedPaper } from "react-icons/gi";
import Breadcrumbs from "../parts/Breadcrumbs";
import { INHERIT, VALIDATIONPATTERNS } from "../../constants";
import Link from "../Link";
import EditParamsModal from "./EditParamsModal";
import SendEmailSignatureModal from "./SendEmailSignatureModal";
import { useAuth } from "../../context/AuthContext";
import { BsFillSendFill, BsPostcard } from "react-icons/bs";
import WizardButtons from "../OrderWizard/WizardButtons";
import EmailSignaturePreview from "./EmailSignaturePreview";

const SCard = styled(Card)`
  .btn-wrap {
    button:not(:first-child) {
      margin-left: 0.4em;
    }
    @media (max-width: 755px) {
      display: none;
    }
  }
  .footer-btns {
    padding-top: 0;
    margin-top: 0;
    border-top: none;
    display: flex;
    justify-content: space-between;
  }
  @media (min-width: 756px) {
    .footer-btns {
      justify-content: flex-end;
      .edit-btn {
        display: none;
      }
    }
  }
`;

const SVariant = styled.div`
  .form-box {
    background: ${(props) => props.theme.borderColor};
    padding: 1em;
    margin-bottom: 1em;
  }
  .popup-half-container {
    display: block;
  }
  .iframe-container {
    min-height: 60vh;
    border: 1px solid ${(props) => props.theme.borderColor};
    cursor: default;
  }
  .carousel .slide iframe {
    width: 100%;
    margin: 0;
    display: block;
  }
  .carousel-wrapper {
    background: white;
    border: 1px solid ${(props) => props.theme.borderColor};
  }
  .edm-wrap {
    background: #e4e4e4;
    text-align: center;
    padding: 1.2em;
    margin: 0 -1.2em -1.2em;
    @media (max-width: 575px) {
      padding: 0;
    }
    .edm-wrap-inner {
      overflow: hidden;
      background: white;
      margin: auto;
      width: 600px;
      max-width: 100%;
    }
  }
  .v-toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    flex-wrap: wrap;
    .text-wrap {
      display: flex;
      justify-content: space-between;
      .text-wrap-inner {
        margin-right: 2em;
      }
      strong {
        display: block;
      }
    }
  }

  .error-wrap {
    background: white;
    margin: 0 -1.2em -1.2em;
    &.not-edm {
      .error-details {
        text-align: center;
      }
    }
  }
  @media (max-width: 900px) {
    .btn-wrap {
      width: 100%;
      button {
        width: calc(50% - 0.2em);
      }
    }
  }
  @media (max-width: 550px) {
    .btn-wrap {
      button {
        width: 100%;
      }
      button:not(:first-child) {
        margin-left: 0 !important;
        margin-top: 0.6em;
      }
    }
    .hide-btn-labels {
      .btn-label {
        display: none;
      }
    }
  }
`;

const EmailSignature = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const { addToast } = useToast();
  const { mutate } = useSWRConfig();

  const { user } = useAuth();

  const [proofs, setProofs] = useState([]);
  const [proofType, setProofType] = useState(null);
  const [proofsError, setProofsError] = useState(null);

  const [showSendMail, setShowSendMail] = useState(false);
  const [email, setEmail] = useState(user?.email);

  const [customParams, setCustomParams] = useState([]);
  const [showParamsModal, setShowParamsModal] = useState(false);

  const {
    data: variant,
    isLoading: variantIsLoading,
    isError: variantIsError,
    cacheKey: variantCacheKey,
  } = useVariant(id);

  const {
    data: variantParams,
    isLoading: variantParamsIsLoading,
    isError: variantParamsIsError,
    cacheKey: variantParamsCacheKey,
  } = useVariantParams(id);

  useEffect(() => {
    createProof({
      id: id,
      onSuccess: (res) => {
        setProofs(res.results);
        setProofsError(null);
        setProofType(res.type);
      },
      onError: (error) => {
        setProofs([]);
        setProofsError(error);
        setProofType(null);
      },
    });
    //}
  }, [id /*, variantParams*/]);

  const updateVariant = () => mutate(variantCacheKey);

  const [paramsFormDisabled, setParamsFormDisabled] = useState(
    !!variantParams && variantParams.length > 0 ? false : true
  );

  // Update the state when variantParams changes
  useEffect(() => {
    setParamsFormDisabled(
      !!variantParams && variantParams.length > 0 ? false : true
    );
  }, [variantParams]);

  const onSubmit = (e) => {
    e.preventDefault();
    setParamsFormDisabled(true);
    const data = customParams.map((param) =>
      param.value === INHERIT ? { name: param.name, value: null } : param
    );

    callVariantParams({
      id: id,
      data: data,
      onSuccess: () => {
        setProofs([]);
        createProof({
          id: id,
          // params: data,
          params: {
            ReplyToEmail: "tamara.yadvichuk@loyaltylab.nl", // TEST
          },
          onSuccess: (res) => {
            setProofs(res.results);
            setProofsError(null);
            setProofType(res.type);
          },
          onError: (error) => {
            setProofs([]);
            setProofsError(error);
            setProofType(null);
          },
        });
        mutate(variantParamsCacheKey);
        updateVariant();
        setShowParamsModal(false);
      },
      onError: (error) => {
        setParamsFormDisabled(false);
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Something-went-wrong")}
              text={t("error.Failed-to-update-parameters")}
              errorText={
                !!error && !!error.length && !!error[0]
                  ? error[0]
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
    });
  };

  const cancelParamsEdit = () => {
    setShowParamsModal(false);
    !!variantParams &&
      !!variantParams.length &&
      setCustomParams(
        variantParams.map((param) => ({
          name: param.name,
          value: !!param.parameterValue ? param.parameterValue : INHERIT,
        }))
      );
  };

  // set custom params
  useEffect(() => {
    !!variantParams &&
      !!variantParams.length &&
      setCustomParams(
        variantParams.map((param) => ({
          name: param.name,
          value: !!param.parameterValue ? param.parameterValue : INHERIT,
        }))
      );
  }, [variantParams]);

  const handleOnClose = () => {
    setShowSendMail(false);
  };

  const sendEmailSignature = () => {
    if (email.match(VALIDATIONPATTERNS.emailPattern)) {
      setShowSendMail(false);
      createProof({
        id: id,
        email: email,
        onSuccess: (res) => {
          setProofsError(null);
          setProofType(res.type);
          addToast(
            ({ close }) => (
              <Notification
                title={t("ui.Done")}
                text={t("ui.Email-signature-sent-to ") + `${email}`}
                type="success"
                handleClose={close}
              />
            ),
            { placement: "bottom" }
          );
        },
        onError: (error) => {
          setProofs([]);
          setProofsError(error);
          setProofType(null);
        },
      });
    } else {
      addToast(
        ({ close }) => (
          <Notification
            title={t("error.Something-went-wrong")}
            text={t("error.Invalid-email")}
            type="error"
            handleClose={close}
          />
        ),
        { placement: "bottom" }
      );
    }
  };

  return (
    <Layout
      main={
        !!variantIsLoading ? (
          <Card shadow title={`${t("ui.Variant")} ${id}`}>
            <Loader
              children={
                <div>
                  <h3>{t("ui.One-moment-please")}</h3>
                  <p>{t("ui.A-proof-is-being-generated")}</p>
                </div>
              }
              style={{ maxHeight: "65vh", minHeight: "50vh" }}
            />
          </Card>
        ) : !!variantIsError ? (
          <Card shadow title={`${t("ui.Variant")} ${id}`}>
            <Error errorObj={variantIsError} />
          </Card>
        ) : !!variant ? (
          <SCard
            title={variant.name}
            shadow
            overflowHidden
            icon={variant.kind === "dm" ? <GiFoldedPaper /> : <BsPostcard />}
            footer={
              <WizardButtons className="footer-btns">
                {!variantParamsIsError ? (
                  <Button
                    className="edit-btn"
                    disabled={
                      paramsFormDisabled ||
                      variantParamsIsLoading ||
                      variantParamsIsError ||
                      (variantParams && !variantParams.length)
                    }
                    secondary
                    wide
                    onClick={() => setShowParamsModal(true)}
                  >
                    {icons.edit} <span>{t("my-account.Edit")}</span>
                  </Button>
                ) : null}
                <Button $primary wide onClick={() => setShowSendMail(true)}>
                  <BsFillSendFill />
                  <span>{t("ui.Send")}</span>
                </Button>
              </WizardButtons>
            }
            headerRight={
              <div className="btn-wrap">
                {!variantParamsIsError ? (
                  <Button
                    disabled={
                      paramsFormDisabled ||
                      variantParamsIsLoading ||
                      variantParamsIsError ||
                      (variantParams && !variantParams.length)
                    }
                    secondary
                    wide
                    onClick={() => setShowParamsModal(true)}
                  >
                    {icons.edit} <span>{t("my-account.Edit")}</span>
                  </Button>
                ) : null}
                {showParamsModal ? (
                  <EditParamsModal
                    variantParams={variantParams}
                    paramsFormDisabled={paramsFormDisabled}
                    setParamsFormDisabled={setParamsFormDisabled}
                    customParams={customParams}
                    setCustomParams={setCustomParams}
                    cancelParamsEdit={cancelParamsEdit}
                    onSubmit={onSubmit}
                  />
                ) : null}
              </div>
            }
          >
            <SVariant>
              <div className="v-toolbar">
                <Breadcrumbs
                  noMargin
                  pageTitle={variant.name}
                  pathArray={
                    variant.customerApproval.approved
                      ? []
                      : [
                          {
                            title: t("ui.Email-signature"),
                            url: "/tools/email-signature",
                          },
                        ]
                  }
                />
              </div>
              {!!proofs.length ? (
                proofType === "edm" ? (
                  !!proofs[0].urls &&
                  !!proofs[0].urls.length &&
                  !!proofs[0].documentId ? (
                    <div className="edm-wrap">
                      <EmailSignaturePreview
                        url={proofs[0].urls[0]}
                        docId={proofs[0].documentId}
                      />
                    </div>
                  ) : null
                ) : (
                  <div className="error-wrap border-top not-edm">
                    <Error
                      errorMessage={t("error.Incorrect-proof-type")}
                      details={`proofType: ${proofType}`}
                      children={
                        <p style={{ paddingTop: ".2em" }}>
                          <Button
                            as={Link}
                            $link
                            to="/tools/email-signature"
                            className="icon-link"
                          >
                            {icons.chevronLeft} {t("ui.Go-back")}
                          </Button>
                        </p>
                      }
                      errorObj={proofsError}
                    />
                  </div>
                )
              ) : proofsError ? (
                <div className="error-wrap border-top">
                  <Error
                    errorMessage={t("error.Failed-to-create-proof")}
                    details={proofsError}
                    children={
                      <p style={{ paddingTop: ".2em" }}>
                        <Button
                          as={Link}
                          $link
                          to="/tools/email-signature"
                          className="icon-link"
                        >
                          {icons.chevronLeft} {t("ui.Go-back")}
                        </Button>
                      </p>
                    }
                    errorObj={proofsError}
                  />
                </div>
              ) : (
                <div className="error-wrap">
                  <Loader
                    children={
                      <div>
                        <h3>{t("ui.One-moment-please")}</h3>
                        <p>{t("ui.A-proof-is-being-generated")}</p>
                      </div>
                    }
                    style={{ maxHeight: "65vh", minHeight: "50vh" }}
                  />
                </div>
              )}
            </SVariant>
            {showParamsModal ? (
              <EditParamsModal
                variantParams={variantParams}
                paramsFormDisabled={paramsFormDisabled}
                setParamsFormDisabled={setParamsFormDisabled}
                customParams={customParams}
                setCustomParams={setCustomParams}
                cancelParamsEdit={cancelParamsEdit}
                onSubmit={onSubmit}
              />
            ) : null}
            {showSendMail ? (
              <SendEmailSignatureModal
                setEmail={setEmail}
                email={email}
                setShowSendMail={setShowSendMail}
                onClose={handleOnClose}
                onSubmit={sendEmailSignature}
              />
            ) : null}
          </SCard>
        ) : null
      }
      pageTitle={!!variant ? variant.name : t("ui.Variant") + " " + id}
    />
  );
};

export default EmailSignature;
