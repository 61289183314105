import fetcher from "./fetcher";
import useSWRInfinite from "swr/infinite";
import encodeParams from "./encodeParams";

const PAGE_SIZE = 30;

const useInfinite = ({
  key,
  pageSize = PAGE_SIZE,
  shouldFetch = () => true,
  params = {},

  noCache = false,
}) => {
  const { data, error, isValidating, size, setSize, mutate } = useSWRInfinite(
    (pageIndex) =>
      shouldFetch()
        ? `${key}${encodeParams({
            pageSize: pageSize,
            page: pageIndex + 1,
            ...params,
          })}`
        : null,
    fetcher,
    {
      revalidateIfStale: noCache,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  return {
    data: data,
    isLoading: isValidating,
    isError: error,
    size: size,
    setSize: setSize,
    mutate,
  };
};

export default useInfinite;
