import config from "../config";

const isUnderMaintenance = () => {
  const now = Date.parse(Date());

  const start = config?.uiSettings?.maintenanceMessage?.start;
  const mainTenanceStart = start && Date.parse(start);

  const end = config?.uiSettings?.maintenanceMessage?.end;
  const mainTenanceEnd = end && Date.parse(end);

  const isUnderMaintenanceBool = // true if
    !!config?.uiSettings?.isUnderMaintenance || // isUnderMaintenance is true OR
    (!!mainTenanceEnd && // end date is set
      mainTenanceEnd > now && // AND is in the future, AND EITHER
      ((!!mainTenanceStart && mainTenanceStart <= now) || // start date is set and is in the past
        !mainTenanceStart)); // OR start date is not set

  return isUnderMaintenanceBool;
};

export default isUnderMaintenance;
