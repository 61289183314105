import {
  Body as TableBody,
  Cell,
  Row as TableRow,
  Table,
} from "@zendeskgarden/react-tables";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useCustomer from "../../utils/useCustomer";
import Badge from "../Badge";
import Card from "../Card";
import Error from "../Error";
import ImageBox from "../ImageBox";
import Loader from "../Loader";
import { FaIdCard } from "react-icons/fa";
import styled from "styled-components";
import {
  Modal,
  Header,
  Body,
  Footer,
  Close,
} from "@zendeskgarden/react-modals";
import { useToast } from "@zendeskgarden/react-notifications";
import callImages from "../../utils/callImages";
import Notification from "../Notification";
import uploadFile from "../../utils/uploadFile";
import { useDropzone } from "react-dropzone";
import icons from "../../icons";
import { Field, FileUpload, Input } from "@zendeskgarden/react-forms";
import { BsArrow90DegUp } from "react-icons/bs";
import { AiOutlineFullscreen, AiOutlineUpload } from "react-icons/ai";
import Button from "../Button";
import apiConfig from "../../apiConfig";
import { Col, Row } from "styled-bootstrap-grid";
import { IoBriefcaseSharp } from "react-icons/io5";
import config from "../../config";
import { VOLVO } from "../../constants";

const { images: imageApi } = apiConfig;

const SLogoWrap = styled.div`
  background: white;
  padding: 4.4em 2em 4em;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 2px dashed #ccc; // Grey dashed border
    box-sizing: border-box; // Include the border in the element's dimensions
    transition: border 0.3s ease; // Smooth transition for border property
  }

  .image-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6c757d; // Placeholder text color
    font-size: 1rem; // Adjust as needed
    text-align: center;
  }

  // Change the border to solid blue on hover
  &:hover::after {
    border: 2px dashed ${(props) => props.theme.accentColor}; // Solid blue border on hover
  }

  .img-box-img {
    width: auto;
    margin: auto;
    max-width: 80%;
  }
`;

const SModal = styled(Modal)`
  max-width: 97%;
  max-height: 97%;
  margin: auto;
  left: 0;
  right: 0;
  .modal-header {
    padding-left: 20px;
  }
  .modal-body {
    padding-left: 21px;
    padding-right: 21px;
  }
  .logo-modal-footer {
    justify-content: space-between;
    .info-label {
      margin: -0.5em 0;
      padding-left: 2px;
      width: 100%;
      .label-smaller {
        font-size: 12px;
        line-height: 1.35em;
      }
      .icon {
        float: left;
        display: block;
        height: 50px;
        width: 45px;
        padding-right: 8px;
        svg {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      h4 {
        font-weight: 600;
        margin-bottom: 0;
        line-height: 1.35;
      }
    }
    .btn-wrap {
      width: 100%;
      text-align: right;
      white-space: nowrap;
      a,
      button {
        margin-left: 6px;
      }
    }
    @media (max-width: 676px) {
      flex-wrap: wrap;
      .info-label {
        margin-bottom: 0.5em;
      }
    }
  }
  .delete-btn:not(:disabled) {
    &:hover {
      background: #9d5454;
    }
  }
`;

const SFileUploadField = styled(Field)`
  label {
    display: block;
    margin-bottom: 1.2em;
  }
  .upload-box {
    border-width: 3px;
    border-color: #ddd;
    position: absolute;
    z-index: 1;
    inset: -2px;
    justify-content: end;
    align-items: start;
    &:hover {
      background: transparent;
    }
    .upload-box-hint {
      position: absolute;
      top: 100%;
      display: block;
      right: 0;
      font-size: 12px;
      opacity: 0.5;
      transition: opacity 0.2s;
    }
    &:hover {
      border-color: ${(props) => props.theme.accentColor};
      .upload-box-hint {
        opacity: 1;
      }
    }
    .arrow {
      font-size: 1.3em;
      line-height: 1;
      margin-right: 0.4em;
      color: ${(props) => props.theme.successColor};
    }
  }
  .btn-toolbar {
    position: absolute;
    font-size: 14px;
    right: 0.6em;
    top: 0.6em;
    z-index: 1;
    .btn {
      margin-left: 6px;
      height: 39px;
      width: 39px;
      border-radius: 4px;
      vertical-align: top;
    }
    .delete-btn {
      &:hover {
        background: #9d5454;
      }
    }
  }
`;

const STable = styled(Table)`
  td {
    width: max-content;
  }
  td:first-child {
    text-align: right;
    font-weight: 600;
    color: ${(props) => props.theme.headingColor};
  }
  td:last-child:not(.line-break-normal) {
    line-break: anywhere;
  }
  @media (max-width: 676px) {
    td {
      display: block;
      width: 100%;
    }
    td:first-child {
      text-align: left;
      padding-bottom: 0;
    }
    td:last-child {
      padding-top: 0;
    }
  }
`;

const CustomerInfo = ({ images, user, logo, refreshMp, mpId, mp }) => {
  const { t } = useTranslation("common");
  const { data: customer, isLoading, isError } = useCustomer();
  const [dialogId, setDialogId] = useState("");
  const [isBusy, setIsBusy] = useState(false);

  const { addToast } = useToast();

  const openDialog = (id) => {
    setDialogId(id);
  };

  const cancelDialog = () => {
    setDialogId("");
  };

  const handleSubmit = ({ key, fileName, fileId }) => {
    callImages({
      mpId: mpId,
      data: {
        ...images,
        [key]: {
          target: key,
          fileName: !!fileName ? fileName : "noFileName",
          fileId: fileId,
          description: "",
        },
      },
      onSuccess: () => {
        setIsBusy(false);
        refreshMp();
        addToast(
          ({ close }) => (
            <Notification
              title={`${t(`my-account.File-upload-successful`)}`}
              text={`${t(`my-account.Logos`)}: ${t()} ${t("ui.saved")}`}
              type="success"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
      onError: (error) => {
        setIsBusy(false);
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Oops-Something-went-wrong")}
              text={t("error.Saving-image-failed")}
              errorText={
                !!error ? error : t("error.Sorry-an-unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
    });
  };

  const onDrop = (acceptedFiles) => {
    setIsBusy(true);

    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      uploadFile({
        format: file.name.split(".").pop(),
        fileName: file.name,
        fileCategory: "CustomerUpload",
        file: file,
        onSuccess: (data) => {
          handleSubmit({
            key: dialogId,
            fileName: file.name,
            fileId: data.fileId,
          });
        },
        onError: (error) => {
          setIsBusy(false);
          addToast(
            ({ close }) => (
              <Notification
                title={t("error.Oops-Something-went-wrong")}
                text={t("error.File-upload-failed")}
                errorText={
                  !!error
                    ? error
                    : t("error.Sorry-an-unexpected-error-occurred")
                }
                type="error"
                handleClose={close}
              />
            ),
            { placement: "bottom" }
          );
        },
      });
    }
  };

  const acceptedFormats = {
    "image/*": [".png", ".jpg", ".jpeg", ".psd", ".eps"],
    "application/pdf": [".pdf", ".ai"],
  };

  const extensionsArr = Object.values(acceptedFormats).flat();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptedFormats,
    multiple: false,
    onDrop,
  });

  const handleDelete = (key) => {
    setIsBusy(true);
    callImages({
      mpId: mpId,
      data: { ...images, [key]: {} },
      onSuccess: () => {
        setIsBusy(false);
        refreshMp();
      },
      onError: (error) => {
        setIsBusy(false);
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Oops-Something-went-wrong")}
              text={t("error.Deleting-image-failed")}
              errorText={
                !!error ? error : t("error.Sorry-an-unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
    });
  };

  if (isLoading)
    return (
      <Card title={t("my-account.User-info")} shadow icon={<FaIdCard />}>
        <Loader />
      </Card>
    );
  if (isError)
    return (
      <Card title={t("my-account.User-info")} shadow icon={<FaIdCard />}>
        <Error errorObj={isError} />
      </Card>
    );

  return (
    <Card
      title={t("my-account.User-info")}
      shadow
      icon={<FaIdCard />}
      headerRight={<Badge>{customer.code}</Badge>}
      extraHeader={
        <SLogoWrap onClick={() => openDialog("logo")}>
          {logo?.fileId ? (
            <ImageBox
              params={{
                Width: 300,
                ResizeMode: "Contain",
              }}
              id={logo?.fileId}
              boxHeight={"100%"}
              className="hover-zoom"
              noZoom
              asBgImage={false}
              center={false}
              bgColor="#ffffff"
            />
          ) : (
            //weird to way to manage the placeholder image size
            <Row>
              <div style={{ width: "33%" }}></div>
              <Col sm={4}>
                <ImageBox
                  params={{
                    Width: 300,
                    ResizeMode: "Contain",
                  }}
                  id={logo?.fileId}
                  boxHeight={"100%"}
                  className="hover-zoom"
                  noZoom
                  asBgImage={false}
                  bgColor="#ffffff"
                />
              </Col>
              <div style={{ width: "33%" }}></div>
            </Row>
          )}
        </SLogoWrap>
      }
    >
      <STable isReadOnly>
        <TableBody style={{ userSelect: "text" }}>
          <TableRow>
            <Cell>
              <strong>{t("my-account.Logged-in-user")}</strong>
            </Cell>
            <Cell className="line-break-normal">{user.name}</Cell>
          </TableRow>
          <TableRow>
            <Cell>
              <strong>{t("my-account.User-email")}</strong>
            </Cell>
            <Cell>{user.email}</Cell>
          </TableRow>

          <TableRow>
            <Cell>
              <strong>{t("my-account.Customer-name")}</strong>
            </Cell>
            <Cell className="line-break-normal">{customer.name}</Cell>
          </TableRow>
          <TableRow>
            <Cell>
              <strong>{t("my-account.Customer-code")}</strong>
            </Cell>
            <Cell>{customer.code}</Cell>
          </TableRow>
          {mp?.properties?.senderEmail ? (
            <TableRow>
              <Cell>
                <strong>{t("my-account.Sender-email")}</strong>
              </Cell>
              <Cell>{mp?.properties?.senderEmail}</Cell>
            </TableRow>
          ) : null}
          {mp?.properties?.clientSurveyUrl ? (
            <TableRow>
              <Cell>
                <strong>
                  {t(
                    `my-account.Client-survey-URL${
                      config?.clientCode === VOLVO ? "-Volvo" : ""
                    }`
                  )}
                </strong>
              </Cell>
              <Cell>{mp?.properties?.clientSurveyUrl}</Cell>
            </TableRow>
          ) : null}
          {mp?.properties?.customerUrl ? (
            <TableRow>
              <Cell>
                <strong>{t("ui.Website")}</strong>
              </Cell>
              <Cell>{mp?.properties?.customerUrl}</Cell>
            </TableRow>
          ) : null}
        </TableBody>
      </STable>
      {dialogId === "logo" && (
        <SModal
          isLarge
          onClose={cancelDialog}
          appendToNode={document.body}
          aria-label="Upload logo"
        >
          <Header className="modal-header">
            <h3>
              <span className="list-icon">
                <IoBriefcaseSharp />
              </span>
              {t("my-account.Company-logo")}
            </h3>
          </Header>
          <Body className="modal-body">
            <SFileUploadField>
              <div
                style={{
                  position: "relative",
                  marginBottom: "1em",
                  marginTop: ".6em",
                }}
              >
                <FileUpload
                  {...getRootProps()}
                  isDragging={isDragActive}
                  className="upload-box"
                >
                  <span className="upload-box-hint">
                    <BsArrow90DegUp className="arrow" />
                    {isDragActive
                      ? t("my-account.Drop-files-here")
                      : t(
                          "my-account.Click-to-choose-a-file-or-drag-and-drop-here"
                        )}
                  </span>
                  <Input {...getInputProps()} id="fileUpload" />
                </FileUpload>

                <ImageBox
                  id={logo?.fileId || null}
                  alt="Preview"
                  boxHeight="70%"
                  params={{
                    Width: 700,
                    Height: 700,
                    ResizeMode: "Contain",
                  }}
                  asBgImage={false}
                  icon={AiOutlineUpload}
                  withLoader={isBusy}
                />

                {!!logo?.fileId ? (
                  <div className="btn-toolbar">
                    <Button
                      as={"a"}
                      target="_blank"
                      href={`${imageApi}/${logo?.fileId}`}
                      secondary
                      square
                      tabIndex={-1}
                      title={t("ui.View-fullscreen")}
                      className="btn"
                    >
                      <AiOutlineFullscreen />
                    </Button>
                  </div>
                ) : null}
              </div>
            </SFileUploadField>
          </Body>
          <Footer className="modal-footer logo-modal-footer">
            <div className="info-label">
              <span className="icon icon-color">{icons.info}</span>
              <h4>{t("my-account.Upload-a-new-image")}</h4>
              <div className="label-smaller">
                <span className="list-label">
                  {t("my-account.Accepted-formats")}{" "}
                </span>
                {extensionsArr
                  .filter((ext) => ext !== ".jpeg")
                  .map((item, index) => (
                    <span key={item} className="font-monospace">
                      {item}{" "}
                    </span>
                  ))}
                <br />
                <span className="list-label">
                  {t("my-account.Minimum-resolution")}{" "}
                </span>
                <span key={logo} className="font-monospace">
                  300+ dpi
                </span>
              </div>
            </div>

            <div className="btn-wrap">
              <Button
                secondary
                type="button"
                onClick={() => handleDelete("logo")}
                className="delete-btn"
                disabled={!logo?.fileId || isBusy}
              >
                {icons.trash}
                <span>{t("my-account.Delete")} </span>
              </Button>
            </div>
          </Footer>
          <Close aria-label={t("my-account.Close")} />
        </SModal>
      )}
    </Card>
  );
};

export default CustomerInfo;
