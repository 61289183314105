import React from "react";
import PropTypes from "prop-types";
import Link from "./Link";
import Button from "./Button";
import Badge from "./Badge";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import icons from "../icons";
import ImageBox from "./ImageBox";
import { opacify } from "polished";
import { IoCartSharp } from "react-icons/io5";

const SOrderList = styled.div`
  .order-link {
    display: block;
    background: white;
    margin-bottom: 0.6em;
    border-radius: 3px;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.05) 1px 2px 5px;
    cursor: pointer;
    transition: all 0.4s ease 0s;
    overflow: hidden;
    color: inherit;
    transition: all 0.4s;
    h4 {
      color: ${(props) => props.theme.headingColor};
      transition: all 0.4s;
      font-weight: 500;
      margin-bottom: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      .header-label-lg {
        display: inline-block;
        @media (max-width: 975px) and (min-width: 768px) {
          display: none;
        }
      }
      .header-label-sm {
        display: none;
        @media (max-width: 975px) and (min-width: 768px) {
          display: inline-block;
        }
      }
    }
    p {
      font-size: 0.95em;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &:hover {
      background: ${(props) => opacify(-0.3, props.theme.accentColor)};
      color: white;
      h4 {
        color: white;
      }
      .btn-link {
        color: white;
      }
    }
    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }
  .img-wrap {
    width: 55px;
    float: left;
  }
  .no-img-icon {
    background: ${(props) => props.theme.iconColor};
    color: rgba(255, 255, 255, 0.8);
  }
  .text-wrap {
    padding: 0.6em;
    padding-left: calc(60px + 0.6em);
  }
  .icon-list-link {
    color: inherit;
    &:hover {
      color: white;
    }
    display: block;
  }
  .order-badge {
    float: right;
    margin-left: 1em;
    white-space: nowrap;
  }
  .order-list-head {
    margin-bottom: 0;
    font-weight: 500;
  }
  .order-page-item {
    padding: 1em 1.2em;
    display: block;
    text-decoration: none;
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
    color: ${(props) => props.theme.headingColor};
    background: white;
    .heading-wrap {
      padding-bottom: 0.5em;
      margin-bottom: 1em;
      border-bottom: 1px solid ${(props) => props.theme.borderColor};
    }
    .product-amount {
      min-width: 3em;
      display: inline-block;
      text-align: right;
    }
    .product-amount-x {
      min-width: 2em;
      display: inline-block;
      text-align: center;
    }
  }
`;

const OrderList = ({ orders = [] }) => {
  const { t } = useTranslation("common");
  if (orders.length === 0) {
    return <div>{t("order.No-orders")}</div>;
  }
  return (
    <SOrderList>
      {orders &&
        orders.length &&
        orders.map((item) => (
          <div key={item.id}>
            <Link to={`/order/${item.id}`} key={item.id} className="order-link">
              <div className="img-wrap">
                <ImageBox
                  params={{
                    Width: 55,
                    Height: 55,
                  }}
                  id={item?.program?.thumbnail}
                  boxHeight="100%"
                  icon={IoCartSharp}
                  bgColor="transparent"
                />
              </div>
              <div className="text-wrap">
                <Badge state={item.state} className="order-badge">
                  {t(`order.${item.state}`)}
                </Badge>
                <h4 className="order-list-head">
                  <span className="header-label-lg">{t("order.Order")}</span>
                  <span className="header-label-sm">#</span> {item.id}{" "}
                </h4>
                <p>
                  {!!item.programName ? (
                    item.programName
                  ) : (
                    <span>
                      {t("ui.Campaign")} {item.programId}
                    </span>
                  )}
                </p>
              </div>
            </Link>
          </div>
        ))}

      <div className="bottom-btn-wrap">
        <Link to="/orders">
          <Button link>
            {t("order.All-orders")}
            {icons.chevronRight}
          </Button>
        </Link>
      </div>
    </SOrderList>
  );
};

OrderList.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default OrderList;
