import React, { useContext } from "react";
import { Field, Label, MediaInput } from "@zendeskgarden/react-forms";
import { ReactComponent as StartIcon } from "@zendeskgarden/svg-icons/src/16/search-stroke.svg";
import { ReactComponent as EndIcon } from "@zendeskgarden/svg-icons/src/16/x-stroke.svg";
import { SearchContext } from "./SearchProvider";
import { useTranslation } from "react-i18next";

const SearchBox = ({ label, filter, setFilter }) => {
  const searchContext = useContext(SearchContext);
  const { t } = useTranslation("common");
  const resetBtn = React.createRef();

  const filterValue = searchContext[filter];
  const setFilterFunc = searchContext[setFilter];

  const clear = () => {
    setFilterFunc("");
    resetBtn.current.click();
  };

  const handleInput = (e) => {
    setFilterFunc(
      e.target.value.length >= 3 ? e.target.value.toLowerCase() : ""
    );
  };

  const handleKeyDown = (e) => e.keyCode === 27 && clear();

  return (
    <form>
      <Field>
        <Label hidden>{!!label ? label : t("ui.Search")}</Label>
        <MediaInput
          start={<StartIcon />}
          end={
            !!filterValue.length && (
              <span onClick={clear}>
                <EndIcon className="cursor-pointer" />
              </span>
            )
          }
          placeholder={!!label ? label : t("ui.Search")}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          defaultValue={filterValue}
          autoFocus
        />
      </Field>
      <input type="reset" ref={resetBtn} hidden />
    </form>
  );
};

export default SearchBox;
