import useSWR from "swr";
import fetcher from "./fetcher";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const useContactTypes = () => {
  const cacheKey = `${phoenix}/persons/ContactDetailTypes`;
  const { data, error } = useSWR(cacheKey, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useContactTypes;
