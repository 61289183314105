import apiConfig from "../apiConfig";
import { BsCalendar2Check, BsPostcard, BsEnvelopeAt } from "react-icons/bs";
import { PiListMagnifyingGlass } from "react-icons/pi";
import vddnURLParams from "../utils/vddnURLParams";

const toolsItems = {
  children: [
    {
      title: "Email-signature",
      url: "/tools/email-signature",
      icon: <BsPostcard />,
    },
    {
      title: "VDDN-Viewer",
      url: `${apiConfig.vddnTools}/vddnviewer/login_engineV2.aspx`,
      icon: <PiListMagnifyingGlass />,
      urlParams: vddnURLParams,
    },
    {
      title: "VDDN-Mail",
      url: `${apiConfig.vddnTools}/vddnmail/`,
      icon: <BsEnvelopeAt />,
      urlParams: vddnURLParams,
    },
    {
      title: "Afleverdatum-Bekend",
      url: `${apiConfig.vddnTools}/afleverdatumbekend/`,
      icon: <BsCalendar2Check />,
      urlParams: vddnURLParams,
    },
  ],
};

export default toolsItems;
