import axios from "axios";
import config from "../config";
import apiConfig from "../apiConfig";

const { reporting } = apiConfig;

const callAction = ({
  reportId,
  actionId,
  rowData,
  onSuccess = () => console.log("action applied"),
  onError = (error) => console.log(error),
}) => {
  const customerId = localStorage.getItem("customerId");
  const url = `${reporting}/views/${reportId}/actions/${actionId}`;
  axios({
    method: "POST",
    url: url,
    data: rowData,
    withCredentials: true,
    headers: {
      pxclient: config.clientId,
      pxcustomer: customerId,
    },
  })
    .then((res) => {
      if (res.status === 200) {
        !!onSuccess && onSuccess(res?.data);
      } else {
        !!onError
          ? onError(res?.statusText)
          : console.log("Request failed", res);
      }
    })
    .catch((error) => {
      if (!!error.response) {
        onError(error.response?.data?.title || error.response || error);
      } else if (!!error.request) {
        onError(error.request);
      } else {
        onError(error?.message || error);
      }
    });
};

export default callAction;
