import useSWR from "swr";
import fetcher from "./fetcher";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const useAllBrands = () => {
  const cacheKey = `${phoenix}/clients/brandlogos`;
  const { data, error } = useSWR(cacheKey, fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    cacheKey: cacheKey,
  };
};

export default useAllBrands;
