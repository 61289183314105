import React from "react";

const HyvesIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 425 512"
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    style={{ transform: "scale(2)" }}
  >
    <path d="M279.5 144H296q2 0 4.75 2.5t4.75 6.25 3 7.5 0 6.25q-.5 2-2.75 3.25t-5 2.5-5.25 2.25-3.5 2.5q-2 2-2.75 6.25t-.75 9.25.25 10.5.25 10v97q0 4-.25 10.5T290 328q1 1.5 4.75 1.25t5.75.25q4.5 1.5 8.25 8.25T309 350q-1 1.5-4.5 2.75t-7 2.25q-7 3.5-18.25 6.75T257.5 364q-3-.5-6.25-2.5t-6.25-5-5-6.25-1.5-6.25q.5-4 8-7.5 2-1 5.75-1T258 334q2.5-2.5 1.75-9.75t-1.25-12.75v-12q0-3 .25-6.75T258 288t-4.25-1.25-7 0-7.5.75-5.25.5q-13.5 2-22.5 3.25T191 296.5q-3.5 1-9.5 2.5t-8.5 5q-1 1.5-1.25 5t0 7.75.75 8.25.5 6.5q.5 2.5 0 6.25t.5 5.75q1.5 2.5 6.25 2t5.75 0q4 1.5 8 6t3 10q-.5 4.5-5.75 7.75t-12.5 5.25-15 3T150 379q-4 .5-6.25.5t-5.25-1q-5-1-10-6.25T124 362q.5-4 5-6.5t8.5-3.5q1-.5 3-.75T143 350q1.5-2 1.25-7.75T143 332q-1.5-13-2-28t-.5-28.5q-.5-16-1-29T139 217v-10.75l-1-7.75q-1.5-1.5-5.25-1.75t-6.25-1.25q-4.5-2-7-5.25t-5-8.75V177q4-7 13-9.75t19.5-4.75q7.5-1.5 15-2.5t13.5-.5 10.5 3.25 6.5 8.75q1 2.5 1.25 6t-1.25 6q-1.5 2-4.25 3t-6 1.75-6 1.75-3.75 2.5-1 6v9q0 14-1 25t0 25.5q.5 2 1 6.75t2 5.75q2.5 1 5.5 0t5.5-1.5q14.5-3 25-5.25T236 260q2-.5 5.25-.75l6.5-.5 6-.5 3.75-1.25q3-2.5 2.25-8.25t-1.25-9.25q-1-11-1-19.5t1-18.5l1.25-10-1.75-8q-1-1.5-5-1.75t-5.5-1.25q-5.5-2.5-10.75-7.75T234 159.5q1-2 3.5-3.75t5-2.75q8.5-4 17.75-5.5t19.25-3.5z" />
  </svg>
);

export default HyvesIcon;
