const strToColor = (
  string = "",
  saturation = 0,
  lightness = 0,
  steps = 180
) => {
  let hash = 0;
  if (typeof string === "string" && string.length !== 0) {
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + hash;
      hash = hash & hash;
    }
  }
  return `hsl(${
    ((hash % steps) * 360) / steps
  }, ${saturation}%, ${lightness}%)`;
};

export default strToColor;
