import { VALIDATIONPATTERNS } from "../constants";

const defaultContactRules = { required: true, maxLength: 50 };
const { phonePattern, urlPattern, emailPattern } = VALIDATIONPATTERNS;
const contactRules = {
  Email: { ...defaultContactRules, pattern: emailPattern },
  "Telefoon nr.": {
    ...defaultContactRules,
    maxLength: 20,
    pattern: phonePattern,
  },
  "Mobiel nr.": {
    ...defaultContactRules,
    maxLength: 20,
    pattern: phonePattern,
  },
  LinkedIn: { ...defaultContactRules, pattern: urlPattern },
  Twitter: { ...defaultContactRules, pattern: urlPattern },
  Facebook: { ...defaultContactRules, pattern: urlPattern },
  Instagram: { ...defaultContactRules, pattern: urlPattern },
  YouTube: { ...defaultContactRules, pattern: urlPattern },
  "Afspraak url": { ...defaultContactRules, pattern: urlPattern },
  Fax: { ...defaultContactRules, maxLength: 20, pattern: phonePattern },
  Overig: defaultContactRules,
};

export default contactRules;
