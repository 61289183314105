import axios from "axios";
import React, { useState, useEffect, useMemo, useContext } from "react";
import apiConfig from "../apiConfig";

export const AuthContext = React.createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const authStore = useMemo(() => {
    const login = () => {
      window.location.href = `${apiConfig.bffUrl}/auth/login?returnUrl=${apiConfig.appUrl}/select-customer`;
    };

    const logout = () => {
      localStorage.removeItem("customerId");
      localStorage.removeItem("session");
      window.location.href = `${apiConfig.bffUrl}/auth/logout?returnUrl=${apiConfig.appUrl}/login`;
    };

    return {
      user,
      isLoading,
      login,
      logout,
    };
  }, [user, isLoading]);

  useEffect(() => {
    const getUser = async () => {
      setIsLoading(true);
      try {
        const { data } = await axios.get(`${apiConfig.bffUrl}/auth/userinfo`, {
          withCredentials: true,
        });
        setUser(data);
      } catch (error) {
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    };
    getUser();
  }, []);

  return (
    <AuthContext.Provider value={authStore}>{children}</AuthContext.Provider>
  );
};
