import styled, { css } from "styled-components";

const WizardButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  @media (max-width: 499px) {
    justify-content: space-between;
    button {
      max-width: 49%;
      min-width: 0;
    }
  }
  background: ${(props) => props.theme.lightBg};
  border-top: 1px solid ${(props) => props.theme.borderColor};
  margin-top: 1.2em;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-bottom: -1em;
  position: sticky;
  bottom: 0;
  ${(props) =>
    props.fixed &&
    css`
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0.6em;
      padding-left: 1.2em;
      margin-bottom: 0;
      box-shadow: 0px 2px 5px #00000038;
      z-index: 2;
      button:first-child {
        margin-right: 0.3em;
      }
      button:last-child {
        margin-left: 0.3em;
      }
    `}
`;

export default WizardButtons;
