import React, { useContext, useState } from "react";
import Card from "../Card";
import Layout from "../parts/Layout";
import { Col } from "styled-bootstrap-grid";
import ContactText from "../ContactText";
import OrderList from "../OrderList";
import filterBy from "../../utils/filterBy";
import ImageBox from "../ImageBox";
import Error from "../Error";
import Loader from "../Loader";
import icons from "../../icons";
import { useTranslation } from "react-i18next";
import SearchBox from "../SearchBox";
import { SearchContext } from "../SearchProvider";
import CycleIcon from "../CycleIcon";
import config from "../../config";
import useOrders from "../../utils/useOrders";
import Breadcrumbs from "../parts/Breadcrumbs";
import usePrograms from "../../utils/usePrograms";
import Button from "../Button";
import CycleActivationModal from "../CycleActivationModal";
import useParticipations from "../../utils/useParticipations";
import Pagination from "../Pagination";
import IconList from "../IconList";
import CycleListItem from "../CycleListItem";
import { VOLVO } from "../../constants";

const PAGE_SIZE = 30;

const MainContent = () => {
  const { t } = useTranslation("common");
  const titleCycles =
    config.clientCode === VOLVO
      ? t("ui.Loyalty-cycles-Volvo")
      : t("ui.Loyalty-cycles");
  const searchContext = useContext(SearchContext);
  const [modalId, setModalId] = useState(null);

  // pagination
  const [participationsPage, setParticipationsPage] = useState(1);
  const [availableCyclesPage, setAvailableCyclesPage] = useState(1);

  const {
    data: participations,
    isLoading: participationsIsLoading,
    isError: participationsIsError,
  } = useParticipations({
    params: {
      page: participationsPage,
      pageSize: PAGE_SIZE,
      programType: "cycle",
      active: true,
    },
  });

  const {
    data: availableCycles,
    isLoading: availableCyclesIsLoading,
    isError: availableCyclesIsError,
  } = usePrograms(
    {
      page: availableCyclesPage,
      pageSize: PAGE_SIZE,
      type: "cycle",
      filter: "available",
    },
    () => config.uiSettings.showAvailableCycles
  );

  const availableWOParticipating =
    !!availableCycles &&
    !!availableCycles.length &&
    !!participations &&
    !participationsIsLoading &&
    !participationsIsError
      ? availableCycles.filter(
          (c) => participations.map((p) => p.program.id).indexOf(c.id) < 0
        )
      : [];

  // Search
  const q = searchContext.campaignsFilter;

  const myCycles = !!participations
    ? participations.map((item) => item.program)
    : [];

  const myFilteredCycles = !!q.length
    ? filterBy(myCycles, ["marketingName", "name", "title", "theme"], q)
    : myCycles;

  const filteredAvailableCycles = !!q.length
    ? filterBy(
        availableWOParticipating,
        ["marketingName", "name", "title", "theme"],
        q
      )
    : availableWOParticipating;

  return (
    <React.Fragment>
      <Card
        children={
          <SearchBox filter="campaignsFilter" setFilter="setCampaignsFilter" />
        }
        shadow
        className="hide-md-up"
      />
      <Card title={titleCycles} icon={<CycleIcon />} shadow>
        <Breadcrumbs pageTitle={titleCycles} />

        {participationsIsLoading ? (
          <Loader />
        ) : participationsIsError ? (
          <Error errorObj={participationsIsError} />
        ) : myFilteredCycles && !!myFilteredCycles.length ? (
          <IconList>
            {myFilteredCycles.map((item, i) => (
              <CycleListItem key={i + "homeCycles" + item.id} item={item} />
            ))}
          </IconList>
        ) : (
          <div
            style={{
              opacity: ".7",
            }}
          >
            {t("ui.No-active-cycles")}
          </div>
        )}

        {!!participations &&
        participations.length &&
        (participationsPage > 1 ||
          (participationsPage === 1 && participations.length === PAGE_SIZE)) ? (
          <Pagination
            page={participationsPage}
            setPage={setParticipationsPage}
            isLastPage={!participations || participations.length < PAGE_SIZE}
          />
        ) : null}
      </Card>

      {config.uiSettings.showAvailableCycles ? (
        <Card
          title={
            config?.clientCode === VOLVO
              ? t("ui.Available-loyalty-cycles-Volvo")
              : t("ui.Available-loyalty-cycles")
          }
          icon={<CycleIcon />}
          shadow
        >
          {availableCyclesIsLoading ? (
            <Loader />
          ) : availableCyclesIsError ? (
            <Error errorObj={availableCyclesIsError} />
          ) : !!filteredAvailableCycles && filteredAvailableCycles.length ? (
            <IconList>
              {filteredAvailableCycles.map((item, j) => (
                <Col md={6} key={item.id}>
                  <div
                    className="icon-list-link hover-zoom"
                    onClick={() => setModalId(item.id)}
                  >
                    {!!item.thumbnailFileId ? (
                      <div
                        style={{
                          width: "40px",
                          marginRight: "1em",
                          minWidth: "40px",
                        }}
                      >
                        <ImageBox
                          params={{
                            Width: 40,
                            Height: 40,
                          }}
                          id={item.thumbnailFileId}
                          boxHeight="100%"
                          className="hover-zoom"
                        />
                      </div>
                    ) : (
                      <CycleIcon className="icon-list-icon" />
                    )}
                    <div>
                      <h4>{!!item.name ? item.name : "..."}</h4>
                      <Button link className="btn-link">
                        {t("ui.Activate")} {icons.chevronRight}
                      </Button>
                    </div>
                  </div>
                  {modalId === item.id ? (
                    <CycleActivationModal
                      id={item.id}
                      title={item.marketingName || item.name || "..."}
                      setModalId={setModalId}
                    />
                  ) : null}
                </Col>
              ))}
            </IconList>
          ) : (
            <div
              style={{
                opacity: ".7",
              }}
            >
              {config?.clientCode === VOLVO
                ? t("ui.No-available-cycles-Volvo")
                : t("ui.No-available-cycles")}
            </div>
          )}
          {!!availableCycles &&
          availableCycles.length &&
          (availableCyclesPage > 1 ||
            (availableCyclesPage === 1 &&
              availableCycles.length === PAGE_SIZE)) ? (
            <Pagination
              page={availableCyclesPage}
              setPage={setAvailableCyclesPage}
              isLastPage={
                !availableCycles || availableCycles.length < PAGE_SIZE
              }
            />
          ) : null}
        </Card>
      ) : null}
    </React.Fragment>
  );
};

const Sidebar = ({ orders, isLoading, isError }) => {
  const { t } = useTranslation("common");

  return (
    <React.Fragment>
      <Card
        children={
          <SearchBox filter="campaignsFilter" setFilter="setCampaignsFilter" />
        }
        shadow
        className="hide-sm-down"
      />
      <Card
        title={t("ui.My-orders")}
        children={
          isLoading ? (
            <Loader />
          ) : isError ? (
            <Error errorObj={isError} />
          ) : (
            <OrderList
              orders={orders}
              isLoading={isLoading}
              isError={isError}
            />
          )
        }
        icon={icons.orders}
        shadow
        withBg
      />
      <Card
        title={t("ui.Contact")}
        children={<ContactText />}
        icon={icons.contact}
        shadow
      />
    </React.Fragment>
  );
};

const Cycles = () => {
  const { t } = useTranslation("common");
  const {
    data: ordersWithHeaders,
    isLoading,
    isError,
  } = useOrders({ pageSize: 5 });

  const orders = !!ordersWithHeaders ? ordersWithHeaders.data : [];

  return (
    <React.Fragment>
      <Layout
        main={<MainContent />}
        sidebar={
          <Sidebar orders={orders} isLoading={isLoading} isError={isError} />
        }
        pageTitle={t("ui.My-loyalty-cycles")}
      />
      ;
    </React.Fragment>
  );
};

export default Cycles;
