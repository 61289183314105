import React, { useState } from "react";
import FormRow from "../FormRow";
import { useTranslation } from "react-i18next";
import icons from "../../icons";
import { Row, Col } from "styled-bootstrap-grid";
import Button from "../Button";
import { Input, Field } from "@zendeskgarden/react-forms";
import { useForm, Controller } from "react-hook-form";
import {
  Dropdown,
  Field as SelectField,
  Menu,
  Item,
  Select,
} from "@zendeskgarden/react-dropdowns";
import styled from "styled-components";
import {
  Modal,
  Header,
  Body,
  Footer,
  FooterItem,
  Close,
} from "@zendeskgarden/react-modals";
import { useToast } from "@zendeskgarden/react-notifications";
import callAddresses from "../../utils/callAddresses";
import Notification from "../Notification";
import { ADDRESSTYPES } from "../../constants";

const SModal = styled(Modal)`
  max-width: 97%;
  max-height: 97%;
  margin: auto;
  left: 0;
  right: 0;
`;

const AddressForm = ({
  customerId,
  addresses = [],
  onSuccess,
  formData,
  index,
  isLink = false,
}) => {
  const { t } = useTranslation("common");
  const { addToast } = useToast();

  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, isSubmitting, reset } = useForm();
  const resetForm = () => {
    reset();
    setIsLoading(false);
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    callAddresses({
      customerId: customerId,
      data: !formData
        ? addresses.concat([{ ...data, type: data.type.value }])
        : addresses.map((item, i) =>
            index === i ? { ...data, type: data.type.value } : item
          ),
      onSuccess: (res) => {
        setModalOpen(false);
        resetForm();
        onSuccess();
      },
      onError: (error) => {
        setIsLoading(false);
        setModalOpen(false);
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Something-went-wrong")}
              text={t("error.Failed-to-update-address")}
              errorText={
                typeof error === "string" && !!error
                  ? error
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
    });
  };

  const addressTypes = [
    {
      value: ADDRESSTYPES.SHOP,
      label: `my-account.address-type-${ADDRESSTYPES.SHOP}`,
    },
    {
      value: ADDRESSTYPES.POSTAL,
      label: `my-account.address-type-${ADDRESSTYPES.POSTAL}`,
    },
    {
      value: ADDRESSTYPES.INVOICE,
      label: `my-account.address-type-${ADDRESSTYPES.INVOICE}`,
    },
    {
      value: ADDRESSTYPES.DELIVERY,
      label: `my-account.address-type-${ADDRESSTYPES.DELIVERY}`,
    },
  ];

  return (
    <React.Fragment>
      {!!formData ? (
        <Button link small onClick={() => setModalOpen(true)}>
          {icons.edit}
          <span>{t("my-account.Edit")}</span>
        </Button>
      ) : (
        <Button
          primary={!isLink}
          link={isLink}
          round={!isLink}
          onClick={() => setModalOpen(true)}
          title={t("my-account.New-address")}
        >
          {isLink ? (
            <>
              {icons.location}
              <span>{t("my-account.New-address")}</span>
            </>
          ) : (
            icons.add
          )}
        </Button>
      )}

      {modalOpen ? (
        <SModal
          isLarge
          onClose={() => setModalOpen(false)}
          appendToNode={document.body}
          aria-label="Address-Form"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Header>
              <h3>{t("my-account.New-address")}</h3>
            </Header>
            <Body className="overflow-hidden">
              <FormRow label={t("my-account.Address-type") + "*"}>
                <Controller
                  name="type"
                  control={control}
                  defaultValue={addressTypes[0]}
                  rules={{ required: true, maxLength: 30 }}
                  render={({ field }) => (
                    <Dropdown
                      selectedItem={field.value}
                      onSelect={field.onSelect}
                      downshiftProps={{
                        ...field,
                        itemToString: (item) => item && item.label,
                      }}
                    >
                      <SelectField>
                        <Select>{t(field.value.label)}</Select>
                      </SelectField>
                      <Menu>
                        {addressTypes.map((option) => (
                          <Item key={option.value} value={option}>
                            {t(option.label)}
                          </Item>
                        ))}
                      </Menu>
                    </Dropdown>
                  )}
                />
              </FormRow>
              <FormRow label={t("ui.Name") + "*"}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={
                    !!formData && !!formData.name ? formData.name : ""
                  }
                  rules={{ required: true, maxLength: 50 }}
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      validation={fieldState.invalid ? "error" : null}
                    />
                  )}
                />
              </FormRow>
              <FormRow label={t("my-account.Street") + "*"}>
                <Controller
                  name="street"
                  control={control}
                  defaultValue={
                    !!formData && !!formData.street ? formData.street : ""
                  }
                  rules={{ required: true, maxLength: 50 }}
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      validation={fieldState.invalid ? "error" : null}
                    />
                  )}
                />
              </FormRow>
              <FormRow label={t("my-account.House-number") + "*"}>
                <div className="row-wrap margin-bottom">
                  <Row>
                    <Col sm={6} xs={6}>
                      <Field>
                        <Controller
                          name="houseNumber"
                          control={control}
                          defaultValue={
                            !!formData && !!formData.houseNumber
                              ? formData.houseNumber
                              : ""
                          }
                          rules={{ required: true, maxLength: 30 }}
                          render={({ field, fieldState }) => (
                            <Input
                              {...field}
                              placeholder={t("my-account.House-number")}
                              validation={fieldState.invalid ? "error" : null}
                            />
                          )}
                        />
                      </Field>
                    </Col>
                    <Col sm={6} xs={6}>
                      <Field>
                        <Controller
                          name="suffix"
                          control={control}
                          defaultValue={
                            !!formData && !!formData.suffix
                              ? formData.suffix
                              : ""
                          }
                          rules={{ maxLength: 30 }}
                          render={({ field, fieldState }) => (
                            <Input
                              {...field}
                              placeholder={t("my-account.Extension")}
                              validation={fieldState.invalid ? "error" : null}
                            />
                          )}
                        />
                      </Field>
                    </Col>
                  </Row>
                </div>
              </FormRow>
              <FormRow label={t("my-account.Postcode") + "*"}>
                <Controller
                  name="postalCode"
                  control={control}
                  defaultValue={
                    !!formData && !!formData.postalCode
                      ? formData.postalCode
                      : ""
                  }
                  rules={{ required: true, maxLength: 7 }}
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      validation={fieldState.invalid ? "error" : null}
                    />
                  )}
                />
              </FormRow>
              <FormRow label={t("my-account.City") + "*"}>
                <Controller
                  name="city"
                  control={control}
                  defaultValue={
                    !!formData && !!formData.city ? formData.city : ""
                  }
                  rules={{ required: true, maxLength: 50 }}
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      validation={fieldState.invalid ? "error" : null}
                    />
                  )}
                />
              </FormRow>
              <FormRow label={t("my-account.Province")}>
                <Controller
                  name="province"
                  control={control}
                  defaultValue={
                    !!formData && !!formData.province ? formData.province : ""
                  }
                  rules={{ required: false, maxLength: 30 }}
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      validation={fieldState.invalid ? "error" : null}
                    />
                  )}
                />
              </FormRow>
              <FormRow label={t("my-account.Country") + "*"}>
                <Controller
                  name="country"
                  control={control}
                  defaultValue={
                    !!formData && !!formData.country ? formData.country : ""
                  }
                  rules={{ required: true, maxLength: 30 }}
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      validation={fieldState.invalid ? "error" : null}
                    />
                  )}
                />
              </FormRow>
            </Body>
            <Footer>
              <FooterItem>
                <Button
                  type="button"
                  onClick={() => setModalOpen(false)}
                  outline
                >
                  {icons.close}
                  <span>{t("ui.Cancel")} </span>
                </Button>
              </FooterItem>
              <FooterItem>
                <Button
                  primary
                  type="submit"
                  onClick={!isLoading ? handleSubmit(onSubmit) : () => null}
                  disabled={isSubmitting || isLoading}
                >
                  {icons.check}
                  <span>{t("my-account.Save")}</span>
                </Button>
              </FooterItem>
            </Footer>
          </form>
          <Close aria-label="Close modal" />
        </SModal>
      ) : null}
    </React.Fragment>
  );
};

export default AddressForm;
