import React, { useEffect, useRef } from "react";
import { Field, Label, Textarea, Toggle } from "@zendeskgarden/react-forms";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { INHERIT } from "../../constants";
import icons from "../../icons";
import { Col, Row } from "styled-bootstrap-grid";
import DropdownTextField from "../DropdownTextField";

const SVariantParam = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
    padding-bottom: 1em;
    margin-bottom: 1em;
  }
  .block-label {
    display: block;
    margin-bottom: 0.4em;
  }
  .label-icon {
    vertical-align: middle;
    margin-right: 0.2em;
  }
  .top-right-label {
    margin-bottom: 0.4em;
    float: right;
  }
  .label-disabled {
    opacity: 0.6;
  }
  .toggle-wrap {
    padding-top: 1px;
    display: inline-block;
    vertical-align: top;
    width: 50px;
  }
  .label-wrap {
    padding-bottom: 0.6em;
  }
  .bool-field-wrap {
    border: 1px solid #ddd;
    padding: 0.8em 0.8em 0.6em 0.8em;
    border-radius: 6px;
    &.bool-filed-wrap-disabled {
      background: #f8f9f9;
      border-color: #eaeaea;
    }
  }
  .resizing-textarea-wrap {
    padding-right: 43px;
  }
`;

const ResizingTextArea = ({
  value,
  param,
  thisCustomParam,
  onTextChange,
  customParams,
}) => {
  const textareaRef = useRef(null);
  const adjustHeight = () => {
    if (textareaRef.current) {
      const newHeight = textareaRef.current.scrollHeight + 2;
      textareaRef.current.style.height = "auto"; // Reset the height
      textareaRef.current.style.height = `${newHeight}px`; // Adjust height based on content
    }
  };

  useEffect(() => {
    adjustHeight(); // Adjust height on initial load
  }, [customParams]); // Ensure it runs if value changes

  return (
    <div className="resizing-textarea-wrap">
      <Textarea
        name={param.name}
        isResizable
        ref={textareaRef}
        rows={2}
        disabled={thisCustomParam.value === INHERIT}
        onChange={onTextChange}
        value={value}
      />
    </div>
  );
};

const VariantParam = ({
  param,
  customParams,
  setCustomParams,
  setParamsFormDisabled,
}) => {
  const { t } = useTranslation("common");
  const thisCustomParam = !!customParams
    ? customParams.filter((cParam) => cParam.name === param.name)?.[0]
    : null;

  if (!thisCustomParam) return null;

  const handleToggleInerit = () => {
    setCustomParams((curr) => [
      ...curr.filter((cParam) => cParam.name !== param.name),
      {
        name: thisCustomParam.name,
        value:
          thisCustomParam.value === INHERIT
            ? param.parameterValue
              ? param.parameterValue
              : param.inheritedValue
            : INHERIT,
      },
    ]);
    setParamsFormDisabled(false);
  };

  const onTextChange = (e) => {
    setCustomParams((curr) => [
      ...curr.filter((cParam) => cParam.name !== param.name),
      {
        name: thisCustomParam.name,
        value: e.target.value,
      },
    ]);
    setParamsFormDisabled(false);
  };

  const onBoolChange = (e) => {
    setCustomParams((curr) => [
      ...curr.filter((cParam) => cParam.name !== param.name),
      {
        name: thisCustomParam.name,
        value: e.target.checked ? "True" : "False",
      },
    ]);
    setParamsFormDisabled(false);
  };

  const options = param.valueOptions.map((option) => option?.value);
  return (
    <SVariantParam>
      <div className="row-wrap">
        <Row>
          <Col md={5} className="label-wrap">
            <Field title={t("ui.Customize")} className="toggle-wrap">
              <Toggle
                checked={thisCustomParam.value !== INHERIT}
                onChange={handleToggleInerit}
              >
                <Label isRegular hidden>
                  {t("ui.Customize")}
                </Label>
              </Toggle>
            </Field>
            <Label
              className={
                thisCustomParam.value === INHERIT ? "label-disabled" : ""
              }
            >
              {param.displayName}
            </Label>
          </Col>
          <Col md={7}>
            {param.valueType === "bit" ? (
              <div
                className={`bool-field-wrap ${
                  thisCustomParam.value === INHERIT
                    ? "bool-filed-wrap-disabled"
                    : ""
                }`}
              >
                <Field>
                  <Toggle
                    disabled={thisCustomParam.value === INHERIT}
                    name={param.name}
                    checked={
                      thisCustomParam.value === INHERIT
                        ? param.inheritedValue === "True"
                        : thisCustomParam.value === "True"
                    }
                    onChange={onBoolChange}
                  >
                    <Label isRegular>
                      {(thisCustomParam.value === INHERIT &&
                        param.inheritedValue === "True") ||
                      thisCustomParam.value === "True" ? (
                        <span>
                          <span className="label-icon success-color">
                            {icons.check}
                          </span>
                          {t("my-account.Yes")}
                        </span>
                      ) : (
                        <span>
                          <span className="label-icon error-color">
                            {icons.close}
                          </span>
                          {t("my-account.No")}
                        </span>
                      )}
                    </Label>
                  </Toggle>
                </Field>
              </div>
            ) : (
              <Field>
                {param.valueOptions && param.valueOptions.length > 1 ? (
                  <DropdownTextField
                    options={options}
                    value={
                      thisCustomParam.value === INHERIT
                        ? param.inheritedValue || ""
                        : thisCustomParam.value || ""
                    }
                    hasResizing={true}
                    disabled={thisCustomParam.value === INHERIT}
                    placeholder={param.displayName}
                    name={param.name}
                    onChange={onTextChange}
                  />
                ) : (
                  <ResizingTextArea
                    value={
                      thisCustomParam.value === INHERIT
                        ? param.inheritedValue || ""
                        : thisCustomParam.value || ""
                    }
                    param={param}
                    thisCustomParam={thisCustomParam}
                    onTextChange={onTextChange}
                    customParams={customParams}
                  />
                )}
              </Field>
            )}
          </Col>
        </Row>
      </div>
    </SVariantParam>
  );
};

export default VariantParam;
