import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../Button";
import icons from "../../icons";
import Badge from "../Badge";
import strToColor from "../../utils/strToColor";
import Link from "../Link";
import useProgram from "../../utils/useProgram";
import ImageBox from "../ImageBox";
import Loader from "../Loader";

const SCampaignBlock = styled.div`
  &::before,
  &::after {
    content: "";
    display: table;
    clear: both;
  }
  .img-wrap {
    float: left;
    margin-right: 1em;
    width: 70px;
    height: 70px;
  }
  .text-wrap:not(:first-child) {
    padding-left: calc(70px + 1em);
  }
`;

const CampaignBlock = ({ id, programData }) => {
  const { t } = useTranslation("common");
  const {
    data: fetchedProgram,
    isLoading,
    isError,
  } = useProgram(id, () => !programData);
  const program = !!programData ? programData : fetchedProgram;

  if (!programData && isLoading) {
    return <Loader />;
  }
  if (!programData && isError) {
    return (
      <>
        <h4>{`${t("ui.Campaign")} ${id}`}</h4>
        <Link to={`/campaign/${id}`}>
          <Button link>
            <span>{t("ui.View")}</span>
            {icons.chevronRight}
          </Button>
        </Link>
      </>
    );
  }

  return (
    <SCampaignBlock>
      {!!program.thumbnailFileId && (
        <div className="img-wrap">
          <ImageBox
            params={{
              Width: 70,
              Height: 70,
            }}
            id={program.thumbnailFileId}
            boxHeight="100%"
          />
        </div>
      )}
      <div className="text-wrap">
        {!!program.theme && (
          <Badge
            background={strToColor(program.theme, 60, 85)}
            className="margin-bottom-small inline-block"
          >
            {program.theme}
          </Badge>
        )}
        <h4>{program.marketingName || program.name || "..."}</h4>
        <Link
          to={
            program.type === "cycle"
              ? `/cycle/${program.id}`
              : `/campaign/${program.id}`
          }
        >
          <Button link disabled={!program?.id}>
            <span>{t("ui.View")}</span>
            {icons.chevronRight}
          </Button>
        </Link>
      </div>
    </SCampaignBlock>
  );
};

export default CampaignBlock;
