import useSWR from "swr";
import fetcher from "./fetcher";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const useProfiles = (id, shouldFetch = () => true) => {
  const { data, error } = useSWR(
    () => (shouldFetch() ? `${phoenix}/programs/${id}/profiles` : null),
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useProfiles;
