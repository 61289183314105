import useSWR from "swr";
import fetcher from "./fetcher";
import encodeParams from "./encodeParams";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const usePrograms = (params, shouldFetch = () => true) => {
  let page = 0;
  const paramsWithPage = params.hasOwnProperty("page")
    ? params
    : { ...params, page: +page + 1 };
  const { data, error } = useSWR(
    shouldFetch() ? `${phoenix}/programs${encodeParams(paramsWithPage)}` : null,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default usePrograms;
