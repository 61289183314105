import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import useView from "../../utils/useView";
import Card from "../Card";
import Error from "../Error";
import Loader from "../Loader";
import Layout from "../parts/Layout";
import Sidebar from "../parts/Sidebar";
import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";
import Breadcrumbs from "../parts/Breadcrumbs";
import DOMPurify from "dompurify";
import domPurifyWithHooks from "../../utils/adjustLinks";
import icons from "../../icons";
import useViews from "../../utils/useViews";
import Button from "../Button";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { Link } from "react-router-dom";
import { VOLVO } from "../../constants";
import config from "../../config";

const QSEmbed = () => {
  const { t } = useTranslation("common");
  const titleReports =
    config.clientCode === VOLVO ? t("menu-Volvo.Reports") : t("menu.Reports");
  const { qsReportId: reportId, qsViewId: viewId } = useParams();
  const { data: menuReports } = useViews({ chartType: "quickSight" });

  const {
    data: view,
    isLoading,
    isError: viewError,
    isValidating,
  } = useView({ reportId, viewId });

  domPurifyWithHooks(DOMPurify);

  const getTitle = () =>
    menuReports &&
    !!menuReports.length &&
    !!menuReports.filter(
      (item) => item.id === +viewId && item.reportId === +reportId
    ).length
      ? menuReports.filter(
          (item) => item.id === +viewId && item.reportId === +reportId
        )[0]?.name
      : "Report " + reportId;

  const [viewFullScreen, setViewFullScreen] = useState(false);

  const handleFullScreen = () => {
    setViewFullScreen((curr) => {
      if (!curr) {
        document
          .getElementsByTagName("body")?.[0]
          ?.classList.add("overflow-hidden");
        document.getElementById("static-container").classList.add("fullscreen");
      } else {
        document
          .getElementsByTagName("body")?.[0]
          ?.classList.remove("overflow-hidden");
        document
          .getElementById("static-container")
          .classList.remove("fullscreen");
      }

      return !curr;
    });
  };

  useEffect(() => {
    const container = document.getElementById("static-container");
    // Clean up
    return () => {
      document
        .getElementsByTagName("body")?.[0]
        ?.classList.remove("overflow-hidden");
      container.classList.add("hidden");
      container.classList.remove("fullscreen");
    };
  }, []);

  useEffect(() => {
    const container = document.getElementById("static-container");
    container.innerHTML = "";
    container.classList.remove("qs-loaded");
  }, [viewId]);

  useEffect(() => {
    const container = document.getElementById("static-container");
    container.classList.remove("hidden");
    container.classList.remove("qs-loaded");
    if (
      !!view &&
      view?.chartType === "quickSight" &&
      view?.charts?.[0]?.embedUrl &&
      !isValidating
    ) {
      createEmbeddingContext().then((embeddingContext) => {
        embeddingContext
          .embedDashboard(
            {
              // frameOptions
              url: view?.charts?.[0]?.embedUrl,
              container: container,
              resizeHeightOnSizeChangedEvent: true,
              onChange: (changeEvent) => {
                if (changeEvent?.eventName === "FRAME_LOADED") {
                  container.classList.add("qs-loaded");
                }
              },
            },
            {
              // contentOptions
              parameters: [
                {
                  Name: "CustomerUids",
                  Values: view?.charts?.[0]?.customerIds,
                },
              ],
              toolbarOptions: {
                export: true,
                undoRedo: true,
                reset: true,
              },
            }
          )
          .catch((err) => console.log("Error: embedding QS dashboard failed"));
      });
    } else {
      container.classList.add("hidden");
      container.classList.remove("qs-loaded");
      container.innerHTML = "";
    }
    return () => {
      if (container) {
        container.classList.add("hidden");
        container.classList.remove("qs-loaded");
        container.innerHTML = null;
      }
    };
  }, [view, isValidating]);

  return (
    <React.Fragment>
      <Layout
        fullScreen={viewFullScreen}
        main={
          isLoading ? (
            <Card title={"..."} icon={icons.reports} shadow>
              <Loader />
            </Card>
          ) : viewError ? (
            <Fragment>
              <Card
                title={getTitle()}
                icon={icons.reports}
                shadow
                className="main-card"
                headerRight={
                  <Button
                    secondary={!viewFullScreen}
                    primary={!!viewFullScreen}
                    small
                    onClick={() => handleFullScreen()}
                    title={
                      !viewFullScreen
                        ? t("ui.View-fullscreen")
                        : t("ui.Exit-fullscreen-view")
                    }
                  >
                    {!!viewFullScreen ? (
                      <AiOutlineFullscreenExit />
                    ) : (
                      <AiOutlineFullscreen />
                    )}
                  </Button>
                }
                noRadius={!!viewFullScreen}
                stretchFullScreen={!!viewFullScreen}
                noMargin={!!viewFullScreen}
                noPadding={!!viewFullScreen}
              >
                {!!viewFullScreen ? null : (
                  <div
                    className="border-bottom"
                    style={{ paddingBottom: "1em", marginBottom: "1em" }}
                  >
                    <Breadcrumbs
                      pageTitle={getTitle()}
                      pathArray={[{ title: titleReports, url: "/reports" }]}
                      noMargin
                    />
                  </div>
                )}
                <Error
                  errorMessage={viewError?.message ? viewError.message : null}
                  children={
                    <div>
                      <Button
                        as={Link}
                        $link
                        to="/reports"
                        className="icon-link"
                      >
                        {icons.chevronLeft} {t("ui.Back-to-reports")}
                      </Button>
                    </div>
                  }
                  errorObj={viewError}
                />
              </Card>
              <div className="no-print margin-top">
                <Sidebar isRow />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <Card
                title={view?.name}
                icon={icons.reports}
                shadow
                className="main-card"
                headerRight={
                  <Button
                    secondary={!viewFullScreen}
                    primary={!!viewFullScreen}
                    small
                    onClick={() => handleFullScreen()}
                    title={
                      !viewFullScreen
                        ? t("ui.View-fullscreen")
                        : t("ui.Exit-fullscreen-view")
                    }
                  >
                    {!!viewFullScreen ? (
                      <AiOutlineFullscreenExit />
                    ) : (
                      <AiOutlineFullscreen />
                    )}
                  </Button>
                }
                noRadius={!!viewFullScreen}
                stretchFullScreen={!!viewFullScreen}
                noMargin={!!viewFullScreen}
                noPadding={!!viewFullScreen}
              >
                {!!viewFullScreen ? null : (
                  <React.Fragment>
                    <div
                      className="border-bottom"
                      style={{ paddingBottom: "1em", marginBottom: "1em" }}
                    >
                      <Breadcrumbs
                        pageTitle={view?.name}
                        pathArray={[{ title: titleReports, url: "/reports" }]}
                        noMargin
                      />
                    </div>
                    {!!view?.description && (
                      <div
                        className="margin-bottom"
                        dangerouslySetInnerHTML={
                          !!view &&
                          !!view.description && {
                            __html: DOMPurify.sanitize(view.description, {
                              USE_PROFILES: { html: true },
                            }),
                          }
                        }
                      />
                    )}
                  </React.Fragment>
                )}
              </Card>
            </Fragment>
          )
        }
        pageTitle={!!view ? view?.name : getTitle()}
      />
    </React.Fragment>
  );
};

export default QSEmbed;
