import useSWR from "swr";
import apiConfig from "../apiConfig";
import fetcherWithHeaders from "./fetcherWithHeaders";
import config from "../config";

const useSession = (shouldFetch = () => !!config.uiSettings.toolsMenu) => {
  const cacheKey = apiConfig.getLegacySession;
  const { data, error } = useSWR(
    () =>
      shouldFetch()
        ? { url: cacheKey, multiCustomer: false, method: "POST" }
        : null,
    fetcherWithHeaders,
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  );
  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
    cacheKey: cacheKey,
  };
};

export default useSession;
