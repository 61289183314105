import React from "react";
import { MaintenanceMessageContent } from "./MaintenanceMessage";
import { FcVlc } from "react-icons/fc";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { GiGears } from "react-icons/gi";
import { useLocation, Navigate } from "react-router-dom";

const SMaintenancePage = styled.div`
  text-align: center;
  display: flex;
  min-height: 90vh;
  .inner {
    width: 600px;
    margin: auto;
    max-width: 80%;
  }
  .main-icon {
    display: block;
    width: 250px;
    max-width: 70%;
    height: auto;
    margin: auto;
    margin-bottom: 0.5em;
  }
  .small-icon {
    display: block;
    width: 50px;
    height: auto;
    margin: auto;
  }
  .corner-icon {
    position: fixed;
    bottom: -6px;
    right: -6px;
    height: 200px;
    max-height: 20%;
    width: auto;
    color: rgb(0, 0, 0, 0.1);
  }
  .content-block {
    @media (min-width: 700px) {
      font-size: 130%;
    }
    color: ${(props) => props.theme.textOnBg};
    margin-bottom: 1.5em;
    .date-str {
      display: inline-block;
      font-size: 1.1em;
      margin-top: 0.6em;
      font-weight: normal;
      opacity: 0.9;
      background: rgb(0, 0, 0, 0.1);
      padding: 0.1em 0.5em;
      font-family: monospace;
    }
    .msg-wrap {
      display: block;
    }
  }
  .main-heading {
    margin-bottom: 0.3em;
    font-weight: 4 00;
    opacity: 0.97;
    line-height: 1.3;
    font-size: 1.6em;
    color: ${(props) => props.theme.textOnBg};
  }
`;

const MaintenancePage = () => {
  const { t } = useTranslation("common");
  const location = useLocation();

  if (location.pathname !== "/") {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <SMaintenancePage>
      <div className="inner">
        <FcVlc className="main-icon fadeInGrow" />
        <div className="content-block text-on-bg fadeInDown">
          <h1 className="main-heading">{t("ui.Maintenance-in-progress")}</h1>
          <MaintenanceMessageContent showStartDate={false} />
        </div>
      </div>
      <GiGears className="corner-icon" />
    </SMaintenancePage>
  );
};

export default MaintenancePage;
