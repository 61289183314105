import React from "react";
import noContent from "../assets/img/no-content.svg";
import { useTranslation } from "react-i18next";

const NoItems = ({
  label = "order.No-articles-available",
  children = null,
}) => {
  const { t } = useTranslation("common");
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <img
        src={noContent}
        alt={label}
        style={{
          width: "15em",
          margin: "2.5em auto 1em",
          maxWidth: "50%",
          opacity: 0.6,
        }}
      />
      <div>
        <p
          style={{
            textAlign: "center",
            opacity: 0.4,
            fontSize: "1.1em",
            margin: "-.5em 0 1.75em",
          }}
        >
          {t(label)}
        </p>
        {children}
      </div>
    </div>
  );
};

export default NoItems;
