import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Button from "./Button";
import {
  Modal,
  Header,
  Body,
  Footer,
  FooterItem,
  Close,
} from "@zendeskgarden/react-modals";
import styled from "styled-components";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { Field, Input, Label } from "@zendeskgarden/react-forms";
import { Col, Row } from "styled-bootstrap-grid";
import icons from "../icons";
import { Alert, Title } from "@zendeskgarden/react-notifications";
import Error from "./Error";
import ReCAPTCHA from "react-google-recaptcha";
import apiConfig from "../apiConfig";
import { BsSendXFill } from "react-icons/bs";
import { FaUserCheck } from "react-icons/fa";
import { VOLVO } from "../constants";
import config from "../config";
const SModal = styled(Modal)`
  max-width: 97%;
  max-height: 97%;
  margin: auto;
  left: 0;
  right: 0;
  .alert-wrap {
    margin: -21px 0;
  }
  .error-wrap {
    min-height: 370px;
    .center-icon {
      height: 160px;
      padding-bottom: 20px;
    }
  }
`;

const RequestAccountButton = ({ clientId }) => {
  const { t } = useTranslation("common");
  const [visible, setVisible] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isAPIKeyValid, setIsAPIKeyValid] = useState(true);
  const recaptchaRef = React.createRef();
  const openModel = () => {
    setVisible(true);
  };

  const APIKEY = process.env.REACT_APP_API_KEY_RECAPTCHA;

  const onRecaptchaError = () => {
    console.error("reCaptcha error");
    setIsAPIKeyValid(false);
    setError({
      message: "reCaptcha error",
    });
  };

  const closeModal = () => {
    setVisible(false);
    setSuccess(false);
    setError(false);
  };

  const { control, handleSubmit, isSubmitting } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      shopName: "",
      city: "",
      phoneNumber: "",
    },
  });
  const onSubmit = (data) => {
    setSuccess(false);
    setError(false);
    data.clientId = clientId;
    axios({
      method: "POST",
      url: apiConfig.userRequest,
      data: data,
    })
      .then((res) => {
        if (res.status === 200) {
          setSuccess(true);
          setTimeout(() => {
            closeModal();
          }, 5000);
        } else {
          setError(res);
          console.log("Submit failed", res);
        }
      })
      .catch((err) => {
        setError(err);
        console.log("Request failed", err);
      });
  };

  return (
    <span className="login-btn-inner">
      {APIKEY && isAPIKeyValid && (
        <Button primary onClick={() => openModel()}>
          <span>{t("ui.Request-an-account")}</span>
          <FaUserCheck />
        </Button>
      )}
      <form
        onSubmit={() => {
          recaptchaRef.current.execute();
          handleSubmit(onSubmit);
        }}
      >
        {APIKEY && (
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={APIKEY}
            onErrored={onRecaptchaError}
          />
        )}
        {visible && (
          <SModal
            isLarge
            onClose={() => closeModal()}
            aria-label="Modal Description"
          >
            <Header>
              <h3>{t("ui.Request-an-account")}</h3>
            </Header>
            {!error ? (
              <Body>
                <div className="row-wrap margin-bottom">
                  <Field>
                    <Row>
                      <Col sm={3}>
                        <Label className="grid-form-label">
                          {t("my-account.First-name")}*
                        </Label>
                      </Col>
                      <Col sm={9}>
                        <Controller
                          name="firstName"
                          control={control}
                          rules={{ required: true, maxLength: 30 }}
                          render={({ field, fieldState }) => (
                            <Input
                              {...field}
                              validation={fieldState.invalid ? "error" : null}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </Field>
                </div>
                <div className="row-wrap margin-bottom">
                  <Field>
                    <Row>
                      <Col sm={3}>
                        <Label className="grid-form-label">
                          {t("my-account.Last-name")}*
                        </Label>
                      </Col>
                      <Col sm={9}>
                        <Controller
                          name="lastName"
                          control={control}
                          rules={{ required: true, maxLength: 30 }}
                          render={({ field, fieldState }) => (
                            <Input
                              {...field}
                              validation={fieldState.invalid ? "error" : null}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </Field>
                </div>
                <div className="row-wrap margin-top">
                  <Field>
                    <Row>
                      <Col sm={3}>
                        <Label className="grid-form-label">
                          {" "}
                          {t("ui.Email")}*
                        </Label>
                      </Col>
                      <Col sm={9}>
                        <Controller
                          name="email"
                          control={control}
                          rules={{
                            required: true,
                            maxLength: 50,
                            pattern:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          }}
                          render={({ field, fieldState }) => (
                            <Input
                              {...field}
                              type="email"
                              validation={fieldState.invalid ? "error" : null}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </Field>
                </div>
                <div className="row-wrap margin-top">
                  <Field>
                    <Row>
                      <Col sm={3}>
                        <Label className="grid-form-label">
                          {config?.clientCode === VOLVO
                            ? t("ui.Branchname")
                            : t("ui.Shopname")}
                          *
                        </Label>
                      </Col>
                      <Col sm={9}>
                        <Controller
                          name="shopName"
                          control={control}
                          rules={{ required: true, maxLength: 30 }}
                          render={({ field, fieldState }) => (
                            <Input
                              {...field}
                              validation={fieldState.invalid ? "error" : null}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </Field>
                </div>
                <div className="row-wrap margin-top">
                  <Field>
                    <Row>
                      <Col sm={3}>
                        <Label className="grid-form-label">
                          {t("my-account.City")}*
                        </Label>
                      </Col>
                      <Col sm={9}>
                        <Controller
                          name="city"
                          control={control}
                          rules={{ required: true, maxLength: 30 }}
                          render={({ field, fieldState }) => (
                            <Input
                              {...field}
                              validation={fieldState.invalid ? "error" : null}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </Field>
                </div>
                <div className="row-wrap margin-top">
                  <Field>
                    <Row>
                      <Col sm={3}>
                        <Label className="grid-form-label">
                          {t("ui.Phone-number")}*
                        </Label>
                      </Col>
                      <Col sm={9}>
                        <Controller
                          name="phoneNumber"
                          control={control}
                          rules={{
                            required: true,
                            maxLength: 12,
                            minLength: 9,
                            pattern: /^[0-9]+$/i,
                          }}
                          render={({ field, fieldState }) => (
                            <Input
                              {...field}
                              type="number"
                              validation={fieldState.invalid ? "error" : null}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </Field>
                </div>
              </Body>
            ) : (
              <div className="error-wrap">
                <Error
                  errorObj={error}
                  icon={<BsSendXFill className="center-icon" />}
                >
                  {t("error.check-internet-connection")}
                </Error>
              </div>
            )}

            <Footer>
              {success ? (
                <div className="row-wrap flex-grow-1 alert-wrap">
                  <Row>
                    <Col sm={3}></Col>
                    <Col sm={9}>
                      {success && (
                        <Alert type="success" className="fadeInGrow">
                          <Title>{t("ui.Thank-you-for-your-message")}</Title>
                          {t("ui.Info-send-clientsupport")}
                        </Alert>
                      )}
                    </Col>
                  </Row>
                </div>
              ) : error ? (
                <FooterItem>
                  <Button outline onClick={() => setError(false)}>
                    <span className="btn-icon" aria-label="OK">
                      {icons.chevronRight}
                    </span>
                  </Button>
                </FooterItem>
              ) : (
                <FooterItem>
                  <Button
                    primary
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={isSubmitting}
                  >
                    {t("ui.Send")}{" "}
                    <span className="btn-icon-right">{icons.send}</span>
                  </Button>
                </FooterItem>
              )}
            </Footer>

            <Close aria-label="Close modal" />
          </SModal>
        )}
      </form>
    </span>
  );
};

export default RequestAccountButton;
