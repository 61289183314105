import axios from "axios";
import config from "../config";
import apiConfig from "../apiConfig";

const { order } = apiConfig;

const getOrder = ({ id, onSucces = () => null, onError = () => null }) => {
  const url = `${order}/orders/${id}`;
  const customerId = localStorage.getItem("customerId");

  return axios(url, {
    withCredentials: true,
    pxclient: config.clientId,
    headers: {
      pxclient: config.clientId,
      pxcustomer: customerId,
    },
  })
    .then((res) => {
      !!onSucces && onSucces(res);
      return {
        data: res.data,
        headers: {
          ...res.headers,
        },
      };
    })
    .catch((error) => {
      !!onError && onError(error);
    });
};

export default getOrder;
