import React, { useState } from "react";
import styled from "styled-components";
import Carousel from "../Carousel";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import {
  BsArrowClockwise,
  BsBoxArrowUpRight,
  BsX,
  BsZoomIn,
  BsZoomOut,
} from "react-icons/bs";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ZoomImageWrap = styled.div`
  background-image: linear-gradient(rgb(235, 235, 235) 2px, transparent 2px),
    linear-gradient(90deg, rgb(235, 235, 235) 2px, transparent 2px),
    linear-gradient(rgb(235, 235, 235) 1px, transparent 1px),
    linear-gradient(90deg, rgb(235, 235, 235) 1px, rgb(255, 255, 255) 1px);
  background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;
  background-position: -4px -10px, 0px -4px, 0px 0px, 0px 0px;
  position: relative;
  .abs-buttons {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    padding: 1em;
    gap: 0.5em;
    z-index: 10;
  }
  .carousel-status {
    color: #333;
    text-shadow: none;
    font-size: 1em;
    right: 1em;
    top: 0.5em;
    padding: 0;
  }

  .carousel {
    .control-dots {
      right: 1em;
      bottom: 1em;
    }
    .react-transform-wrapper {
      margin: auto;
      max-width: 100%;
    }
    .proof-img {
      --proof-container-height: ${({ isFullscreen, isPopup }) =>
        isFullscreen
          ? "var(--fullscreen-height)"
          : isPopup
          ? "var(--modal-body-height)"
          : "75%"};
      width: auto;
      display: block;
      max-width: ${({ flipPosition, isFullscreen, isPopup }) =>
        flipPosition === 1 || flipPosition === 3
          ? isFullscreen || isPopup
            ? "calc(var(--proof-container-height) - 2em)"
            : "calc(80vh - 2em)"
          : "75%"};
      max-height: 75%;
      transition: transform 0.2s;
    }
  }

  .canvas {
    max-height: ${({ flipPosition, isFullscreen, isPopup }) =>
      isPopup ? "var(--modal-body-height)" : "80vh"};
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: move;
  }
  .placeholder {
    width: 0;
    padding-bottom: ${({ flipPosition, isFullscreen, isPopup }) =>
      isPopup
        ? "calc( var(--modal-body-height) - 2em)"
        : "calc(80vh - 2em)"}; // 75%
  }

  // Fullscreen
  --fullscreen-height: calc(-57px + 100vh);
  --modal-body-height: calc(-240px + 100vh);
  height: ${(props) =>
    props.isFullscreen ? "var(--fullscreen-height)" : "auto"};
  .full-screen {
    .canvas {
      max-height: var(--fullscreen-height);
    }
    .placeholder {
      padding-bottom: var(--fullscreen-height);
    }
  }
`;

const VariantCarousel = ({
  proofs,
  proofType,
  isPopup = false,
  viewFullScreen = false,
  flipPosition = 0,
  handleFlip = () => null,
  resetFlip,
}) => {
  const { t } = useTranslation("common");

  const handleOpenTab = (url) => window.open(url);
  const [scale, setScale] = useState(1);

  return (
    <ZoomImageWrap
      proofType={proofType}
      isPopup={isPopup}
      isFullscreen={viewFullScreen}
      flipPosition={flipPosition}
    >
      <Carousel
        showThumbs={false}
        className={`${isPopup ? "modal-carousel" : ""}  ${
          proofType === "edm" ? "edm" : ""
        } ${viewFullScreen ? "full-screen" : ""}`}
        statusFormatter={(curr, total) => `${curr} ${t("ui.out-of")} ${total}`}
        showStatus={false}
        showArrows={false}
        renderIndicator={(onClick, isSelected, index) => (
          <li
            className={`dot indicator ${isSelected ? "selected" : ""}`}
            value={0}
            role="button"
            tabIndex={0}
            aria-label={`slide item ${index}`}
            onClick={onClick}
          >
            {index + 1}
          </li>
        )}
      >
        {proofs.map((doc) =>
          doc.urls.map((url) => (
            <div key={url} className="carousel-item">
              <TransformWrapper
                initialScale={1}
                centerOnInit
                minScale={0.5}
                onTransformed={(ref, state) => {
                  setScale(state?.scale);
                }}
              >
                {({
                  zoomIn,
                  zoomOut,
                  resetTransform,
                  setTransform,
                  ...rest
                }) => {
                  return (
                    <React.Fragment>
                      <div
                        className="abs-buttons"
                        style={{
                          right: 0,
                          left: "auto",
                          flexDirection: "column",
                        }}
                      >
                        <Button
                          secondary={flipPosition === 0}
                          primary={flipPosition !== 0}
                          square
                          onClick={handleFlip}
                          className="flip-btn"
                          // title={t("ui.Rotate")}
                        >
                          <BsArrowClockwise
                            style={{
                              transform: `rotate(${flipPosition * 90}deg)`,
                            }}
                          />
                        </Button>
                        <Button
                          secondary={scale <= 1}
                          primary={scale > 1}
                          square
                          onClick={() => zoomIn()}
                          style={{ flexShrink: 0 }}
                          disabled={scale === 8}
                        >
                          <BsZoomIn />
                        </Button>
                        <Button
                          secondary={scale >= 1}
                          primary={scale < 1}
                          square
                          onClick={() => zoomOut()}
                          style={{ flexShrink: 0 }}
                          disabled={scale === 0.5}
                        >
                          <BsZoomOut />
                        </Button>

                        <Button
                          secondary
                          square
                          onClick={() => {
                            resetFlip();
                            resetTransform();
                          }}
                          style={{ flexShrink: 0 }}
                          disabled={scale === 1 && flipPosition === 0}
                        >
                          <BsX />
                        </Button>
                      </div>
                      <TransformComponent>
                        <div className="canvas">
                          <div className="placeholder" />
                          <img
                            className="proof-img"
                            src={url}
                            alt={url}
                            style={{
                              transform: `rotate(${flipPosition * 90}deg)`,
                            }}
                          />
                        </div>
                      </TransformComponent>
                    </React.Fragment>
                  );
                }}
              </TransformWrapper>

              {proofType === "dm" ? (
                <div
                  className="abs-buttons"
                  style={{ right: 0, left: "auto", bottom: 0, top: "auto" }}
                >
                  <Button
                    secondary
                    square
                    onClick={() => handleOpenTab(url)}
                    className="new-tab-btn"
                    title={t("ui.View-in-new-tab")}
                  >
                    <BsBoxArrowUpRight />
                  </Button>
                </div>
              ) : null}
            </div>
          ))
        )}
      </Carousel>
    </ZoomImageWrap>
  );
};

export default VariantCarousel;
