import React from "react";
import { useTranslation } from "react-i18next";
import icons from "../../icons";
import VariantParam from "./VariantParam";
import Button from "../Button";
import {
  Modal,
  Header,
  Body,
  Footer,
  FooterItem,
  Close,
} from "@zendeskgarden/react-modals";

const EditParamsModal = ({
  variantParams,
  paramsFormDisabled,
  setParamsFormDisabled,
  customParams,
  setCustomParams,
  cancelParamsEdit,
  onSubmit,
}) => {
  const { t } = useTranslation("common");

  return (
    <Modal
      aria-label="edit-params-modal"
      onClose={() => cancelParamsEdit()}
      appendToNode={document.body}
      isLarge
    >
      <Header>
        <h3>{t("ui.Edit-parameters")}</h3>
      </Header>
      <Body>
        <form className="form-box">
          {variantParams.map((param) => (
            <VariantParam
              param={param}
              key={param.name}
              customParams={customParams}
              setCustomParams={setCustomParams}
              setParamsFormDisabled={setParamsFormDisabled}
            />
          ))}
        </form>
      </Body>
      <Footer className="modal-footer mob-btn">
        <FooterItem className="modal-footer-item">
          <Button
            className="hide-label-xs"
            type="button"
            onClick={() => {
              cancelParamsEdit();
            }}
            outline
            title={t("ui.Cancel")}
          >
            {icons.close}
            <span className="btn-label-hidden-xs">{t("ui.Cancel")}</span>
          </Button>
        </FooterItem>
        {!!variantParams && !!variantParams.length ? (
          <FooterItem className="modal-footer-item">
            <Button
              className="hide-label-xs"
              $success
              type="submit"
              onClick={onSubmit}
              disabled={paramsFormDisabled}
              title={t("ui.Apply-changes")}
            >
              {icons.check}
              <span className="btn-label-hidden-xs">
                {t("ui.Apply-changes")}
              </span>
            </Button>
          </FooterItem>
        ) : null}
      </Footer>
      <Close aria-label="Close modal" className="modal-close-btn" />
    </Modal>
  );
};

export default EditParamsModal;
