import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Modal, Close } from "@zendeskgarden/react-modals";
import { IoClose } from "react-icons/io5";
import { BsArrowRight, BsPersonCheckFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { Checkbox, Field, Label } from "@zendeskgarden/react-forms";
import { ThemeProvider, DEFAULT_THEME } from "@zendeskgarden/react-theming";
import config from "../config";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import apiConfig from "../apiConfig";

const SAlertModal = styled(Modal)`
  .warning-alert {
    color: rgba(0, 0, 0, 0.6);
    position: relative;
    padding: 3em;
    padding-bottom: calc(3em + 10px);
    border-radius: 6px 6px 0 0;
    text-align: left;
    background-color: #f9dc41;
  }
  .alert-inner {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .alert-img {
    color: rgba(0, 0, 0, 0.2);
    width: 100px;
    height: 100%;
    margin-right: 25px;
  }
  .text-wrap {
    a {
      color: rgba(0, 0, 0, 0.8);
      &:hover {
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
  .btn-wrap {
    margin-top: 0.5em;
    text-align: right;
    * + button,
    * + a {
      margin-left: 1em;
    }
    a,
    button {
      vertical-align: middle;
      margin-top: 0.4em;
    }
  }
  h4 {
    color: inherit;
    font-size: 1.8em;
    margin-top: 0;
    margin-bottom: 0.4em;
    line-height: 1.25;
  }
  @media (max-width: 600px) {
    .alert-inner {
      flex-direction: column;
    }
    .img-wrap {
      width: 100%;
      text-align: center;
    }
    .alert-img {
      margin-right: 0;
      margin-bottom: 1em;
    }
    .text-wrap {
      text-align: center;
      max-width: 80%;
      margin: auto;
    }
    .btn-wrap {
      button,
      a {
        width: 100%;
        margin-left: 0;
      }
    }
  }
  .alert-with-border {
    position: relative;
  }
  .alert-with-border:before,
  .alert-with-border:after {
    content: "";
    position: absolute;
    height: 8px;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.8;
    background-image: linear-gradient(
      45deg,
      #000000 25%,
      #ffc400 25%,
      #ffc400 50%,
      #000000 50%,
      #000000 75%,
      #ffc400 75%,
      #ffc400 100%
    );
    background-size: 35px 35px;
  }
  .alert-with-border:before {
    top: 0;
  }
  .close-btn {
    right: 7px;
    top: 16px;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
    &:active {
      background: rgba(0, 0, 0, 0.2);
    }
  }
  .dont-show-checkbox {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }
`;

const WarningModal = ({ helpContent }) => {
  const { t } = useTranslation("common");
  const { search } = useLocation();
  const qParams = new URLSearchParams(search);
  const isMigratedParam = qParams.get("migrated") === "true";

  const isMigratedCookie = localStorage.getItem("migrated");

  const [modalOpen, setModalOpen] = useState(
    !isMigratedParam && !isMigratedCookie
  );

  const [dontShowAgain, setDontShowAgain] = useState(false);

  const toggleDontShow = () => {
    setDontShowAgain((curr) => !curr);
  };

  const onCloseModal = () => {
    setModalOpen(false);
    dontShowAgain && localStorage.setItem("migrated", true);
  };

  useEffect(() => {
    isMigratedParam && localStorage.setItem("migrated", true);
  }, [isMigratedParam]);

  return (
    <>
      <Button link onClick={() => setModalOpen(true)} className="link-on-bg">
        <span>{t("ui.Account-migration")}</span>
      </Button>
      {modalOpen ? (
        <SAlertModal onClose={onCloseModal}>
          <div className="warning-alert alert-with-border">
            <div className="alert-inner">
              <div className="img-wrap">
                <BsPersonCheckFill className="alert-img" />
              </div>
              <div>
                <div className="text-wrap">
                  <h4>{t("ui.Account-migration")}</h4>
                  <p>{t("ui.Migration-popup-msg")}</p>
                  <p>
                    {t("ui.Migration-popup-help")},{" "}
                    {helpContent?.accountHelpPhone ? (
                      <span>
                        {t("ui.Migration-popup-help-action-call")}{" "}
                        <a href={`tel:${helpContent.accountHelpPhone}`}>
                          {helpContent.accountHelpPhone}
                        </a>
                      </span>
                    ) : null}
                    {helpContent?.email ? (
                      <span>
                        {t("ui.Migration-popup-help-action-email")}{" "}
                        <a href={`mailto:${helpContent.email}`}>
                          {helpContent.email}
                        </a>
                      </span>
                    ) : null}
                  </p>
                </div>
                <ThemeProvider
                  theme={{
                    ...DEFAULT_THEME,
                    colors: {
                      ...DEFAULT_THEME.colors,
                      background: "#f9dc41",
                      foreground: "rgba(0,0,0,.6)",
                      primaryHue: "rgba(0,0,0,.5)",
                      neutralHue: "rgba(0,0,0,.2)",
                    },
                    fontSizes: {
                      ...DEFAULT_THEME.fontSizes,
                      md: "13px",
                    },
                  }}
                >
                  <div className="dont-show-checkbox">
                    <Field>
                      <Checkbox
                        checked={isMigratedParam ? true : dontShowAgain}
                        onChange={toggleDontShow}
                        disabled={isMigratedParam}
                      >
                        <Label>{t("ui.Dont-show-again")}</Label>
                      </Checkbox>
                    </Field>
                  </div>
                </ThemeProvider>
                <div className="btn-wrap">
                  <Button darkOutline onClick={onCloseModal}>
                    <IoClose /> <span>{t("ui.Not-now")}</span>
                  </Button>
                  <Button
                    as="a"
                    dark
                    href={`${apiConfig.accountMigrationUrl}?clientname=${config.clientName}&returnUrl=${apiConfig.appUrl}/login?migrated=true`}
                    className="btn alert-btn"
                    rel="noopener noreferrer"
                  >
                    <span>{t("ui.Migration-popup-main-btn")}</span>
                    <BsArrowRight />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Close
            aria-label="Not now"
            title={t("my-account.Close")}
            className="close-btn"
          />
        </SAlertModal>
      ) : null}
    </>
  );
};

export default WarningModal;
