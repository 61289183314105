import axios from "axios";
import config from "../config";
import apiConfig from "../apiConfig";

const { reporting } = apiConfig;

const callViewExportFile = ({
  reportId,
  fileType = "csv",
  onSuccess,
  onError = () => console.log("Failed to download CSV"),
}) => {
  const customerId = localStorage.getItem("customerId");
  const responseType = fileType === "csv" ? {} : { responseType: "blob" };
  return axios({
    method: "GET",
    url: `${reporting}/views/${reportId}/data/${
      fileType === "csv" ? "file" : "filebuffered"
    }?fileType=${fileType}`,
    ...responseType,
    withCredentials: true,
    headers: {
      pxclient: config.clientId,
      pxcustomer: customerId,
    },
  })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((error) => {
      if (error.response) {
        // Request made and server responded
        onError(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        onError(error.request);
      } else {
        // Something happened while setting up the request that triggered an Error
        onError(error.message);
      }
    });
};

export default callViewExportFile;
