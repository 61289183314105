import React, { useState } from "react";
import icons from "../icons";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Header,
  Body,
  Footer,
  FooterItem,
  Close,
} from "@zendeskgarden/react-modals";
import {
  Dropdown,
  Field,
  Menu,
  Item,
  Select,
} from "@zendeskgarden/react-dropdowns";
import Button from "./Button";
import FormRow from "./FormRow";
import useProfiles from "../utils/useProfiles";
import Loader from "./Loader";
import Error from "./Error";
import domPurifyWithHooks from "../utils/adjustLinks";
import DOMPurify from "dompurify";
import callParticipations from "../utils/callParticipations";
import { useToast } from "@zendeskgarden/react-notifications";
import Notification from "./Notification";
import callProfile from "../utils/callProfile";
import { useNavigate } from "react-router-dom";

const CycleActivationModal = ({ id, title, setModalId }) => {
  const { t } = useTranslation("common");
  const { addToast } = useToast();
  const navigate = useNavigate();

  const [selectedItem, setSelectedItem] = useState(null);

  const {
    data: profiles,
    isLoading: profilesIsLoading,
    isError: profilesIsError,
  } = useProfiles(id);

  domPurifyWithHooks(DOMPurify);

  const selectProfile = ({ profileId, participationId }) => {
    callProfile({
      profileId: profileId,
      participationId: participationId,
      onSuccess: () => {
        setModalId(null);
        navigate(`/cycle/${id}`);
      },
      onError: (error) => {
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Something-went-wrong")}
              text={t("error.Failed-to-select-profile")}
              errorText={error}
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
    });
  };

  const handleSubmit = () => {
    callParticipations({
      id: id,
      onSuccess: (res) => {
        selectProfile({
          profileId: selectedItem.value,
          participationId: res.id,
        });
      },
      onError: (error) => {
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Something-went-wrong")}
              text={t("error.Failed-to-activate-loyalty-cycle")}
              errorText={error}
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
    });
  };

  return (
    <Modal onClose={() => setModalId(null)} appendToNode={document.body}>
      <Header>
        <h3>
          {t("ui.Activate-loyalty-cycle")}: <b>{title}</b>
        </h3>
      </Header>
      <Body style={{ overflow: "visible" }}>
        {profilesIsLoading ? (
          <Loader />
        ) : profilesIsError ? (
          <Error errorObj={profilesIsError} />
        ) : (
          <>
            <FormRow label={t("ui.Profile")} multiField>
              {profiles && profiles.length ? (
                <Dropdown
                  selectedItem={selectedItem}
                  onSelect={(item) => setSelectedItem(item)}
                  downshiftProps={{
                    itemToString: (item) => item && item.label,
                  }}
                >
                  <Field>
                    <Select>{selectedItem?.label}</Select>
                  </Field>
                  <Menu>
                    {profiles.map((item) => (
                      <Item
                        key={item.id}
                        value={{
                          value: item.id,
                          label: item.name,
                          description: item.description,
                        }}
                      >
                        {item.name}
                      </Item>
                    ))}
                  </Menu>
                </Dropdown>
              ) : (
                <p
                  style={{
                    opacity: ".7",
                    paddingTop: ".72em",
                  }}
                >
                  {t("ui.No-profiles-available")}
                </p>
              )}
              {selectedItem && selectedItem.description ? (
                <div
                  style={{ marginTop: "1em" }}
                  dangerouslySetInnerHTML={
                    !!selectedItem &&
                    !!selectedItem.description && {
                      __html: DOMPurify.sanitize(selectedItem.description, {
                        USE_PROFILES: { html: true },
                      }),
                    }
                  }
                />
              ) : null}
            </FormRow>
          </>
        )}
      </Body>
      <Footer className="modal-footer">
        <FooterItem>
          <Button type="button" onClick={() => setModalId(null)} outline>
            {icons.close}
            <span>{t("ui.Cancel")} </span>
          </Button>
        </FooterItem>
        <FooterItem className="modal-footer-item">
          <Button primary onClick={handleSubmit} disabled={!selectedItem}>
            {icons.check}
            <span>{t("ui.Activate")}</span>
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label={t("my-account.Close")} />
    </Modal>
  );
};

export default CycleActivationModal;
