import React, { useState, useEffect } from "react";
import { Dropdown, Menu, Item, Trigger } from "@zendeskgarden/react-dropdowns";
import { useTranslation } from "react-i18next";
import config from "../../config";
import { BsTranslate } from "react-icons/bs";
import styled from "styled-components";
import Button from "../Button";

const SLangSwitch = styled.div`
  &.btn-only {
    .lang-dropdown {
      font-size: 13px;
    }
  }
  .trigger-wrap {
    button {
      padding-right: 4px;
      vertical-align: -4px;
    }
  }
`;

const LanguageSwitch = () => {
  const { t, i18n } = useTranslation("common");
  const [selectedItem, setSelectedItem] = useState({
    label: t(`lang.${i18n.language}`),
    value: i18n.language,
  });
  useEffect(() => {
    localStorage.setItem("language", selectedItem.value);
  }, [selectedItem]);

  const handleSelect = (item) => {
    setSelectedItem(item);
    i18n.changeLanguage(item.value);
  };

  useEffect(() => {
    setSelectedItem({
      label: t(`lang.${i18n.language}`),
      value: i18n.language,
    });
  }, [i18n.language, t]);

  return (
    <SLangSwitch className="btn-only">
      <Dropdown
        selectedItem={selectedItem}
        onSelect={handleSelect}
        downshiftProps={{
          itemToString: (item) => item && item.label,
        }}
      >
        <Trigger>
          <div className="trigger-wrap">
            <Button link bigger>
              <BsTranslate title={t("my-account.Language")} />{" "}
            </Button>
          </div>
        </Trigger>
        <Menu
          className={`lang-dropdown dropdown-min-width`}
          hasArrow
          placement="bottom-end"
        >
          {config.lang.map((item) => (
            <Item
              key={item}
              value={{
                label: t(`lang.${item}`),
                value: item,
              }}
            >
              {t(`lang.${item}`)}
            </Item>
          ))}
        </Menu>
      </Dropdown>
    </SLangSwitch>
  );
};

export default LanguageSwitch;
