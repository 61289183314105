import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import icons from "../../icons";
import Card from "../Card";
import ContactText from "../ContactText";
import Layout from "../parts/Layout";
import styled from "styled-components";
import Link from "../Link";
import { opacify } from "polished";
import { VOLVO } from "../../constants";
import config from "../../config";
import toolsItems from "../../content/ToolsSubMenu";
import useCustomer from "../../utils/useCustomer";
import { isExternalLink } from "../../utils/isExternalLink";
import { BsBoxArrowUpRight } from "react-icons/bs";
import useStoredSession from "../../utils/useStoredSession";
import FAQList from "../FAQList";

const SList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  gap: 1em;
  li {
    width: 100%;
    text-align: center;
    flex-grow: 1;
    flex-shrink: 0;
  }
  a {
    border: none;
    padding: 4em 1em;
    background: ${(props) => opacify(-0.95, props.theme.accentColor)};
    position: relative;
    display: block;
    transition: 0.2s background;
    text-decoration: none;
    height: 100%;
    &:hover {
      background: ${(props) => opacify(-0.9, props.theme.accentColor)};
    }
    svg:not(:first-child):last-child {
      font-size: inherit;
      vertical-align: 10%;
      margin-left: 0.4em;
    }
    .link-text {
      font-size: 1.15em;
      margin-top: 0.25em;
      display: block;
    }
    svg:first-child {
      display: block;
      width: 3em;
      height: auto;
      margin: auto;
    }
    svg:not(:first-child):last-child {
      position: absolute;
      top: 0;
      right: 0;
      margin: 1em;
    }
  }
  @media (min-width: 992px) {
    li {
      flex-basis: 36%;
    }
  }
`;

const Tools = () => {
  const { t } = useTranslation("common");
  const titleTools =
    config.clientCode === VOLVO ? t("menu-Volvo.Tools") : t("menu.Tools");

  const session = useStoredSession();
  const { data: customer } = useCustomer();

  return (
    <Layout
      main={
        <Card shadow title={titleTools} icon={icons.tools}>
          <SList className="icon-list">
            {toolsItems?.children?.map(
              ({ title, url, icon, urlParams = () => "" }) => (
                <li key={url} style={{ marginBottom: 0 }}>
                  <Link
                    to={`${url}${urlParams({
                      customerCode: customer?.code,
                      sessionGuid: session,
                      url,
                    })}`}
                    {...(isExternalLink(url)
                      ? { target: "_blank", rel: "noopener noreferrer" }
                      : null)}
                  >
                    {icon}{" "}
                    <span className="link-text">{t(`menu.${title}`)}</span>
                    {isExternalLink(url) ? (
                      <BsBoxArrowUpRight size="1em" />
                    ) : null}
                  </Link>
                </li>
              )
            )}
          </SList>
        </Card>
      }
      sidebar={
        <Fragment>
          <Card
            title={t("ui.Contact")}
            children={<ContactText />}
            icon={icons.contact}
            shadow
          />
          <Card
            title={t("menu.FAQ")}
            children={<FAQList />}
            icon={icons.faq}
            withBg
            shadow
          />
        </Fragment>
      }
      pageTitle={titleTools}
    />
  );
};

export default Tools;
