import React from "react";
import Link from "./Link";
import bannerEssilorNL from "../assets/img/essilorNL/banner.jpg";
import bannerEssilorBE from "../assets/img/essilorBE/banner.jpg";
import bannerOogwereld from "../assets/img/oogwereld/banner.jpg";
import bannerPortalUK from "../assets/img/portalUK/banner.jpg";
import bannerTematica from "../assets/img/tematica/banner.jpg";
import bannerVolvo from "../assets/img/volvo/banner.jpg";
import bannerOMP from "../assets/img/OMP/banner.jpg";
import bannerBikeNL from "../assets/img/bikeNL/banner.jpg";
import bannerAcousticsNL from "../assets/img/acousticsNL/banner.jpg";
import bannerRodenstock from "../assets/img/rodenstock/banner.jpg";
import bannerEyecare from "../assets/img/eyecare/banner.jpg";
import bannerEGSDE from "../assets/img/EGSDE/banner.jpg";
import {
  ESSILORNL,
  ESSILORBE,
  OOGWERELDNL,
  OOGWERELDBE,
  PORTALUK,
  TEMATICA,
  VOLVO,
  OMP,
  BIKENL,
  ACOUSTICSNL,
  RODENSTOCKDE,
  EYECARENL,
  EGSDE,
  TEST,
} from "../constants";
import config from "../config";

const selectBanner = (clientName) => {
  switch (clientName) {
    case TEST:
    case ESSILORNL:
      return bannerEssilorNL;
    case ESSILORBE:
      return bannerEssilorBE;
    case RODENSTOCKDE:
      return bannerRodenstock;
    case OOGWERELDNL:
    case OOGWERELDBE:
      return bannerOogwereld;
    case PORTALUK:
      return bannerPortalUK;
    case TEMATICA:
      return bannerTematica;
    case VOLVO:
      return bannerVolvo;
    case OMP:
      return bannerOMP;
    case BIKENL:
      return bannerBikeNL;
    case ACOUSTICSNL:
      return bannerAcousticsNL;
    case EYECARENL:
      return bannerEyecare;
    case EGSDE:
      return bannerEGSDE;
    default:
      return null;
  }
};

const HomeBanner = () => {
  const bannerImg = selectBanner(config.clientCode);

  return bannerImg ? (
    <Link to="/campaigns" className="banner-wrapper">
      <img src={bannerImg} alt="Banner" />
    </Link>
  ) : null;
};

export default HomeBanner;
