import React from "react";
import Card from "../Card";
import Layout from "../parts/Layout";
import OrderList from "../OrderList";
import Error from "../Error";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";
import CycleIcon from "../CycleIcon";
import IconList from "../IconList";
import HomeBanner from "../HomeBanner";
import CycleListItem from "../CycleListItem";
import usePrograms from "../../utils/usePrograms";
import icons from "../../icons";
import ImageBox from "../ImageBox";
import Carousel from "../Carousel";
import Link from "../Link";
import Badge from "../Badge";
import strToColor from "../../utils/strToColor";
import FAQList from "../FAQList";
import Button from "../Button";
import useParticipations from "../../utils/useParticipations";
import useOrders from "../../utils/useOrders";
import ContactText from "../ContactText";
import domPurifyWithHooks from "../../utils/adjustLinks";
import DOMPurify from "dompurify";
import { VOLVO } from "../../constants";
import config from "../../config";

const CyclesBlock = () => {
  const { t } = useTranslation("common");
  const {
    data: participations,
    isLoading: participationsIsLoading,
    isError: participationsIsError,
  } = useParticipations({
    params: {
      programType: "cycle",
    },
  });
  const titleCycles =
    config?.clientCode === VOLVO
      ? t("ui.My-loyalty-cycles-Volvo")
      : t("ui.My-loyalty-cycles");

  const myCycles = !!participations
    ? participations.map((item) => item.program)
    : [];

  if (participationsIsLoading)
    return (
      <Card
        title={titleCycles}
        children={<Loader />}
        icon={icons.campaigns}
        shadow
      />
    );

  if (participationsIsError)
    return (
      <Card
        title={titleCycles}
        children={<Error errorObj={participationsIsError} />}
        icon={icons.campaigns}
        shadow
      />
    );

  return (
    <Card title={titleCycles} icon={<CycleIcon />} shadow withBg>
      <IconList>
        {!!myCycles && !!myCycles.length ? (
          myCycles.map((item, i) => (
            <CycleListItem key={i + "homeCycles" + item.id} item={item} />
          ))
        ) : (
          <div style={{ paddingLeft: ".3em", opacity: ".7" }}>
            {t("ui.No-active-cycles")}
          </div>
        )}
      </IconList>
      <div className="bottom-btn-wrap">
        <Link to="/cycles">
          <Button link>
            {config?.clientCode === VOLVO
              ? t("ui.All-loyalty-cycles-Volvo")
              : t("ui.All-loyalty-cycles")}
            {icons.chevronRight}
          </Button>
        </Link>
      </div>
    </Card>
  );
};

const Sidebar = ({ orders, isLoading, isError }) => {
  const { t } = useTranslation("common");

  return (
    <React.Fragment>
      <Card
        title={t("ui.My-orders")}
        children={
          isLoading ? (
            <Loader />
          ) : isError ? (
            <Error errorObj={isError} />
          ) : (
            <OrderList orders={orders} />
          )
        }
        icon={icons.orders}
        shadow
        withBg
      />
      <Card
        title={t("ui.Contact")}
        children={<ContactText />}
        icon={icons.contact}
        shadow
      />
      <Card
        title={t("menu.FAQ")}
        children={<FAQList />}
        icon={icons.faq}
        withBg
        shadow
      />
    </React.Fragment>
  );
};

const MainContent = () => {
  const { t } = useTranslation("common");

  const {
    data: programs,
    isLoading,
    isError,
  } = usePrograms({
    filter: "orderable",
    type: "campaign",
    pageSize: 5,
    orderBy: "slider",
  });

  if (isLoading)
    return (
      <Card
        title={t("ui.One-off-campaigns")}
        children={<Loader />}
        icon={icons.campaigns}
        shadow
      />
    );

  if (isError)
    return (
      <Card
        title={t("ui.One-off-campaigns")}
        children={<Error errorObj={isError} />}
        icon={icons.campaigns}
        shadow
      />
    );

  const sliderItems = programs?.length ? programs : [];

  domPurifyWithHooks(DOMPurify);

  return (
    <React.Fragment>
      {sliderItems && sliderItems.length ? (
        <Card
          title={
            config?.clientCode === VOLVO
              ? t("menu-Volvo.Campaigns")
              : t("ui.One-off-campaigns")
          }
          icon={icons.campaigns}
          noPadding
          shadow
          overflowHidden
          headerRight={
            <Link to="/campaigns">
              <Button link>
                {t("ui.All-campaigns")}
                {icons.chevronRight}
              </Button>
            </Link>
          }
        >
          <Carousel
            autoPlay
            interval={5000}
            infiniteLoop
            showStatus={false}
            showThumbs={false}
          >
            {sliderItems.map((item) => (
              <div
                className={sliderItems.length === 1 ? "single-item" : ""}
                key={item.id}
              >
                <Link
                  to={`/campaign/${item.id}`}
                  className="carousel-slide hover-zoom"
                >
                  <div className="carousel-thumb-wrap">
                    <ImageBox
                      id={item.thumbnailFileId}
                      params={{
                        Width: 350,
                        Height: 350,
                      }}
                      boxHeight="100%"
                    />
                  </div>
                  <div className="carousel-legend">
                    {item.theme && (
                      <Badge background={strToColor("theme", 60, 85)}>
                        {item.theme}
                      </Badge>
                    )}
                    <h3 className="carousel-title">
                      {item.marketingName || item.name || "..."}
                    </h3>
                    {item.description && (
                      <div
                        className="carousel-descr"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            item?.tooltip || item?.description,
                            {
                              USE_PROFILES: { html: true },
                            }
                          ),
                        }}
                      />
                    )}
                  </div>
                </Link>
              </div>
            ))}
          </Carousel>
        </Card>
      ) : null}
      <CyclesBlock />
    </React.Fragment>
  );
};

const Home = () => {
  const { t } = useTranslation("common");

  const {
    data: ordersWithHeaders,
    isLoading: ordersIsLoading,
    isError: ordersIsError,
  } = useOrders({ pageSize: 5 });

  const orders = !!ordersWithHeaders ? ordersWithHeaders.data : [];
  const titleDashboard =
    config.clientCode === VOLVO
      ? t("menu-Volvo.Dashboard")
      : t("menu.Dashboard");

  return (
    <React.Fragment>
      <Layout
        main={<MainContent />}
        sidebar={
          <Sidebar
            orders={orders}
            isLoading={ordersIsLoading}
            isError={ordersIsError}
          />
        }
        banner={<HomeBanner />}
        pageTitle={titleDashboard}
      />
    </React.Fragment>
  );
};

export default Home;
