import useSWR from "swr";
import fetcher from "./fetcher";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const useMpProperties = ({ mpId = "", shouldFetch = () => true }) => {
  const cacheKey = `${phoenix}/marketingprofiles/${mpId}/properties`;
  const { data, error, mutate } = useSWR(
    () => (shouldFetch() ? cacheKey : null),
    fetcher,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
    }
  );
  return {
    data,
    isLoading: !error && !data,
    error,
    mutate,
  };
};

export default useMpProperties;
