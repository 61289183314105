import config from "../config";

const vddnURLParams = ({ customerCode, sessionGuid, url = "" }) => {
  const isViewer = url.indexOf("/vddnviewer") > -1;
  const isAfleverDatum = url.indexOf("/afleverdatumbekend") > -1;
  const sessionParam = isViewer ? "sessionGuid" : "token";
  const clientObj = isViewer
    ? { clientId: config.clientId }
    : isAfleverDatum
    ? { clientUid: config.clientId }
    : {};

  const params = {
    ...clientObj,
    dealernummer: customerCode,
    [sessionParam]: sessionGuid,
  };

  return customerCode && sessionGuid
    ? `?${new URLSearchParams(params).toString()}`
    : "";
};

export default vddnURLParams;
