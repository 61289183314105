import axios from "axios";
import config from "../config";

const fetcherWithHeaders = ({ url, multiCustomer = false, method = "GET" }) => {
  const customerId = localStorage.getItem("customerId");
  return axios(url, {
    method: method,
    withCredentials: true,
    pxclient: config.clientId,
    headers: multiCustomer
      ? { pxclient: config.clientId }
      : {
          pxclient: config.clientId,
          pxcustomer: customerId,
        },
  }).then((res) => {
    return {
      data: res?.data,
      headers: {
        ...res.headers,
      },
    };
  });
};

export default fetcherWithHeaders;
