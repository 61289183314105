import React from "react";
import { Row, Col } from "styled-bootstrap-grid";
import { Field, Label } from "@zendeskgarden/react-forms";
import styled from "styled-components";

const SFormRow = styled.div`
  &:not(:last-child) {
    margin-bottom: ${(props) => (props.noMargin ? 0 : "1.2em")};
  }
`;

const FormRow = ({
  label,
  htmlLabel,
  children,
  multiField,
  noMargin,
  labelSizeSm = 3,
}) => (
  <SFormRow className="row-wrap" noMargin={noMargin}>
    {multiField ? (
      <Row>
        <Col sm={labelSizeSm}>
          {!!label && <Label className="grid-form-label">{label}</Label>}
        </Col>
        <Col sm={12 - labelSizeSm}>{children}</Col>
      </Row>
    ) : (
      <Field>
        <Row>
          <Col sm={labelSizeSm}>
            {!!label && <Label className="grid-form-label">{label}</Label>}
          </Col>
          <Col sm={12 - labelSizeSm}>{children}</Col>
        </Row>
      </Field>
    )}
  </SFormRow>
);

export default FormRow;
