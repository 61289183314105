import axios from "axios";
import config from "../config";
import apiConfig from "../apiConfig";

const { order } = apiConfig;

const callOrderItem = ({
  orderId,
  orderItemId,
  method = "PUT",
  data = {},
  onSuccess,
  onError,
  headers = {},
}) => {
  const customerId = localStorage.getItem("customerId");

  return axios({
    method: method,
    url: `${order}/orders/${orderId}/items${
      !!orderItemId ? "/" + orderItemId : ""
    }`,
    data: data,
    withCredentials: true,
    headers: {
      ...headers,
      pxclient: config.clientId,
      pxcustomer: customerId,
    },
  })
    .then((res) => {
      !!onSuccess && onSuccess(res?.data);
    })
    .catch((error) => {
      if (!!error.response) {
        onError(
          !!error.response.data && !!error.response.data[0]
            ? error.response.data[0]
            : ""
        );
      } else if (!!error.request) {
        onError(!!error.message ? error.message : "");
      } else {
        onError(!!error.message ? error.message : "");
      }
    });
};

export default callOrderItem;
