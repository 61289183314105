import styled from "styled-components";

const ButtonWrap = styled.div`
  text-align: right;
  margin-top: 1em;
  button {
    vertical-align: middle;
  }
  button + button {
    margin-left: 1em;
  }
`;

export default ButtonWrap;
