import styled from "styled-components";
import { opacify, desaturate, lighten } from "polished";

const AddressBox = styled.div`
  background: ${(props) => opacify(-0.5, props.theme.mediumLightBg)};
  transition: background 0.2s;
  opacity: ${(props) => (props.isLoading ? ".5" : 1)};
  padding: 1em;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
  &.hover-box:hover,
  &.active {
    background: ${(props) => opacify(-0.8, props.theme.accentColor)};
    cursor: pointer;
    .badge-absolute {
      background: ${({ theme }) =>
        lighten(0.3, desaturate(0.4, theme.accentColor))};
    }
  }
  .logo {
    width: auto;
    max-width: 100%;
    margin: auto;
  }
  .box-footer {
    h3 {
      padding-top: 0.8em;
    }
  }
  .badge-absolute {
    font-weight: 600;
    position: absolute;
    top: -5px;
    left: -3px;
    font-size: 1.25em;
    display: inline-block;
    line-height: 0.8;
    color: white;
    transition: background 0.2s;
    background: ${({ theme }) =>
      lighten(0.4, desaturate(0.6, theme.accentColor))};
  }
`;

export default AddressBox;
