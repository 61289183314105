const filterBy = (arr, propsArr = ["text"], q) =>
  arr.filter((item) =>
    propsArr
      .map(
        (prop) =>
          !!item[prop] && item[prop].toLowerCase().indexOf(q.toLowerCase()) > -1
      )
      .some((el) => el === true)
  );

export default filterBy;
