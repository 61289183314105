import {
  ESSILORNL,
  ESSILORBE,
  OOGWERELDNL,
  OOGWERELDBE,
  PORTALUK,
  TEMATICA,
  VOLVO,
  OMP,
  BIKENL,
  ACOUSTICSNL,
  RODENSTOCKDE,
  EYECARENL,
  EGSDE,
  TEST,
} from "../constants";
import faqEssilorNL from "../content/essilorNL/faqItemsArray.json";
import faqEssilorBE from "../content/essilorBE/faqItemsArray.json";
import faqOogwereldNL from "../content/oogwereldNL/faqItemsArray.json";
import faqOogwereldBE from "../content/oogwereldBE/faqItemsArray.json";
import faqPortalUK from "../content/portalUK/faqItemsArray.json";
import faqTematica from "../content/tematica/faqItemsArray.json";
import faqVolvo from "../content/volvo/faqItemsArray.json";
import faqOMP from "../content/OMP/faqItemsArray.json";
import faqBikeNL from "../content/bikeNL/faqItemsArray.json";
import faqAcousticsNL from "../content/acousticsNL/faqItemsArray.json";
import faqRodenstock from "../content/rodenstock/faqItemsArray.json";
import faqEyecareNL from "../content/eyecareNL/faqItemsArray.json";
import faqEGSDE from "../content/EGSDE/faqItemsArray.json";

const getFAQs = (clientName) => {
  switch (clientName) {
    case TEST:
    case ESSILORNL:
      return faqEssilorNL;
    case ESSILORBE:
      return faqEssilorBE;
    case RODENSTOCKDE:
      return faqRodenstock;
    case OOGWERELDNL:
      return faqOogwereldNL;
    case OOGWERELDBE:
      return faqOogwereldBE;
    case PORTALUK:
      return faqPortalUK;
    case TEMATICA:
      return faqTematica;
    case VOLVO:
      return faqVolvo;
    case OMP:
      return faqOMP;
    case BIKENL:
      return faqBikeNL;
    case ACOUSTICSNL:
      return faqAcousticsNL;
    case EYECARENL:
      return faqEyecareNL;
    case EGSDE:
      return faqEGSDE;
    default:
      return null;
  }
};

export default getFAQs;
