import {
  Body,
  Footer,
  FooterItem,
  Header,
  Modal,
} from "@zendeskgarden/react-modals";
import { useTranslation } from "react-i18next";
import React from "react";
import Button from "../Button";
import icons from "../../icons";
import { Field, Input } from "@zendeskgarden/react-forms";

const SendEmailSignatureModal = ({ onSubmit, onClose, setEmail, email }) => {
  const { t } = useTranslation("common");

  const onTextChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <Modal
      aria-label="send-email-signature-modal"
      onClose={() => onClose}
      appendToNode={document.body}
    >
      <Header>
        <h3>{t("ui.Send-email-signature-to")}</h3>
      </Header>
      <Body>
        <form>
          <div className="row-wrap">
            <Field title="email-for-email-signature">
              <Input
                name="email-destination"
                isResizable
                rows={2}
                value={!!email && email}
                onChange={onTextChange}
              />
            </Field>
          </div>
        </form>
      </Body>
      <Footer className="modal-footer mob-btn">
        <FooterItem className="modal-footer-item">
          <Button
            className="hide-label-xs"
            type="button"
            onClick={() => onClose()}
            outline
            wide
          >
            {icons.close}
            <span className="btn-label-hidden-xs">{t("ui.Cancel")}</span>
          </Button>
        </FooterItem>
        <FooterItem className="modal-footer-item">
          <Button
            className="hide-label-xs"
            $success
            wide
            type="submit"
            onClick={onSubmit}
          >
            {icons.check}
            <span className="btn-label-hidden-xs">{t("ui.Send")}</span>
          </Button>
        </FooterItem>
      </Footer>
    </Modal>
  );
};

export default SendEmailSignatureModal;
