import icons from "../icons";
import HyvesIcon from "../components/HyvesIcon";

const getIconFromString = (str, altIcon) => {
  let newStr = str;
  switch (str) {
    case "Telefoon nr.":
      newStr = "phone";
      break;
    case "Mobiel nr.":
      newStr = "phone";
      break;
    case "Afspraak url":
      newStr = "calendar";
      break;
    default:
      break;
  }

  let icon = !!altIcon ? altIcon : icons.link;
  if (newStr.toLowerCase().indexOf("hyves") > -1) {
    return <HyvesIcon />;
  }
  for (const item in icons) {
    if (newStr.toLowerCase().indexOf(item) > -1) {
      icon = icons[item];
    }
  }
  return icon;
};

export default getIconFromString;
