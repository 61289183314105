import React from "react";
import styled from "styled-components";
import Link from "./Link";
import Error from "./Error";
import Loader from "./Loader";
import groupByPath from "../utils/groupByPath";
import { useTranslation } from "react-i18next";
import useViews from "../utils/useViews";

const SNestedReportsList = styled.div`
  h4,
  h3 {
    margin-top: 1.25em;
    margin-bottom: 0.5em;
  }
  .nested-link {
    display: block;
    margin: 0.5em 0;
  }
  .cat-wrap {
    .nested-link {
      padding-left: 0.5em;
    }
    .cat-wrap {
      h4,
      h3 {
        padding-left: 0.5em;
      }
      .nested-link {
        padding-left: 1em;
      }
      .cat-wrap {
        h4,
        h3 {
          padding-left: 1em;
        }
        .nested-link {
          padding-left: 1.5em;
        }
        .cat-wrap {
          h4,
          h3 {
            padding-left: 1.5em;
          }
          .nested-link {
            padding-left: 2em;
          }
          .cat-wrap {
            h4,
            h3 {
              padding-left: 2em;
            }
            .nested-link {
              padding-left: 2.5em;
            }
            .cat-wrap {
              h4,
              h3 {
                padding-left: 2.5em;
              }
              .nested-link {
                padding-left: 3em;
              }
            }
          }
        }
      }
    }
    & > div:first-child > div > .nested-link {
      padding-left: 0;
    }
  }
`;

const NestedReportsItem = ({ item, itemKey }) => {
  const childKeys = Object.keys(item).filter(
    (key) => key !== "nestedItems" && key !== "nestedItemPath"
  );
  return (
    <div className="cat-wrap">
      {itemKey !== "/" ? <h3>{itemKey}</h3> : null}

      {!!item.nestedItems.length ? (
        <div>
          {item.nestedItems.map((childItem) => (
            <div key={childItem.id}>
              <Link
                className="nested-link"
                to={`/${
                  childItem.chartType === "quickSight"
                    ? "report"
                    : "action-list"
                }/${childItem.reportId}/${childItem.id}`}
              >
                {childItem.name}
              </Link>
            </div>
          ))}
        </div>
      ) : null}

      {!!childKeys.length
        ? childKeys.map((innerItem) => (
            <NestedReportsItem
              key={innerItem}
              itemKey={innerItem}
              item={item[innerItem]}
            />
          ))
        : null}
    </div>
  );
};

const ReportsList = ({ chartType }) => {
  const { t } = useTranslation("common");

  const {
    data: reports,
    isLoading: reportsIsLoading,
    isError: reportsIsError,
  } = useViews({ chartType: chartType });

  const nestedObj = groupByPath(!!reports?.length ? reports : []);

  return reportsIsLoading ? (
    <Loader />
  ) : reportsIsError ? (
    <Error errorObj={reportsIsError} />
  ) : reports.length ? (
    <SNestedReportsList>
      {Object.keys(nestedObj)
        .sort()
        .map((key) => (
          <NestedReportsItem key={key} itemKey={key} item={nestedObj[key]} />
        ))}
    </SNestedReportsList>
  ) : (
    t(
      chartType === "quickSight"
        ? "ui.No-reports-available"
        : "ui.No-action-lists-available"
    )
  );
};

export default ReportsList;
