import useSWR from "swr";
import fetcher from "./fetcher";
import apiConfig from "../apiConfig";

const { reporting } = apiConfig;

const useViewPage = ({
  shouldFetch = () => true,
  reportId,
  page = 1,
  pageSize = 1000,
  pageFilters = [],
  search = "",
}) => {
  const filterStr =
    pageFilters.map((item) => item.filters).join().length > 0
      ? "&" +
        pageFilters
          .map((item) =>
            item.filters
              .map(
                (filter) =>
                  `filter=${encodeURI(item.colId)}:${encodeURI(filter)}`
              )
              .join("&")
          )
          .join("&")
      : "";
  const cacheKey = `${reporting}/views/${reportId}/data?page=${page}&pageSize=${pageSize}${filterStr}&search=${search}`;
  
  const shouldFetchResult = shouldFetch();
  const { data, error } = useSWR(
    () => (shouldFetch() ? cacheKey : null),
    fetcher,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
    }
  );

  const isLoading = shouldFetchResult && !data && !error;

  return {
    data: data,
    isLoading: isLoading,
    isError: error,
    cacheKey: cacheKey,
  };
};

export default useViewPage;
