import React, { useContext, useEffect } from "react";
import Card from "../Card";
import Layout from "../parts/Layout";
import CollapsibleBlock from "../CollapsibleBlock";
import styled from "styled-components";
import CardList from "../CardList";
import Link from "../Link";
import { Col } from "styled-bootstrap-grid";
import ContactText from "../ContactText";
import OrderList from "../OrderList";
import groupBy from "../../utils/groupBy";
import filterBy from "../../utils/filterBy";
import ImageBox from "../ImageBox";
import useOrders from "../../utils/useOrders";
import Error from "../Error";
import Loader from "../Loader";
import strToColor from "../../utils/strToColor";
import icons from "../../icons";
import { useTranslation } from "react-i18next";
import Badge from "../Badge";
import SearchBox from "../SearchBox";
import { SearchContext } from "../SearchProvider";
import useInfinite from "../../utils/useInfinite";
import Breadcrumbs from "../parts/Breadcrumbs";
import apiConfig from "../../apiConfig";
import { VOLVO } from "../../constants";
import config from "../../config";

const { phoenix: phoenixApi } = apiConfig;

const AccordionWrapper = styled.div`
  .item-wrapper:not(:last-child) {
    margin-bottom: 0.5em;
    overflow: hidden;
  }
  h2 span {
    font-weight: normal;
  }
`;

const MainContent = () => {
  const { t } = useTranslation("common");
  const titleCampaigns =
    config?.clientCode === VOLVO
      ? t("menu-Volvo.Campaigns")
      : t("menu.Campaigns");
  const searchContext = useContext(SearchContext);

  const PAGE_SIZE = 30;

  const {
    data: programs,
    isLoading: programsIsLoading,
    isError: programsIsError,
    size: programsPage,
    setSize: setProgramsPage,
  } = useInfinite({
    key: `${phoenixApi}/programs`,
    pageSize: PAGE_SIZE,
    params: {
      filter: "orderable",
      type: "campaign",
      orderBy: "theme",
    },
  });

  const allPrograms = !!programs ? [].concat(...programs) : [];
  const programsIsLoadingInitialData = !programs && !programsIsError;
  const programsIsLoadingMore =
    programsIsLoadingInitialData ||
    (programsPage > 0 &&
      !!programs &&
      typeof programs[programsPage - 1] === "undefined");
  const programsIsEmpty = programs?.[0]?.length === 0;
  const programsIsReachingEnd =
    programsIsEmpty ||
    (!!programs && programs[programs.length - 1]?.length < PAGE_SIZE);

  useEffect(() => {
    if (
      !!programs &&
      !!programs[programsPage - 1] &&
      !programsIsEmpty &&
      !programsIsLoadingMore &&
      !programsIsReachingEnd
    ) {
      setProgramsPage(programsPage + 1);
    }
  }, [
    programs,
    programsPage,
    programsIsEmpty,
    programsIsLoadingMore,
    programsIsReachingEnd,
    setProgramsPage,
  ]);

  if (programsIsLoading || !programsIsReachingEnd)
    return (
      <Card
        title={titleCampaigns}
        children={<Loader />}
        icon={icons.campaigns}
        shadow
      />
    );

  if (programsIsError)
    return (
      <Card
        title={titleCampaigns}
        children={<Error errorObj={programsIsError} />}
        icon={icons.campaigns}
        shadow
      />
    );

  // Search
  const q = searchContext.campaignsFilter;
  const programsWithTheme = allPrograms.filter((item) => !!item.theme);
  const filteredItems = !!q.length
    ? filterBy(
        programsWithTheme,
        ["marketingName", "name", "title", "theme"],
        q
      )
    : programsWithTheme;
  const groupedByTheme =
    !!filteredItems &&
    !!filteredItems.length &&
    groupBy(filteredItems, "theme");

  return (
    <React.Fragment>
      <Card
        children={
          <SearchBox filter="campaignsFilter" setFilter="setCampaignsFilter" />
        }
        shadow
        className="hide-md-up"
      />
      <Card title={titleCampaigns} icon={icons.campaigns} shadow>
        <div className="border-bottom" style={{ paddingBottom: "1em" }}>
          <Breadcrumbs pageTitle={titleCampaigns} noMargin />
        </div>
        <AccordionWrapper>
          {!!programsWithTheme && !!programsWithTheme.length
            ? Object.keys(groupedByTheme).map((key, i) => (
                <div className="item-wrapper" key={i}>
                  <CollapsibleBlock
                    triggerText={
                      <h2>
                        <Badge
                          background={strToColor(
                            groupedByTheme[key].key,
                            60,
                            90
                          )}
                        >
                          {groupedByTheme[key].key
                            ? groupedByTheme[key].key
                            : "..."}
                        </Badge>
                      </h2>
                    }
                    open={i === 0}
                  >
                    <CardList>
                      {groupedByTheme[key].items.map((item, j) => (
                        <Col lg={4} md={6} sm={6} xs={12} key={j}>
                          <Link
                            to={`/campaign/${item.id}`}
                            className="no-decoration cursor-pointer hover-zoom"
                          >
                            <Card className="full-height" whiteBg>
                              <ImageBox
                                params={{
                                  Width: 200,
                                  Height: 200,
                                }}
                                id={item.thumbnailFileId}
                                boxHeight="100%"
                                className="hover-zoom"
                              />
                              <div className="card-text">
                                <h4>
                                  {item.marketingName || item.name || "..."}
                                </h4>
                              </div>
                            </Card>
                          </Link>
                        </Col>
                      ))}
                    </CardList>
                  </CollapsibleBlock>
                </div>
              ))
            : null}
        </AccordionWrapper>
      </Card>
    </React.Fragment>
  );
};

const Sidebar = () => {
  const {
    data: ordersWithHeaders,
    isLoading,
    isError,
  } = useOrders({ pageSize: 5 });
  const orders = !!ordersWithHeaders ? ordersWithHeaders.data : [];

  const { t } = useTranslation("common");

  return (
    <React.Fragment>
      <Card
        children={
          <SearchBox filter="campaignsFilter" setFilter="setCampaignsFilter" />
        }
        shadow
        className="hide-sm-down"
      />
      <Card
        title={t("ui.My-orders")}
        children={
          isLoading ? (
            <Loader />
          ) : isError ? (
            <Error errorObj={isError} />
          ) : (
            <OrderList orders={!!orders && !!orders.length ? orders : []} />
          )
        }
        icon={icons.orders}
        shadow
        withBg
      />
      <Card
        title={t("ui.Contact")}
        children={<ContactText />}
        icon={icons.contact}
        shadow
      />
    </React.Fragment>
  );
};

const Campaigns = () => {
  const { t } = useTranslation("common");
  const titleCampaigns =
    config.clientCode === VOLVO
      ? t("menu-Volvo.Campaigns")
      : t("menu.Campaigns");
  return (
    <React.Fragment>
      <Layout
        main={<MainContent />}
        sidebar={<Sidebar />}
        pageTitle={titleCampaigns}
      />
      ;
    </React.Fragment>
  );
};

export default Campaigns;
