import Detail from "./components/pages/Default";
import Home from "./components/pages/Home";
import Campaings from "./components/pages/Campaigns";
import FAQ from "./components/pages/FAQ";
import PageNotFound from "./components/pages/PageNotFound";
import Orders from "./components/pages/Orders";
import OrderWizard from "./components/OrderWizard/OrderWizard";
import CampaignDetail from "./components/pages/CampaignDetail";
import MyAccount from "./components/MyAccount/MyAccount";
import CycleDetail from "./components/pages/CycleDetail";
import Cycles from "./components/pages/Cycles";
import Calendar from "./components/pages/Calendar";
import ViewAL from "./components/pages/ViewAL";
import ReportsPage from "./components/pages/ReportsPage";
import ActionListsPage from "./components/pages/ActionListsPage";
import Variant from "./components/proofing/Variant";
import VariantList from "./components/pages/VariantList";
import DownloadsPage from "./components/pages/DownloadsPage";
import UploadTool from "./components/pages/UploadTool";
import SelectCustomer from "./components/pages/SelectCustomer";
import QSEmbed from "./components/pages/QSEmbed";
// import EmailSignatureUC from "./components/pages/EmailSignatureUC";
import GenerateLogos from "./components/GenerateLogos";
import EmailSignatureList from "./components/pages/EmailSignatureList";
import EmailSignature from "./components/proofing/EmailSignature";
import Tools from "./components/pages/Tools";
// import ToolsTest from "./components/pages/ToolsTest";

const routes = [
  {
    path: "/",
    element: Home,
  },
  {
    path: "/calendar",
    element: Calendar,
  },
  {
    path: "/campaigns",
    element: Campaings,
  },
  {
    path: "/cycles",
    element: Cycles,
  },
  {
    path: "/reporting",
    element: Detail,
  },
  {
    path: "/actions",
    element: Detail,
  },
  {
    path: "/my-account",
    element: MyAccount,
  },
  {
    path: "/my-account/:sectionId",
    element: MyAccount,
  },
  {
    path: "/orders",
    element: Orders,
  },
  {
    path: "/faq",
    element: FAQ,
  },
  {
    path: "/faq/:qId",
    element: FAQ,
  },
  {
    path: "/order/:orderId",
    element: OrderWizard,
  },
  {
    path: "/campaign/:campaignId",
    element: CampaignDetail,
  },
  {
    path: "/cycle/:cycleId",
    element: CycleDetail,
  },
  {
    path: "/cycle/:cycleId/mm/:mmId",
    element: CycleDetail,
  },
  {
    path: "/report/:qsReportId/:qsViewId",
    element: QSEmbed,
  },
  {
    path: "/report-qs/:qsReportId/:qsViewId",
    element: QSEmbed,
  },
  {
    path: "/action-list/:alReportId/:alViewId",
    element: ViewAL,
  },
  {
    path: "/reports",
    element: ReportsPage,
  },
  {
    path: "/action-lists",
    element: ActionListsPage,
  },
  {
    path: "/variant/:id",
    element: Variant,
  },
  {
    path: "/variants/:filter",
    element: VariantList,
  },
  {
    path: "/variants/:filter",
    element: VariantList,
  },
  {
    path: "/downloads",
    element: DownloadsPage,
  },
  {
    path: "/tools",
    element: Tools,
  },
  // {
  //   path: "/tools-dev",
  //   element: ToolsTest,
  // },
  // {
  //   path: "/tools/email-signature",
  //   element: EmailSignatureUC,
  // },
  {
    path: "/tools/email-signature",
    element: EmailSignatureList,
  },
  {
    path: "/tools/email-signature/:id",
    element: EmailSignature,
  },
  {
    path: "*",
    element: PageNotFound,
  },
];

export const publicRoutes = [
  {
    path: "/upload",
    element: UploadTool,
  },
  {
    path: "/select-customer",
    element: SelectCustomer,
  },
  {
    path: "/generate-logos",
    element: GenerateLogos,
  },
];

export default routes;
