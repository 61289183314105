import axios from "axios";
import config from "../config";
import apiConfig from "../apiConfig";

const { order } = apiConfig;

const submitOrder = ({ url, onSuccess, onError, headers }) => {
  const customerId = localStorage.getItem("customerId");
  return axios({
    method: "POST",
    url: `${order}${url}`,
    data: null,
    headers: {
      ...headers,
      pxclient: config.clientId,
      pxcustomer: customerId,
    },
    withCredentials: true,
  })
    .then((res) => {
      !!onSuccess && onSuccess(res?.data);
    })
    .catch((error) => {
      if (error.response) {
        onError(
          !!error.response.data && !!error.response.data.length > 0
            ? error.response.data.join("; ")
            : ""
        );
      } else if (error.request) {
        onError(error.request);
      } else {
        onError(error.message);
      }
    });
};

export default submitOrder;
