import axios from "axios";
import config from "../config";
import apiConfig from "../apiConfig";

const { order } = apiConfig;

const callOrder = ({
  id,
  url,
  method = "PUT",
  data = {},
  onSuccess,
  onError,
  headers = null,
}) => {
  const customerId = localStorage.getItem("customerId");
  return axios({
    method: method,
    url: !!url ? url : `${order}/orders${!!id ? "/" + id : ""}`,
    data: data,
    withCredentials: true,
    headers: {
      ...headers,
      pxclient: config.clientId,
      pxcustomer: customerId,
    },
  })
    .then((res) => {
      !!onSuccess && onSuccess(res?.data);
    })
    .catch((error) => {
      if (error.response) {
        onError(
          !!error.response.data && !!error.response.data.length > 0
            ? error.response.data.join("; ")
            : ""
        );
      } else if (error.request) {
        onError(error.request);
      } else {
        onError(error.message);
      }
    });
};

export default callOrder;
