import axios from "axios";
import config from "../config";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const callParticipations = ({
  id,
  method = "POST",
  shouldPost = true,
  onSuccess = () => console.log("Participation created"),
  onError = () => console.log("Error creating participation"),
  onFinished = () => null,
}) => {
  if (!shouldPost) {
    onFinished();
    return null;
  } else {
    const customerId = localStorage.getItem("customerId");
    return axios({
      method: method,
      url: `${phoenix}/participations`,
      data: {
        programId: id,
      },
      withCredentials: true,
      headers: {
        pxclient: config.clientId,
        pxcustomer: customerId,
      },
    })
      .then((res) => {
        !!onSuccess && onSuccess(res?.data);
      })
      .catch((error) => {
        if (error.response) {
          onError(
            !!error.response.data && !!error.response.data.length > 0
              ? error.response.data.join("; ")
              : "error"
          );
        } else if (error.request) {
          onError(error.request);
        } else {
          onError(error.message);
        }
      })
      .finally(() => onFinished());
  }
};

export default callParticipations;
