import React, { useState, createContext } from "react";

export const SearchContext = createContext({});

const SearchProvider = ({ children }) => {
  const [faqFilter, setFaqFilter] = useState("");
  const [campaignsFilter, setCampaignsFilter] = useState("");
  const [brandFilter, setBrandFilter] = useState("");
  const [variantsFilter, setVariantsFilter] = useState("");
  return (
    <SearchContext.Provider
      value={{
        faqFilter,
        setFaqFilter,
        campaignsFilter,
        setCampaignsFilter,
        brandFilter,
        setBrandFilter,
        variantsFilter,
        setVariantsFilter,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
