import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import icons from "../../icons";
import Card from "../Card";
import ContactText from "../ContactText";
import FAQList from "../FAQList";
import Layout from "../parts/Layout";
import ReportsList from "../ReportsList";
import {
  VOLVO,
} from "../../constants";
import config from "../../config";

const ActionListsPage = () => {
  const { t } = useTranslation("common");
  const titleActionlists = config.clientCode === VOLVO ? t("menu-Volvo.Action-lists") : t("menu.Action-lists");

  return (
    <Layout
      main={
        <Card shadow title={titleActionlists} icon={icons.actions}>
          <ReportsList chartType="actionList" />
        </Card>
      }
      sidebar={
        <Fragment>
          <Card
            title={t("ui.Contact")}
            children={<ContactText />}
            icon={icons.contact}
            shadow
          />
          <Card
            title={t("menu.FAQ")}
            children={<FAQList />}
            icon={icons.faq}
            withBg
            shadow
          />
        </Fragment>
      }
      pageTitle={titleActionlists}
    />
  );
};

export default ActionListsPage;
