import React, { useState, useEffect } from "react";
import Card from "../Card";
import Button, { ButtonWithRef } from "../Button";
import { Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";
import Badge from "../Badge";
import Link from "../Link";
import Loader from "../Loader";
import Error from "../Error";
import ImageBox from "../ImageBox";
import strToColor from "../../utils/strToColor";
import icons from "../../icons";
import { useTranslation } from "react-i18next";
import { opacify } from "polished";
import { Popover, ArrowContainer } from "react-tiny-popover";
import theme from "../../styles/theme";
import { MONTHS } from "../../constants";
import Layout from "../parts/Layout";
import useInfinite from "../../utils/useInfinite";
import Sidebar from "../parts/Sidebar";
import { IoCartSharp } from "react-icons/io5";
import apiConfig from "../../apiConfig";

const months = MONTHS;
const { order: orderApi } = apiConfig;
const { phoenix: phoenixApi } = apiConfig;

const SCalendarCard = styled(Card)`
  .calendar-year-control {
    min-width: 6em;
    text-align: center;
    display: inline-flex;
    justify-content: space-between;
    margin-right: 1em;
  }
  .header-left {
    max-width: 50%;
    h2 {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  @media (max-width: 600px) {
    .hide-mobile {
      display: none;
      & + svg {
        margin-left: 0 !important;
      }
    }
    .btn-print {
      padding-left: 0;
      padding-right: 0;
      width: 3em;
    }
    .card-header {
      flex-direction: row;
      flex-wrap: nowrap;
      .header-right {
        margin: -1em 0 -1em auto;
      }
    }
  }
  @media print {
    .print-4 {
      flex: 0 0 33.333333333333336%;
      max-width: 33.333333333333336%;
    }
  }
`;

const SCalendar = styled.div`
  padding: 0 16px;
  border-radius: ${(props) =>
    `0 0 ${props.theme.borderRadius} ${props.theme.borderRadius}`};
  overflow: hidden;
`;

const SMonthBox = styled.div`
  border: 1px solid #efefef;
  min-height: 14.9em;
  height: 100%;
  padding: 0px 2px;
  position: relative;
  transition: background 0.2s;
  background: ${(props) =>
    props.isSelected ? props.theme.lightBg : "transparent"};
  .month-wrapper {
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
    margin-bottom: 2px;
  }
  .add-item {
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
`;

const SCalenderItem = styled.div`
  height: 50px;
  overflow: hidden;
  width: 100%;
  background: ${(props) =>
    props.background ? props.background : props.theme.mediumLightBg};
  margin-bottom: 2px;
  box-sizing: border-box;
  border-radius: 4px;
  transition: all 0.4s;
  -webkit-print-color-adjust: exact;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background: ${(props) => opacify(-0.8, props.theme.accentColor)};
  }
  .link-wrap {
    display: block;
  }
  .img-wrap {
    width: 50px;
    float: left;
    overflow: hidden;
  }
  h4 {
    color: ${(props) => props.theme.headingColor};
    margin-left: 0.3em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 1em;
    width: 100%;
  }
  .text-box {
    margin-left: 50px;
    padding: 0.6em 1em 0.5em 0.6em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    flex-wrap: wrap;
    font-size: 0.95em;
    .arrow {
      line-height: 1em;
      opacity: 0.7;
    }
  }
`;

const SPopupCard = styled(Card)`
  box-shadow: 0px 0px 100px #00000066;
  margin-bottom: 0;
  z-index: 2;
  .card-body {
    padding: 1em;
  }
  .popup-card-inner {
    max-height: 414px;
    overflow-y: auto;
  }
`;

const MonthBox = ({
  programs,
  children,
  isFuture,
  selectedYear,
  monthIndex,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { t } = useTranslation("common");

  return (
    <SMonthBox isSelected={isPopoverOpen}>
      {children}
      <Popover
        isOpen={isPopoverOpen}
        positions={["top", "right", "left", "bottom"]}
        padding={2}
        onClickOutside={() => setIsPopoverOpen(false)}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={theme.lightBg}
            arrowSize={10}
            arrowStyle={{ zIndex: 999 }}
            className="popover-arrow-container fadeInGrow"
            arrowClassName="popover-arrow"
          >
            <SPopupCard
              enterEffect="none"
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              title={t("ui.Select-campaign")}
            >
              <div className="popup-card-inner">
                {programs.map((item) => (
                  <SCalenderItem
                    key={item.id}
                    className="hover-zoom hover-slide-right"
                  >
                    <Link
                      to={`/${
                        item.programType === "campaign" ? "campaign" : "cycle"
                      }/${item.id}?year=${selectedYear}&month=${monthIndex}`}
                      className="no-decoration link-wrap"
                    >
                      <div className="img-wrap">
                        <ImageBox
                          params={{
                            Width: 40,
                            Height: 40,
                          }}
                          id={item.thumbnailFileId}
                          boxHeight="100%"
                          icon={IoCartSharp}
                        />
                      </div>
                      <div className="text-box smaller">
                        <div className="smaller">
                          {item.theme ? (
                            <Badge
                              background={strToColor(item.theme, 60, 85)}
                              className="smaller"
                            >
                              {item.theme}
                            </Badge>
                          ) : (
                            <br />
                          )}
                          <h4>
                            {item.marketingName ||
                              item.name ||
                              item.title ||
                              "..."}
                          </h4>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </Link>
                  </SCalenderItem>
                ))}
              </div>
            </SPopupCard>
          </ArrowContainer>
        )}
      >
        <ButtonWithRef
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          secondary
          round
          className="add-item no-print"
          disabled={!programs.length || !isFuture}
        >
          {icons.add}
        </ButtonWithRef>
      </Popover>
    </SMonthBox>
  );
};

const Calendar = () => {
  const currentDate = new Date();
  const { t } = useTranslation("common");

  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());

  const PAGE_SIZE = 30;
  const {
    data: orders,
    isLoading: ordersIsLoading,
    isError: ordersIsError,
    size: ordersPage,
    setSize: setOrdersPage,
  } = useInfinite({
    key: `${orderApi}/orders`,
    pageSize: PAGE_SIZE,
    noCache: true,
  });

  const allOrders = !!orders ? [].concat(...orders) : [];
  const ordersIsLoadingInitialData = !orders && !ordersIsError;
  const ordersIsLoadingMore =
    ordersIsLoadingInitialData ||
    (ordersPage > 0 &&
      !!orders &&
      typeof orders[ordersPage - 1] === "undefined");
  const ordersIsEmpty = orders?.[0]?.length === 0;
  const ordersIsReachingEnd =
    ordersIsEmpty ||
    (!!orders && orders[orders.length - 1]?.length < PAGE_SIZE);

  const {
    data: programs,
    isLoading: programsIsLoading,
    isError: programsIsError,
    size: programsPage,
    setSize: setProgramsPage,
  } = useInfinite({
    key: `${phoenixApi}/programs`,
    pageSize: PAGE_SIZE,
    params: {
      filter: "orderable",
      type: "campaign",
      orderBy: "sequence",
    },
  });

  const allPrograms = !!programs ? [].concat(...programs) : [];
  const programsIsLoadingInitialData = !programs && !programsIsError;
  const programsIsLoadingMore =
    programsIsLoadingInitialData ||
    (programsPage > 0 &&
      !!programs &&
      typeof programs[programsPage - 1] === "undefined");
  const programsIsEmpty = programs?.[0]?.length === 0;
  const programsIsReachingEnd =
    programsIsEmpty ||
    (!!programs && programs[programs.length - 1]?.length < PAGE_SIZE);

  useEffect(() => {
    if (
      !!orders &&
      !!orders[ordersPage - 1] &&
      !ordersIsEmpty &&
      !ordersIsLoadingMore &&
      !ordersIsReachingEnd
    ) {
      setOrdersPage(ordersPage + 1);
    }
  }, [
    orders,
    ordersPage,
    ordersIsEmpty,
    ordersIsLoadingMore,
    ordersIsReachingEnd,
    setOrdersPage,
  ]);

  useEffect(() => {
    if (
      !!programs &&
      !!programs[programsPage - 1] &&
      !programsIsEmpty &&
      !programsIsLoadingMore &&
      !programsIsReachingEnd
    ) {
      setProgramsPage(programsPage + 1);
    }
  }, [
    programs,
    programsPage,
    programsIsEmpty,
    programsIsLoadingMore,
    programsIsReachingEnd,
    setProgramsPage,
  ]);

  const ordersWithProgram = allOrders
    .map((order) => ({
      ...order,
      program: !!allPrograms.filter((program) => program.id === order.programId)
        .length
        ? allPrograms.filter((program) => program.id === order.programId)[0]
        : null,
    }))
    .filter((order) => !!order.participationDate);

  // Place orders in calendar
  const matchDate = (participationDate, monthIndex, selectedYear) => {
    const date = new Date(participationDate);
    return (
      date.getFullYear() === selectedYear && date.getMonth() === monthIndex
    );
  };

  // Place campaigns in popup
  const filterAvailable = (monthIndex, year) =>
    allPrograms.filter(
      (program) =>
        !!program.alwaysOrderable ||
        !!program.dropDateSchedules.filter(({ dropDateStart }) => {
          const date = new Date(dropDateStart);
          return date.getFullYear() === year && date.getMonth() === monthIndex;
        }).length
    );

  // Year control
  const calendarYears = [
    currentDate.getFullYear() - 2,
    currentDate.getFullYear() - 1,
    currentDate.getFullYear(),
    currentDate.getFullYear() + 1,
    currentDate.getFullYear() + 2,
  ];

  const prevYear = () =>
    setSelectedYear(calendarYears[calendarYears.indexOf(selectedYear) - 1]);
  const nextYear = () =>
    setSelectedYear(calendarYears[calendarYears.indexOf(selectedYear) + 1]);

  return (
    <Layout
      main={
        ordersIsLoading ||
        programsIsLoading ||
        !ordersIsReachingEnd ||
        !programsIsReachingEnd ? (
          <Card
            title={t("menu.Calendar")}
            children={<Loader />}
            icon={icons.calendar}
            shadow
          />
        ) : ordersIsError || programsIsError ? (
          <Card
            title={t("menu.Calendar")}
            children={
              <Error
                errorObj={
                  ordersIsError && typeof ordersIsError === "object"
                    ? ordersIsError
                    : programsIsError && typeof programsIsError === "object"
                    ? programsIsError
                    : null
                }
              />
            }
            icon={icons.calendar}
            shadow
          />
        ) : (
          <>
            <SCalendarCard
              title={t("menu.Calendar")}
              icon={icons.calendar}
              headerRight={
                <React.Fragment>
                  <span className="calendar-year-control">
                    <Button
                      className="no-print"
                      link
                      onClick={prevYear}
                      disabled={
                        !(
                          calendarYears[
                            calendarYears.indexOf(selectedYear) - 1
                          ] !== undefined
                        )
                      }
                    >
                      {icons.chevronLeft}
                    </Button>
                    <Badge primary={currentDate.getFullYear() === selectedYear}>
                      {selectedYear}
                    </Badge>
                    <Button
                      className="no-print"
                      link
                      onClick={nextYear}
                      disabled={
                        !(
                          calendarYears[
                            calendarYears.indexOf(selectedYear) + 1
                          ] !== undefined
                        )
                      }
                    >
                      {icons.chevronRight}
                    </Button>
                  </span>
                  <Button
                    secondary
                    small
                    onClick={() => window.print()}
                    className="btn-print no-print"
                  >
                    <span className="hide-mobile">
                      {t("ui.Print-calendar")}
                    </span>
                    {icons.print}
                  </Button>
                </React.Fragment>
              }
              noPadding
              shadow
              whiteBg
            >
              <SCalendar>
                <Row>
                  {months.map((item, monthIndex) => {
                    return (
                      <Col
                        noGutter
                        md={3}
                        sm={4}
                        xs={6}
                        key={item}
                        className="print-4"
                      >
                        <MonthBox
                          programs={filterAvailable(monthIndex, selectedYear)}
                          isFuture={
                            selectedYear > currentDate.getFullYear() ||
                            (selectedYear === currentDate.getFullYear() &&
                              monthIndex >= currentDate.getMonth())
                          }
                          selectedYear={selectedYear}
                          monthIndex={monthIndex}
                        >
                          <div className="month-wrapper">
                            <Badge
                              primary={
                                currentDate.getMonth() === monthIndex &&
                                currentDate.getFullYear() === selectedYear
                              }
                            >
                              {t(`month.${item}`)}
                            </Badge>
                          </div>
                          {ordersWithProgram
                            .filter(({ participationDate }) =>
                              matchDate(
                                participationDate,
                                monthIndex,
                                selectedYear
                              )
                            )
                            .map((order) => (
                              <SCalenderItem
                                className="hover-zoom"
                                key={order.id}
                              >
                                <Link
                                  className="no-decoration"
                                  to={`/order/${order.id}`}
                                >
                                  <div className="img-wrap">
                                    <ImageBox
                                      params={{
                                        Width: 40,
                                        Height: 40,
                                      }}
                                      id={order?.program?.thumbnailFileId}
                                      boxHeight="100%"
                                      icon={IoCartSharp}
                                    />
                                  </div>
                                  <div className="text-box">
                                    <Badge
                                      state={order.state}
                                      className="smaller overflow-ellipsis"
                                      style={{ marginBottom: ".1em" }}
                                    >
                                      {t(`order.${order.state}`)}
                                    </Badge>
                                    <h4>{order.programName}</h4>
                                  </div>

                                  <div className="clearfix"></div>
                                </Link>
                              </SCalenderItem>
                            ))}
                        </MonthBox>
                      </Col>
                    );
                  })}
                </Row>
              </SCalendar>
            </SCalendarCard>
            <div className="no-print">
              <Sidebar isRow />
            </div>
          </>
        )
      }
      pageTitle={t("menu.Calendar")}
    />
  );
};

export default Calendar;
