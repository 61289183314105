import React from "react";
import styled from "styled-components";
import Button from "../Button";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const SEdmPreview = styled.div`
  text-align: center;
  position: relative;
  padding: 1em;
  background-image: linear-gradient(rgb(235, 235, 235) 2px, transparent 2px),
    linear-gradient(90deg, rgb(235, 235, 235) 2px, transparent 2px),
    linear-gradient(rgb(235, 235, 235) 1px, transparent 1px),
    linear-gradient(90deg, rgb(235, 235, 235) 1px, rgb(255, 255, 255) 1px);
  background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;
  background-position: -4px -10px, 0px -4px, 0px 0px, 0px 0px;
  .abs-buttons {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    padding: 1em;
    gap: 0.5em;
    z-index: 10;
  }
  iframe {
    width: 620px;
    max-width: 100%;
    margin: 0 auto;
    background: white;
    outline: none;
    border: 1px solid #ddd;
    display: block;
    min-height: 70vh;
  }
  &.full-screen {
    height: calc(-41px + 100vh);
    padding: 1.2em 0;
    margin: 0;
    iframe {
      min-height: calc(-2.4em - 60px + 100vh);
    }
  }
`;

const EdmPreview = ({
  url,
  docId,
  viewFullScreen = false,
  isPopup = false,
}) => {
  const { t } = useTranslation("common");
  const handleOpenTab = (url) => window.open(url);

  return (
    <SEdmPreview className={viewFullScreen ? "full-screen" : ""}>
      <iframe src={url} title={`id: ${docId}`} />
      <div
        className="abs-buttons"
        style={{ right: 0, bottom: 0, top: "auto", left: "auto" }}
      >
        {isPopup ? null : (
          <Button
            secondary
            square
            onClick={() => handleOpenTab(url)}
            className="new-tab-btn"
            title={t("ui.View-in-new-tab")}
          >
            <BsBoxArrowUpRight />
          </Button>
        )}
      </div>
    </SEdmPreview>
  );
};

export default EdmPreview;
