import React from "react";
import { FiMeh } from "react-icons/fi";
import Card from "../Card";
import Layout from "../parts/Layout";
import Sidebar from "../parts/Sidebar";
import styled from "styled-components";
import Button from "../Button";
import Link from "../Link";
import { useTranslation } from "react-i18next";

const S404Block = styled.div`
  text-align: center;
  .center-icon {
    display: block;
    font-size: 20em;
    color: ${(props) => props.theme.mediumLightBg};
    margin: 2rem auto 1rem;
    max-width: 85%;
  }
`;

const MainContent = () => {
  const { t } = useTranslation("common");
  return (
    <Card title={"Error 404: " + t("error.Page-not-found")} shadow>
      <S404Block>
        <FiMeh className="center-icon" />
        <h3>{t("error.Oops-The-page-you-requested-could-not-be-found")}</h3>
        <Link to="/">
          <Button link>{t("order.Back-home")}</Button>
        </Link>
      </S404Block>
    </Card>
  );
};

const PageNotFound = () => (
  <Layout main={<MainContent />} sidebar={<Sidebar />} pageTitle="404" />
);

export default PageNotFound;
