const makeBlobFromCSV = (csv, { decodeURI = false }) => {
  const content = decodeURI
    ? "\uFEFF" +
      decodeURIComponent(
        window
          .atob(csv)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      )
    : csv;
  return new Blob([content], {
    type: "text/csv;charset=utf-8",
  });
};
export default makeBlobFromCSV;
