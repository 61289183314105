import React from "react";
import useSignatures from "../../utils/useSignatures";
import SignaturesBlock from "./SignaturesBlock";
import { useTranslation } from "react-i18next";
import Card from "../Card";
import Error from "../Error";
import Loader from "../Loader";
import styled from "styled-components";
import { opacify } from "polished";
import { FaSignature } from "react-icons/fa6";

const SWrapper = styled.div`
  .signature-list {
    .list-item {
      padding: 1.5em 0;
      &:not(:last-child) {
        border-bottom: 1px solid ${(props) => props.theme.borderColor};
      }
    }
    .list-item-inner {
      width: 600px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .item-details {
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .sign-img-wrap {
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 1em;
    }
    border: 1px solid ${(props) => props.theme.borderColor};
    transition: border-color 0.2s;
    position: relative;
    .overlay {
      display: flex;
      opacity: 0;
      position: absolute;
      inset: 0;
      align-items: center;
      justify-content: center;
      transition: opacity 0.2s;
      background: ${(props) => opacify(-0.8, props.theme.accentColor)};
    }
    &:hover {
      border-color: ${(props) => props.theme.accentColor};
      .overlay {
        opacity: 1;
      }
    }
  }
  .sidebar-card .sign-img-wrap {
    border-color: ${(props) => props.theme.borderColor};
  }
  fieldset {
    border: none;
    outline: none;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 1em 0;
  }
  .text-top {
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
  }
  .btn-wrap-with-border {
    padding-top: 1em;
    border-top: 1px solid ${(props) => props.theme.borderColor};
    margin-top: 1em;
  }
`;

const SignaturesWrapper = ({ customerId }) => {
  const { t } = useTranslation("common");

  const { data, isLoading, error, mutate } = useSignatures({
    mpId: customerId,
    shouldFetch: () => !!customerId,
  });

  return (
    <Card title={t("my-account.Signatures")} shadow icon={<FaSignature />}>
      <SWrapper>
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Error errorObj={error} />
        ) : data ? (
          <SignaturesBlock signatures={data} mutate={mutate} />
        ) : null}
      </SWrapper>
    </Card>
  );
};

export default SignaturesWrapper;
