import React from "react";
import {
  Notification as ZNotification,
  Title,
  Close,
} from "@zendeskgarden/react-notifications";

const Notification = ({
  type = "info",
  title,
  text,
  errorText,
  handleClose,
}) => (
  <ZNotification type={type} style={{ maxWidth: "400px" }}>
    {!!title && <Title>{title}</Title>}
    {!!text && text}
    {!!errorText && (
      <div
        style={{
          fontSize: "80%",
          fontFamily: "monospace",
          background: "#eee",
          padding: ".5em 1em",
          marginTop: ".5em",
        }}
      >
        {errorText}
      </div>
    )}
    <Close aria-label="Close" onClick={handleClose} />
  </ZNotification>
);

export default Notification;
