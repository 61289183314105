import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import common_nl from "./translations/nl/common.json";
import common_vl from "./translations/vl/common.json";
import common_fr from "./translations/fr/common.json";
import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";
import common_es from "./translations/es/common.json";
import config from "./config";

const getLang = () => {
  const storedLang = localStorage?.getItem("language");
  if (
    !!storedLang &&
    !!config.lang.filter((lang) => lang === storedLang)?.length
  ) {
    return storedLang;
  } else {
    return config.defaultLang;
  }
};

i18n.init({
  interpolation: { escapeValue: false },
  lng: getLang(),
  resources: {
    nl: {
      common: common_nl,
    },
    vl: {
      common: common_vl,
    },
    fr: {
      common: common_fr,
    },
    de: {
      common: common_de,
    },
    en: {
      common: common_en,
    },
    es: {
      common: common_es,
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);
