const formatDoB = (date) => {
  const dateObj = new Date(date);
  return {
    day: dateObj.getDate(),
    month: dateObj.getMonth(),
    year: dateObj.getFullYear(),
  };
};

export default formatDoB;
