import axios from "axios";
import config from "../config";

const fetcher = (url) => {
  const customerId = localStorage.getItem("customerId");
  if (!customerId) {
    console.log("No customer ID found");
  }
  return axios
    .get(url, {
      withCredentials: true,
      headers: customerId
        ? {
            pxclient: config.clientId,
            pxcustomer: customerId,
          }
        : {
            pxclient: config.clientId,
          },
    })
    .then((res) => res.data);
};

export default fetcher;
