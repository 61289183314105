import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Card from "../Card";
import icons from "../../icons";
import Loader from "../Loader";
import Error from "../Error";
import formatPrice from "../../utils/formatPrice";
import OrderLine from "./OrderLine";
import { darken } from "polished";
import { FaCube, FaCubes } from "react-icons/fa";

const SCartSummary = styled(Card)`
  .cart-total {
    float: right;
    color: ${(props) => darken(0.1, props.theme.successColor)};
    font-size: 1.4em;
    line-height: 1;
  }
  .no-prod {
    color: #777;
    margin-top: -0.5em;
    padding-left: 2.2em;
  }
  .summary-header {
    padding-left: 0.35em;
    font-size: 1.1em;
  }
  .pack-prod-summary {
    padding-left: 27px;
    margin-bottom: 1em;
  }
  .orderline-wrap {
    border-top: 1px dashed #bbb;
  }
`;

const CartSummary = ({
  orderProductItems = [],
  orderPackageItems = [],
  isLoading = false,
  isError = false,
  total,
  deleteOrderItem,
  disabled,
  orderStatus,
}) => {
  const { t } = useTranslation("common");

  return (
    <SCartSummary
      title={t("order.Selected-articles")}
      icon={icons.cart}
      shadow
      style={{ position: "sticky", top: "0" }}
    >
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <Error errorObj={isError} />
      ) : (
        <>
          <div className="margin-bottom">
            <h4 className="summary-header">
              <FaCubes className="icon-inline" />
              {t("order.Packages")}
            </h4>
            {orderPackageItems.length > 0 ? (
              <div className="orderline-wrap">
                {orderPackageItems.map((item) => (
                  <div key={item.id}>
                    <OrderLine
                      orderItemId={item.id}
                      name={item.name}
                      price={item.price ? formatPrice(item.price) : null}
                      deleteOrderItem={deleteOrderItem}
                      disabled={disabled}
                      orderStatus={orderStatus}
                    />
                    <div className="pack-prod-summary">
                      {item.products.map((productItem) => (
                        <OrderLine
                          key={productItem.productId}
                          name={productItem.productName}
                          amount={productItem.amount}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="no-prod">{t("order.No-packages-selected")}</p>
            )}
          </div>
          <div className="margin-bottom">
            <h4 className="summary-header">
              <FaCube className="icon-inline" />
              {t("order.Products")}
            </h4>

            {orderProductItems.length > 0 ? (
              <div className="orderline-wrap">
                {orderProductItems.map((item) => (
                  <OrderLine
                    key={item.id}
                    orderItemId={item.id}
                    amount={item.amount}
                    name={item.productName}
                    price={item.price ? formatPrice(item.price) : null}
                    deleteOrderItem={deleteOrderItem}
                    disabled={disabled}
                    orderStatus={orderStatus}
                  />
                ))}
              </div>
            ) : (
              <p className="no-prod">{t("order.No-articles-selected")}</p>
            )}
          </div>
          <div>
            <span className="summary-header">{t("order.Total")}</span>
            <span className="cart-total">{formatPrice(total)}</span>
          </div>
        </>
      )}
    </SCartSummary>
  );
};

export default CartSummary;
