import axios from "axios";
import config from "../config";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const callVariantApproval = ({ id, method = "PUT", onSuccess, onError }) => {
  const customerId = localStorage.getItem("customerId");
  return axios({
    method: method,
    url: `${phoenix}/variants/${id}/customerapproval`,
    data: { resetCustomerApproval: false, approved: true, description: "" },
    withCredentials: true,
    headers: {
      pxclient: config.clientId,
      pxcustomer: customerId,
    },
  })
    .then((res) => {
      if (res.status === 200) {
        !!onSuccess && onSuccess(res?.data);
      } else {
        !!onError ? onError(res) : console.log("Request failed", res);
      }
    })
    .catch((error) => {
      if (error.response) {
        onError(error.response.data);
      } else if (error.request) {
        onError(error.request);
      } else {
        onError(error.message);
      }
    });
};

export default callVariantApproval;
