import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import icons from "../../icons";
import Button from "../Button";
import Card from "../Card";
import ImageBox from "../ImageBox";
import DOMPurify from "dompurify";
import Badge from "../Badge";
import config from "../../config";
import formatPrice from "../../utils/formatPrice";
import AmountInput from "./AmountInput";
import { darken } from "polished";
import { BiChevronRight, BiLoader } from "react-icons/bi";
import { useCollapse } from "react-collapsed";
import domPurifyWithHooks from "../../utils/adjustLinks";
import { Alert, Title } from "@zendeskgarden/react-notifications";
import OrderLine from "./OrderLine";
import { FaCube, FaCubes } from "react-icons/fa";

const SCartProduct = styled(Card)`
  &:last-child {
    margin-bottom: 0;
  }
  .card-body {
    width: 100%;
    position: relative;
  }
  &.with-footer {
    > .card-body {
      border-bottom-left-radius: ${(props) =>
        !!props.footer ? 0 : props.theme.borderRadius};
      border-bottom-right-radius: ${(props) =>
        !!props.footer ? 0 : props.theme.borderRadius};
    }
  }
  .card-footer {
    padding: 1.2em;
  }
  h4 {
    color: ${(props) => props.theme.headingColor};
  }
  .prod-description {
    br + br {
      display: none;
    }
  }
  .img-wrap {
    flex: 0 0 20%;
    align-self: flex-start;
    margin-right: 1.2em;
    border: 1px solid ${(props) => props.theme.borderColor};
  }
  .content-wrap {
    flex-grow: 1;
    position: relative;
    width: 100%;
  }
  .btn-wrap {
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    flex-grow: 1;
    position: absolute;
    right: 0;
    bottom: -35px;
    > *:not(:first-child) {
      margin-left: 0.5em;
    }
  }
  .price-wrap {
    color: ${(props) => props.theme.secondaryColor};
    display: inline-block;
    .price-format-wrap {
      color: ${(props) => darken(0.1, props.theme.successColor)};
      font-size: 1.4em;
      line-height: 1;
    }
  }
  .amount-badge {
    float: right;
    margin-left: 0.5em;
    font-size: 1em;
    line-height: 0.95;
    padding: 0.4em;
    display: block;
    margin-top: -8px;
    margin-right: -8px;
  }
  .product-wrap {
    display: flex;
    width: 100%;
    position: relative;
  }
  .details-trigger {
    padding: 1em;
    width: 100%;
    display: block;
    text-align: center;
    .expanded {
      transform: rotate(90deg);
    }
  }
  .pack-prod-wrap {
    margin-top: 1.2em;
    .pack-prod-card:not(:last-child) {
      margin-bottom: 1em;
    }
    .pack-prod-card:last-child {
      margin-bottom: 0;
    }
    .content-wrap {
      padding-bottom: 0;
    }
    .acc-item-inner {
      padding: 0;
    }
  }
  .prod-amount-outer {
    .prod-amount-wrap {
      margin-right: 1.2em;
      flex: 0 0 20%;
      min-width: 100px;
      @media (max-width: 360px) {
        flex-basis: 100%;
      }
    }
    .prod-btn-wrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .heading {
      font-size: 1em;
      margin-top: 0;
      margin-bottom: 0.3em;
    }
    .amount-details {
      line-height: 1.4;
      color: #777;
      font-size: 95%;
      @media (max-width: 360px) {
        margin-top: 0.7em;
      }
      > div {
        display: flex;
        justify-content: space-between;
      }
      .nr {
        margin-left: 0.4em;
        color: ${(props) => darken(0.1, props.theme.successColor)};
      }
    }
  }
  .alert-text-smaller {
    font-size: 90%;
    color: #999;
    .pack-name {
      color: #737373;
    }
  }
  .prod-alert-orderline-wrap {
    margin-top: 1em;
    font-size: 90%;
    line-height: 1.8;
    margin-bottom: 0.8em;
    border-top: 1px dashed #bbb;
    @media (max-width: 500px) {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
  .img-wrap,
  .content-wrap h4,
  .prod-code-wrap,
  .amount-details,
  .prod-description {
    opacity: ${(props) => (props.alreadyInCart ? ".6" : 1)};
    transition: opacity 0.2s;
  }
`;

const CartProduct = ({
  name,
  description,
  code,
  price,
  thumbnailFileId,
  addOrderItem,
  deleteOrderItem,
  updateOrderItem,
  productId = null,
  packageId = null,
  amount,
  orderItemId,
  disabled,
  setDisabled,
  products = [],
  isCustom,
  minAmount = 1,
  stepSize = 1,
  inCartPackName,
}) => {
  const { t } = useTranslation("common");
  const [isBusy, setIsBusy] = useState(false);

  const { getCollapseProps, getToggleProps, isExpanded, setExpanded } =
    useCollapse();

  const updateAmount = (newAmount, itemProps) =>
    updateOrderItem({
      orderItemId: itemProps.orderItemId,
      isCustom: itemProps.isCustom,
      amount: newAmount,
    });

  domPurifyWithHooks(DOMPurify);

  const calcMinAmount = (minAmount, packAmount) =>
    minAmount > packAmount ? minAmount : packAmount;

  const productsAlreadyInCart = products.filter(
    (pp) => !!pp?.inCartProdOrderItemId
  );

  const productsAlreadyInCartInPack = products.filter(
    (pp) => !!pp?.inCartPackName
  );

  const alreadyInCart = packageId
    ? !!productsAlreadyInCart?.length || !!productsAlreadyInCartInPack?.length
    : !!inCartPackName;

  return (
    <SCartProduct
      shadow
      className="with-footer"
      footer={
        !!packageId ? (
          <div>
            <Button
              link
              className="details-trigger"
              disabled={!products.length}
              aria-label={t("my-account.Close")}
            >
              <span {...getToggleProps()}>
                {t("order.Products-in-this-package")}
                <BiChevronRight
                  className={`arrow-icon ${isExpanded ? "expanded" : ""}`}
                />
              </span>
            </Button>
            <div {...getCollapseProps()}>
              {products.length ? (
                <div className="pack-prod-wrap">
                  {products.map((p) => (
                    <Card
                      shadow
                      className="pack-prod-card"
                      key={p.productId}
                      footer={
                        <div className="prod-amount-outer">
                          <div className="prod-btn-wrap">
                            <div className="prod-amount-wrap">
                              <AmountInput
                                currentAmount={p.amount}
                                minAmount={calcMinAmount(
                                  p.minAmount,
                                  p.minPackageAmount
                                )}
                                stepSize={p.stepSize} // stepsize if available
                                updateAmount={updateAmount}
                                itemProps={{
                                  orderItemId: p.orderItemId,
                                  isCustom: p.isCustom,
                                }}
                                disabled={disabled || !p.orderItemId}
                              />
                            </div>
                            {calcMinAmount(p.minAmount, p.minPackageAmount) >
                              1 || p.stepSize > 1 ? (
                              <div className="amount-details">
                                {calcMinAmount(
                                  p.minAmount,
                                  p.minPackageAmount
                                ) > 1 ? (
                                  <div>
                                    <span>{t("order.Minimum")}</span>{" "}
                                    <span className="nr">
                                      {calcMinAmount(
                                        p.minAmount,
                                        p.minPackageAmount
                                      )}{" "}
                                      {t("order.items")}
                                    </span>
                                  </div>
                                ) : null}
                                {p.stepSize > 1 ? (
                                  <div>
                                    <span>{t("order.Batch-size")}</span>{" "}
                                    <span className="nr">
                                      {p.stepSize} {t("order.items")}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      }
                    >
                      <div className="product-wrap">
                        {p.thumbnailFileId ? (
                          <div className="img-wrap">
                            <ImageBox
                              id={p.thumbnailFileId}
                              boxHeight="100%"
                              params={{
                                Width: 100,
                                Height: 100,
                              }}
                            />
                          </div>
                        ) : null}
                        <div className="content-wrap">
                          {amount > 0 ? (
                            <Badge success round className="amount-badge">
                              {p.amount}
                            </Badge>
                          ) : (
                            <Badge secondary round className="amount-badge">
                              {p.amount}
                            </Badge>
                          )}
                          <h4>
                            <FaCube className="icon-inline" />
                            {p.name}
                          </h4>
                          <p>
                            {!!p.code ? (
                              <span style={{ marginRight: "1em" }}>
                                <Badge>{p.code}</Badge>{" "}
                              </span>
                            ) : null}
                          </p>
                          {!!p.description && (
                            <div
                              className="prod-description"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(p.description, {
                                  USE_PROFILES: { html: true },
                                }),
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="prod-amount-outer">
            <div className="prod-btn-wrap">
              <div className="prod-amount-wrap">
                <AmountInput
                  currentAmount={amount}
                  minAmount={minAmount}
                  stepSize={stepSize}
                  updateAmount={updateAmount}
                  itemProps={{
                    orderItemId: orderItemId,
                    isCustom: isCustom,
                  }}
                  disabled={disabled || !orderItemId}
                />
              </div>
              {minAmount > 1 || stepSize > 1 ? (
                <div className="amount-details">
                  {minAmount > 1 ? (
                    <div>
                      <span>{t("order.Minimum")}</span>{" "}
                      <span className="nr">
                        {minAmount} {t("order.items")}
                      </span>
                    </div>
                  ) : null}
                  {stepSize > 1 ? (
                    <div>
                      <span>{t("order.Batch-size")}</span>{" "}
                      <span className="nr">
                        {stepSize} {t("order.items")}
                      </span>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        )
      }
      alreadyInCart={alreadyInCart}
    >
      <div className="product-wrap">
        {thumbnailFileId ? (
          <div className="img-wrap">
            <ImageBox
              id={thumbnailFileId}
              boxHeight="100%"
              params={{
                Width: 100,
                Height: 100,
              }}
            />
          </div>
        ) : null}
        <div className="content-wrap">
          {amount > 0 ? (
            <Badge success round className="amount-badge">
              {!!productId ? amount : icons.check}
            </Badge>
          ) : null}
          <h4>
            {!!packageId ? (
              <FaCubes className="icon-inline" />
            ) : (
              <FaCube className="icon-inline" />
            )}
            {name}
          </h4>
          <p className="prod-code-wrap">
            {!!code ? (
              <span style={{ marginRight: "1em" }}>
                <Badge>{code}</Badge>{" "}
              </span>
            ) : null}
            {config.uiSettings.showPrices && !!price ? (
              <span className="price-wrap">
                {t("order.price")} {formatPrice(price)}
              </span>
            ) : null}
          </p>
          {!!description && (
            <div
              className="prod-description"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(description, {
                  USE_PROFILES: { html: true },
                }),
              }}
            />
          )}
          {alreadyInCart ? (
            !!packageId ? (
              // Products in this package already in cart
              <Alert type="info" style={{ marginTop: "1em" }}>
                <Title>{t("order.Package-products-already-added")}</Title>
                <div className="prod-alert-orderline-wrap">
                  {/* As a single product */}
                  {productsAlreadyInCart.map((cartProd) => (
                    <OrderLine
                      key={cartProd.productId}
                      orderItemId={cartProd.inCartProdOrderItemId}
                      amount={cartProd.amount}
                      name={cartProd.name}
                      deleteOrderItem={deleteOrderItem}
                      disabled={disabled}
                    />
                  ))}
                  {/* In a package */}
                  {productsAlreadyInCartInPack.map((cartProd) => (
                    <OrderLine
                      key={cartProd.productId}
                      name={cartProd.name}
                      inCartPackName={cartProd?.inCartPackName}
                    />
                  ))}
                </div>
              </Alert>
            ) : (
              // This product is in a package that is in already in cart
              <Alert type="info" style={{ marginTop: "1em" }}>
                <Title>{t("order.Product-already-added")}</Title>
                <div className="prod-alert-orderline-wrap">
                  <OrderLine name={name} inCartPackName={inCartPackName} />
                </div>
              </Alert>
            )
          ) : null}
        </div>
        {!!addOrderItem ? (
          <div className="btn-wrap">
            {amount === 0 ? (
              <>
                <Button
                  primary
                  round
                  onClick={() => {
                    setIsBusy(true);
                    setDisabled(true);
                    !disabled &&
                      addOrderItem({
                        productId: productId,
                        packageId: packageId,
                        amount: minAmount ? minAmount : 1,
                        onSuccess: () => {
                          setTimeout(() => {
                            setExpanded(true);
                          }, 400);
                          setIsBusy(false);
                        },
                        onError: () => {
                          setIsBusy(false);
                        },
                      });
                  }}
                  ariaLabel={t("order.Add-one")}
                  disabled={disabled || alreadyInCart}
                >
                  {isBusy ? <BiLoader className="spin" /> : icons.cart}
                </Button>
              </>
            ) : (
              <>
                <Button
                  secondary
                  round
                  onClick={() => {
                    setIsBusy(true);
                    deleteOrderItem({
                      orderItemId: orderItemId,
                      onSuccess: () => {
                        setExpanded(false);
                        setIsBusy(false);
                      },
                      onError: () => {
                        setIsBusy(false);
                      },
                    });
                  }}
                  ariaLabel={t("order.Remove-one")}
                  disabled={disabled}
                >
                  {isBusy ? <BiLoader className="spin" /> : icons.trash}
                </Button>
              </>
            )}
          </div>
        ) : null}
      </div>
    </SCartProduct>
  );
};

export default CartProduct;
