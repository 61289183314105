import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import icons from "../../icons";
import Card from "../Card";
import ContactText from "../ContactText";
import FAQList from "../FAQList";
import Layout from "../parts/Layout";
import ReportsList from "../ReportsList";
import {
  VOLVO,
} from "../../constants";
import config from "../../config";

const ReportsPage = () => {
  const { t } = useTranslation("common");
  const titleReports = config.clientCode === VOLVO ? t("menu-Volvo.Reports") : t("menu.Reports");

  return (
    <Layout
      main={
        <Card shadow title={titleReports} icon={icons.reports}>
          <ReportsList chartType="quickSight" />
        </Card>
      }
      sidebar={
        <Fragment>
          <Card
            title={t("ui.Contact")}
            children={<ContactText />}
            icon={icons.contact}
            shadow
          />
          <Card
            title={t("menu.FAQ")}
            children={<FAQList />}
            icon={icons.faq}
            withBg
            shadow
          />
        </Fragment>
      }
      pageTitle={titleReports}
    />
  );
};

export default ReportsPage;
