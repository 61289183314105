import useSWR from "swr";
import fetcher from "./fetcher";
import encodeParams from "./encodeParams";
import apiConfig from "../apiConfig";

const { order } = apiConfig;

const usePackages = ({ params, shouldFetch = () => true }) => {
  const { data, error } = useSWR(
    () => (shouldFetch() ? `${order}/packages${encodeParams(params)}` : null),
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default usePackages;
