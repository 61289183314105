import React from "react";
import { BiChevronRight, BiChevronLeft, BiEdit } from "react-icons/bi";
import {
  IoBarChart,
  IoMegaphone,
  IoCalendar,
  IoHelpCircle,
  IoPrint,
  IoChatboxEllipses,
  IoSpeedometer,
  IoLanguage,
  IoLogOut,
  IoMenuOutline,
  IoClose,
  IoPaperPlaneSharp,
  IoCartSharp,
  IoCheckmarkCircleSharp,
  IoAddSharp,
  IoRemoveSharp,
  IoCheckmarkSharp,
  IoTrashSharp,
  IoListSharp,
  IoMailSharp,
  IoInformationCircleSharp,
  IoLocationSharp,
  IoLinkSharp,
  IoNewspaperSharp,
  IoStorefrontSharp,
  IoStarSharp,
  IoBookmarksSharp,
  IoLogoFacebook,
  IoLogoLinkedin,
  IoLogoInstagram,
  IoSaveSharp,
  IoCogSharp,
  IoLogoYoutube,
  IoLogoDiscord,
  IoLogoGoogle,
  IoLogoTiktok,
  IoBriefcaseSharp,
  IoPhonePortraitSharp,
  IoImage,
  IoCart,
  IoPerson,
} from "react-icons/io5";
import { IoLogoGoogleplus } from "react-icons/io";
import { BsTools } from "react-icons/bs";
import { FaTasks } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const icons = {
  dashboard: <IoSpeedometer />,
  calendar: <IoCalendar />,
  campaigns: <IoMegaphone />,
  reports: <IoBarChart />,
  actions: <FaTasks />,
  contact: <IoChatboxEllipses />,
  faq: <IoHelpCircle />,
  logout: <IoLogOut />,
  orders: <IoCart />,
  print: <IoPrint />,
  user: <IoPerson />,
  language: <IoLanguage />,
  menu: <IoMenuOutline />,
  close: <IoClose />,
  send: <IoPaperPlaneSharp />,
  cart: <IoCartSharp />,
  chevronRight: <BiChevronRight />,
  chevronLeft: <BiChevronLeft />,
  checkCircle: <IoCheckmarkCircleSharp />,
  add: <IoAddSharp />,
  remove: <IoRemoveSharp />,
  check: <IoCheckmarkSharp />,
  trash: <IoTrashSharp />,
  list: <IoListSharp />,
  email: <IoMailSharp />,
  phone: <IoPhonePortraitSharp />,
  info: <IoInformationCircleSharp />,
  location: <IoLocationSharp />,
  link: <IoLinkSharp />,
  newspaper: <IoNewspaperSharp />,
  store: <IoStorefrontSharp />,
  star: <IoStarSharp />,
  bookmark: <IoBookmarksSharp />,
  edit: <BiEdit />,
  save: <IoSaveSharp />,
  settings: <IoCogSharp />,
  job: <IoBriefcaseSharp />,
  image: <IoImage />,
  URL: <IoLinkSharp />,
  facebook: <IoLogoFacebook />,
  linkedin: <IoLogoLinkedin />,
  twitter: <FaXTwitter />,
  instagram: <IoLogoInstagram />,
  youtube: <IoLogoYoutube />,
  discord: <IoLogoDiscord />,
  google: <IoLogoGoogle />,
  googleplus: <IoLogoGoogleplus />,
  tiktok: <IoLogoTiktok />,
  tools: <BsTools />,
};

export default icons;
