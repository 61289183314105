import React from "react";
import Link from "./Link";
import Button from "./Button";
import filterBy from "../utils/filterBy";
import { Col } from "styled-bootstrap-grid";
import IconList from "./IconList";
import makeUrl from "../utils/makeUrl";
import getFAQs from "../utils/getFAQs";
import { useTranslation } from "react-i18next";
import icons from "../icons";
import config from "../config";

const FAQList = () => {
  const faqItemsArray = getFAQs(config.clientCode);
  const configCat = config?.uiSettings?.sidebarFAQ;

  const pickRandomItem = (array = []) => {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex] ? array[randomIndex] : null;
  };
  const uniqueCats = faqItemsArray?.length
    ? [...new Set(faqItemsArray.map((item) => item.categoryTitle))]
    : [];

  const sidebarFAQCatTitle = !!faqItemsArray?.length
    ? !!configCat &&
      !!faqItemsArray.filter((q) => q.categoryTitle === configCat)?.length
      ? configCat
      : pickRandomItem(uniqueCats) || faqItemsArray[0]?.categoryTitle || null
    : null;

  const catUrl = sidebarFAQCatTitle ? makeUrl(sidebarFAQCatTitle) : null;
  const items = filterBy(
    faqItemsArray,
    ["categoryTitle"],
    sidebarFAQCatTitle
  ).filter((item) => !!item?.title);
  const { t } = useTranslation("common");

  if (!faqItemsArray || !faqItemsArray.length) {
    return (
      <p
        style={{
          opacity: ".7",
        }}
      >
        {t("ui.No-FAQs-available")}
      </p>
    );
  }

  return (
    <React.Fragment>
      <IconList>
        {items &&
          items.length &&
          items.map((item, i) => (
            <Col md={12} key={i}>
              <Link to={`/faq/${catUrl}-q${i + 1}`} className="icon-list-link">
                <h4 className="font-weight-normal">{item.title}</h4>
              </Link>
            </Col>
          ))}
      </IconList>
      <div className="bottom-btn-wrap">
        <Link to="/faq">
          <Button link>
            {t("ui.All-FAQ")}
            {icons.chevronRight}
          </Button>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default FAQList;
