import React from "react";
import { useTranslation } from "react-i18next";
import { BiDownload } from "react-icons/bi";
import config from "../../config";
import Card from "../Card";
import Error from "../Error";
import Layout from "../parts/Layout";
import Sidebar from "../parts/Sidebar";
import ViewAL from "./ViewAL";

const DownloadsPage = () => {
  const reportId = config?.downloadsPageReport?.reportId;
  const viewId = config?.downloadsPageReport?.viewId;
  const { t } = useTranslation("common");

  if (!reportId || !viewId)
    return (
      <Layout
        main={
          <Card icon={<BiDownload />} shadow title={t("menu.Downloads")}>
            <Error
              errorTitle="Downloads not available due to a missing configuration setting"
              errorMessage="Please contact Loyalty Lab for help"
            />
          </Card>
        }
        sidebar={<Sidebar />}
        pageTitle={t("menu.Downloads")}
        description={t("ui.Downloads-page-text")}
        pathArray={[]}
      />
    );

  return (
    <ViewAL
      reportId={reportId}
      viewId={viewId}
      icon={<BiDownload />}
      pageTitle={t("menu.Downloads")}
      description={t("ui.Downloads-page-text")}
      pathArray={[]}
    />
  );
};

export default DownloadsPage;
