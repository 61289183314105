import config from "../config";
import { VOLVO } from "../constants";
import useLocalStorage from "./useLocalStorage";
import useSession from "./useSession";
import { useEffect } from "react";
// import useRequest from "./useRequest";

const useStoredSession = (shouldFetch = () => true) => {
  const [storedSession, setStoredSession] = useLocalStorage("session", "");
  const shouldFetchSession = () =>
    !storedSession?.length && config.clientCode === VOLVO && shouldFetch();

  const { data: session } = useSession(shouldFetchSession);

  useEffect(() => {
    session && setStoredSession(session);
  }, [session, setStoredSession]);

  return storedSession;
};

export default useStoredSession;
