import { useTranslation } from "react-i18next";
import { FiLogIn, FiLogOut } from "react-icons/fi";
import { useAuth } from "../context/AuthContext";
import Button from "./Button";

const AuthButton = ({ btnProps = {}, loginText = "", logoutText = "" }) => {
  const { user, login, logout } = useAuth();
  const { t } = useTranslation("common");

  if (!!user?.subject) {
    return (
      <Button $primary onClick={logout} {...btnProps}>
        {logoutText || (
          <>
            <span>{logoutText || t("ui.Log-out")}</span>
            <FiLogOut />
          </>
        )}
      </Button>
    );
  } else {
    return (
      <Button $primary onClick={login} {...btnProps}>
        <span>{loginText || t("ui.Log-in")}</span>
        <FiLogIn />
      </Button>
    );
  }
};

export default AuthButton;
