import { invert, grayscale, readableColor } from "polished";
import React from "react";
import styled from "styled-components";
import config from "../../config";

const SFooter = styled.footer`
  color: ${(props) =>
    readableColor(
      props.theme.background,
      props.theme.headingColor,
      grayscale(invert(props.theme.headingColor)),
      false
    )};
  text-align: center;
  font-size: 10px;
  padding-bottom: 1.2em;
`;

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <SFooter className="no-print">
      &copy; {year}{" "}
      {config?.uiSettings?.showFooterText ? "Powered by Loyalty Lab" : ""}
    </SFooter>
  );
};

export default Footer;
