import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import callSignatures from "../../utils/callSignatures";
import { useToast } from "@zendeskgarden/react-notifications";
import LoaderOverlay from "../LoaderOverlay";
import Notification from "../Notification";
import SignatureItem from "./SignatureItem";
import WizardButtons from "../OrderWizard/WizardButtons";
import { Row } from "styled-bootstrap-grid";
import Button from "../Button";
import icons from "../../icons";

const SignaturesBlock = ({ signatures, mutate }) => {
  const { t } = useTranslation("common");
  const { addToast } = useToast();
  const [isBusy, setIsBusy] = useState(false);
  const [isError, setIsError] = useState(false);
  const [dialogId, setDialogId] = useState(null);

  const defaultValues = signatures ? Object.assign({}, signatures) : [];

  const {
    register,
    handleSubmit,
    formState,
    getValues,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues,
  });

  const { dirtyFields, isDirty } = formState;

  const values = getValues();

  const onSubmit = (data) => {
    setIsBusy(true);
    callSignatures({
      data: Object.values(data),
      onSuccess: () => {
        mutate();
        setDialogId(null);
        setIsBusy(false);
        setIsError(false);
        addToast(
          ({ close }) => (
            <Notification
              title={t("ui.Done")}
              text={t("my-account.Signatures-updated")}
              type="success"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
      onError: (error) => {
        setIsBusy(false);
        setIsError(true);
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Oops-Something-went-wrong")}
              text={t("error.Failed-to-update-signatures")}
              errorText={
                !!error.title
                  ? error.title
                  : typeof error === "string" && !!error
                  ? error
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={() => {
                close();
                reset();
              }}
            />
          ),
          { placement: "bottom" }
        );
        setTimeout(() => {
          reset();
          setIsError(false);
        }, 1000);
      },
    });
  };

  const formSubmit = () => {
    onSubmit(getValues());
  };

  // Update form values after fetch
  useEffect(() => {
    signatures?.length && reset(Object.assign({}, signatures));
    return () => {};
  }, [signatures, reset]);

  const onCancel = () => {
    reset();
    setIsError(false);
    setIsBusy(false);
    setDialogId(null);
  };

  return (
    <div style={{ position: "relative" }}>
      <p className="margin-bottom">{t("my-account.Signatures-description")}</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row-wrap" style={{ marginBottom: "-1.2em" }}>
          <Row>
            {signatures.map((item, i) => (
              <SignatureItem
                key={`${item?.name}${i}`}
                i={i}
                item={signatures[i]}
                isError={isError}
                setIsError={setIsError}
                dialogId={dialogId}
                setDialogId={setDialogId}
                dirtyFields={dirtyFields}
                values={values}
                mutate={mutate}
                setValue={setValue}
                formSubmit={formSubmit}
                watch={watch}
                register={register}
              />
            ))}
          </Row>
        </div>
        <WizardButtons>
          <Button
            secondary
            onClick={onCancel}
            disabled={!isDirty || isBusy}
            wide
          >
            {icons.close}
            <span>{t("ui.Cancel")}</span>
          </Button>
          <Button type="submit" $success disabled={!isDirty || isBusy} wide>
            {icons.check}
            <span>{t("my-account.Save")}</span>
          </Button>
        </WizardButtons>
      </form>
      {isBusy ? <LoaderOverlay style={{ margin: "-1.2em -1.2em 0" }} /> : null}
    </div>
  );
};

export default SignaturesBlock;
