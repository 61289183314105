const groupBy = (arr, prop) => {
  if (arr && arr.length && prop) {
    const newArr = arr.reduce((acc, obj) => {
      const key = obj[prop] || "...";
      if (!acc[key]) {
        acc[key] = {
          key: key,
          items: [],
        };
      }
      acc[key].items.push(obj);
      return acc;
    }, {});
    return newArr;
  } else {
    return null;
  }
};

export default groupBy;
