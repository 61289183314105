import { BiDownload } from "react-icons/bi";
import CycleIcon from "../components/CycleIcon";
import config from "../config";
import icons from "../icons";
import toolsItems from "./ToolsSubMenu";

export const allMenuItems = [
  {
    title: "Dashboard",
    url: "/",
    icon: icons.dashboard,
  },
  {
    title: "Calendar",
    url: "/calendar",
    icon: icons.calendar,
  },
  {
    title: "Campaigns",
    url: "/campaigns",
    icon: icons.campaigns,
  },
  {
    title: "Cycles",
    url: "/cycles",
    icon: <CycleIcon />,
  },
  {
    title: "Reports",
    url: "/reports",
    icon: icons.reports,
  },
  {
    title: "Action-lists",
    url: "/action-lists",
    icon: icons.actions,
  },
  {
    title: "Downloads",
    url: "/downloads",
    icon: <BiDownload />,
  },
  {
    title: "Tools",
    url: "/tools",
    icon: icons.tools,
    ...toolsItems,
  },
  {
    title: "FAQ",
    url: "/faq",
    icon: icons.faq,
  },
];

const menuItems = allMenuItems
  .filter((item) =>
    !config.uiSettings.toolsMenu ? item.url !== "/tools" : item
  )
  .filter((item) =>
    !config.uiSettings.downloadsMenu ? item.url !== "/downloads" : item
  );

export default menuItems;
