const formatPrice = (amount) => {
  const amountAvailable = !!amount ? amount : 0;
  const amountFormatted = amountAvailable.toFixed(2).replace(".", ",");
  return (
    <span className="price-format-wrap">
      <span className="price-format-currency">&euro;</span>
      <span className="price-format-amount">{amountFormatted}</span>
    </span>
  );
};

export default formatPrice;
