import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import icons from "../../icons";
import Button from "../Button";

const SOrderLine = styled.div`
  &.no-remove-btn {
    padding-left: calc(0.25em + 27px);
  }
  border-bottom: 1px dashed #bbb;
  padding: 0.4em 0.25em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.3;
  color: #999;
  &:hover {
    .remove-btn {
      color: #999;
    }
  }
  .remove-btn {
    svg {
      font-size: 1.5em;
    }
    margin-right: 5px;
    width: 22px;
    height: 18px;
    color: #ddd;
    &:hover {
      color: #9d5454;
    }
  }
  span {
    display: inline-block;
    vertical-align: middle;
  }
  .orderline-amount {
    font-family: monospace;
    line-height: 1;
    white-space: nowrap;
    display: inline-block;
    text-align: right;
    margin-right: 0.5em;
    font-size: 90%;
  }
  .orderline-text {
    display: block;
    font-size: 90%;
  }
  .orderline-name {
    color: #737373;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .orderline-left-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:not(:only-child) {
      max-width: calc(100% - 50px);
    }
  }
  .orderline-price {
    display: block;
    white-space: nowrap;
  }
`;

const OrderLine = ({
  orderItemId = null,
  amount,
  name,
  inCartPackName = null,
  disabled,
  price,
  deleteOrderItem = () => null,
  orderStatus,
}) => {
  const { t } = useTranslation("common");

  return (
    <SOrderLine className={!!inCartPackName ? "no-remove-btn" : ""}>
      <div className="orderline-left-wrap">
        {!!orderItemId && (orderStatus === "new" || orderStatus === "open") ? (
          <Button
            className="remove-btn"
            link
            title={t("order.Remove")}
            onClick={() =>
              deleteOrderItem({
                orderItemId: orderItemId,
              })
            }
            disabled={disabled}
          >
            {icons.trash}
          </Button>
        ) : null}
        {!!amount ? <span className="orderline-amount">{amount} x</span> : null}
        {!!inCartPackName ? (
          <span className="orderline-text">
            <span className="orderline-name">{name}</span>{" "}
            <span className="orderline-note">{t("order.as-part-of")}</span>{" "}
            <span className="orderline-name">{inCartPackName}</span>
          </span>
        ) : (
          <span className="orderline-text orderline-name">{name}</span>
        )}
      </div>
      {price ? <span className="orderline-price">{price}</span> : null}
    </SOrderLine>
  );
};

export default OrderLine;
