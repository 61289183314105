import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "styled-bootstrap-grid";
import icons from "../../icons";
import useCustomer from "../../utils/useCustomer";
import Button from "../Button";
import Card from "../Card";
import AddressBox from "./AddressBox";
import AddressForm from "./AddressForm";
import ButtonWrap from "../ButtonWrap";
import { useSWRConfig } from "swr";
import Dialog from "../Dialog";
import { useToast } from "@zendeskgarden/react-notifications";
import Notification from "../Notification";
import LoaderOverlay from "../LoaderOverlay";
import callAddresses from "../../utils/callAddresses";
import Loader from "../Loader";
import Error from "../Error";

const AddressesBlock = ({ customerId }) => {
  const { t } = useTranslation("common");
  const { mutate } = useSWRConfig();
  const { addToast } = useToast();

  const [itemEditedId, setItemEditedId] = useState("");
  const [dialogId, setDialogId] = useState("");

  const openDialog = (id) => {
    setDialogId(id);
    setItemEditedId(id);
  };
  const cancelDelete = () => {
    setDialogId("");
    setItemEditedId("");
  };

  const { data: customer, isLoading, isError, cacheKey } = useCustomer();

  if (!!isLoading) {
    return (
      <Card title={t("my-account.Addresses")} shadow icon={icons.info}>
        <Loader />
      </Card>
    );
  }
  if (!!isError) {
    return (
      <Card title={t("my-account.Addresses")} shadow icon={icons.info}>
        <Error errorObj={isError} />
      </Card>
    );
  }

  const refetchCustomer = () => mutate(cacheKey);

  const handleDeleteAddress = (index) => {
    callAddresses({
      customerId: customerId,
      data: customer.addresses.filter((item, itemIndex) => itemIndex !== index),
      onSuccess: () => {
        setDialogId("");
        setItemEditedId("");
        refetchCustomer();
      },
      onError: (error) => {
        setDialogId("");
        setItemEditedId("");
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Something-went-wrong")}
              text={t("error.Failed-to-delete-address")}
              errorText={
                !!error && !!error.title
                  ? error.title
                  : typeof error === "string" && !!error
                  ? error
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
    });
  };

  return (
    <Card title={t("my-account.Addresses")} shadow icon={icons.location}>
      <p>{t("my-account.Addresses-description")}</p>
      <div className="row-wrap padding-top">
        <Row>
          {!!customer && !!customer.addresses.length
            ? customer.addresses.map((item, index) => (
                <Col
                  sm={6}
                  key={item.type + index}
                  style={{ marginBottom: "1.2em" }}
                >
                  <AddressBox>
                    {(!!item.name || item.type) && (
                      <h4>
                        {!!item.name && item.name + " "}
                        {!!item.type && (
                          <span>
                            ({t(`my-account.address-type-${item.type}`)})
                          </span>
                        )}
                      </h4>
                    )}
                    {(!!item.street || !!item.houseNumber || !!item.suffix) && (
                      <div>
                        {!!item.street && item.street + " "}
                        {!!item.houseNumber && item.houseNumber + " "}
                        {!!item.suffix && item.suffix}
                      </div>
                    )}
                    {(!!item.postalCode || !!item.city) && (
                      <div>
                        {!!item.postalCode && item.postalCode + " "}
                        {!!item.city && item.city}
                      </div>
                    )}
                    {!!item.province && <div>{item.province}</div>}
                    {!!item.country && <div>{item.country}</div>}

                    <ButtonWrap>
                      <AddressForm
                        customerId={customerId}
                        onSuccess={refetchCustomer}
                        formData={item}
                        index={index}
                        addresses={customer.addresses}
                      />
                      <Button
                        link
                        small
                        onClick={() => openDialog(item.type + index)}
                      >
                        {icons.trash}
                        <span>{t("my-account.Delete")}</span>
                      </Button>
                    </ButtonWrap>

                    {itemEditedId === item.type + index ? (
                      <LoaderOverlay />
                    ) : null}

                    {dialogId === item.type + index ? (
                      <Dialog
                        title={t("my-account.Delete-supplier")}
                        text={t("my-account.Delete-this-supplier")}
                        name={item.name}
                        onConfirm={() => handleDeleteAddress(index)}
                        onCancel={cancelDelete}
                      />
                    ) : null}
                  </AddressBox>
                </Col>
              ))
            : null}
        </Row>
      </div>
      <div className="text-right">
        <AddressForm
          customerId={customerId}
          addresses={customer.addresses}
          onSuccess={refetchCustomer}
        />
      </div>
    </Card>
  );
};

export default AddressesBlock;
