import React, { useState } from "react";
import styled from "styled-components";
import menuItems from "../../content/MenuItems";
import NavLink from "../NavLink";
import { useTranslation } from "react-i18next";
import useViews from "../../utils/useViews";
import { useLocation } from "react-router";
import Link from "../Link";
import groupByPath from "../../utils/groupByPath";
import icons from "../../icons";
import config from "../../config";
import { VOLVO } from "../../constants";
import { isExternalLink } from "../../utils/isExternalLink";
import { BsBoxArrowUpRight } from "react-icons/bs";

const SNav = styled.nav`
  background: ${(props) => props.theme.lightBg};
  border-top: 1px solid ${(props) => props.theme.borderColor};
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
  height: 49px;
  display: flex;
  justify-content: stretch;
  z-index: 1;
  position: relative;
  &:last-child {
    margin-bottom: -1px;
    border-radius: 0 0
      ${(props) => props.theme.borderRadius + " " + props.theme.borderRadius};
  }
  .link-wrapper {
    position: relative;
    flex-grow: 1;
    &:last-child .main-menu-link {
      border-right: none;
    }
    &:first-child- > a {
      border-bottom-left-radius: ${(props) => props.theme.borderRadius};
    }
  }
  // Max 6 menu items on smaller phones
  @media (max-width: 360px) {
    .link-wrapper:nth-child(7),
    .link-wrapper:nth-child(8) {
      display: none;
    }
  }
  // Max 5 menu items on smaller phones
  @media (max-width: 300px) {
    .link-wrapper:nth-child(6) {
      display: none;
    }
  }
  .main-menu-link {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 1em;
    line-height: 1;
    font-size: 13px;
    color: ${(props) => props.theme.headingColor};
    border-right: 1px solid ${(props) => props.theme.borderColor};
    height: 48px;
    margin-bottom: -1px;
    transition: background 0.2s;
    width: 100%;
    @media (max-width: 991px) {
      svg {
        font-size: 1.85em;
        margin: auto;
      }
      .link-text {
        display: none;
      }
    }
    svg {
      font-size: 1.5em;
      color: ${(props) => props.theme.accentColor};
    }
    > div:first-child {
      width: 1.65em;
    }
    .link-text {
      margin-left: 0.6em;
      margin-bottom: -1px;
    }
    &:hover,
    &:active,
    .hovered {
      background: ${(props) => props.theme.hoverColor};
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: -1px;
      height: 6px;
      background: transparent;
      transition: background 0.2s;
    }
    &.active {
      &:after {
        background: ${(props) => props.theme.accentColor};
        transition: background 0.2s;
      }
    }
  }
  .submenu {
    background: ${(props) => props.theme.lightBg};
    border: 1px solid ${(props) => props.theme.borderColor};
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    min-width: 20em;
    position: absolute;
    left: 0;
    z-index: 1;
    @media (max-width: 1200px) {
      left: auto;
      right: 0;
    }
    .submenu-overlay {
      z-index: -1;
      height: 78vh;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
    }
    .submenu-link {
      display: block;
      border-bottom: 1px solid ${(props) => props.theme.borderColor};
      padding: 0.75em 1.25em 0.55em 1.25em;
      text-decoration: none;
      color: ${(props) => props.theme.headingColor};
      overflow-wrap: break-word;
      cursor: pointer;
      &:hover,
      &:active {
        background: ${(props) => props.theme.hoverColor};
      }
      &:last-child {
        border-bottom: none;
      }
      svg:last-child {
        font-size: 0.9em;
        margin-left: 0.6em;
        vertical-align: baseline;
        overflow: visible;
      }
    }
  }
  .submenu-links-wrap {
    display: none;
  }
  .submenu-cat-wrap.isSelected > .submenu-links-wrap {
    display: block;
  }
  .submenu-cat-wrap .submenu-cat-wrap {
    display: none;
  }
  .submenu-cat-wrap.isSelected .submenu-cat-wrap {
    display: block;
  }
  .submenu-inner > .submenu-cat-wrap {
    display: none;
  }
  .submenu-inner.isRoot > .submenu-cat-wrap {
    display: block;
  }
  .submenu-inner > .submenu-cat-wrap.isSelected {
    display: block;
  }
`;

const SNestedSubmenu = styled.div`
  .submenu-cat {
    display: block;
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
    padding: 0;
    text-decoration: none;
    color: ${(props) => props.theme.headingColor};
    overflow-wrap: break-word;
    cursor: pointer;
    &:hover {
      background: ${(props) => props.theme.hoverColor};
    }
    .submenu-arrow-right,
    .submenu-arrow-left {
      padding: 1em 1em 0.5em;
      display: inline-block;
    }
    .submenu-arrow-right {
      float: right;
    }
    .submenu-arrow-left {
      float: left;
      margin-right: -1.6em;
      display: none;
    }
    .submenu-cat-text {
      display: inline-block;
      padding: 0.75em 1.25em;
      font-weight: 600;
      color: ${(props) => props.theme.headingColor};
    }
  }
  .submenu-cat-wrap.isSelected:not(.isActive) {
    > .submenu-cat,
    > .submenu-links-wrap {
      display: none;
    }
  }
  .isSelected > .submenu-cat {
    background: ${(props) => props.theme.borderColor};
    > .submenu-arrow-left {
      display: inline-block;
    }
    > .submenu-arrow-right {
      display: none;
    }
  }
`;

const NestedSubmenu = ({
  itemsObj,
  setHovered,
  selectedPath,
  setSelectedPath,
}) => {
  const itemKeys = Object.keys(itemsObj).sort();

  // move "/" to the end
  const itemKeysRerdered =
    itemKeys?.[0] === "/"
      ? [...itemKeys.filter((key) => key !== "/"), itemKeys?.[0]]
      : itemKeys;

  return (
    <div className={`submenu-inner ${selectedPath === "/" ? "isRoot" : ""}`}>
      {itemKeysRerdered.map((key) => (
        <NestedObjItem
          key={key}
          itemKey={key}
          item={itemsObj[key]}
          selectedPath={selectedPath}
          setSelectedPath={setSelectedPath}
          setHovered={setHovered}
        />
      ))}
    </div>
  );
};

const MenuItem = ({
  title,
  url,
  icon,
  children,
  nested,
  customerCode,
  sessionGuid,
}) => {
  const { t } = useTranslation("common");
  const { pathname } = useLocation();

  const [hovered, setHovered] = useState(false);
  const [selectedPath, setSelectedPath] = useState("/");

  const isSubpage = (path, url) =>
    (path.indexOf("/campaign/") > -1 && url === "/campaigns") ||
    (path.indexOf("/cycle/") > -1 && url === "/cycles") ||
    (path.indexOf("/report/") > -1 && url === "/reports") ||
    (path.indexOf("/action-list/") > -1 && url === "/action-lists") ||
    (path.indexOf("/tools/") > -1 && url === "/tools");

  if (!config?.uiSettings?.showCalendar && url === "/calendar") {
    return null;
  }

  return (
    <div className="link-wrapper" onMouseLeave={() => setHovered(false)}>
      <NavLink
        key={`${url}-${title}`}
        to={url}
        className={`main-menu-link ${isSubpage(pathname, url) ? "active" : ""}`}
        end={url === "/"}
        onMouseEnter={() => setHovered(true)}
      >
        {icon}
        <span className="link-text">
          {t(`menu${config?.clientCode === VOLVO ? "-Volvo" : ""}.${title}`)}
        </span>
      </NavLink>
      {url !== "/reports" &&
        url !== "/action-lists" &&
        children &&
        children.length &&
        hovered === true && (
          <div className="submenu" onMouseEnter={() => setHovered(true)}>
            {children.map(({ title, url, urlParams = () => "" }) => (
              <NavLink
                key={`${url}-${title}`}
                to={`${url}${urlParams({ customerCode, sessionGuid, url })}`}
                className="submenu-link"
                end
                {...(isExternalLink(url) && {
                  target: "_blank",
                  rel: "noopener noreferrer",
                })}
              >
                {t(`menu.${title}`) || title}
                {isExternalLink(url) ? <BsBoxArrowUpRight /> : null}
              </NavLink>
            ))}
          </div>
        )}
      {(url === "/reports" || url === "/action-lists") &&
      hovered === true &&
      !!nested &&
      typeof nested === "object" &&
      !!Object.keys(nested).length ? (
        <SNestedSubmenu
          className="submenu"
          onMouseEnter={() => setHovered(true)}
        >
          <NestedSubmenu
            itemsObj={nested}
            setHovered={setHovered}
            selectedPath={selectedPath}
            setSelectedPath={setSelectedPath}
          />
          <div
            className="submenu-overlay"
            onMouseEnter={() => setHovered(true)}
          ></div>
        </SNestedSubmenu>
      ) : null}
    </div>
  );
};

const NestedObjItem = ({
  item,
  itemKey,
  setHovered,
  selectedPath,
  setSelectedPath,
}) => {
  const childKeys = Object.keys(item).filter(
    (key) => key !== "nestedItems" && key !== "nestedItemPath"
  );
  const handleClick = () => {
    setSelectedPath((currentSelectedPath) =>
      currentSelectedPath !== item.nestedItemPath
        ? item.nestedItemPath
        : // Category clicked is already active
        currentSelectedPath.replace(itemKey, "") === "/"
        ? "/"
        : currentSelectedPath.replace("/" + itemKey, "")
    );
    setHovered(true);
  };

  return (
    <div
      className={`submenu-cat-wrap ${
        (itemKey === "/" && selectedPath === "/") ||
        (selectedPath !== "/" && selectedPath.split("/").includes(itemKey))
          ? "isSelected"
          : ""
      } ${selectedPath.endsWith(itemKey) ? "isActive" : ""}`}
    >
      {itemKey !== "/" ? (
        <div className="submenu-cat" onClick={handleClick}>
          <span className="submenu-arrow-left">{icons.chevronLeft}</span>
          <span className="submenu-cat-text">{itemKey}</span>
          <span className="submenu-arrow-right">{icons.chevronRight}</span>
        </div>
      ) : null}
      <div
        className="submenu-links-wrap"
        onClick={() => {
          setHovered(false);
          setSelectedPath("/");
        }}
      >
        {item.nestedItems.map((childItem) => (
          <Link
            key={childItem.id}
            to={`/${
              childItem.chartType === "quickSight" ? "report" : "action-list"
            }/${childItem.reportId}/${childItem.id}`}
            className="submenu-link border-bottom"
          >
            {childItem.name}
          </Link>
        ))}
      </div>
      {!!childKeys.length
        ? childKeys.map((innerItem) => (
            <NestedObjItem
              key={innerItem}
              itemKey={innerItem}
              item={item[innerItem]}
              selectedPath={selectedPath}
              setSelectedPath={setSelectedPath}
              setHovered={setHovered}
            />
          ))
        : null}
    </div>
  );
};

const Nav = ({ customerCode, sessionGuid }) => {
  const {
    data: menuReportsQS,
    isLoadingQS,
    isErrorQS,
  } = useViews({ chartType: "quickSight" });
  const {
    data: menuReportsAL,
    isLoadingAL,
    isErrorAL,
  } = useViews({ chartType: "actionList" });

  if (isLoadingQS || isLoadingAL || isErrorQS || isErrorAL) {
    return (
      <>
        <SNav className="clearfix">
          {menuItems.map((props) => (
            <MenuItem
              {...props}
              key={`${props.url}-${props.title}`}
              customerCode={customerCode}
              sessionGuid={sessionGuid}
            />
          ))}
        </SNav>
      </>
    );
  }

  const menuItemsWithReports = menuItems.map((item) =>
    item.url === "/reports"
      ? {
          ...item,
          nested: groupByPath(menuReportsQS?.length ? menuReportsQS : []),
        }
      : item.url === "/action-lists"
      ? {
          ...item,
          nested: menuReportsAL?.length ? groupByPath(menuReportsAL) : [],
        }
      : item
  );

  return (
    <>
      <SNav className="clearfix">
        {menuItemsWithReports.map((props) => (
          <MenuItem
            {...props}
            key={`${props.url}-${props.title}`}
            customerCode={customerCode}
            sessionGuid={sessionGuid}
          />
        ))}
      </SNav>
    </>
  );
};

export default Nav;
