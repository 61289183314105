import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row } from "styled-bootstrap-grid";

const SCardList = styled.div`
  padding: 0 8px;
  &:last-child {
    margin-bottom: -1.2em;
  }
  .full-height {
    height: calc(100% - 1.2em);
    .card-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .card-text {
    text-align: center;
    &:last-child {
      margin-bottom: 0;
    }
    h4 {
      padding-top: 0.5em;
      color: ${(props) => props.theme.headingColor};
    }
  }
  .btn-wrap {
    text-align: right;
    padding-top: 0.6em;
  }
  &.cardlist-w-margin {
    margin-bottom: -1.2em;
    @media (max-width: 575px) {
      margin-bottom: -0.6em;
    }
  }
`;

const CardList = ({ style = {}, className = "", children }) => {
  return (
    <SCardList style={style} className={className}>
      <Row>{children}</Row>
    </SCardList>
  );
};

CardList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.object,
  ]),
  style: PropTypes.object,
};

export default CardList;
