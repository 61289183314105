import axios from "axios";
import config from "../config";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const callProfile = ({
  profileId,
  participationId,
  method = "POST",
  onSuccess = () => console.log("success"),
  onError = () => console.log("error"),
}) => {
  const customerId = localStorage.getItem("customerId");
  return axios({
    method: method,
    url: `${phoenix}/profiles/${profileId}/actions/applyprofile?participationId=${participationId}`,
    withCredentials: true,
    headers: {
      pxclient: config.clientId,
      pxcustomer: customerId,
    },
  })
    .then((res) => {
      !!onSuccess && onSuccess(res?.data);
    })
    .catch((error) => {
      if (error.response) {
        onError(
          !!error.response.data && !!error.response.data.length > 0
            ? error.response.data.join("; ")
            : ""
        );
      } else if (error.request) {
        onError(error.request);
      } else {
        onError(error.message);
      }
    });
};

export default callProfile;
