import useSWR from "swr";
import fetcher from "./fetcher";
import apiConfig from "../apiConfig";

const { phoenix } = apiConfig;

const useSuppliers = (mpId) => {
  const { data, error } = useSWR(
    `${phoenix}/customers/${mpId}/suppliers`,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    cacheKey: `${phoenix}/customers/${mpId}/suppliers`,
  };
};

export default useSuppliers;
