import React, { useState } from "react";
import MultilevelSidebar from "react-multilevel-sidebar";
import "react-multilevel-sidebar/src/Sidebar.css";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import theme from "../../styles/theme";
import menuItems from "../../content/MenuItems";
import icons from "../../icons";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";
import config from "../../config";
import { VOLVO } from "../../constants";
import { isExternalLink } from "../../utils/isExternalLink";
import { BsBoxArrowUpRight } from "react-icons/bs";

const MenuWrapper = styled.div`
  opacity: ${(props) => !props.isOpen && 0};
  .slidebar {
    .sidebar-main {
      overflow: hidden !important;
    }
    .sidebar-main-content ul {
      line-height: 1;
      border-top: 1px solid ${theme.borderColor};
      li {
        border-bottom: 1px solid ${theme.borderColor};
        padding: 0.95em 1em 0.85em;
        svg:first-child:not(.close-icon) {
          font-size: 1.5em;
          margin-right: 0.5em;
          color: ${theme.accentColor};
          width: 19.5px;
        }
        svg:not(:first-child) {
          font-size: 0.9em;
          margin-left: 0.6em;
        }
      }
    }
    .first-back-btn svg {
      opacity: 0.5;
      width: 0.75em;
      margin-right: 0.5em;
    }
  }
`;

const CloseBtn = styled.button`
  background: transparent;
  border: none;
  outline: none;
  float: right;
  width: 2em;
  height: 2em;
  text-align: center;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
`;

const MenuBtn = styled.button`
  background: transparent;
  border: none;
  outline: none;
  width: 4em;
  min-width: 4em;
  height: 4em;
  text-align: center;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  display: none;
  svg {
    font-size: 2em;
  }
  @media (max-width: ${theme.menuBreakpoint}) {
    display: inline-block;
  }
`;

const SideMenu = ({ customerCode, sessionGuid }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const { logout } = useAuth();

  const handleSidebarToggle = (isOpen) => {
    if (isOpen) {
      document.body.classList.add("no-overflow");
    } else {
      document.body.classList.remove("no-overflow");
    }
    setIsOpen(isOpen);
  };

  const handleClick = ({ onClick, url }) => {
    if (onClick !== undefined) {
      onClick();
    } else {
      navigate(url);
    }
  };

  const menuConfig = [
    {
      content: menuItems
        .filter(
          ({ url }) =>
            !(!config?.uiSettings?.showCalendar && url === "/calendar") &&
            !(!config?.uiSettings?.toolsMenu && url === "/tools")
        )
        .map(({ title, url, icon, children }) => {
          if (children !== undefined) {
            return {
              name: t(
                `menu${config?.clientCode === VOLVO ? "-Volvo" : ""}.${title}`
              ),
              id: `${url}-${title}`,
              icon: icon,
              children: [
                {
                  content: children.map(
                    ({ title, url, icon, urlParams = () => "" }) => {
                      return {
                        name: (
                          <>
                            <span>{title}</span>
                            {isExternalLink(url) ? <BsBoxArrowUpRight /> : null}
                          </>
                        ),
                        url: `${url}${urlParams({
                          customerCode,
                          sessionGuid,
                          url,
                        })}`,
                        icon,
                      };
                    }
                  ),
                },
              ],
            };
          } else {
            return {
              name: t(
                `menu${config?.clientCode === VOLVO ? "-Volvo" : ""}.${title}`
              ),
              id: `${url}-${title}`,
              url: url,
              icon: icon,
            };
          }
        }),
    },
  ];

  const menuConfigWithUser = [
    ...menuConfig[0].content,
    {
      id: "logout",
      name: t("ui.Log-out"),
      icon: icons.logout,
      onClick: logout,
    },
  ];

  return (
    <React.Fragment>
      <MenuWrapper isOpen={isOpen}>
        <MultilevelSidebar
          open={isOpen}
          onToggle={handleSidebarToggle}
          options={[{ content: [...menuConfigWithUser] }]}
          header={
            <CloseBtn
              onClick={() => handleSidebarToggle(false)}
              className="close-icon"
            >
              {icons.close}
            </CloseBtn>
          }
          onItemClick={handleClick}
        />
      </MenuWrapper>
      <MenuBtn onClick={() => handleSidebarToggle(true)}>{icons.menu}</MenuBtn>
    </React.Fragment>
  );
};

export default SideMenu;
