const addTime = (date) => {
  if (!date) {
    return;
  }
  const dateObj = new Date(date);
  const dateWithTime = dateObj.setHours(12);
  const dateWithTimeObj = new Date(dateWithTime);
  const isoString = dateWithTimeObj.toISOString();
  const noMS = isoString.replace(".000Z", "");
  return noMS;
};

export default addTime;
