import React, { useState } from "react";
import Card from "../Card";
import Layout from "../parts/Layout";
import { useTranslation } from "react-i18next";
import Loader from "../Loader";
import Error from "../Error";
import icons from "../../icons";
import ContactText from "../ContactText";
import OrderList from "../OrderList";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useProgram from "../../utils/useProgram";
import encodeParams from "../../utils/encodeParams";
import Button from "../Button";
import Link from "../Link";
import WizardButtons from "../OrderWizard/WizardButtons";
import callOrder from "../../utils/callOrder";
import DOMPurify from "dompurify";
import Badge from "../Badge";
import { useToast } from "@zendeskgarden/react-notifications";
import Notification from "../Notification";
import strToColor from "../../utils/strToColor";
import Breadcrumbs from "../parts/Breadcrumbs";
import useOrders from "../../utils/useOrders";
import domPurifyWithHooks from "../../utils/adjustLinks";
import callParticipations from "../../utils/callParticipations";
import useParticipations from "../../utils/useParticipations";
import { IoBriefcaseSharp, IoCartSharp, IoMegaphone } from "react-icons/io5";
import { useAuth } from "../../context/AuthContext";
import { FiLogIn } from "react-icons/fi";
import { Link as RouterLink } from "react-router-dom";
import apiConfig from "../../apiConfig";
import {
  VOLVO,
} from "../../constants";
import config from "../../config";

const CampaignDetail = () => {
  const { campaignId } = useParams();
  const { t } = useTranslation("common");
  const titleCampaigns = config.clientCode === VOLVO ? t("menu-Volvo.Campaigns") : t("menu.Campaigns");
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { month, year } = {
    month: searchParams.get("month"),
    year: searchParams.get("year"),
  };
  const { addToast } = useToast();
  const [disabled, setDisabled] = useState(false);
  const { logout } = useAuth();

  // Data
  const {
    data: campaign,
    isLoading: campaignIsLoading,
    isError: campaignIsError,
  } = useProgram(campaignId);

  const {
    data: displayOrdersWithHeaders,
    isLoading: displayOrdersIsLoading,
    isError: displayOrdersIsError,
  } = useOrders({ pageSize: 5 });
  const displayOrders = !!displayOrdersWithHeaders
    ? displayOrdersWithHeaders.data
    : [];

  const {
    data: existingOrdersWithHeaders,
    isLoading: existingOrdersIsLoading,
    isError: existingOrdersIsError,
  } = useOrders({
    extraParams: `programId=${campaignId}&state=open&state=new&state=pending`,
  });
  const existingOrders = !!existingOrdersWithHeaders
    ? existingOrdersWithHeaders.data
    : [];

  const customerId = localStorage.getItem("customerId");
  const { data: participations, isLoading: participationsIsLoading } =
    useParticipations({
      params: {
        programId: campaignId,
        customerId: customerId,
      },
    });

  const bannerImgUrl =
    !!campaign &&
    (!!campaign.bigSliderFileId ||
      !!campaign.smallSliderFileId ||
      !!campaign.demoFileId)
      ? apiConfig.images +
        "/" +
        (!!campaign.bigSliderFileId
          ? campaign.bigSliderFileId
          : !!campaign.smallSliderFileId
          ? campaign.smallSliderFileId
          : !!campaign.demoFileId
          ? campaign.demoFileId
          : null) +
        encodeParams({
          Width: 715,
          Height: 300,
          ResizeMode: "Cover",
        })
      : null;

  const isOrderable =
    !!campaign &&
    (campaign.alwaysOrderable ||
      campaign.dropDateSchedules.some((item) =>
        !!item.orderDeadline ? new Date(item.orderDeadline) > new Date() : true
      ));

  // Methods
  const handleCreateOrder = (id) => {
    setDisabled(true);
    callParticipations({
      shouldPost: !participations?.length,
      id: id,
      onSuccess: () => null,
      onError: (error) => {
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Something-went-wrong")}
              text={t("error.Failed-to-activate-campaign")}
              errorText={error}
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
      onFinished: () => {
        callOrder({
          id: null,
          url: null,
          method: "POST",
          data: { programId: id },
          dataWithCustomerId: true,
          onSuccess: (res) => {
            setDisabled(false);
            navigate(
              `/order/${res.id}${
                !!month && !!year ? `?year=${year}&month=${month}` : ""
              }`
            );
          },
          onError: (error) => {
            setDisabled(false);
            addToast(
              ({ close }) => (
                <Notification
                  title={t("error.Something-went-wrong")}
                  text={t("error.Failed-to-create-order")}
                  errorText={
                    !!error.title
                      ? error.title
                      : typeof error === "string" && !!error
                      ? error
                      : t("error.Unexpected-error-occurred")
                  }
                  type="error"
                  handleClose={close}
                />
              ),
              { placement: "bottom" }
            );
          },
        });
      },
    });
  };

  domPurifyWithHooks(DOMPurify);

  return (
    <Layout
      main={
        <Card
          title={
            (!!campaign && campaign.marketingName) ||
            (!!campaign && campaign.name) ||
            "..."
          }
          shadow
          icon={icons.campaigns}
          headerRight={
            !!campaign && !!campaign.active ? (
              <Badge success style={{ fontSize: "1.2em" }}>
                {t("order.active")}
              </Badge>
            ) : null
          }
        >
          {campaignIsLoading ? (
            <Loader />
          ) : campaignIsError ? (
            <Error
              errorMessageUnauthorized={t("error.Campaign-unavailable")}
              actionsUnauthorized={
                <p>
                  <Button
                    as={Link}
                    $link
                    to="/campaigns"
                    className="icon-link"
                    style={{ margin: "0 .6em" }}
                  >
                    {icons.chevronLeft} {t("ui.All-campaigns")}
                  </Button>{" "}
                  <Button
                    as={RouterLink}
                    $link
                    to="/select-customer"
                    className="icon-link"
                    style={{ margin: "0 .6em" }}
                  >
                    <IoBriefcaseSharp style={{ opacity: ".4" }} />{" "}
                    {t("ui.Select-location")}
                  </Button>{" "}
                  <Button
                    link
                    onClick={logout}
                    className="icon-link"
                    style={{ margin: "0 .6em" }}
                  >
                    {t("error.Log-in-with-another-account")}{" "}
                    <FiLogIn style={{ marginLeft: ".4em" }} />
                  </Button>
                </p>
              }
              errorObj={campaignIsError}
              icon={<IoMegaphone className="center-icon fadeInGrow" />}
            />
          ) : (
            <>
              <Breadcrumbs
                pageTitle={
                  (!!campaign && campaign.marketingName) ||
                  (!!campaign && campaign.name) ||
                  "..."
                }
                pathArray={[{ title: titleCampaigns, url: "/campaigns" }]}
              />
              {!!bannerImgUrl && (
                <div className="margin-bottom">
                  <img
                    src={bannerImgUrl}
                    alt={
                      campaign.marketingName ||
                      campaign.name ||
                      "Campaign banner"
                    }
                  />
                </div>
              )}
              {!!campaign.theme && (
                <p>
                  <Badge background={strToColor(campaign.theme, 60, 90)}>
                    {campaign.theme}
                  </Badge>
                </p>
              )}
              {!!campaign.description && (
                <div
                  dangerouslySetInnerHTML={
                    !!campaign &&
                    !!campaign.description && {
                      __html: DOMPurify.sanitize(campaign.description, {
                        USE_PROFILES: { html: true },
                      }),
                    }
                  }
                />
              )}
              <WizardButtons>
                <Button $link as={Link} to="/campaigns">
                  {icons.chevronLeft} <span>{t("ui.Back-to-overview")}</span>
                </Button>
                {!existingOrdersIsLoading &&
                !existingOrdersIsError &&
                !!existingOrders ? (
                  !!existingOrders.length ? (
                    <Button
                      $primary
                      as={Link}
                      to={`/order/${existingOrders[0].id}`}
                      disabled={
                        campaignIsLoading || participationsIsLoading || disabled
                      }
                    >
                      <span>{t("order.Resume-order")}</span>{" "}
                      {icons.chevronRight}
                    </Button>
                  ) : (
                    <Button
                      $primary
                      onClick={() => handleCreateOrder(campaign.id)}
                      disabled={
                        !isOrderable ||
                        campaignIsLoading ||
                        participationsIsLoading ||
                        disabled
                      }
                    >
                      <span>{t("order.New-order")}</span> {icons.chevronRight}
                    </Button>
                  )
                ) : null}
              </WizardButtons>
            </>
          )}
        </Card>
      }
      sidebar={
        <React.Fragment>
          <Card
            title={t("ui.My-orders")}
            children={
              displayOrdersIsLoading ? (
                <Loader />
              ) : displayOrdersIsError ? (
                <Error errorObj={displayOrdersIsError} icon={<IoCartSharp />} />
              ) : (
                <OrderList orders={displayOrders} />
              )
            }
            icon={icons.orders}
            shadow
            withBg
          />
          <Card
            title={t("ui.Contact")}
            children={<ContactText />}
            icon={icons.contact}
            shadow
          />
        </React.Fragment>
      }
      pageTitle={
        (!!campaign && campaign.marketingName) ||
        (!!campaign && campaign.name) ||
        "..."
      }
    />
  );
};

export default CampaignDetail;
