import useSWR from "swr";
import fetcher from "./fetcher";
import apiConfig from "../apiConfig";

const { reporting } = apiConfig;

const useView = ({
  reportId,
  viewId,
  revalidateOnMount = true,
  shouldFetch = () => true,
}) => {
  const cacheKey = `${reporting}/views/${reportId}/${viewId}`;
  const { data, error, mutate, isValidating } = useSWR(
    shouldFetch() ? cacheKey : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      revalidateOnMount: revalidateOnMount,
    }
  );
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    cacheKey,
    mutate,
    isValidating,
  };
};

export default useView;
